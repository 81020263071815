import {
  GET_USER_TIPPLY,
  GET_USER_FOOD,
  CHECK_ALLOWANCE,
  WINNING_MODAL_STATE,
} from "../types";

const initialState = {
  tipplies: [],
  food: [],
  isUnlock: false,
  winning_modal_state: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_TIPPLY:
      return {
        ...state,
        tipplies: action.payload,
      };
    case GET_USER_FOOD:
      return {
        ...state,
        food: action.payload,
      };
    case WINNING_MODAL_STATE:
      return {
        ...state,
        winning_modal_state: action.payload,
      };
    // case CHECK_ALLOWANCE:
    //   return {
    //     ...state,
    //     isUnlock: action.payload,
    //   };
    default:
      return state;
  }
}
