import Web3 from "web3";
import axios from "axios";
import { ROUTES } from "../Utils/routes";
// let web3 = new Web3(
//   "https://rinkeby.infura.io/v3/c89f216154d84b83bb9344a7d0a91108"
// );
let Address = require("./Address");

// import {ERC20_ABI, DYT_ADDRESS,ENJ_ADDRESS ,CENTRAL_WALLET} from './Address'

//withdraw ETH from central wallet
export const withdrawETH = async (
  provider,
  amount,
  fromAccount,
  checkUser,
  setPageLoaderState,
  setPageLoaderMessage
) => {
  try {
    let web3 = new Web3(provider);
    let amountWei = web3.utils.toWei(amount.toString());
    const signedTransaction = await axios.post(ROUTES.SIGN_TRANSACTION, {
      address: fromAccount,
      amount: amount,
      currency: 0,
    });

    const { deadline, r, s, v } = signedTransaction.data;
    console.log("Testing API", deadline, r, s, v);

    let contract = new web3.eth.Contract(
      Address.CENTRAL_WALLET_ABI,
      Address.CENTRAL_WALLET
    );

    await contract.methods
      .withdrawAmount(amountWei, 0, v, r, s, deadline, fromAccount)
      .send({
        from: fromAccount,
      })

      .then(async (result) => {
        setTimeout(() => {
          setPageLoaderState(false);
          checkUser(fromAccount);
          setPageLoaderMessage("");
        }, 10000);
      })
      .catch((error) => {
        setPageLoaderState(false);
        setPageLoaderMessage("");
      });
  } catch (Err) {
    console.log(Err);
  }
};

//Withdraw DYT from central wallet
export const withdrawDYT = async (
  provider,
  amount,
  fromAccount,
  checkUser,
  setPageLoaderState,
  setPageLoaderMessage
) => {
  try {
    let web3 = new Web3(provider);
    let amountWei = web3.utils.toWei(amount.toString());

    const signedTransaction = await axios.post(ROUTES.SIGN_TRANSACTION, {
      address: fromAccount,
      amount: amount,
      currency: 1,
    });

    const { deadline, r, s, v } = signedTransaction.data;
    console.log("Testing API", deadline, r, s, v);

    let contract = new web3.eth.Contract(
      Address.CENTRAL_WALLET_ABI,
      Address.CENTRAL_WALLET
    );

    await contract.methods
      .withdrawAmount(amountWei, 1, v, r, s, deadline, fromAccount)
      .send({
        from: fromAccount,
      })
      .then(async (result) => {
        setTimeout(() => {
          setPageLoaderState(false);
          checkUser(fromAccount);
          setPageLoaderMessage("");
        }, 10000);
      })
      .catch((error) => {
        setPageLoaderState(false);
        setPageLoaderMessage("");
      });
  } catch (Err) {
    console.log(Err);
  }
};

//Withdraw ENJ from central wallet
export const withdrawENJ = async (
  provider,
  amount,
  fromAccount,
  checkUser,
  setPageLoaderState,
  setPageLoaderMessage
) => {
  let web3 = new Web3(provider);
  let txhash;
  try {
    let amountWei = web3.utils.toWei(amount.toString());

    const signedTransaction = await axios.post(ROUTES.SIGN_TRANSACTION, {
      address: fromAccount,
      amount: amount,
      currency: 2,
    });

    const { deadline, r, s, v } = signedTransaction.data;
    console.log("Testing API", deadline, r, s, v);

    let contract = new web3.eth.Contract(
      Address.CENTRAL_WALLET_ABI,
      Address.CENTRAL_WALLET
    );

    await contract.methods
      .withdrawAmount(amountWei, 2, v, r, s, deadline, fromAccount)
      .send({
        from: fromAccount,
      })
      .then(async (result) => {
        setTimeout(() => {
          setPageLoaderState(false);
          checkUser(fromAccount);
          setPageLoaderMessage("");
        }, 10000);
      })
      .catch((error) => {
        setPageLoaderState(false);
        setPageLoaderMessage("");
      });
  } catch (Err) {
    console.log(Err);
  }
};

export const getCurrentGasPrices = async () => {
  try {
    let response = await axios.get(
      "https://ethgasstation.info/json/ethgasAPI.json"
    );
    let prices = {
      low: response.data.safeLow / 10,
      medium: response.data.average / 10,
      high: response.data.fast / 10,
    };
    return prices;
  } catch (e) {
    console.log(e);
  }
};
