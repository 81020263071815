import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Modal,
  Input,
} from "@material-ui/core";
import baby from "../../../Assets/baby_tipply.png";
import { getHistory, CheckPin } from "../../../redux/actions/authActions";
import { access_token_null } from "../../../redux/actions/pinAction";
import axios from "axios";
import { ROUTES } from "../../../Utils/routes";

import Snackbar from "../../Common/Snackbar/Snackbar";

import snail from "../../../Assets/snail.png";
import worm from "../../../Assets/worm.png";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// import down from '../../../Assets/down.svg';
// import UP from '../../../Assets/UP.svg';
import CloseIcon from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import "./Food.css";
import { connect } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import dyt from "../../../Assets/dyt.png";
import enj from "../../../Assets/enj.png";
import eth from "../../../Assets/eth.png";

const useStyles = makeStyles((theme) => ({
  inc: {
    margin: "auto 10px",
    height: "25px",
    borderRadius: "15px",
    backgroundColor: theme.palette.secondary.modalbody,
  },

  dropdownclass: {
    display: "flex",
    justifyContent: "space-between",
    width: "400px",
    borderRadius: "5px",
    backgroundColor: theme.palette.button.primary,
    fontSize: "15px",
    color: theme.palette.button.white,
    "@media only screen and (max-width:400px)": {
      fontSize: "11px !important",
    },
  },
  dropdownclass2: {
    display: "flex",
    justifyContent: "space-around",
    width: "400px",
    height: "60px",
    paddingBottom: "10px",
    paddingTop: "10px",
    marginTop: "20px",
    borderRadius: "5px",
    backgroundColor: theme.palette.button.primary,
    fontSize: "15px",
    color: theme.palette.button.white,
    "@media only screen and (max-width:400px)": {
      fontSize: "11px !important",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    margin: "8px",
    width: "120px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  headerClass: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontSize: "20px",
    backgroundColor: theme.palette.secondary.modalbody,
    color: theme.palette.text.primary3,
    "@media only screen and (max-width:420px)": {
      padding: "0 !important",
    },
  },
  SelectText: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    padding: "10px 0px 10px 50px",
    fontSize: "15px",
    color: theme.palette.text.primary3,
    "@media only screen and (max-width:420px)": {
      padding: "30px 0 0 0 !important",
    },
  },
  SelectText2: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    padding: "0 0 30px 0",
    fontSize: "25px",
    color: theme.palette.text.primary3,
    "@media only screen and (max-width:420px)": {
      padding: "0 !important",
    },
  },
  modalStyles: {
    border: "none",
    outline: "none",
    position: "absolute",
    maxWidth: 500,
    backgroundColor: theme.palette.secondary.modalbody,
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: "20px 10px 16px 10px",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: 380,
    margin: "auto",

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 4, 2),
    },
    "@media only screen and (max-width:350px)": {
      padding: "16px !important",
    },
  },

  modalInput: {
    height: "40px",
    width: "100%",
    borderRadius: "4px",
    outline: "none",
    borderRadius: " 4px",
    borderColor: "white !important",
    boxShadow: "0px 2px 10px #00000029",
    border: "none !important",
    padding: "0 15px !important",
    color: theme.palette.secondary.other,
    backgroundColor: "theme.palette.primary.other",
  },
  modalText: {
    fontWeight: "bold",
    color: theme.palette.button.white,
  },
  modalButton: {
    cursor: "pointer",
    width: "120px",
    height: "40px",
    color: "white",
    backgroundColor: theme.palette.button.primary,
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },

  inputdiv: {
    borderRadius: "8px",
    background: theme.palette.primary.main,
    border: "1px solid",
    outline: "none",
    borderColor: theme.palette.primary.borderColor,
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
    height: "35px",
    color: theme.palette.text.primary2,
    fontSize: "15px",
    "@media (min-width:960px) and (max-width:1080px)": {
      width: "153px",
    },
    "@media (min-width:450px) and (max-width:499px)": {
      width: "170px !important",
    },
    "@media (min-width:430px) and (max-width:450px)": {
      width: "160px !important",
    },
    "@media (min-width:408px) and (max-width:430px)": {
      width: "150px !important",
    },
    "@media (min-width:408px) and (max-width:409px)": {
      width: "112px !important",
    },
  },
  input: {
    fontSize: "12px",
    padding: "5px",
    height: "25px",
    borderRadius: "8px",
    background: "inherit",
    "& .MuiInputBase-input": {
      color: `${theme.palette.text.color}!important`,
      fontSize: "15px",
      textAlign: "left",
      paddingLeft: "8px",
    },
  },
  card: {
    margin: "5px",
    textAlign: "center",
    backgroundColor: "inherit",
    border: "0.5px solid",
    borderColor: "#B1B1B1",
  },
  cardHeading: {
    padding: 0,
    margin: 0,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  cardText: {
    color: theme.palette.text.secondary,
    "@media (min-width:408px) and (max-width:470px)": {
      fontSize: "12px !imporant",
    },
  },
  mediaHolder: {
    background: "radial-gradient(#00f,transparent);",
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    margin: 0,
  },
  media: {
    height: "100%",
    width: "100%",
    "@media only screen and (max-width:408px)": {
      width: "50% !important",
    },
  },

  cardButton: {
    textTransform: "none",
    color: "white !important",
    backgroundColor: theme.palette.button.primary,
    height: "27px",
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },
  loaderStyles: {
    color: theme.palette.text.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -8,
  },
}));

const Tipplys = (props) => {
  const foodData = props.food;

  const themeMode = localStorage.getItem("themeMode");
  const [openModal, setOpenModal] = useState(false);

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [buyFoodLoader, setBuyFoodLoader] = useState(false);
  const [count, setCount] = useState({});
  const [buyFoodId, setBuyFoodId] = useState("");

  const [value, setValue] = useState("DYT");
  const [ccurrency, setCurrency] = useState("");

  const [ethPrice, setEthPrice] = useState(0);
  const [dytPrice, setDytPrice] = useState(0);
  const [enjPrice, setEnjPrice] = useState(0);
  const [foodName, setFoodName] = useState("");
  const [foodid, setFoodid] = useState("");
  const [inputCount, setInputCount] = useState(0);
  const [foodPrice, setFoodPrice] = useState("");
  const [image, setImage] = useState("");

  const [marketplacePrice, setMarketplacePrice] = useState(0);

  const usdToETH = async (price, id) => {
    let response = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD"
    );
    if (count[id]) {
      let Token = price / response.data.ethereum.usd;
      let token = Token * count[id];
      if (token < 0.01) {
        setEthPrice(token.toFixed(5));
      } else {
        setEthPrice(token.toFixed(3));
      }
      // console.log(ethPrice, "eth price", Token, "Token", token, "token")
    }
  };

  const usdToDYT = async (price, id) => {
    let response = await axios.get(
      "https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0x740623d2c797b7D8D1EcB98e9b4Afcf99Ec31E14&vs_currencies=USD"
    );
    if (count[id]) {
      let Token =
        price / response.data["0x740623d2c797b7d8d1ecb98e9b4afcf99ec31e14"].usd;
      let token = Token * count[id];
      setDytPrice(token.toFixed(3));
      // console.log(marketplacePrice / response.data[0x740623d2c797b7d8d1ecb98e9b4afcf99ec31e14].usd, "marketprice");
      // console.log(Token, "token");
      // console.log((marketplacePrice / response.data[0x740623d2c797b7d8d1ecb98e9b4afcf99ec31e14].usd) + count[id], "original dyt coversion");
    }
  };

  const usdToENJ = async (price, id) => {
    let response = await axios.get(
      "https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c&vs_currencies=USD"
    );
    if (count[id]) {
      let Token =
        price / response.data["0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c"].usd;
      let token = Token * count[id];
      setEnjPrice(token.toFixed(3));
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setInputCount("");
    setCount({
      ...count,
      [foodid]: "",
    });
  };

  const handleModalOpen = (id) => {
    setOpenModal(true);
  };
  const onSumbitForm = async (food) => {
    if (value !== " " || foodName === "Pellets" || foodName === "Flakes") {
      if (count[foodid] === 0) {
        setSnackbarState(true);
        setSnackbarMessage(
          "Less than 1 Food cant be bought, please increase your count."
        );
      } else {
        let data;
        try {
          setBuyFoodLoader(buyFoodId);
          if (foodName === "Pellets" || foodName === "Flakes") {
            data = {
              userID: props.user._id,
              foodID: buyFoodId,
              currency: "DYT", //currency value
              count: Number(count[buyFoodId]),
            };
          } else {
            data = {
              userID: props.user._id,
              foodID: buyFoodId,
              currency: value, //currency value
              count: Number(count[buyFoodId]),
            };
          }
          let response = await axios.post(ROUTES.BUY_FOOD, data);
          if (response.data.status === 200) {
            setSnackbarState(true);
            setSnackbarMessage("Food bought");
          } else {
            setSnackbarState(true);
            setSnackbarMessage("Something went wrong");
          }
        } catch (e) {
          // console.log(e.response, "food marketplace reponse");
          if (e.response.data.statusCode === 401) {
            // console.log(e.response, "reached inside");
            setBuyFoodLoader("");
            await props.getHistory("/fishtank");
            // console.log("in fishtankkkkkkkkkkk");
            // console.log("access_token_null called in marketplace food");
            props.access_token_null();
            setSnackbarState(true);
            setSnackbarMessage(
              "Your password has been exipred, please enter your password again."
            );
          }
          setBuyFoodLoader("");
          setSnackbarState(true);
          setSnackbarMessage("Not enough balance");
        }

        setCount({
          ...count,
          [buyFoodId]: "",
        });
        props.checkUser(props.user.address);
        setBuyFoodLoader("");
        setValue(" ");
        handleModalClose(true);
      }
    } else {
      setSnackbarState(true);
      setSnackbarMessage("Please select one of the currency to buy the food.");
    }
  };

  const classes = useStyles();

  const countChangeHandler = (e) => {
    setInputCount(e.target.value);
    setCount({
      ...count,
      [e.target.id]: e.target.value,
    });
  };

  const upHandler = (id) => {
    if (count[id] == undefined || count[id] == NaN) {
      setCount({
        ...count,
        [id]: 1,
      });
    } else {
      setCount({
        ...count,
        [id]: Number(count[id]) + 1,
      });
    }
  };

  const downHandler = (id) => {
    if (count[id] > 1) {
      setCount({
        ...count,
        [id]: count[id] - 1,
      });
    }
  };

  const buyClickedHandler = async (price, foodId, name, image) => {
    setFoodid(foodId);
    // console.log(count[foodId], foodId, "buy handler");

    if (count[foodId] <= 0) {
      setSnackbarState(true);
      setSnackbarMessage("Please enter a valid value which is above 0.");
    } else {
      setFoodName(name);
      setFoodPrice(price);
      setFoodid(foodId);

      if (count[foodId] <= 0) {
        setSnackbarState(true);
        setSnackbarMessage(
          "Please enter the amount greater then 0 to buy this food."
        );
      } else {
        setImage(image);
        handleModalOpen(foodid);
        usdToDYT(foodPrice, foodid);
        usdToETH(foodPrice, foodid);
        usdToENJ(foodPrice, foodid);
        setBuyFoodId(foodId);
        setMarketplacePrice(price);
      }
    }
  };

  // useEffect(() => {
  //     setCount({
  //         ...count,
  //         [foodid]: 1,
  //     });
  // }, [foodid]);

  useEffect(() => {
    usdToDYT(foodPrice, foodid);
    usdToENJ(foodPrice, foodid);
    usdToETH(foodPrice, foodid);
  }, [count[foodid]]);

  return (
    <Grid container>
      <Snackbar
        open={snackbarState}
        message={snackbarMessage}
        handleClose={() => setSnackbarState(false)}
      />
      <Modal open={openModal} onClose={handleModalClose}>
        <div className={classes.modalStyles}>
          <div className={classes.headerClass}>
            <div></div>
            <CloseIcon onClick={handleModalClose} />
          </div>

          <div className={classes.SelectText2}>
            <div>Buy The Food</div>
          </div>

          <div className={classes.SelectText}>
            <div>Select Token</div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={classes.dropdownclass}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  margin: "auto 10px",
                }}
              >
                {value === "ETH"
                  ? Number(props.walletBalance.ETH_amount).toFixed(5)
                  : value === "DYT"
                  ? Number(props.walletBalance.DYT_amount).toFixed(3)
                  : value === "ENJ"
                  ? Number(props.walletBalance.ENJ_amount).toFixed(3)
                  : "0.000"}
              </div>
              <FormControl className={classes.formControl}>
                <Select
                  value={value}
                  onChange={handleChange}
                  defaultValue={value}
                >
                  <div id="DYT" value="DYT" style={{ display: "flex" }}>
                    {" "}
                    <img
                      src={dyt}
                      style={{ height: "22px", width: "auto", margin: "auto" }}
                    />
                    <option
                      style={{ cursor: "pointer", margin: "auto" }}
                      id="DYT"
                      value="DYT"
                    >
                      {" "}
                      DYT
                    </option>
                  </div>
                  <div id="ETH" value="ETH" style={{ display: "flex" }}>
                    {" "}
                    <img
                      src={eth}
                      style={{ height: "25px", width: "auto", margin: "auto" }}
                    />{" "}
                    <option
                      style={{ cursor: "pointer", margin: "auto" }}
                      id="ETH"
                      value="ETH"
                    >
                      ETH{" "}
                    </option>
                  </div>
                  <div id="ENJ" value="ENJ" style={{ display: "flex" }}>
                    <img
                      src={enj}
                      style={{ height: "25px", width: "auto", margin: "auto" }}
                    />{" "}
                    <option
                      style={{ cursor: "pointer", margin: "auto" }}
                      id="ENJ"
                      value="ENJ"
                    >
                      {" "}
                      ENJ
                    </option>
                  </div>
                </Select>
              </FormControl>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <div className={classes.dropdownclass2}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ margin: "auto" }}>
                  <img
                    style={{
                      width: "auto",
                      height: "35px",
                      margin: "auto 5px",
                    }}
                    src={image !== "" ? image : snail}
                  />
                </div>{" "}
                <div style={{ margin: "auto" }}>
                  {foodName === "" ? "Food" : foodName}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "auto 0px",
                }}
              >
                <div className={classes.inc}>
                  <RemoveIcon
                    onClick={() => {
                      downHandler(foodid);
                    }}
                    style={{
                      margin: "3px auto",
                      fill: "#8e8e8e",
                      width: "25px",
                      height: "18px",
                    }}
                  />
                </div>
                <div style={{ margin: "auto" }}>{count[foodid]}</div>
                <div className={classes.inc}>
                  <AddIcon
                    onClick={() => {
                      upHandler(foodid);
                    }}
                    style={{
                      margin: "3px auto",
                      fill: "#8e8e8e",
                      width: "25px",
                      height: "18px",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "auto 2px",
                }}
              >
                <div style={{ margin: "auto 5px" }}>
                  {value === "" ? "Select Token" : value}
                </div>

                <div>
                  {count[foodid] === 0
                    ? "0.00"
                    : value === "ETH"
                    ? ethPrice
                    : value === "DYT"
                    ? dytPrice
                    : value === "ENJ"
                    ? enjPrice
                    : "0.000"}
                </div>
              </div>
            </div>
          </div>

          <div style={{ textAlign: "center", marginTop: "38px" }}>
            <Button
              onClick={() => onSumbitForm(foodName)}
              className={classes.modalButton}
            >
              {buyFoodLoader === buyFoodId ? (
                <CircularProgress size={16} className={classes.loaderStyles} />
              ) : (
                "Checkout"
              )}
            </Button>
          </div>
        </div>
      </Modal>
      {foodData.map((food) => {
        return (
          <Grid item xs={12} sm={6}>
            <Card className={classes.card}>
              <Grid justify="space-around" container>
                <Grid item xs={6} sm={4}>
                  <img
                    className={classes.media}
                    src={food.image == " " ? worm : food.image}
                  />
                </Grid>
              </Grid>

              <p className={classes.cardHeading}>{food.name}</p>

              <CardContent style={{ padding: 0 }}>
                <Typography
                  className={classes.cardText}
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  <p style={{ margin: "6px 0px" }}>
                    +{food.energy} Energy unit
                  </p>
                  <p style={{ margin: "6px 0px" }}>
                    Max {food.maxhour} per {food.maxTime} min can be fed
                  </p>
                  <p style={{ margin: "6px 0px" }}>{food.price} $USD/each </p>
                </Typography>
                <div className={classes.inputdiv}>
                  <TextField
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 7);
                    }}
                    min={0}
                    // inputProps={{ maxLength: 7 }}
                    id={food._id}
                    onChange={countChangeHandler}
                    className={classes.input}
                    value={count[food._id]}
                    style={{ width: "150px" }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <KeyboardArrowUpIcon
                      onClick={() => {
                        upHandler(food._id);
                      }}
                      style={{ fill: "#8E8E8E", width: "30px", height: "18px" }}
                    />
                    <KeyboardArrowDownIcon
                      onClick={() => {
                        downHandler(food._id);
                      }}
                      style={{ fill: "#8E8E8E", width: "30px", height: "18px" }}
                    />
                  </div>
                </div>
              </CardContent>
              <CardActions>
                <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                  <Button
                    disabled={buyFoodLoader}
                    onClick={(price) =>
                      buyClickedHandler(
                        food.price,
                        food._id,
                        food.name,
                        food.image
                      )
                    }
                    className={classes.cardButton}
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  >
                    Buy
                  </Button>
                </div>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  walletBalance: state.layout.user,
});

export default connect(mapStateToProps, { getHistory, access_token_null })(
  Tipplys
);
