import { useState, useEffect } from "react";
import React from "react";
import "./RandomTipply.css";
import "./CardTipply.css";
import "./Loader.css";

import Body1 from "../../../Assets/tipply_parts5/casual/body";
import Body2 from "../../../Assets/tipply_parts5/nerdyweirdo/body";
import Body3 from "../../../Assets/tipply_parts5/sporty/body";
import Body4 from "../../../Assets/tipply_parts5/aggroevil/body";
import Body5 from "../../../Assets/tipply_parts5/cutysexy/body";

import Tail1 from "../../../Assets/tipply_parts5/casual/tail";
import Tail2 from "../../../Assets/tipply_parts5/nerdyweirdo/tail";
import Tail3 from "../../../Assets/tipply_parts5/sporty/tail";
import Tail4 from "../../../Assets/tipply_parts5/aggroevil/tail";
import Tail5 from "../../../Assets/tipply_parts5/cutysexy/tail";

import Spikes1 from "../../../Assets/tipply_parts5/casual/spikes";
import Spikes2 from "../../../Assets/tipply_parts5/nerdyweirdo/spikes";
import Spikes3 from "../../../Assets/tipply_parts5/sporty/spikes";
import Spikes4 from "../../../Assets/tipply_parts5/aggroevil/spikes";
import Spikes5 from "../../../Assets/tipply_parts5/cutysexy/spikes";

import Top1 from "../../../Assets/tipply_parts5/casual/top";
import Top2 from "../../../Assets/tipply_parts5/nerdyweirdo/top";
import Top3 from "../../../Assets/tipply_parts5/sporty/top";
import Top4 from "../../../Assets/tipply_parts5/aggroevil/top";
import Top5 from "../../../Assets/tipply_parts5/cutysexy/top";

import Fins1 from "../../../Assets/tipply_parts5/casual/fins";
import Fins2 from "../../../Assets/tipply_parts5/nerdyweirdo/fins";
import Fins3 from "../../../Assets/tipply_parts5/sporty/fins";
import Fins4 from "../../../Assets/tipply_parts5/aggroevil/fins";
import Fins5 from "../../../Assets/tipply_parts5/cutysexy/fins";

import Eyes1 from "../../../Assets/tipply_parts5/casual/eye";
import Eyes2 from "../../../Assets/tipply_parts5/nerdyweirdo/eyes";
import Eyes3 from "../../../Assets/tipply_parts5/sporty/eyes";
import Eyes4 from "../../../Assets/tipply_parts5/aggroevil/eyes";
import Eyes5 from "../../../Assets/tipply_parts5/cutysexy/eyes";

import { ReactComponent as Mouth1 } from "../../../Assets/tipply_parts5/casual/mouth.svg";
import { ReactComponent as Mouth2 } from "../../../Assets/tipply_parts5/nerdyweirdo/mouth.svg";
import { ReactComponent as Mouth3 } from "../../../Assets/tipply_parts5/sporty/mouth.svg";
import { ReactComponent as Mouth4 } from "../../../Assets/tipply_parts5/aggroevil/mouth.svg";
import { ReactComponent as Mouth5 } from "../../../Assets/tipply_parts5/cutysexy/mouth.svg";

import { TestFish } from "../TipplyFunctions/testFish";
import { TestFins } from "../TipplyFunctions/testFins";
import { TestBody } from "../TipplyFunctions/testBody";
import { TestTail } from "../TipplyFunctions/testTail";
import { TestTop } from "../TipplyFunctions/testTop";
import { TestSpikes } from "../TipplyFunctions/testSpikes";
import { TestEyes } from "../TipplyFunctions/testEyes";

// import { Fins } from "../TipplyFunctions/TipplyFunctions";
// import { Body } from "../TipplyFunctions/TipplyFunctions";
// import { Tail } from "../TipplyFunctions/TipplyFunctions";
// import { Top } from "../TipplyFunctions/TipplyFunctions";
// import { Spikes } from "../TipplyFunctions/TipplyFunctions";
// import { Eyes } from "../TipplyFunctions/TipplyFunctions";
import { ReactComponent as DeadEyes } from "../../../Assets/deadEyes.svg";

import { ReactComponent as Test } from "../../../Assets/svgBackground.svg";
import styled, { keyframes } from "styled-components";

import "./RandomTipply.css";
import "./CardTipply.css";
import "./Loader.css";
import "./Screenshot.css";
import "./screenshot1000.css";

var colors = ["#0066CC", "#55800D"];

const fishAnimation = keyframes`
 0% {
  transform: rotateX(0deg);
  transform-origin: center;
}
 25% {
  transform: rotateX(15deg);
  transform-origin: center;
}
50% {
  transform: rotateX(0deg);
  transform-origin: center;
}
 75% {
  transform: rotateX(-15deg);
  transform-origin: center;
}
100%{
  transform: rotateX(0deg);
  transform-origin: center;
}
`;

const finAnimation = keyframes`
 0% {
  transform: skewY(0deg);
  transform-origin: center;
}
50% {
  transform: skewY(20deg);
  transform-origin: center;
}
100%{
  transform: skewY(0deg);
  transform-origin: center;
}
`;

const tailAnimation = keyframes` 
  0% {
  transform: rotateY(0deg);
  transform-origin: center;
}
25%{
 transform: rotateY(30deg);
  transform-origin: center;
}
50% {
  transform: rotateY(0deg);
  transform-origin: center;
}
75%{
 transform: rotateY(-30deg);
  transform-origin: center;
}
100%{
  transform: rotateY(0deg);
  transform-origin: center;
}
`;

const eyeRightAnimation = keyframes` 
  0% {
  transform: rotateY(0deg);
  transform-origin: center;
}
25%{
 transform: rotateY(40deg);
  transform-origin: center;
}
50% {
  transform: rotateY(0deg);
  transform-origin: center;
}
75%{
 transform: rotateY(-40deg);
  transform-origin: center;
}
100%{
  transform: rotateY(0deg);
  transform-origin: center;
}
`;

const AnimatedFins = (props) => {
  const Container = styled(props.FinSVG)`
    position: absolute;
    width: 7vh;
    left: 8vh;
    top: 16vh;
    .finAnimate {
      animation: ${finAnimation} infinite 1s linear;
    }
    .casualFins-1 {
      fill: ${colors[props.colorType]};
    }
    .casualFins-2 {
      fill: ${colors[props.colorType]};
    }
    .casualFins-3 {
      fill: ${colors[props.colorType]};
    }
    .casualFins-4 {
      fill: ${colors[props.colorType]};
    }
  `;
  return <Container />;
};

const AnimatedTail = (props) => {
  const Container = styled(props.TailSVG)`
    position: absolute;
    width: 8vh;
    top: 6vh;
    left: 0vh;
    .tailAnimate {
      animation: ${tailAnimation} infinite 2s linear;
    }
  `;

  return <Container />;
};

// const AnimatedEye = styled((props) => props.EyeSVG)`
//    {
//     .eyeRightAnimate {
//       animation: ${eyeRightAnimation} infinite 2s linear;
//     }
//   }
// `;

export default function RandomTipply(props) {
  let body = [Body1, Body2, Body3, Body4, Body5];
  let tail = [Tail1, Tail2, Tail3, Tail4, Tail5];
  let spikes = [Spikes1, Spikes2, Spikes3, Spikes4, Spikes5];
  let top = [Top1, Top2, Top3, Top4, Top5];
  let fins = [Fins1, Fins2, Fins3, Fins4, Fins5];
  let eyes = [Eyes1, Eyes2, Eyes3, Eyes4, Eyes5];
  let mouth = [Mouth1, Mouth2, Mouth3, Mouth4, Mouth5];

  const [fish, setFish] = useState([
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ]);

  useEffect(() => {
    setFish(props.image);
  }, [props.image]);

  let TailSVG = tail[fish[0][0]];
  let TopSVG = top[fish[1][0]];
  let BodySVG = body[fish[2][0]];
  let SpikeSVG = spikes[fish[3][0]];
  let FinSVG = fins[fish[4][0]];
  let EyeSVG = eyes[fish[5][0]];
  let MouthSVG = mouth[fish[6][0]];

  return props.type === "card" ? (
    <div className="fishCard">
      <TestTail
        SVG={(_props) => TailSVG(`${props.id}-0`, _props)}
        type={props.type}
        featureType={fish[0][0]}
        colorType={fish[0][1]}
        tipply_type={props.tipply_type}
      />

      <TestTop
        SVG={(_props) => TopSVG(`${props.id}-1`, _props)}
        type={props.type}
        featureType={fish[1][0]}
        colorType={fish[1][1]}
        tipply_type={props.tipply_type}
      />

      <TestBody
        SVG={(_props) => BodySVG(`${props.id}-2`, _props)}
        type={props.type}
        featureType={fish[2][0]}
        colorType={fish[2][1]}
        tipply_type={props.tipply_type}
      />

      <TestFins
        SVG={(_props) => FinSVG(`${props.id}-4`, _props)}
        type={props.type}
        width={7}
        animate={false}
        featureType={fish[4][0]}
        colorType={fish[4][1]}
        tipply_type={props.tipply_type}
      />

      <TestEyes
        SVG={(_props) => EyeSVG(`${props.id}-5`, _props)}
        type={props.type}
        animate={true}
        featureType={fish[5][0]}
        colorType={fish[5][1]}
        tipply_type={props.tipply_type}
      />
      <TestSpikes
        SVG={(_props) => SpikeSVG(`${props.id}-3`, _props)}
        type={props.type}
        featureType={fish[3][0]}
        colorType={fish[3][1]}
        tipply_type={props.tipply_type}
      />
      <MouthSVG
        className={
          fish[6][0] === 0
            ? "mouthCardC"
            : fish[6][0] === 1
            ? "mouthCardN"
            : fish[6][0] === 2
            ? "mouthCardS"
            : fish[6][0] === 3
            ? "mouthCardA"
            : fish[6][0] === 4
            ? "mouthCardCS"
            : ""
        }
      />
    </div>
  ) : props.type === "loader" ? (
    <div className="fishLoader">
      <TestTail
        SVG={(_props) => TailSVG(`${props.id}-0`, _props)}
        type={props.type}
        featureType={fish[0][0]}
        colorType={fish[0][1]}
        tipply_type={props.tipply_type}
        tipply_type={props.tipply_type}
      />
      <TestTop
        SVG={(_props) => TopSVG(`${props.id}-1`, _props)}
        type={props.type}
        featureType={fish[1][0]}
        colorType={fish[1][1]}
        tipply_type={props.tipply_type}
        tipply_type={props.tipply_type}
      />

      <TestBody
        SVG={(_props) => BodySVG(`${props.id}-2`, _props)}
        type={props.type}
        featureType={fish[2][0]}
        colorType={fish[2][1]}
        tipply_type={props.tipply_type}
        tipply_type={props.tipply_type}
      />

      <TestFins
        SVG={(_props) => FinSVG(`${props.id}-4`, _props)}
        type={props.type}
        width={7}
        animate={false}
        featureType={fish[4][0]}
        colorType={fish[4][1]}
        tipply_type={props.tipply_type}
        tipply_type={props.tipply_type}
      />

      <TestEyes
        SVG={(_props) => EyeSVG(`${props.id}-5`, _props)}
        type={props.type}
        animate={true}
        featureType={fish[5][0]}
        colorType={fish[5][1]}
        tipply_type={props.tipply_type}
        tipply_type={props.tipply_type}
      />

      <TestSpikes
        SVG={(_props) => SpikeSVG(`${props.id}-3`, _props)}
        type={props.type}
        featureType={fish[3][0]}
        colorType={fish[3][1]}
        tipply_type={props.tipply_type}
        tipply_type={props.tipply_type}
      />

      <MouthSVG
        className={
          fish[6][0] === 0
            ? "mouthLoaderC"
            : fish[6][0] === 1
            ? "mouthLoaderN"
            : fish[6][0] === 2
            ? "mouthLoaderS"
            : fish[6][0] === 3
            ? "mouthLoaderA"
            : fish[6][0] === 4
            ? "mouthLoaderCS"
            : ""
        }
      />
    </div>
  ) : props.type === "screenshot" ? (
    <div className={props.realSS ? "fishScreenshot1000" : "fishScreenshot"}>
      <TestTail
        SVG={(_props) => TailSVG(`${props.id}-0`, _props)}
        type={props.type}
        featureType={fish[0][0]}
        colorType={fish[0][1]}
        tipply_type={props.tipply_type}
        realSS={props.realSS}
        realSS={props.realSS}
      />

      <TestTop
        SVG={(_props) => TopSVG(`${props.id}-1`, _props)}
        type={props.type}
        featureType={fish[1][0]}
        colorType={fish[1][1]}
        tipply_type={props.tipply_type}
        realSS={props.realSS}
      />

      <TestBody
        SVG={(_props) => BodySVG(`${props.id}-2`, _props)}
        type={props.type}
        featureType={fish[2][0]}
        colorType={fish[2][1]}
        tipply_type={props.tipply_type}
        realSS={props.realSS}
      />

      <TestFins
        SVG={(_props) => FinSVG(`${props.id}-4`, _props)}
        type={props.type}
        width={7}
        animate={false}
        featureType={fish[4][0]}
        colorType={fish[4][1]}
        tipply_type={props.tipply_type}
        realSS={props.realSS}
      />

      <TestEyes
        SVG={(_props) => EyeSVG(`${props.id}-5`, _props)}
        type={props.type}
        animate={true}
        featureType={fish[5][0]}
        colorType={fish[5][1]}
        tipply_type={props.tipply_type}
        realSS={props.realSS}
      />

      <TestSpikes
        SVG={(_props) => SpikeSVG(`${props.id}-3`, _props)}
        type={props.type}
        featureType={fish[3][0]}
        colorType={fish[3][1]}
        tipply_type={props.tipply_type}
        realSS={props.realSS}
      />

      <MouthSVG
        className={
          fish[6][0] === 0 && !props.realSS
            ? "mouthScreenshotC"
            : fish[6][0] === 1 && !props.realSS
            ? "mouthScreenshotN"
            : fish[6][0] === 2 && !props.realSS
            ? "mouthScreenshotS"
            : fish[6][0] === 3 && !props.realSS
            ? "mouthScreenshotA"
            : fish[6][0] === 4 && !props.realSS
            ? "mouthScreenshotCS"
            : fish[6][0] === 0 && props.realSS
            ? "mouthScreenshot1000C"
            : fish[6][0] === 1 && props.realSS
            ? "mouthScreenshot1000N"
            : fish[6][0] === 2 && props.realSS
            ? "mouthScreenshot1000S"
            : fish[6][0] === 3 && props.realSS
            ? "mouthScreenshot1000A"
            : fish[6][0] === 4 && props.realSS
            ? "mouthScreenshot1000CS"
            : ""
        }
      />
    </div>
  ) : props.type === "fishTank" ? (
    <TestFish isDead={props.isDead} animationSpeech={props.animationSpeech}>
      <TestTail
        SVG={(_props) => TailSVG(`${props.id}-0`, _props)}
        type={props.type}
        featureType={fish[0][0]}
        colorType={fish[0][1]}
        animate={props.isDead ? false : true}
        tipply_type={props.tipply_type}
      />
      <TestTop
        SVG={(_props) => TopSVG(`${props.id}-1`, _props)}
        type={props.type}
        featureType={fish[1][0]}
        colorType={fish[1][1]}
        tipply_type={props.tipply_type}
      />
      <TestBody
        SVG={(_props) => BodySVG(`${props.id}-2`, _props)}
        type={props.type}
        featureType={fish[2][0]}
        colorType={fish[2][1]}
        tipply_type={props.tipply_type}
      />

      <TestFins
        SVG={(_props) => FinSVG(`${props.id}-4`, _props)}
        type={props.type}
        animate={props.isDead ? false : true}
        featureType={fish[4][0]}
        colorType={fish[4][1]}
        tipply_type={props.tipply_type}
        // animate
      />
      {props.isDead === false || props.isDead === undefined ? (
        <TestEyes
          SVG={(_props) => EyeSVG(`${props.id}-5`, _props)}
          type={props.type}
          animate={true}
          featureType={fish[5][0]}
          colorType={fish[5][1]}
          tipply_type={props.tipply_type}
        />
      ) : (
        <DeadEyes className="isDead" />
      )}
      <TestSpikes
        SVG={(_props) => SpikeSVG(`${props.id}-3`, _props)}
        type={props.type}
        featureType={fish[3][0]}
        colorType={fish[3][1]}
        tipply_type={props.tipply_type}
      />
      <MouthSVG
        className={
          fish[6][0] === 0
            ? "mouthC"
            : fish[6][0] === 1
            ? "mouthN"
            : fish[6][0] === 2
            ? "mouthS"
            : fish[6][0] === 3
            ? "mouthA"
            : fish[6][0] === 4
            ? "mouthCS"
            : ""
        }
      />
    </TestFish>
  ) : null;
}
