import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

import * as serviceWorker from "./serviceWorker";

function getLibrary(provider) {
  // console.log("provider ===>>>", provider);
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
}
// const userInput = prompt("Please enter Tipply App Main network password");
// if (userInput == "tipplyrecovery22") {
  ReactDOM.render(
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </PersistGate>
      </Provider>
    </Web3ReactProvider>,
    document.getElementById("root")
  );
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
