import { combineReducers } from "redux";
import authReducer from "./authReducer";
import layoutReducer from "./layoutReducer";
import marketplaceReducer from "./marketplaceReducer";
import fishtankReducer from "./fishtankReducer";
import animationReducer from "./animationReducer";
import walletReducer from "./walletReducer";
import pinReducer from "./pinReducer";

export default combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  fishtank: fishtankReducer,
  animation: animationReducer,
  marketplace: marketplaceReducer,
  walletReducer: walletReducer,
  pin: pinReducer,
});
