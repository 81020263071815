import React from "react";
export default (dynamicId, props) => (
    <svg id="top" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" viewBox="0 0 200.1 127" {...props}>
        <defs>
            <linearGradient id={dynamicId} x1="553.69" y1="-244.07" x2="476.39" y2="-186.05"
                gradientTransform="translate(0 704)" gradientUnits="userSpaceOnUse">
                <stop class="startTopColor" offset="0" />
                <stop class="stopTopColor" offset="1" />
            </linearGradient>
        </defs>
        <title>top</title>
        <path id="top-inner"
            d="M411.4,477.8l1.5,54.5,45-19.1c-10.5-16.1-25.9-27.9-46.5-35.4m113.2,29.7,75.8,6.3q-29.7-61-68.9-84.3l-6.9,78m-66.7,5.7,66.8-5.8q-25.5-40.65-66.8-60.1v65.9Z"
            transform="translate(-400.4 -409.8)" fill={`url(#${dynamicId})`} />
        <path id="top-outline"
            d="M408.3,536.8l.1-2.5-.4.1.3,2.4M567,442.4a156.68,156.68,0,0,0-48.2-32.6L515,483.7q-23.85-39.6-68.2-52.9l3.1,62.9q-15.6-20.4-49.5-28.8l7.6,69.5.4-.1,4.5-1.9v-.1l-1.5-54.5c20.6,7.6,36,19.4,46.5,35.5V447.4q41.25,19.5,66.8,60.1l6.9-78.1q39.15,23.25,68.9,84.3c0-.5-.1-1.1-.1-1.6a7.57,7.57,0,0,0-.1-1.5A111.72,111.72,0,0,0,567,442.4Z"
            transform="translate(-400.4 -409.8)" />
    </svg>
);
