import React from "react";
export default (dynamicId, props) => (
  <svg
    id="top"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsxlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 165.03 284.29"
    {...props}
  >
    <defs>
      <linearGradient
        id={dynamicId}
        x1="513.91"
        y1="649.24"
        x2="436.61"
        y2="707.27"
        gradientTransform="translate(0 -200)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" class="startTailColor" />
        <stop offset="1" class="stopTailColor" />
      </linearGradient>
    </defs>
    <title>tailt</title>
    <g className="tailAnimate">
      <path
        id="tail-shadow"
        d="M435.8,351c-9.7,10.4-9.1,24.1,2,41.4s19.9,31.5,26.8,42.9,10.6,19.3,11.1,23.9c.2,3.8.4,7.3.5,10.5s4.7,7.3,14,12.5c-9,1.5-13.3,4.5-12.8,9s.9,10.2,1.3,17c-.1,4.7-2.8,13-8.3,25.1s-11.7,26.9-18.7,44.3-5.9,29.9,3.1,37.8c9.9,8.7,19.2,11.3,28.1,7.9s20.9-13.4,35.9-29.8,29.8-34,44.3-52.8A175.19,175.19,0,0,0,594,478l-.1-2.6h0q-13.35-33.15-33.4-57.5-16.95-20.7-45.8-46.3c-19.2-17.1-35.7-27.1-49.3-30.1s-23.5.2-29.6,9.5m22,60q-29.1-42-11.7-54.9,16-11.85,43.1,9C507.3,379,522,391.6,533.5,403s20.4,21.7,26.9,31.3a211.07,211.07,0,0,1,21.4,40.9l.3.9c-.2.9-.4,1.8-.6,2.6-4,16.7-9.3,31.4-15.8,44-5.3,10.3-13,21.7-23,34.2-10.1,12.8-23.2,27.1-39.5,43.1s-30.2,20.7-41.8,14.1q-18.75-10.8,5.1-55.9,21-39.9,30.2-74.9C487.8,459.9,474.9,435.8,457.8,411Z"
        transform="translate(-428.97 -340.45)"
      />
      <path
        id="tail-inner"
        d="M446.1,356.2q-17.4,12.9,11.7,54.9,25.65,37,38.9,72.3-9.15,35-30.2,74.9-23.85,45.15-5.1,55.9,17.4,9.9,41.8-14.1t39.5-42.9c10-12.5,17.7-23.9,23-34.2,6.5-12.6,11.8-27.3,15.8-44,.2-.9.4-1.8.6-2.6l-.3-.9a205.71,205.71,0,0,0-21.4-40.9c-6.5-9.6-15.5-20-26.9-31.3s-26.2-24-44.3-37.9C471.1,351.3,456.8,348.3,446.1,356.2Z"
        transform="translate(-428.97 -340.45)"
        fill={`url(#${dynamicId})`}
      />
      <path
        id="tail-shadow-2"
        data-name="tail-shadow"
        d="M577,504.7a8.79,8.79,0,0,0-1-.6q-12.45,21.9-38.3,51.8t-44.1,46.6c-12.2,11.2-21.8,14.1-28.8,8.8s-10.6-8-10.9-8.1.1,1.1.9,3.5,2.9,4.8,6.5,7.1a17.88,17.88,0,0,0,10.3,3.3,32,32,0,0,0,10.1-2.1c-.7.1-.6,0,.4-.3a7.65,7.65,0,0,1,2.1-.8,67.09,67.09,0,0,0,13.2-8.8A229.19,229.19,0,0,0,526,576.3q25.5-30.15,51.4-65.4c1.7-2.2,1.6-4.4-.4-6.2m-34.3-92-11.6-11.9L480,446.9l7.8,15.3,54.9-49.5m-21.8-21.4L506,378.5l-45,37.3,10.8,17.7Z"
        transform="translate(-428.97 -340.45)"
        fill="#fff"
        fill-opacity="0.08"
      />
    </g>
  </svg>
);
