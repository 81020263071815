import TestBodySVG from "../../../Assets/tipply_parts5/casual/body";

import styled, { keyframes, css } from "styled-components";
import React from "react";
import "../RandomTipply/RandomTipply.css";
import "../RandomTipply/CardTipply.css";
import "../RandomTipply/Loader.css";
import "../RandomTipply/Screenshot.css";
import "../RandomTipply/screenshot1000.css";

var colorTail = {
  casual: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],
  nerdyweirdo: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],
  sporty: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],
  agroevil: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],
  cutysexy: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],


  //free
  free_casual: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
  free_nerdyweirdo: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
  free_sporty: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
  free_agroevil: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
  free_cutysexy: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
};

const tailAnimation = keyframes` 
  0% {
  transform: rotateY(0deg);
  transform-origin: center;
}

25%{
 transform: rotateY(30deg);
  transform-origin: center;
}


50% {
  transform: rotateY(0deg);
  transform-origin: center;
}

75%{
 transform: rotateY(-30deg);
  transform-origin: center;
}

100%{
  transform: rotateY(0deg);
  transform-origin: center;
}
`;

const TailAnimation = (props) => css`
  ${tailAnimation} infinite 2s linear;
`;

const Container = styled((props) => {
  return <props.svg {...props} />;
})`
  ${(props) => {
    return props.featureType === 0 && props.tipply_type === "paid"
      ? css`
          .tailAnimate {
            animation: ${props.animate
          ? css`
                  ${tailAnimation} infinite 2s linear
                `
          : ""};
          }
          .startTailColor {
            stop-color: ${colorTail.casual[props.colorType][0]};
          }
          .stopTailColor {
            stop-color: ${colorTail.casual[props.colorType][1]};
          }
        `
      : props.featureType === 1 && props.tipply_type === "paid"
        ? css`
          .tailAnimate {
            animation: ${props.animate
            ? css`
                  ${tailAnimation} infinite 2s linear
                `
            : ""};
          }
          .startTailColor {
            stop-color: ${colorTail.nerdyweirdo[props.colorType][0]};
          }
          .stopTailColor {
            stop-color: ${colorTail.nerdyweirdo[props.colorType][1]};
          }
        `
        : props.featureType === 2 && props.tipply_type === "paid"
          ? css`
          .tailAnimate {
            animation: ${props.animate
              ? css`
                  ${tailAnimation} infinite 2s linear
                `
              : ""};
          }
          .startTailColor {
            stop-color: ${colorTail.sporty[props.colorType][0]};
          }
          .stopTailColor {
            stop-color: ${colorTail.sporty[props.colorType][1]};
          }
        `
          : props.featureType === 3 && props.tipply_type === "paid"
            ? css`
          .tailAnimate {
            animation: ${props.animate
                ? css`
                  ${tailAnimation} infinite 2s linear
                `
                : ""};
          }
          .startTailColor {
            stop-color: ${colorTail.agroevil[props.colorType][0]};
          }
          .stopTailColor {
            stop-color: ${colorTail.agroevil[props.colorType][1]};
          }
        `
            : props.featureType === 4 && props.tipply_type === "paid"
              ? css`
          .tailAnimate {
            animation: ${props.animate
                  ? css`
                  ${tailAnimation} infinite 2s linear
                `
                  : ""};
          }
          .startTailColor {
            stop-color: ${colorTail.cutysexy[props.colorType][0]};
          }
          .stopTailColor {
            stop-color: ${colorTail.cutysexy[props.colorType][1]};
          }
        `
              : props.featureType === 0 && props.tipply_type === "Free"
                ? css`
                  .tailAnimate {
                    animation: ${props.animate
                    ? css`
                          ${tailAnimation} infinite 2s linear
                        `
                    : ""};
                  }
                  .startTailColor {
                    stop-color: ${colorTail.free_casual[props.colorType][0]};
                  }
                  .stopTailColor {
                    stop-color: ${colorTail.free_casual[props.colorType][1]};
                  }
                `
                : props.featureType === 1 && props.tipply_type === "Free"
                  ? css`
                  .tailAnimate {
                    animation: ${props.animate
                      ? css`
                          ${tailAnimation} infinite 2s linear
                        `
                      : ""};
                  }
                  .startTailColor {
                    stop-color: ${colorTail.free_nerdyweirdo[props.colorType][0]};
                  }
                  .stopTailColor {
                    stop-color: ${colorTail.free_nerdyweirdo[props.colorType][1]};
                  }
                `
                  : props.featureType === 2 && props.tipply_type === "Free"
                    ? css`
                  .tailAnimate {
                    animation: ${props.animate
                        ? css`
                          ${tailAnimation} infinite 2s linear
                        `
                        : ""};
                  }
                  .startTailColor {
                    stop-color: ${colorTail.free_sporty[props.colorType][0]};
                  }
                  .stopTailColor {
                    stop-color: ${colorTail.free_sporty[props.colorType][1]};
                  }
                `
                    : props.featureType === 3 && props.tipply_type === "Free"
                      ? css`
                  .tailAnimate {
                    animation: ${props.animate
                          ? css`
                          ${tailAnimation} infinite 2s linear
                        `
                          : ""};
                  }
                  .startTailColor {
                    stop-color: ${colorTail.free_agroevil[props.colorType][0]};
                  }
                  .stopTailColor {
                    stop-color: ${colorTail.free_agroevil[props.colorType][1]};
                  }
                `
                      : props.featureType === 4 && props.tipply_type === "Free"
                        ? css`
                  .tailAnimate {
                    animation: ${props.animate
                            ? css`
                          ${tailAnimation} infinite 2s linear
                        `
                            : ""};
                  }
                  .startTailColor {
                    stop-color: ${colorTail.free_cutysexy[props.colorType][0]};
                  }
                  .stopTailColor {
                    stop-color: ${colorTail.free_cutysexy[props.colorType][1]};
                  }
                `
                        : "";
  }}
`;

export const TestTail = ({ SVG, featureType, colorType, type, animate, tipply_type, realSS }) => {
  return (
    <Container
      svg={SVG}
      featureType={featureType}
      colorType={colorType}
      animate={animate}
      tipply_type={tipply_type}
      className={
        type === "fishTank" && featureType === 0
          ? "tailC"
          : type === "fishTank" && featureType === 1
            ? "tailN"
            : type === "fishTank" && featureType === 2
              ? "tailS"
              : type === "fishTank" && featureType === 3
                ? "tailA"
                : type === "fishTank" && featureType === 4
                  ? "tailCS"
                  : type === "card" && featureType === 0
                    ? "tailCardC"
                    : type === "card" && featureType === 1
                      ? "tailCardN"
                      : type === "card" && featureType === 2
                        ? "tailCardS"
                        : type === "card" && featureType === 3
                          ? "tailCardA"
                          : type === "card" && featureType === 4
                            ? "tailCardCS"
                            : type === "loader" && featureType === 0
                              ? "tailLoaderC"
                              : type === "loader" && featureType === 1
                                ? "tailLoaderN"
                                : type === "loader" && featureType === 2
                                  ? "tailLoaderS"
                                  : type === "loader" && featureType === 3
                                    ? "tailLoaderA"
                                    : type === "loader" && featureType === 4
                                      ? "tailLoaderCS"
                                      : featureType === 0 && !realSS
                                        ? "tailScreenshotC"
                                        : featureType === 1 && !realSS
                                          ? "tailScreenshotN"
                                          : featureType === 2 && !realSS
                                            ? "tailScreenshotS"
                                            : featureType === 3 && !realSS
                                              ? "tailScreenshotA"
                                              : featureType === 4 && !realSS
                                                ? "tailScreenshotCS"
                                                : featureType === 0 && realSS
                                                  ? "tailScreenshot1000C"
                                                  : featureType === 1 && realSS
                                                    ? "tailScreenshot1000N"
                                                    : featureType === 2 && realSS
                                                      ? "tailScreenshot1000S"
                                                      : featureType === 3 && realSS
                                                        ? "tailScreenshot1000A"
                                                        : featureType === 4 && realSS
                                                          ? "tailScreenshot1000CS"
                                                          : ""
      }
    />
  );
};
