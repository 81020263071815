import { SET_PIN, ENTER_PIN, AT_NULL, AT_PASS_TYPE } from "../types";
import axios from "axios";
import { ROUTES } from "../../Utils/routes";

export const EnterPinAction = (address, password) => async (dispatch) => {
    let Pin = {
        username: address,
        password: password,
    }
    let response;
    let res;
    try {
        response = await axios.post(
            `${ROUTES.LOGIN}`,
            Pin
        );
        if (response.data) {
            res = response.data.access_token;
        }
    } catch (e) {
        res = "error";
    }
    dispatch({
        type: ENTER_PIN,
        payload: res,
    });
};

export const setPin = (address, pin) => async (dispatch) => {
    let res;
    let Pin = {
        address: address,
        securityPin: pin,
    }
    try {
        res = await axios.post(
            `${ROUTES.SET_SECURITY_PIN}`,
            Pin
        );
        // console.log(res.response.data, "setPin try");
    } catch (e) {
        // console.log(e.response, "setPin catch");
        if (e.response) {
            res = 400;
        } else {
            res = "error";
        }
    }
    dispatch({
        type: SET_PIN,
        payload: res,
    });
};


export const access_token_null = () => {
    return async (dispatch) => {
        dispatch({
            type: AT_NULL,
            payload: null,
        });
    };
};
export const AT_PASS = (value) => {
    return async (dispatch) => {
        dispatch({
            type: AT_PASS_TYPE,
            payload: value,
        });
    };
};