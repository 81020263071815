import { GET_WALLET_BALANCE } from "../types";
import { ERC20_ABI, DYT_ADDRESS, ENJ_ADDRESS } from "../../Contracts/Address";
import Web3 from "web3";

export const getWalletBalance = (provider, address) => async (dispatch) => {
  let web3 = new Web3(provider);
  let walletBalance = { ETH: 0, DYT: 0, ENJ: 0 };

  walletBalance.ETH = web3.utils.fromWei(await web3.eth.getBalance(address));

  let contract_dyt = new web3.eth.Contract(ERC20_ABI, DYT_ADDRESS);
  walletBalance.DYT = web3.utils.fromWei(
    await contract_dyt.methods.balanceOf(address).call()
  );

  let contract_enj = new web3.eth.Contract(ERC20_ABI, ENJ_ADDRESS);
  walletBalance.ENJ = web3.utils.fromWei(
    await contract_enj.methods.balanceOf(address).call()
  );

  dispatch({
    type: GET_WALLET_BALANCE,
    payload: walletBalance,
  });
};
