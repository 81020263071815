import React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { ReactComponent as TipplyIcon } from "../../Assets/Tabs/tipplyIcon.svg";
import { ReactComponent as FoodIcon } from "../../Assets/Tabs/foodIcon.svg";
import { ReactComponent as ClotheIcon } from "../../Assets/Tabs/clotheIcon.svg";
import { ReactComponent as TankIcon } from "../../Assets/Tabs/tankIcon.svg";
import Tank from "../../Assets/tt_bowl.png";

import { Grid, withWidth } from "@material-ui/core";
import SocialIcon from "../../Components/Common/SocialIcons/SocialIcons";

import Tipplys from "../../Components/MarketPlace/Tipplys/Tipplys";
import Food from "../../Components/MarketPlace/Food/Food";
import Statsbar from "../../Components/Common/Statsbar/Statsbar";

import { connect } from "react-redux";
import {
  getMarketTipply,
  getMarketFood,
} from "../../redux/actions/marketplaceActions";
import { checkUser } from "../../redux/actions/layoutActions";
import { getHistory } from "../../redux/actions/authActions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "inherit",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    [theme.breakpoints.down("sm")]: {
      borderRadius: "none",
    },
  },

  tabsDiv: {
    // backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 2px 10px #00000029 !important",
  },
  shadowClass: {
    boxShadow: "0px 2px 10px #00000029 !important",
  },

  tabs: {
    backgroundColor: theme.palette.primary.other,
    color: theme.palette.text.primary,
    height: "calc(100vh - 230px)",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      "& .MuiBox-root": {
        padding: "24px 0px",
      },
      "@media (min-width:350px) and (max-width:408px)": {
        padding: "24px 35px",
      },
    },
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    boxShadow: "0px 2px 10px #00000029 !important",
    margin: "0 0 5px 0",
  },
  tabButton: {
    minWidth: 72,
    padding: 0,
    textTransform: "none",
    "& span": {
      flexDirection: "row",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  tabIcon: {
    margin: 0,
    padding: 8,
    [theme.breakpoints.down("xs")]: {
      height: 14,
    },
  },
  tabIconActive: {
    "& .a": { fill: theme.palette.text.primary },
    margin: 0,
    padding: 8,
    [theme.breakpoints.down("xs")]: {
      height: 14,
    },
  },
  tankImage: {
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  margintop: {
    marginTop: "50px",
  },
}));

export function SimpleTabs(props) {
  useEffect(() => {
    props.getMarketTipply();
    props.getMarketFood();
    props.getHistory("/marketplace");
  }, []);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabClicked = (index) => {
    setActiveTab(index);
  };

  return (
    <Grid className={classes.root} container item>
      <Statsbar></Statsbar>

      <Grid style={{ padding: 16 }} container item xs={12} justify="center">
        <Grid justify="center" direction="column" container item xs={12} md={6}>
          <Grid item>
            <div style={{ textAlign: "center" }}>
              <img className={classes.tankImage} src={Tank} />
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          className={props.width === "sm" ? classes.margintop : ""}
          style={{ boxShadow: "0px 2px 10px #00000029 !important" }}
        >
          <div className={classes.tabsDiv}>
            <AppBar className={classes.tabPanel} position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                className={classes.shadowClass}
                variant="fullWidth"
              >
                <Tab
                  className={classes.tabButton}
                  label="Tipplys"
                  {...a11yProps(0)}
                  onClick={() => tabClicked(0)}
                  icon={
                    <TipplyIcon
                      className={
                        activeTab === 0
                          ? classes.tabIconActive
                          : classes.tabIcon
                      }
                    />
                  }
                />
                <Tab
                  className={classes.tabButton}
                  label="Foods"
                  {...a11yProps(1)}
                  onClick={() => tabClicked(1)}
                  icon={
                    <FoodIcon
                      className={
                        activeTab === 1
                          ? classes.tabIconActive
                          : classes.tabIcon
                      }
                    />
                  }
                />
                <Tab
                  className={classes.tabButton}
                  label="Clothes"
                  {...a11yProps(2)}
                  onClick={() => tabClicked(2)}
                  icon={
                    <ClotheIcon
                      className={
                        activeTab === 2
                          ? classes.tabIconActive
                          : classes.tabIcon
                      }
                    />
                  }
                  onClick={() => tabClicked(2)}
                />
                <Tab
                  className={classes.tabButton}
                  label="Tanks"
                  {...a11yProps(3)}
                  onClick={() => tabClicked(3)}
                  icon={
                    <TankIcon
                      className={
                        activeTab === 3
                          ? classes.tabIconActive
                          : classes.tabIcon
                      }
                    />
                  }
                />
              </Tabs>
            </AppBar>
            <TabPanel className={classes.tabs} value={value} index={0}>
              <Tipplys
                tipplies={props.tipplies}
                user={props.user}
                checkUser={props.checkUser}
              />
            </TabPanel>
            <TabPanel className={classes.tabs} value={value} index={1}>
              <Food
                food={props.food}
                user={props.user}
                checkUser={props.checkUser}
              />
            </TabPanel>
            <TabPanel className={classes.tabs} value={value} index={2}>
              Clothes coming soon
            </TabPanel>
            <TabPanel className={classes.tabs} value={value} index={3}>
              Tanks coming soon
            </TabPanel>
          </div>
        </Grid>
        {props.width === "smc" ||
        props.width === "sm" ||
        props.width === "xs" ? (
          <SocialIcon />
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
}

// const mapDispatchToProps = dispatch => {
//   return {
//     // dispatching plain actions
//     getMarketTipply: () => dispatch(getMarketTipply),

//   }

// }

const mapStateToProps = (state) => ({
  tipplies: state.marketplace.tipplies,
  food: state.marketplace.food,
  user: state.layout.user,
});

export default withWidth()(
  connect(mapStateToProps, {
    getMarketTipply,
    getMarketFood,
    checkUser,
    getHistory,
  })(SimpleTabs)
);
