import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import axios from "axios";
import { ROUTES } from "../Utils/routes";

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: "https://speedy-nodes-nyc.moralis.io/93b5e495700257c1f1635d38/eth/mainnet" },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 12000,
});
