//Layout Actions
export const CHECK_WEB3 = "CHECK_WEB3";
export const CHECK_USER = "CHECK_USER";
export const ADD_USER = "ADD_USER";
export const EMAIL_CHECK = "EMAIL_CHECK";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_USER = "UPDATE_USER";
// export const ADD_QR_LINK = "ADD_QR_LINK";
export const OPEN_QRSCAN = "OPEN_QRSCAN";

//Auth Actions
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const PIN_CHECK = "PIN_CHECK";
export const PIN_NULL = "PIN_NULL";

//pin Actions
export const ENTER_PIN = "ENTER_PIN";
export const SET_PIN = "SET_PIN";
export const AT_NULL = "AT_NULL";
export const AT_PASS_TYPE = "AT_PASS_TYPE";

//Marketplace Actions
export const GET_MARKET_TIPPLY = "GET_MARKET_TIPPLY";
export const GET_MARKET_FOOD = "GET_MARKET_FOOD";

//Fisttank Actions
export const GET_USER_TIPPLY = "GET_USER_TIPPLY";
export const GET_USER_FOOD = "GET_USER_FOOD";
export const ENABLE_FEED_ANIMATION = "ENABLE_FEED_ANIMATION";
export const DISABLE_FEED_ANIMATION = "DISABLE_FEED_ANIMATION";
export const ENABLE_FEED_SPEECH = "ENABLE_FEED_SPEECH";
export const DISABLE_FEED_SPEECH = "DISABLE_FEED_SPEECH";
export const DISABLE_RELOAD = "DISABLE_RELOAD";
export const WINNING_MODAL_STATE = "WINNING_MODAL_STATE";
//Wallet Actions
export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";
export const GET_HISTORY = "GET_HISTORY";
//Minting Actions
// export const CHECK_ALLOWANCE = "CHECK_ALLOWANCE";
