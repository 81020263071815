import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Button,
  CircularProgress,
  Modal,
} from "@material-ui/core";
import { access_token_null } from "../../../redux/actions/pinAction";
import { getHistory } from "../../../redux/actions/authActions";
import dyt from '../../../Assets/dyt.png';
import enj from '../../../Assets/enj.png';
import eth from '../../../Assets/eth.png';

import axios from "axios";
import { ROUTES } from "../../../Utils/routes";

import Snackbar from "../../Common/Snackbar/Snackbar";
import egg from "../../../Assets/egg.png";

import CloseIcon from "@material-ui/icons/Close";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  inc: {
    margin: "auto 10px",
    height: "25px",
    borderRadius: "15px",
    backgroundColor: theme.palette.secondary.modalbody,
  },

  dropdownclass: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '400px',
    borderRadius: '5px',
    backgroundColor: theme.palette.button.primary,
    fontSize: '15px',
    color: 'white',
    "@media only screen and (max-width:400px)": {
      fontSize: '11px !important',
    },
  },
  dropdownclass2: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '400px',
    height: "60px",
    paddingBottom: "10px",
    paddingTop: "10px",
    marginTop: "20px",
    borderRadius: '5px',
    backgroundColor: theme.palette.button.primary,
    fontSize: '15px',
    color: 'white',
    "@media only screen and (max-width:400px)": {
      fontSize: '11px !important',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    margin: '8px',
    width: "120px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  headerClass: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 'bold',
    fontSize: "20px",
    backgroundColor: theme.palette.secondary.modalbody,
    color: theme.palette.text.primary3,
    "@media only screen and (max-width:420px)": {
      padding: '0 !important',
    },
  },
  SelectText: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    padding: "10px 0px 10px 50px",
    fontSize: "15px",
    color: theme.palette.text.primary3,
    "@media only screen and (max-width:420px)": {
      padding: '30px 0 0 0 !important',
    },
  },
  SelectText2: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    padding: "0 0 30px 0",
    fontSize: "25px",
    color: theme.palette.text.primary3,
    "@media only screen and (max-width:420px)": {
      padding: '0 !important',
    },
  },
  modalStyles: {
    border: "none",
    outline: "none",
    position: "absolute",
    maxWidth: 500,
    backgroundColor: theme.palette.secondary.modalbody,
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: '20px 10px 16px 10px',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: 380,
    margin: "auto",

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 4, 2),
    },
    "@media only screen and (max-width:350px)": {
      padding: '16px !important',
    },
  },

  modalInput: {
    height: "40px",
    width: "100%",
    borderRadius: "4px",
    outline: "none",
    borderRadius: " 4px",
    borderColor: "white !important",
    boxShadow: "0px 2px 10px #00000029",
    border: "none !important",
    padding: "0 15px !important",
    color: theme.palette.secondary.other,
    backgroundColor: theme.palette.primary.other,
  },
  modalText: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  modalButton: {
    cursor: "pointer",
    width: "120px",
    height: "40px",
    color: "white",
    backgroundColor: theme.palette.button.primary,
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },

  card: {
    margin: "5px",
    padding: "10px 0",
    textAlign: "center",
    backgroundColor: "inherit",
    border: "0.5px solid",
    borderColor: "#B1B1B1",
    minHeight: "240px",
  },
  cardHeading: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  cardText: {
    color: theme.palette.text.secondary,
    // paddingBottom:'10px !important',
  },
  mediaHolder: {
    background: "radial-gradient(#00f,transparent);",
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    margin: 0,
  },
  media: {
    width: "auto !important",
    height: "100%",
  },
  cardButton: {
    // color: theme.palette.text.primary,
    textTransform: "none",
    color: "white",
    backgroundColor: theme.palette.button.primary,
    height: "27px",
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },

  loaderStyles: {
    color: theme.palette.text.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -8,
  },
}));

const Tipplys = (props) => {
  const classes = useStyles();

  const [buyTipplyLoader, setBuyTipplyLoader] = useState("");

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [tipplyName, setTipplyName] = useState("");
  const [tipplyid, setTipplyid] = useState("");
  const [tipplyPrice, setTipplyPrice] = useState("");
  var tipplyData = props.tipplies;

  const buyClickedHandler = (price, id, name) => {
    setTipplyid(id);
    setCount({
      ...count,
      [id]: 1,
    });

    setTipplyName(name);
    setTipplyid(id);
    setTipplyPrice(price);
    handleModalOpen();
    usdToDYT(price, tipplyid);
    usdToETH(price, tipplyid);
    usdToENJ(price, tipplyid);
  };

  const themeMode = localStorage.getItem("themeMode");
  const [openModal, setOpenModal] = useState(false);

  const [buytipplyLoader, setBuytipplyLoader] = useState(false);
  const [count, setCount] = useState({});

  const [value, setValue] = useState("DYT");
  const one = 1;

  const [ethPrice, setEthPrice] = useState(0);
  const [dytPrice, setDytPrice] = useState(0);
  const [enjPrice, setEnjPrice] = useState(0);

  const usdToETH = async (price, id) => {
    let response = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD"
    );
    if (count[id]) {
      let Token = price / response.data.ethereum.usd;
      let token = Token * count[id];
      setEthPrice(token.toFixed(5));
    }
  };

  const usdToDYT = async (price, id) => {

    let response = await axios.get(
      "https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0x740623d2c797b7D8D1EcB98e9b4Afcf99Ec31E14&vs_currencies=USD"
    );
    if (count[id]) {
      let Token =
        price / response.data["0x740623d2c797b7d8d1ecb98e9b4afcf99ec31e14"].usd;
      let token = Token * count[id]
      setDytPrice(token.toFixed(3));
    }
  };

  const usdToENJ = async (price, id) => {
    let response = await axios.get(
      "https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c&vs_currencies=USD"
    );
    if (count[id]) {
      let Token =
        price / response.data["0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c"].usd;
      let token = Token * count[id]
      setEnjPrice(token.toFixed(3));
    }
  };

  useEffect(() => {
    setCount({
      ...count,
      [tipplyid]: 1,
    });
    usdToDYT();
    usdToENJ();
    usdToETH();
  }, [tipplyid])
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const upHandler = (id) => {
    if (count[id] == undefined || count[id] == NaN) {
      setCount({
        ...count,
        [id]: 1,
      });
    } else {
      setCount({
        ...count,
        [id]: Number(count[id]) + 1,
      });
    }
  };

  const downHandler = (id) => {
    if (count[id] > 1) {
      setCount({
        ...count,
        [id]: count[id] - 1,
      });
    }
  };

  const onSumbitForm = async () => {
    if (value !== " ") {
      if (count[tipplyid] === 0) {
        setSnackbarState(true);
        setSnackbarMessage("Less than 1 tipply cant be bought, please increase your count.")
      }
      else {
        try {
          setBuyTipplyLoader(tipplyid);
          // console.log(count[tipplyid], 'count in submit')
          let data = {
            userID: props.user._id,
            tipplyID: tipplyid,
            currency: value, //currency value
            count: count[tipplyid]
          };

          let response = await axios.post(ROUTES.BUY_TIPPLY, data);

          if (response.data.status === 200) {
            setSnackbarState(true);
            setSnackbarMessage(response.data.msg);
          } else {
            setSnackbarState(true);
            setSnackbarMessage("Something went wrong");
          }

          props.checkUser(props.user.address);
          setBuyTipplyLoader("");
        } catch (e) {
          // console.log(e.response, "response check tipply");
          if (e.response.data.statusCode === 401) {
            await props.getHistory("/fishtank");
            props.access_token_null();
            setSnackbarState(true);
            setSnackbarMessage("Your password has been exipred, please enter your password again.");
            // console.log("access_token_null called in marketplace tipplys");
          }
          setBuyTipplyLoader("");
          setSnackbarState(true);
          setSnackbarMessage(" Not enough balance");
        }

        setValue(" ");
        handleModalClose(true);
      }
    } else {
      setSnackbarState(true);
      setSnackbarMessage("Select one currency to buy the tipplys.");
    }
  };

  useEffect(() => {
    usdToDYT(tipplyPrice, tipplyid);
    usdToENJ(tipplyPrice, tipplyid);
    usdToETH(tipplyPrice, tipplyid);
  }, [count[tipplyid]]);

  return (
    <Grid container>
      <Snackbar
        open={snackbarState}
        message={snackbarMessage}
        handleClose={() => setSnackbarState(false)}
      />

      <Modal open={openModal} onClose={handleModalClose}>
        <div className={classes.modalStyles}>

          <div className={classes.headerClass}>
            <div></div>
            <CloseIcon onClick={handleModalClose} />
          </div>

          <div className={classes.SelectText2}>
            <div>Buy The Tipply</div>
          </div>

          <div className={classes.SelectText}>
            <div>Select Token</div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.dropdownclass}>
              <div style={{ fontWeight: "bold", fontSize: '20px', margin: 'auto 10px' }}>
                {value === "ETH"
                  ? Number(props.walletBalance.ETH_amount).toFixed(5)
                  : value === "DYT"
                    ? Number(props.walletBalance.DYT_amount).toFixed(3)
                    : value === "ENJ"
                      ? Number(props.walletBalance.ENJ_amount).toFixed(3)
                      : "0.000"}
              </div>
              <FormControl className={classes.formControl}>
                <Select
                  value={value}
                  onChange={handleChange}
                  defaultValue={value}
                >
                  <div id="DYT" value="DYT" style={{ display: 'flex' }}> <img src={dyt} style={{ height: '22px', width: 'auto', margin: 'auto' }} /><option style={{ cursor: 'pointer', margin: 'auto' }} id="DYT" value="DYT"> DYT</option></div>
                  <div id="ETH" value="ETH" style={{ display: 'flex' }}> <img src={eth} style={{ height: '25px', width: 'auto', margin: 'auto' }} /> <option style={{ cursor: 'pointer', margin: 'auto' }} id="ETH" value="ETH">ETH </option></div>
                  <div id="ENJ" value="ENJ" style={{ display: 'flex' }}><img src={enj} style={{ height: '25px', width: 'auto', margin: 'auto' }} />  <option style={{ cursor: 'pointer', margin: 'auto' }} id="ENJ" value="ENJ"> ENJ</option></div>
                </Select>
              </FormControl>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>

            <div className={classes.dropdownclass2}>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ margin: 'auto' }}>
                  <img style={{
                    width: 'auto',
                    height: '35px',
                    margin: 'auto 5px'
                  }} src={egg} />
                </div>{" "}
                <div style={{ margin: 'auto' }}>{tipplyName === "" ? "Tipply" : tipplyName}</div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", margin: 'auto 0px' }}>
                <div className={classes.inc}>
                  <RemoveIcon
                    onClick={() => {
                      downHandler(tipplyid);
                    }}
                    style={{ margin: '3px auto', fill: "#8e8e8e", width: "25px", height: "18px" }}
                  />
                </div>
                <div style={{ margin: 'auto' }}>{count[tipplyid]}</div>
                <div className={classes.inc}>
                  <AddIcon
                    onClick={() => {
                      upHandler(tipplyid);
                    }}
                    style={{ margin: '3px auto', fill: "#8e8e8e", width: "25px", height: "18px" }}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', margin: 'auto 2px' }}>
                <div style={{ margin: 'auto 5px' }}>
                  {value === "" ? "Select Token" : value}
                </div>
                <div>
                  {count[tipplyid] === 0
                    ? "0.00"
                    :
                    value === "ETH"
                      ? ethPrice
                      : value === "DYT"
                        ? dytPrice
                        : value === "ENJ"
                          ? enjPrice
                          : "0.000"}
                </div>
              </div>
            </div>
          </div>

          <div style={{ textAlign: "center", marginTop: '36px' }}>
            <Button
              onClick={() => onSumbitForm()}
              className={classes.modalButton}
            >
              {buyTipplyLoader === tipplyid ? (
                <CircularProgress
                  size={16}
                  className={classes.loaderStyles}
                />
              ) : (
                "Checkout"
              )}
            </Button>
          </div>
        </div>

      </Modal>

      {tipplyData.map((tipply) => {
        return (
          <Grid item xs={12} sm={6}>
            <Card className={classes.card}>
              <Grid justify="space-around" container>
                <Grid item xs={6} sm={4}>
                  <img className={classes.media} src={egg} />
                </Grid>
              </Grid>
              <p className={classes.cardHeading}>{tipply.name}</p>
              <CardContent style={{ padding: "0px 16px" }}>
                <Typography
                  className={classes.cardText}
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {tipply.age} Years
                  <br />
                  {tipply.level} Level
                  <br />
                  {tipply.energy}% Energy
                  <br />
                  {tipply.price} $USD
                </Typography>
              </CardContent>
              <CardActions style={{ textAlign: "center" }}>
                <Button
                  disabled={buyTipplyLoader}
                  onClick={() => buyClickedHandler(tipply.price, tipply._id, tipply.name)}
                  className={classes.cardButton}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                >
                  Buy
                </Button>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

const mapStateToProps = (state) => ({

  walletBalance: state.layout.user

});

export default connect(mapStateToProps, { getHistory, access_token_null })(Tipplys);
