import React, { useState, useEffect } from "react";

import RandomTipply from "../RandomTipply/RandomTipply";
import { makeStyles, Button, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "../../Common/Snackbar/Snackbar";

import LegendBackground from "../../../Assets/tipply_background/legend.jpg";
import StandardBackground from "../../../Assets/tipply_background/standard.jpg";
import RareBackground from "../../../Assets/tipply_background/rare.jpg";
import GodlikeBackground from "../../../Assets/tipply_background/godlike.jpg";
import EpicBackground from "../../../Assets/tipply_background/epic.jpg";
import ExtraordinaryBackground from "../../../Assets/tipply_background/extraordinary.jpg";

import f2pLegendBackground from "../../../Assets/f2pbackground/legendary.jpg";
import f2pStandardBackground from "../../../Assets/f2pbackground/standard.jpg";
import f2pRareBackground from "../../../Assets/f2pbackground/rare.jpg";
import f2pGodlikeBackground from "../../../Assets/f2pbackground/godlike.jpg";
import f2pEpicBackground from "../../../Assets/f2pbackground/epic.jpg";
import f2pExtraordinaryBackground from "../../../Assets/f2pbackground/extraordinary.jpg";
import Tipplys from "../Tipplys/Tipplys";

const useStyles = makeStyles((theme) => ({
  congratsText: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    padding: "5px 30px",
    color: theme.palette.secondary.other,
  },
  modalStyles: {
    border: "none",
    outline: "none",
    position: "absolute",
    maxWidth: "650px",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.secondary.modalbody,
    padding: "0px",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "400px",
    margin: "auto",
  },

  modalTextColor: {
    color: theme.palette.secondary.other,
    display: "flex",
    justifyContent: "space-around",
    padding: " 10px 600px 0 10px",
  },
  headerClass: {
    display: "flex",
    justifyContent: "space-between",
    padding: " 10px 0px 10px 15px",
    fontSize: "25px",
    backgroundColor: theme.palette.button.primary,
    color: theme.palette.text.primary,
  },
  bodyclass: {
    backgroundColor: theme.palette.secondary.modalbody,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  //canvas styling
  confirmStyling: {
    margin: "auto",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    paddingBottom: "5px",
    "@media only screen and (max-width:959px)": {
      justifyContent: "center",
    },
  },

  LegendBackground: {
    backgroundImage: `url(${LegendBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 600px)',
    height: "200px",
    width: "200px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  StandardBackground: {
    backgroundImage: `url(${StandardBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: "200px",
    width: "200px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  RareBackground: {
    backgroundImage: `url(${RareBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: "200px",
    width: "200px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  GodlikeBackground: {
    backgroundImage: `url(${GodlikeBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: "200px",
    width: "200px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  EpicBackground: {
    backgroundImage: `url(${EpicBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: "200px",
    width: "200px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  ExtraordinaryBackground: {
    backgroundImage: `url(${ExtraordinaryBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: "200px",
    width: "200px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  //free to play backf=ground classes///

  f2pLegendBackground: {
    backgroundImage: `url(${f2pLegendBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 600px)',
    height: "250px",
    width: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pStandardBackground: {
    backgroundImage: `url(${f2pStandardBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 250px)',
    height: "250px",
    width: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pRareBackground: {
    backgroundImage: `url(${f2pRareBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 250px)',
    height: "250px",
    width: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pGodlikeBackground: {
    backgroundImage: `url(${f2pGodlikeBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 250px)',
    height: "250px",
    width: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pEpicBackground: {
    backgroundImage: `url(${f2pEpicBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 250px)',
    height: "250px",
    width: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pExtraordinaryBackground: {
    backgroundImage: `url(${f2pExtraordinaryBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 250px)',
    height: "250px",
    width: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  //end of f2p backgrounds
  //end of canvas styling
}));

const WinningModal = (props) => {
  const [winnerTipply, setWinnerTipply] = useState({});
  useEffect(() => {
    if (props.winnerTipplys.length == 0) {
    }
    if (props.winnerTipplys.length > 0) {
      setWinnerTipply(props.winnerTipplys[props.winnerTipplys.length - 1]);
    }
  }, [props.winnerTipplys]);

  const classes = useStyles();
  return (
    <Modal open={props.open}>
      {winnerTipply ? (
        <div className={classes.modalStyles}>
          <div className={classes.headerClass}>
            <div>Congratulations!!</div>
            <Button onClick={props.close}>
              <CloseIcon />
            </Button>
          </div>
          <div className={classes.bodyclass}>
            <div className={classes.confirmStyling}>
              <div
                className={
                  winnerTipply.category === "Standard Tipply" &&
                  winnerTipply.type === "paid"
                    ? classes.StandardBackground
                    : winnerTipply.category === "Rare Tipply" &&
                      winnerTipply.type === "paid"
                    ? classes.RareBackground
                    : winnerTipply.category === "Epic Tipply" &&
                      winnerTipply.type === "paid"
                    ? classes.EpicBackground
                    : winnerTipply.category === "Legendary Tipply" &&
                      winnerTipply.type === "paid"
                    ? classes.LegendBackground
                    : winnerTipply.category === "Godlike Tipply" &&
                      winnerTipply.type === "paid"
                    ? classes.GodlikeBackground
                    : winnerTipply.category === "Extraordinary Tipply" &&
                      winnerTipply.type === "paid"
                    ? classes.ExtraordinaryBackground
                    : winnerTipply.category === "F2P Standard" &&
                      winnerTipply.type === "Free"
                    ? classes.f2pStandardBackground
                    : winnerTipply.category === "F2P Rare" &&
                      winnerTipply.type === "Free"
                    ? classes.f2pRareBackground
                    : winnerTipply.category === "F2P Epic" &&
                      winnerTipply.type === "Free"
                    ? classes.f2pEpicBackground
                    : winnerTipply.category === "F2P Legendary" &&
                      winnerTipply.type === "Free"
                    ? classes.f2pLegendBackground
                    : winnerTipply.category === "F2P Godlike" &&
                      winnerTipply.type === "Free"
                    ? classes.f2pGodlikeBackground
                    : winnerTipply.category === "F2P Extraordinary" &&
                      winnerTipply.type === "Free"
                    ? classes.f2pExtraordinaryBackground
                    : ""
                }
              >
                <RandomTipply
                  id={winnerTipply._id}
                  image={winnerTipply.image}
                  type="screenshot"
                  tipply_type={winnerTipply.type}
                  realSS={false}
                />
              </div>
            </div>
            {winnerTipply.type === "paid" ? (
              <div className={classes.congratsText}>
                Hey, congratulations, your Tipply has reached{" "}
                {winnerTipply.level}th level. Your winning reward is{" "}
                {winnerTipply.winningReward} DYT. Check your TT-balance to be
                certain and get another Tipply in-line.
              </div>
            ) : (
              <div className={classes.congratsText}>
                Hey, congratulations, your Tipply has reached{" "}
                {winnerTipply.level}th level. You have won 1 paid egg, 400 Worms
                and 100 Snails.
              </div>
            )}
          </div>
        </div>
      ) : (
        "No tipply"
      )}
    </Modal>
  );
};
export default WinningModal;
