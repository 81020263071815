import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import facebook from "../../../Assets/social/facebook.png";
import linkedin from "../../../Assets/social/linked in.png";
import twitter from "../../../Assets/social/Twitter.png";
import insta from "../../../Assets/social/insta.png";
import withWidth from "@material-ui/core/withWidth";
import facebookDark from "../../../Assets/social/facebookDark.png";
import linkedinDark from "../../../Assets/social/linkedinDark.png";
import twitterDark from "../../../Assets/social/twitterDark.png";
import instaDark from "../../../Assets/social/instaDark.png";
import enj from "../../../Assets/enjSvg.svg";
import whiteEnj from "../../../Assets/EnjWhiteSvg.svg";
import { withRouter } from "react-router-dom";

const SocialIcon = (props) => {
  const useStyles = makeStyles((theme) => ({
    topClass: {
      top: "125px",
      "@media only screen and (max-width:660px)": {
        top: "90px",
      },
    },
    drawer: {
      // background: theme.palette.primary.main,
    },
    drawerItem: {
      textAlign: "center",
      textDecoration: "none",
      padding: 32,
      cursor: "pointer",
      color: theme.palette.text.secondary,
    },
    activeDrawerItem: {
      background: theme.palette.primary.other,
      textAlign: "center",
      textDecoration: "none",
      padding: 32,
      boxShadow: "-18px 2px 8px 10px #00000033",
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      borderRight: "none",
      width: "100.33%",
      zIndex: 1,
      color: theme.palette.secondary.main,
    },
    drawerText: {
      fontWeight: "bold",
    },
    activeDrawerImage: {
      "& svg": {
        fill: theme.palette.secondary.main,
      },
    },

    topbar: {
      // background: theme.palette.primary.main,
    },
    topbarItem: {
      textAlign: "center",
      textDecoration: "none",
      cursor: "pointer",
      color: theme.palette.text.secondary,
      padding: 24,
    },
    topbarItem2: {
      textAlign: "center",
      textDecoration: "none",
      cursor: "pointer",
      color: theme.palette.text.secondary,
      padding: "6px",
      fontSize: "13px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    activeTopbarItem: {
      background: theme.palette.primary.other,
      textAlign: "center",
      textDecoration: "none",
      boxShadow: "0px -22px 40px -14px #00000033",
      color: theme.palette.secondary.main,
      borderBottom: "none",
      padding: "24px",
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      height: "100.33%",
      zIndex: 1,
    },
    activeTopbarItem2: {
      background: theme.palette.primary.other,
      textAlign: "center",
      textDecoration: "none",
      boxShadow: "0px -22px 40px -14px #00000033",
      color: theme.palette.secondary.main,
      borderBottom: "none",
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      height: "100.33%",
      zIndex: 1,
      fontSize: "13px",
      padding: "6px",
    },
  }));

  const themeMode = localStorage.getItem("themeMode");

  const classes = useStyles();
  return (
    <div
      style={{ textAlign: "center", position: "relative" }}
      className={classes.topClass}
    >
      <Link
        href="https://m.facebook.com/doyourtip"
        rel="noreferrer"
        target="_blank"
      >
        <img
          style={{ margin: "15px 5px" }}
          height={18}
          width={18}
          src={themeMode === "light" ? facebook : facebookDark}
        />
      </Link>
      <Link
        href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQFC-CUOtKQMcAAAAXaGBImIWTft6H1F-EPIB8YVXVgHjzIk7yRN796HKfl8VIFzhd7sRm8LMXOWuHGabTKcIHxmE_PDrJnz-76F77i16yNSroiPJRUOaw9dLsBdDDdCb1pmSls=&originalReferer=https://doyourtip.io&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fdoyourtip%2F"
        rel="noreferrer"
        target="_blank"
      >
        <img
          style={{ margin: "15px 5px" }}
          height={18}
          width={18}
          src={themeMode === "light" ? linkedin : linkedinDark}
        />
      </Link>
      <Link
        href="https://twitter.com/DoYourTip"
        rel="noreferrer"
        target="_blank"
      >
        <img
          style={{ margin: "15px 5px" }}
          height={18}
          width={18}
          src={themeMode === "light" ? twitter : twitterDark}
        />
      </Link>
      <Link
        href="https://instagram.com/doyour.tip?igshid=1xel1mp379egc"
        rel="noreferrer"
        target="_blank"
      >
        <img
          style={{ margin: "15px 5px" }}
          height={18}
          width={18}
          src={themeMode === "light" ? insta : instaDark}
        />
      </Link>
      <Link
        href="https://enjin.io/powered-by-enjin/tipply-tank"
        rel="noreferrer"
        target="_blank"
      >
        <img
          style={{ margin: "15px 5px" }}
          height={18}
          width={18}
          src={themeMode === "light" ? enj : whiteEnj}
        />
      </Link>
    </div>
  );
};

export default withRouter(withWidth()(SocialIcon));
