import React from "react";
export default (dynamicId, props) => (
  <svg
    id="eyes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.5 148.3"
    {...props}
  >
    <path
      id="eye-brows"
      d="M153,38c9.6-13.7,24.7-13,45.5,2.1c-9.9-15.1-19.7-23-29.5-23.6C159.1,15.8,153.8,23,153,38 M57,0.1
	C39.6,0.9,20.7,12.7,0.1,35.5c35.5-22.8,69.9-25.9,103-9.1C89.7,8.1,74.3-0.7,57,0.1z"
    />
    <g id="eye-right">
      <g id="eye-right-outline" transform="matrix( 1, 0, 0, 1, 0,0) ">
        <g>
          <g id="Layer12_0_FILL">
            <path
              fill="#030303"
              d="M162.8,42c-3.5,1.2-6.2,3.9-8,8c-0.6,1.4-1.2,3-1.6,4.8c-1.4,6.4-1.6,13.5-0.4,21.3
					c2.3,15.5,7.9,28.6,16.8,39.2c1.1,1.3,2.2,2.5,3.4,3.6c3.3,3.2,6.8,5.5,10.5,6.7c7.6,2.1,13.8-0.2,18.6-6.7
					c0.5-0.6,0.9-1.3,1.4-2c0.8-1.3,1.5-2.7,2.1-4.1c2.7-6.4,3.6-14.2,2.7-23.4c-0.5-4.7-1.4-9.2-2.7-13.5
					c-1.8-6.1-4.4-11.8-7.8-17.1c-2.2-3.4-4.6-6.3-7.2-8.7c-0.1-0.1-0.3-0.2-0.3-0.3C180.8,41.5,171.7,38.9,162.8,42 M160.7,53.5
					c0.3-0.6,0.6-1.2,1-1.7c0.6-0.8,1.2-1.4,1.9-1.8c0.1-0.1,0.2-0.1,0.3-0.2c4.8-2,9.8-1.9,14.9,0.2c4,1.6,8,4.5,12.1,8.6
					c4.5,4.8,7.9,10.5,10.2,17.1c1.2,3.5,2.1,7.3,2.6,11.4c0.4,2.9,0.5,5.7,0.5,8.3c0,0.7,0,1.4,0,2.1c-0.1,1.6-0.3,3.2-0.6,4.7l0,0
					l-0.3,1.2c-0.8,3.3-2.1,6.2-3.8,8.8c-0.3,0.5-0.7,0.9-1,1.4c-2.1,2.7-4.5,4.5-7.1,5.3c-2.3,0.8-4.8,0.8-7.5,0
					c-1.6-0.5-3.3-1.2-5-2.2c-1.6-0.9-3.1-2-4.5-3.4c-0.2-0.2-0.4-0.4-0.6-0.7l-2-2.1v-0.1c-0.1-0.1-0.1-0.1-0.2-0.2l-0.5-0.7
					c-0.2-0.2-0.4-0.4-0.5-0.6l0,0c-6.7-8.8-11-19.4-12.8-31.9C156.4,67.4,157.4,59.5,160.7,53.5z"
            />
            <path
              fill="#DFDFDF"
              d="M204.1,97.5c0-0.7,0-1.4,0-2.1c-0.1,0.6-0.3,1.3-0.4,2c-0.8,3.4-0.9,5-0.2,4.8v-0.1
					C203.8,100.6,204,99.1,204.1,97.5 M171.1,109.5l0.5,0.7c0,0.1,0.1,0.1,0.2,0.2l0.7-0.5c-0.6-0.3-1.2-0.7-1.8-1l0,0
					C170.8,109.1,170.9,109.3,171.1,109.5z"
            />
            <path
              fill="#B7CAE8"
              d="M173.7,112.5c0.2,0.2,0.4,0.5,0.6,0.7c1.4,1.4,2.9,2.6,4.5,3.4c1.7,1,3.4,1.7,5,2.2
					c2.7,0.8,5.2,0.8,7.5,0c2.6-0.8,4.9-2.6,7.1-5.3c0.4-0.5,0.7-0.9,1-1.4c1.7-2.6,2.9-5.5,3.8-8.8l0.3-1.2c0,0.1-0.1,0.2-0.1,0.3
					c-1.1,2.3-2.6,4.4-4.5,6.3c-3.1,3.1-7.1,4.7-12,4.7c-4.4,0-9.3-1.2-14.5-3.5l-0.7,0.5v0.1L173.7,112.5z"
            />
            <path
              fill="#FFFFFF"
              d="M170.6,108.9c0.6,0.3,1.2,0.6,1.8,1c5.2,2.4,10.1,3.5,14.5,3.5c5,0,9-1.6,12-4.7c1.9-1.9,3.3-4,4.5-6.3
					c0-0.1,0.1-0.1,0.1-0.3l0,0v0.1c-0.7,0.2-0.6-1.4,0.2-4.8c0.2-0.7,0.3-1.4,0.4-2c0-2.6-0.1-5.4-0.5-8.3
					c-0.5-4.1-1.4-7.9-2.6-11.4c-10.3-19.6-23.7-27-40.3-22.3c-3.3,6-4.3,13.9-2.9,23.5C159.7,89.5,163.9,100.1,170.6,108.9z"
            />
            <path
              fill="#DADADA"
              d="M161.7,51.8c-0.4,0.5-0.7,1.1-1,1.7c16.6-4.7,30.1,2.7,40.3,22.3c-2.2-6.6-5.6-12.4-10.2-17.1
					c-4.1-4.1-8.2-7-12.1-8.6c-5.1-2.1-10-2.1-14.9-0.2c-0.1,0-0.2,0.1-0.3,0.2C162.9,50.4,162.3,51,161.7,51.8z"
            />
          </g>
        </g>
      </g>
      <g id="eye-right-pupil" transform="matrix( 1, 0, 0, 1, 0,0) ">
        <g>
          <g id="Layer11_0_FILL">
            <path
              class="pupilUpper"
              d="M159.1,82.4h-0.3v0.1L159.1,82.4 M178.3,83.5c-0.1-0.3-0.2-0.6-0.2-0.9h-0.8c0.1,1.1,0.4,2.8,0.8,5.4
					C178.3,86.6,178.4,85.1,178.3,83.5z"
            />
            <path class="pupilUpper" d="M159.1,82.4h-0.3l0,0H159.1z" />
            <path
              class="pupilUpper"
              d="M178.1,82.6c0,0.3,0.1,0.6,0.2,0.9c0-0.3,0-0.6,0-1L178.1,82.6z"
            />
            <path
              class="pupilLower"
              d="M173.2,79.8c0.4,2.2,0.6,4.2,0.5,6.1c-0.1,1.8-0.9,4-2.4,6.6c-1.5,2.5-3.6,3.7-6.2,3.6
					c-0.8-0.1-1.6-0.3-2.3-0.8c1.2,2.9,2.6,5.7,4.2,8.4c3.4-0.1,6.3-2.1,8.5-6.2c1.6-2.8,2.5-5.9,2.8-9.5c-0.4-2.5-0.7-4.3-0.8-5.4
					c0-0.1,0-0.2,0-0.3c-0.1-1-0.4-2.8-1.1-5.5c-0.8-2.6-2.8-4.6-6-5.8C171.7,74.4,172.6,77.3,173.2,79.8z"
            />
            <path
              d="M169.6,74.9c-0.3-0.4-0.6-0.8-0.9-1.1c0.1,0.2,0.1,0.4,0.1,0.6c0.2,1.9,0,3.5-0.6,4.9s-1.5,2.1-2.7,2.2
					c-1.1,0.1-2.1-0.5-3.1-1.8s-1.6-2.8-2-4.6V75c-1.1,2.1-1.6,4.5-1.4,7.5c0.1,1.4,0.4,2.8,0.8,3.9v0.1c0.4,1.2,1,2.4,1.7,3.4
					c1.4,2.1,3,3.1,4.8,3.1s3.2-1,4.4-3.1c1.1-2.1,1.5-4.5,1.3-7.4C171.8,79.4,171,76.9,169.6,74.9z"
            />
            <path
              fill="#FFFFFF"
              d="M168.8,73.7c-0.3-1.6-0.8-3-1.7-4.1c-1-1.2-2.1-1.8-3.3-1.7c-1.2,0.1-2.1,0.9-2.8,2.3s-0.9,2.9-0.5,4.7
					V75c0.3,1.8,1,3.3,2,4.6c1,1.3,2,1.9,3.1,1.8s2-0.8,2.7-2.2c0.7-1.4,0.9-3,0.6-4.9C168.8,74.1,168.8,73.9,168.8,73.7z"
            />
            <path
              class="pupilLower"
              d="M163.8,61.4c-2.7,0-5,1.2-7,3.5c-0.1,3.4,0.1,7.1,0.7,11.1c0.3,2.2,0.7,4.3,1.2,6.4h0.3
					c-0.2-2.9,0.3-5.4,1.4-7.5c-0.4-1.8-0.2-3.3,0.5-4.7c0.7-1.4,1.6-2.2,2.8-2.3c1.2-0.1,2.3,0.5,3.3,1.7c0.8,1.1,1.4,2.5,1.7,4.1
					c0.3,0.3,0.6,0.7,0.9,1.1c1.4,2.1,2.2,4.6,2.4,7.5s-0.3,5.4-1.3,7.4c-1.1,2.1-2.6,3.1-4.4,3.1c-1.8,0-3.4-1-4.8-3.1
					c-0.7-1-1.3-2.2-1.7-3.4v-0.1c-0.4-1.2-0.6-2.5-0.8-3.9l-0.2,0.2c0.3,1.3,0.6,2.6,0.9,3.9l0,0v0.1c0.3,1.3,0.7,2.6,1.1,4
					c0.5,1.5,1.1,3.1,1.9,4.8c0.8,0.5,1.5,0.7,2.3,0.8c2.6,0.1,4.7-1.1,6.2-3.6s2.3-4.7,2.4-6.6c0.1-1.9-0.1-3.9-0.5-6.1
					c-0.6-2.5-1.5-5.4-2.9-8.7c3.2,1.2,5.2,3.2,6,5.8c0.7,2.7,1,4.5,1.1,5.5c0,0.1,0,0.2,0,0.3h0.8l0.2-0.1
					c-0.4-5.8-2-10.8-4.9-14.9C170.6,63.5,167.4,61.4,163.8,61.4z"
            />
          </g>
        </g>
        <g>
          <path
            id="Layer11_0_1_STROKES"
            fill="none"
            stroke="#000000"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="
				M156.9,64.9c1.9-2.3,4.3-3.5,7-3.5c3.5,0,6.7,2.1,9.5,6.2c2.9,4.1,4.5,9.1,4.9,14.9 M178.3,83.5c0,1.6,0,3.1-0.1,4.5
				c-0.3,3.5-1.2,6.7-2.8,9.5c-2.2,4.1-5,6.1-8.5,6.2"
          />
        </g>
      </g>
    </g>
    <g id="eye-left">
      <g id="eye-left-outline">
        <path
          fill-opacity="0.4"
          d="M5.8,80.5c-0.4,10.9,1,20.3,4.2,28.3c3.3,8.3,8.9,16.1,16.8,23.5c7.9,7.3,17.2,12.3,27.9,14.8
			c18.5,3.4,34.7,0,48.6-10.3s21.5-24.3,22.8-42c1.2-17.7-2.1-30.9-10.1-39.6c-8-8.8-14.6-14.4-19.9-16.8s-12.9-4.3-22.7-5.7
			C64.6,31.5,54.5,31.9,43,34c-11.7,2-20.9,7.8-27.4,17.3C9.6,60.2,6.3,69.9,5.8,80.5 M13.1,107.8c-3-7.6-4.4-16.5-3.9-26.7
			c0.4-10,3.5-19.2,9.3-27.6c6.2-8.9,14.8-14.4,25.9-16.3c10.8-1.9,20.4-2.3,28.7-1.2c9.3,1.3,18.1,4.5,26.5,9.8
			c5.1,3.2,9.5,7.2,13,11.9c9.3,12.2,13,25.6,11.3,40.1c-1.8,14.7-8.8,26.6-21.1,35.5c-14,10.2-29.8,13.7-47.3,10.5
			c-10.1-2.4-18.9-7-26.4-13.9C21.5,123,16.2,115.6,13.1,107.8z"
        />
        <path
          fill="#030303"
          d="M9.2,81.1c-0.4,10.2,0.9,19.1,3.9,26.7c3.1,7.8,8.4,15.2,15.9,22.2c7.5,6.9,16.3,11.5,26.4,13.9
			c17.5,3.2,33.2-0.3,47.3-10.5c12.3-9,19.3-20.8,21.1-35.5c1.8-14.5-2-27.9-11.3-40.1c-3.5-4.7-7.9-8.7-13-11.9
			c-8.4-5.2-17.2-8.5-26.5-9.8c-8.3-1.1-17.9-0.7-28.7,1.2c-11.1,1.9-19.7,7.3-25.9,16.3C12.6,61.9,9.6,71.1,9.2,81.1 M16.1,88
			c-0.3-2.1-0.5-4.2-0.6-6.4c-0.5-10.7,2.5-19.7,9-27c1.8-2,3.8-3.8,6.1-5.5c0.1-0.1,0.1-0.1,0.3-0.1l0,0c11-8.1,23.3-11,37-8.7
			c6.1,1,11.9,2.8,17.4,5.2c12.2,5.4,21.6,13.6,28.2,24.8c1.2,2.1,2.3,4.2,3.2,6.3c2.1,5.1,3.2,10.3,3.3,15.7l0,0c0,0.7,0,1.4,0,2.1
			c-0.3,4.5-1.2,8.9-2.7,13c-1.2,3.5-2.8,6.8-4.8,9.9c-4.5,7.3-10.6,12.8-18.3,16.7c-7.5,3.8-15.4,5.6-23.7,5.5
			c-8.1-0.2-15.9-2.1-23.1-5.7c-7.5-3.7-13.7-8.9-18.6-15.6c-0.2-0.3-0.4-0.5-0.6-0.9c-0.4-0.5-0.8-1-1.1-1.5
			c-1.7-2.5-3.1-5-4.5-7.6c-3.1-6.3-5.2-12.9-6.3-19.9v-0.1C16.1,88.1,16.1,88.1,16.1,88z"
        />
        <path
          fill="#DADADA"
          d="M85.2,45.5c-5.5-2.4-11.3-4.1-17.4-5.2c-13.7-2.3-26,0.6-37,8.7v0.1l0,0c-0.1,0-0.2,0.1-0.3,0.1
			c-2.3,1.7-4.3,3.5-6.1,5.5c48.2-18.5,80-5.9,95.4,37.6c0-5.4-1.1-10.6-3.3-15.7c-0.9-2.1-2-4.2-3.2-6.3
			C106.7,59.1,97.3,50.9,85.2,45.5z"
        />
        <path
          fill="#B7CAE8"
          d="M16.2,88.1L16.1,88v0.1v0.1c1.1,7,3.2,13.7,6.3,19.9l5.9,8.9l-1.5-1.3c0.4,0.5,0.8,1,1.1,1.5
			c0.2,0.3,0.4,0.6,0.6,0.9c4.9,6.7,11.1,12,18.6,15.6c7.3,3.6,15,5.5,23.1,5.7c8.3,0.2,16.2-1.6,23.7-5.5
			c7.7-3.9,13.8-9.4,18.3-16.7c2-3.2,3.6-6.5,4.8-9.9l0,0c-2,4.1-3.8,7-5.4,8.7c-1.6,1.6-4.3,3.8-8.1,6.4c-3.9,2.6-10,5.1-18.3,7.6
			c-8,2.5-16.1,3.2-24.5,2.3c-8.3-0.8-15.6-4.2-21.8-10.1c-6.2-5.9-11.3-12.7-15.4-20.3C21.2,97.3,18.7,92.7,16.2,88.1z"
        />
        <path
          fill="#F4F4F4"
          d="M16.1,88l0.1,0.1c2.6,4.5,5.1,9.1,7.5,13.7c4,7.6,9.1,14.4,15.4,20.3s13.5,9.3,21.8,10.1
			c8.3,1,16.5,0.2,24.5-2.3c8.3-2.5,14.4-5,18.3-7.6c3.8-2.6,6.5-4.8,8.1-6.4c1.5-1.7,3.3-4.6,5.4-8.7l0,0c1.4-4.1,2.3-8.5,2.7-13
			c0-0.7,0-1.4,0-2.1l0,0c-15.4-43.5-47.2-56-95.4-37.6c-6.5,7.3-9.5,16.3-9,27C15.6,83.8,15.7,85.9,16.1,88 M59.4,110.1
			c0.4,0,0.9,0,1.3,0l-0.1,0.2C60.3,110.2,59.9,110.2,59.4,110.1 M86.8,87.7l0.1-2.8l0,0V85c0,0.1,0,0.2,0,0.3
			c0.1,0.8,0.1,1.5,0.1,2.3h-0.2V87.7z"
        />
      </g>
      <g id="eye-left-pupil">
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <g>
            <g id="Layer10_0_FILL">
              <path
                class="pupilUpper"
                d="M87,83.8L86.7,84c0.1,0.3,0.2,0.7,0.3,1C87,84.6,87,84.2,87,83.8z"
              />
              <path
                class="pupilUpper"
                d="M45.7,85c-0.2-1.1-0.1-2.5,0.4-4.1c-2.3,3.6-3.8,7-4.4,10.4c-0.7,3.4-1,5.3-0.9,5.9
						c1,1.5,2.1,2.9,3.5,4.3c4.9,4.9,10.8,7.3,17.7,7.3c6.9,0,12.7-2.4,17.6-7.3c3.3-3.3,5.5-7,6.6-11.2c-0.5-3.3-0.8-5.5-0.8-6.6
						c0-1.2-0.6-3.3-1.6-6.5c-1.1-3.1-4.6-5.4-10.5-6.9c2.1,4,3.6,7.4,4.3,10.3c0.6,2.6,0.6,5,0.3,7.2c-0.4,2.2-2.2,4.8-5.5,7.8
						S65,100,59.9,99.9c-5.1-0.2-9.1-3.1-12.1-8.8C46.7,89.2,46.1,87.2,45.7,85z"
              />
              <path
                class="pupilLower"
                d="M62,58.8c-6.9,0-12.8,2.4-17.7,7.3C39.4,71,37,76.9,37,83.8c0,2.8,0.4,5.5,1.3,8
						c0.2,0.8,0.5,1.5,0.8,2.3c0,0.1,0.1,0.2,0.2,0.3c0.2,0.7,0.7,1.6,1.5,2.9c-0.1-0.6,0.2-2.5,0.9-5.9c0.6-3.4,2.1-6.8,4.4-10.4
						c-0.5,1.6-0.6,3-0.4,4.1c0.4,2.2,1,4.2,2,6c3,5.7,7,8.6,12.1,8.8c5.1,0.2,9.2-1.3,12.5-4.3s5.1-5.6,5.5-7.8
						c0.4-2.2,0.3-4.6-0.3-7.2c-0.7-2.9-2.2-6.3-4.3-10.3c6,1.5,9.5,3.8,10.5,6.9s1.6,5.3,1.6,6.5s0.3,3.4,0.8,6.6
						c0.4-1.7,0.7-3.5,0.8-5.3c-0.1-0.3-0.2-0.7-0.3-1l0.3-0.2c0-6.9-2.5-12.8-7.4-17.7C74.7,61.2,68.9,58.8,62,58.8 M50,83.5
						c0-3.5,1.2-6.4,3.6-8.8S59,71,62.5,71s6.4,1.2,8.8,3.7s3.7,5.4,3.7,8.8c0,3.5-1.2,6.4-3.7,8.9c-2.4,2.4-5.4,3.6-8.8,3.6
						c-3.5,0-6.4-1.2-8.9-3.6C51.2,90,50,87,50,83.5z"
              />
              <path
                d="M53.7,74.7c-2.4,2.4-3.6,5.4-3.6,8.8c0,3.5,1.2,6.4,3.6,8.9c2.4,2.4,5.4,3.6,8.9,3.6s6.4-1.2,8.8-3.6s3.7-5.4,3.7-8.9
						s-1.2-6.4-3.7-8.8S66,71,62.6,71C59,71,56.1,72.3,53.7,74.7z"
              />
            </g>
          </g>
          <g>
            <path
              id="Layer10_0_1_STROKES"
              fill="none"
              stroke="#000000"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="
					M40.8,97.1c-0.8-1.2-1.3-2.1-1.5-2.9c-0.1-0.1-0.1-0.1-0.2-0.3c-0.3-0.7-0.6-1.5-0.8-2.3c-0.8-2.5-1.2-5.2-1.3-8
					c0-6.9,2.4-12.8,7.3-17.7c4.9-4.9,10.8-7.3,17.7-7.3c6.9,0,12.7,2.4,17.6,7.3S87,76.7,87,83.6 M87,85c-0.1,1.8-0.4,3.6-0.8,5.3
					c-1.1,4.2-3.3,7.9-6.6,11.2c-4.9,4.9-10.7,7.3-17.6,7.3s-12.8-2.4-17.7-7.3c-1.4-1.4-2.5-2.8-3.5-4.3"
            />
          </g>
        </g>
        <g transform="matrix( 1, 0, 0, 1, 0,0) ">
          <path
            fill="#FFFFFF"
            d="M56.8,80.3c1.7,1.5,3.6,2.2,5.8,2.2c2.2-0.1,4-1,5.4-2.6s2.1-3.5,1.9-5.8c-0.2-2.2-1.1-4.1-2.9-5.6
				c-1.7-1.5-3.7-2.1-6-2c-2.4,0.1-4.3,1-5.8,2.7s-2,3.6-1.6,5.7C54.1,77,55.2,78.8,56.8,80.3z"
          />
        </g>
      </g>
    </g>
  </svg>
);