import React from "react";
export default (dynamicId, props) => (
  <svg
    version="1.1"
    id="fins-below"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enable-background="new 0 0 100 100"
    xmlSpace="preserve"
    {...props}
  >
    <path
      id="fin-outline"
      d="M7.9,68.7c0-0.3,0-0.6,0-0.9h0v0c0,0,0,0,0,0c0-0.9,0-1.8,0.1-2.7c0.4-17.1,2.4-32.7,6-46.7
	C4,32.6,0.5,52.9,3.4,79.2c3.8-1.5,7.4-3.3,10.8-5.4c19.7-12.2,35-28.7,45.7-49.6c-15.8,17.7-33.2,33.6-52,47.9
	C7.9,71,7.9,69.9,7.9,68.7 M62.6,47.5l-5.3-0.1c-0.6,7.4-0.4,15.5,0.6,24.4c3.5-1.3,6.9-3,10.2-5.1c11.3-7,21.1-15.5,29.3-25.5
	c-3.8,1.4-7.3,2.5-10.4,3.2c-7.2,6.7-15.5,13.6-24.9,20.7C62.1,58.7,62.2,52.8,62.6,47.5z"
    />
    <linearGradient
      id={dynamicId}
      gradientUnits="userSpaceOnUse"
      x1="136.887"
      y1="149.3269"
      x2="97.6236"
      y2="119.8539"
      gradientTransform="matrix(1 0 0 -1 0 100)"
    >
      <stop offset="0" class="startFinsColor" />
      <stop offset="1" class="stopFinsColor" />
    </linearGradient>
    <g className="finAnimate">
      <path
        id="fin-large"
        fill={`url(#${dynamicId})`}
        d="M7.9,67.9c0,0.3,0,0.6,0,0.9c0,1.1,0,2.2,0,3.4c18.9-14.3,36.2-30.3,52-47.9
	c-4.3,0.6-7,0.9-8.1,1c-11.5,0.7-24.1-1.6-37.9-6.8c-3.6,14-5.6,29.6-6,46.7C7.9,66,7.9,66.9,7.9,67.9C7.9,67.8,7.9,67.8,7.9,67.9
	L7.9,67.9L7.9,67.9z"
      />
      <path
        id="fin-small"
        class="leftFin"
        d="M87,44.5l-24.3,3c-0.4,5.3-0.6,11.2-0.6,17.7C71.5,58.1,79.8,51.2,87,44.5z"
      />

      <path
        id="fin-upper-stroke"
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M57.3,47.4l5.3,0.1l24.3-3c3.1-0.7,6.6-1.8,10.4-3.2"
      />
    </g>
  </svg>
);
