import React from "react";
export default (dynamicId, props) => (
  <svg
    version="1.1"
    id="tail"
    xmlns="http://www.w3.org/2000/svg"
    xmlnXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enable-background="new 0 0 100 100"
    xmlSpace="preserve"
    {...props}
  >
    <linearGradient
      id={dynamicId}
      gradientUnits="userSpaceOnUse"
      x1="61.4092"
      y1="23.5041"
      x2="32.5093"
      y2="45.1977"
      gradientTransform="matrix(1 0 0 1 0 2)"
    >
      <stop offset="0" class="startTailColor" />
      <stop offset="1" class="stopTailColor" />
    </linearGradient>
    <g className="tailAnimate">
      <path
        id="tail-outline"
        d="M15.4,4.1c5,4.6,9.4,9.9,13.1,15.7c3.2,5.1,5.9,10.5,7.9,16.1c0.6,1.6,1.2,3.2,1.7,4.9
	c0.6,1.9,1.1,3.9,1.5,5.8c0.8,3.6,1.4,7.3,1.7,11c0.3,3.6,0.2,7.2-0.1,10.9c-0.3,3.6-0.9,7.2-1.7,10.8c-0.6,2.6-1.3,5.2-2.2,7.7
	c-1,3-2.3,6.1-3.8,9c3.7-1.6,7.3-3.3,10.9-5.3c4.8-2.7,9.2-5.8,13.4-9.3c2.7-2.3,5.2-4.7,7.5-7.3c0.2-0.2,0.3-0.4,0.5-0.6
	c2.5-2.8,4.7-5.8,6.7-9c1.6-2.6,3.1-5.3,4.4-8c0.6-1.2,1.1-2.4,1.6-3.7c0.7-1.7,1.3-3.3,1.9-5c1.1-3.1,1.9-6.2,2.7-9.4l-0.4-0.6
	c-4.9-7.4-10.4-13.6-16.6-18.6c-5.2-4.2-10.8-7.5-17-9.9c-6.3-2.5-12.9-4.1-19.6-4.9C24.8,3.9,20.1,3.8,15.4,4.1 M32.4,19.9
	C29.1,15,25.3,10.5,21,6.3C26.1,6,31.9,6.7,38.2,8.4s12,4.2,17,7.4s9,6.3,12.1,9.3c4,3.9,7.6,8.4,10.9,13.4l0.4,0.6
	c-0.9,3.8-2.1,7.4-3.5,11c-0.3,0.9-0.7,1.7-1.1,2.6c-0.9,2.1-1.9,4.1-3,6.1C69.3,62,67.3,65,65.1,68c0,0-0.1,0.1-0.1,0.2
	c-2.2,3-4.6,5.8-7.2,8.4c-2.6,2.6-5.4,5.1-8.4,7.3c-2.6,1.9-5.2,3.7-8,5.2c-1.1,0.7-2.3,1.3-3.4,2c0.4-1,0.8-2,1.2-2.9
	c0.2-0.6,0.4-1.1,0.6-1.7l0,0l0,0c0.9-2.7,1.7-5.5,2.4-8.3c0.8-3.9,1.4-7.8,1.7-11.7c0.4-5.2,0.3-10.5-0.3-15.7
	c-0.2-1.7-0.4-3.3-0.7-5c-0.4-2-0.8-3.9-1.4-5.8C39.8,32.8,36.7,26.1,32.4,19.9z"
      />

      <path
        id="tail-inner"
        fill={`url(#${dynamicId})`}
        d="M21,6.3c4.2,4.1,8,8.6,11.4,13.5c4.2,6.2,7.4,12.9,9.3,20.2
	c0.5,1.9,1,3.9,1.4,5.8c0.3,1.7,0.5,3.3,0.7,5c0.6,5.2,0.8,10.4,0.3,15.7c-0.3,3.9-0.9,7.8-1.7,11.7C41.8,81,41,83.8,40,86.5l0,0
	c-0.2,0.6-0.4,1.1-0.6,1.7c-0.4,1-0.8,2-1.2,2.9c1.1-0.6,2.3-1.3,3.4-2c2.8-1.6,5.4-3.3,8-5.2c3-2.2,5.8-4.6,8.4-7.3
	c2.6-2.6,5-5.4,7.2-8.4c0-0.1,0.1-0.1,0.1-0.2c2.2-3,4.1-6,5.9-9.2c1.1-2,2.1-4,3-6.1c0.4-0.8,0.7-1.7,1.1-2.6
	c1.4-3.6,2.6-7.2,3.5-11l-0.4-0.6c-3.3-5-6.9-9.5-10.9-13.4c-3.1-3-7.1-6.1-12.1-9.3s-10.7-5.7-17-7.4C31.9,6.8,26.2,6.1,21,6.3z"
      />
    </g>
  </svg>
);
