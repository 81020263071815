import React from "react";
export default (dynamicId, props) => (
  <svg
    id="tail"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsxlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 131.6 245.5"
    {...props}
  >
    <defs>
      <linearGradient
        id={dynamicId}
        x1="538"
        y1="624.82"
        x2="460.7"
        y2="682.84"
        gradientTransform="translate(0 -200)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" class="startTailColor" />
        <stop offset="1" class="stopTailColor" />
      </linearGradient>
    </defs>
    <title>tailt</title>
    <g className="tailAnimate">
      <path
        id="tail-outline"
        d="M552.2,402.1q-39.3-43.65-97.4-50.1,39.15,73.5,32.7,156.4a212.46,212.46,0,0,1-27.3,89.1q54.75-13.95,89.1-59.3a221.25,221.25,0,0,0,37.1-75,157.34,157.34,0,0,0-34.2-61.1m-86.3-42.5c2.3.5,4.6,1.1,6.9,1.6q51.3,13.05,81.4,56.8l3.6,5.4a165.92,165.92,0,0,1,19,40.7c-1.6,5.5-3.5,11-5.6,16.3a220.75,220.75,0,0,1-38.4,62.4,183.55,183.55,0,0,1-61.7,46.6c3-5.9,5.6-11.8,7.9-17.7a229.78,229.78,0,0,0,15.8-81.5A286.79,286.79,0,0,0,465.9,359.6Z"
        transform="translate(-454.8 -352)"
      />
      <path
        id="tail-inner"
        d="M472.8,361.2c-2.3-.5-4.6-1.1-6.9-1.6a286.79,286.79,0,0,1,28.9,130.6A232,232,0,0,1,479,571.7c-2.3,5.9-4.9,11.8-7.9,17.7a184.54,184.54,0,0,0,61.7-46.6,220.75,220.75,0,0,0,38.4-62.4c2.1-5.3,4-10.7,5.6-16.3a165.92,165.92,0,0,0-19-40.7l-3.6-5.4Q524.05,374.35,472.8,361.2Z"
        transform="translate(-454.8 -352)"
        fill={`url(#${dynamicId}`}
      />
      <g id="tail-shadow">
        <path
          d="M511.52,375.92,487,402.46q2.38,6.54,4.24,13.31l32.08-31.64A115.68,115.68,0,0,0,511.52,375.92Z"
          transform="translate(-454.8 -352)"
          fill="#fff"
          fill-opacity="0.800000011920929"
        />
        <path
          d="M548.17,409.81q-4.87-6.13-10.71-12.4l-41.47,40c.92,5.68,1.64,11.54,2.11,17.46Z"
          transform="translate(-454.8 -352)"
          fill="#fff"
          fill-opacity="0.6000000238418579"
        />
        <path
          d="M576.29,464.62l-.57-1.8q-41,90.7-99.42,115.28h-.08l.07.11c-.69,1.42-1.31,2.95-2,4.37-1.16,2.46-2.41,5-3.64,7.46,2.62-1.35,5.33-2.69,8-4.23a172.6,172.6,0,0,0,19-11.47,169.1,169.1,0,0,0,20.65-16.79,182.66,182.66,0,0,0,18.43-20.13,1.31,1.31,0,0,1,.38-.35,228.11,228.11,0,0,0,15.95-22.7c3-4.92,5.88-10.08,8.64-15.24,1.11-2.17,2.15-4.35,3.27-6.51A271.35,271.35,0,0,0,576.29,464.62Z"
          transform="translate(-454.8 -352)"
          fill="#e1d09d"
        />
      </g>
    </g>
  </svg>
);
