import React, { useState, useEffect } from "react";
import axios from "axios";
import { ROUTES } from "../../../Utils/routes";

import { makeStyles } from "@material-ui/core/styles";

import { Hidden, Grid, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  statsDiv: {
    textAlign: "center",
  },
  statsText: {
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    margin: 0,
  },
  topStatsBar: {
    boxShadow: "0px 2px 10px #00000029",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    padding: "5px 0px",

    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
}));

export default function Statsbar() {
  const [stats, setStats] = useState({});

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    let response = await axios.get(ROUTES.GET_STATS);
    setStats(response.data);
  };

  const classes = useStyles();

  return (
    <Hidden smDown>
      {Object.keys(stats).length !== 0 ? (
        <Grid className={classes.topStatsBar} container item xs={12}>
          <Grid item xs={3}>
            <Tooltip title="Total hatched eggs in the game">
              <div className={classes.statsDiv}>
                <p className={classes.statsText}>Born Tipplys</p>
                <p
                  style={{ color: "green", paddingTop: "8px" }}
                  className={classes.statsText}
                >
                  {stats.born}
                </p>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Total dead tipplys in the game">
              <div className={classes.statsDiv}>
                <p className={classes.statsText}>Dead Tipplys</p>
                <p
                  style={{ color: "red", paddingTop: "8px" }}
                  className={classes.statsText}
                >
                  {stats.dead}
                </p>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Total eggs sold in the game">
              <div className={classes.statsDiv}>
                <p className={classes.statsText}>Market Sales</p>
                <p
                  style={{ color: "#FBBE3F", paddingTop: "8px" }}
                  className={classes.statsText}
                >
                  {stats.sales}
                </p>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Current pool prize of the game">
              <div className={classes.statsDiv}>
                <p className={classes.statsText}>Pool Prize</p>
                <p
                  style={{ color: "#FBBE3F", paddingTop: "8px" }}
                  className={classes.statsText}
                >
                  {Math.round(stats.poolPrize)}
                </p>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        <Grid className={classes.topStatsBar} container item xs={12}>
          <Grid item xs={3}>
            <div className={classes.statsDiv}>
              <p className={classes.statsText}>Born Tipplys</p>
              <p
                style={{ color: "green", paddingTop: "8px" }}
                className={classes.statsText}
              >
                0
              </p>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.statsDiv}>
              <p className={classes.statsText}>Dead Tipplys</p>
              <p
                style={{ color: "red", paddingTop: "8px" }}
                className={classes.statsText}
              >
                0
              </p>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.statsDiv}>
              <p className={classes.statsText}>Market Sales</p>
              <p
                style={{ color: "#FBBE3F", paddingTop: "8px" }}
                className={classes.statsText}
              >
                0
              </p>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.statsDiv}>
              <p className={classes.statsText}>Pool Prize</p>
              <p
                style={{ color: "#FBBE3F", paddingTop: "8px" }}
                className={classes.statsText}
              >
                0
              </p>
            </div>
          </Grid>
        </Grid>
      )}
    </Hidden>
  );
}
