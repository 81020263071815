import { SET_PIN, ENTER_PIN, AT_NULL, AT_PASS_TYPE } from "../types";

const initialState = {
    access_token: null,
    setPinReq: null,
    access_token_saved: false,
    access_token_pass: true,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AT_PASS_TYPE:
            return {
                ...state,
                access_token_pass: action.payload,
            };
        case AT_NULL:
            return {
                ...state,
                access_token: null,
                access_token_saved: false,
                access_token_pass: true,
                setPinReq: null,
            };
        case ENTER_PIN:
            if (action.payload === "error") {
                return {
                    ...state,
                    access_token: action.payload,
                    access_token_saved: false,
                    access_token_pass: true,
                };
            } else {
                return {
                    ...state,
                    access_token: action.payload,
                    access_token_saved: true,
                    access_token_pass: true,
                };
            }
        case SET_PIN:

            return {
                ...state,
                setPinReq: action.payload,
            };
        default:
            return state;
    }
}
