import React from "react";
export default (dynamicId, props) => (
  <svg
    version="1.1"
    id="spikes"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100;"
    xmlSpace="preserve"
    {...props}
  >
    <linearGradient
      id={dynamicId}
      gradientUnits="userSpaceOnUse"
      x1="31.7291"
      y1="36.4805"
      x2="72.8355"
      y2="58.055"
      gradientTransform="matrix(1 0 0 -1 0 102)"
    >
      <stop class="startSpikesColor" offset="0" />
      <stop class="stopSpikesColor" offset="1" />
    </linearGradient>
    <path
      id="spikes-inner"
      fill={`url(#${dynamicId})`}
      d="M83,95.8l-3.6-7L77.2,90L83,95.8 M22,84.3l-0.1,0.1l-0.1,0.1c-1.3,1.8-2.9,3.6-4.5,5.4
	c2.4-1.3,4.7-2.6,6.7-4L22,84.3z M91,78.6l-1.6,2l7.4,4.3L91,78.6z M22.9,72.4l-4.1-4.2L8.7,77.7L22.9,72.4 M16.5,59.5l-1.7-5.3
	c-4,1-8,2.2-11.9,3.7C8.1,58.8,12.6,59.3,16.5,59.5 M15.8,42.2l2.9-5L4.5,34.5L15.8,42.2 M35.4,50.9c-0.9-1.2-1.1-3.1-0.8-5.6
	c-4.8,1.8-9.2,3.9-13.1,6.4C26.8,51,31.4,50.8,35.4,50.9 M15.4,16.9l9.1,10.3c0.9-0.9,2.1-2,3.5-3.4C24.6,21.2,20.4,18.9,15.4,16.9
	 M69.1,22.2c0.1-5.1,1-10.2,2.8-15.5c-3.5,4.5-6,8.9-7.7,13.1C65.6,19.8,67.3,20.6,69.1,22.2 M40.9,5.3L42.7,20
	c1.2-0.7,3.1-1.2,5.5-1.3L40.9,5.3z M75.7,20.8l1.7,1.2c0.9-1.8,2-3.5,3.1-5.3C78.7,18.1,77.1,19.5,75.7,20.8z"
    />
    <path
      id="spikes-outline"
      d="M89.3,80.6l-0.8,0.9l10.1,5.2l-6.9-9L91,78.6l5.7,6.3L89.3,80.6 M80.3,88.3l-0.9,0.5l3.6,7L77.2,90
	l-1.1,0.6l8.3,7.5L80.3,88.3 M25.1,86.8L24,86c-2,1.4-4.3,2.7-6.7,4c1.7-1.7,3.2-3.5,4.5-5.4l0.1-0.1c0,0,0-0.1,0.1-0.1l-1-0.8
	c-1.7,2.9-3.6,5.6-5.8,8.2C18.7,90.3,22,88.7,25.1,86.8 M74.6,20.1l1,0.8c1.5-1.4,3.1-2.7,4.8-4.1c-1.2,1.7-2.2,3.5-3.1,5.3l1,0.7
	c1.1-2.8,2.4-5.5,3.9-8.1C79.5,16.4,77,18.1,74.6,20.1 M27.9,23.8c1.2-0.6,1.6-1.2,1.4-1.8c-4.5-2.7-10.1-5.1-16.7-7.3l10,13.8
	c0.8-0.2,1.4-0.6,1.8-1.3l-9.1-10.3C20.4,18.9,24.6,21.2,27.9,23.8z M18.8,68.3l-0.7-1.8l-11,12.1l17.6-5.1l-1.9-1.1L8.7,77.7
	L18.8,68.3 M17.2,61.6c0.3-0.6,0.1-1.3-0.7-2c-3.9-0.2-8.4-0.7-13.6-1.6c3.9-1.5,7.9-2.7,11.9-3.7c0.1-0.7-0.2-1.2-0.8-1.7
	c-4.4,1.2-8.7,3.1-13.1,5.6L17.2,61.6 M34.7,52.7c0.3-0.4,0.5-1,0.7-1.8c-4-0.2-8.6,0.1-13.9,0.8c4-2.5,8.3-4.6,13.1-6.4
	c-0.5-0.5-1.1-1-1.8-1.3c-5.2,2.4-9.9,5.3-14.3,8.6C24.6,52.1,30,52.1,34.7,52.7 M62.4,19.2l1.8,0.6c1.7-4.3,4.3-8.6,7.7-13.1
	c-1.8,5.3-2.7,10.5-2.8,15.5l1.8,0.6c-0.2-6.4,0.5-12.5,2.1-18.5C68.6,8.8,65.1,13.8,62.4,19.2 M48.2,18.7c0.8-0.4,1.3-0.7,1.3-0.8
	l-9.6-16l0.9,17.3c0.2,0.2,0.8,0.5,1.9,0.7L40.9,5.3L48.2,18.7z M18.7,37.2c0.3-0.9,0.3-1.5,0-2L1.9,33.4l12.6,10.1
	c0.6-0.2,1.1-0.6,1.3-1.3L4.5,34.5L18.7,37.2z"
    />
    <path
      id="spikes-shadow"
      fill="#FFFFFF"
      fill-opacity="0.251"
      d="M21.4,72.1c-1.1-0.6-1.9-1.1-2.2-1.5c-2.4,1.9-5.1,3.9-8,6L21.4,72.1 M15.1,57.4
	c-0.1-0.9-0.3-1.6-0.6-2.1c-3.3,0.8-6.7,1.7-10.4,2.6L15.1,57.4 M34.3,48.9c0.1-0.9-0.1-1.6-0.4-2.1c-3.4,1-6.4,2.2-8.9,3.6
	L34.3,48.9 M16.6,39.8c0.6-0.6,0.8-1.3,0.9-1.7c-4-1.1-7.9-2.1-11.7-2.8L16.6,39.8 M26.1,25.5c0.6-0.5,0.8-0.9,0.9-1
	c-3.6-2.5-7-4.7-10.2-6.4L26.1,25.5 M44.9,19.2c1.1,0.1,2,0,2.4-0.2c-1.7-3.6-3.5-7.1-5.3-10.5L44.9,19.2z M64.6,20.1
	c0.4,0.5,0.8,0.6,1.4,0.6c1.1-3.8,2.4-7.5,4.1-11.1C67.3,13.7,65.6,17.2,64.6,20.1z"
    />
    <path
      id="gills"
      fill="#070707"
      d="M31.2,55.6L31.2,55.6c0.1-0.9,0.1-1.6,0.4-2.4c-2.4,4.9-1.6,9.2,2.5,12.9C34,66,34,65.9,33.9,65.9
	c-0.4-0.9-0.9-1.7-1.2-2.6c0,0,0,0,0-0.1C31.6,60.5,31.1,58,31.2,55.6 M36.8,64.5c0-0.1-0.1-0.1-0.1-0.2c-1.4-2.9-1.9-5.8-1.7-8.8
	c0-0.1,0-0.1,0-0.2c0.1-0.7,0.2-1.4,0.3-2.2c0-0.1,0-0.1,0-0.2c-3.2,4.8-2.3,9.5,2.8,14.1c-0.2-0.2-0.3-0.4-0.5-0.7
	C37.5,65.8,37.1,65.2,36.8,64.5 M43.5,68.8c-0.1-0.1-0.1-0.1-0.2-0.2l0,0l-0.1-0.1c-0.7-0.8-1.3-1.6-1.8-2.5
	c-1.9-3.1-2.9-6.9-2.9-11.3c0-1,0.1-1.9,0.2-3C35.9,59.3,37.6,65,43.5,68.8z"
    />
  </svg>
);
