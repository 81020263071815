import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Navbar from "../Components/Common/Navbar/Navbar";
import Drawer from "../Components/Common/Drawer/MyDrawer";
import { connect } from "react-redux";
import StatsbarMobile from "../Components/Common/StatsbarMobile/StatsbarMobile";
import Snackbar from "../Components/Common/Snackbar/Snackbar";
import Clock from "react-live-clock";

import { withRouter } from "react-router-dom";

import { Grid, Container, Hidden } from "@material-ui/core";
import "./layout.css";
const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.primary.other,
    minHeight: "calc(100vh - 64px)",
    boxShadow: "0px 2px 24px #00000033",

    [theme.breakpoints.up("md")]: {
      borderRadius: "16px",
    },
  },

  box2: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "calc(100vh - 64px)",
  },
  contentContainer: {
    background: theme.palette.primary.main,
    paddingRight: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
    },
  },

  statsDiv: {
    textAlign: "center",
  },
  statsText: {
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    margin: 0,
    fontSize: "12px",
  },
  topStatsBar: {
    boxShadow: "0px 2px 10px #00000029",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    padding: "15px 0px",
    margin: "10px 0px",

    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
}));

const Layout = (props) => {
  const [snackBarNet, setSnackBarNet] = useState(false);
  const [networkMessage, setNetworkMessage] = useState("");


  const classes = useStyles();

  return props.history.location.pathname === "/" ? (
    <Fragment>
      <Navbar />
      <Container maxWidth="xl">
        <Grid className={classes.box2} container item md={12}>
          {props.children}
        </Grid>
        <Snackbar
          open={snackBarNet}
          message={networkMessage}
          handleClose={() => setSnackBarNet(false)}
        />
      </Container>
    </Fragment>
  ) : (
      <Fragment>
        <Navbar />
        <Container style={{ padding: 0 }} maxWidth="xl">
          <StatsbarMobile />
          <Grid className={classes.contentContainer} container>
            <Grid container style={{ position: "relative" }} item xs={12} md={2}>
              <div className={"clock"}>
                <Clock format={"HH:mm:ss"} ticking={true} timezone={"UTC"} />
              </div>
              <Drawer />
            </Grid>
            <Grid className={classes.box} container item xs={12} md={10}>
              {props.children}
            </Grid>
          </Grid>
          <Snackbar
            open={snackBarNet}
            message={networkMessage}
            handleClose={() => setSnackBarNet(false)}
          />
        </Container>
      </Fragment>
    );
};
const mapStateToProps = (state) => ({
  network: state.layout.network,
});
export default withRouter(connect(mapStateToProps, {})(Layout));
