import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Menu } from "@material-ui/icons";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withRouter } from "react-router-dom";
import enj from "../../../Assets/enj.png";
import copyicon from "../../../Assets/copyicon.svg";
import copyiconWhite from "../../../Assets/copyiconWhite.svg";
import Snackbar from "../../Common/Snackbar/Snackbar";
import "./Navbar.css";
import { qrScan } from "../../../redux/actions/layoutActions";
import { connect } from "react-redux";
import {
  checkWeb3,
  updateAddress,
  updateUser,
} from "../../../redux/actions/layoutActions";
import axios from "axios";
import { ROUTES } from "../../../Utils/routes";
import metaMaskIcon from "../../../Assets/metamask-logo.png";

import {
  Grid,
  Avatar,
  withWidth,
  useMediaQuery,
  CircularProgress,
  Modal,
  Tooltip,
} from "@material-ui/core";
import EnjinModal from "../Modal/enjinModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faMoneyBill,
  faShoppingBasket,
  faFileImage,
  faHistory,
  faQuestionCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useWeb3React } from "@web3-react/core";

import logo from "../../../Assets/Navbar/logo.png";
import logoDark from "../../../Assets/Navbar/logo.png";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  navbar: {
    backgroundColor: theme.palette.primary.main2,
    color: theme.palette.text.primary,
    padding: "12px 20px 12px 45px",
    "@media only screen and (max-width:600px)": {
      padding: "12px 8px !important",
    },
  },
  loaderStyles: {
    color: theme.palette.text.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -8,
  },
  navItem: {
    fontWeight: "bold",
    textTransform: "none",
    color: theme.palette.text.secondary,
    marginTop: "12px",
  },

  navItemActive2: {
    margin: "12px 3px",
    fontWeight: "bold",
    textTransform: "none",
    color: theme.palette.text.nonbtn,
    "@media (min-width: 960px)": {
      margin: "0 5px",
    },
  },
  navItemActive: {
    margin: "12px 3px",
    fontWeight: "bold",
    textTransform: "none",
    color: theme.palette.text.btn,
    // "&:hover": {
    //   background: theme.palette.button.hover,
    // },
    "@media (min-width: 960px)": {
      margin: "0 5px",
    },
  },

  drawer: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    height: "100%",
  },
  walletAddress: {
    borderRadius: "8px",
    color: "white",
    "@media (min-width:600px) and (max-width:959px)": {
      position: "absolute",
      top: "12px",
      right: "20px",
      marginRight: 6,
      padding: "5px 15px 5px 15px",
      fontSize: "10px",
    },
    "@media (min-width:300px) and (max-width:600px)": {
      position: "absolute",
      top: "12px",
      right: "10px",
      fontSize: "10px",
      marginRight: 6,
      padding: "5px 15px 5px 15px",
    },
    "@media (min-width:150px) and (max-width:300px)": {
      position: "absolute",
      top: "12px",
      right: "10px",
      marginRight: 6,
      fontSize: "10px",
      padding: "5px 9px 5px 15px",
    },
  },
}));

const SwipeableTemporaryDrawer = (props) => {
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [scanInterval, setScanInterval] = useState("");
  const is960 = useMediaQuery("(max-width:960px)");
  const [userData, setUserData] = useState({});
  const [enjinText, setEnjinText] = useState("link to enjin");
  const themeMode = localStorage.getItem("themeMode");
  const [textLoader, setTextLoader] = useState(false);
  const [qrModal, setQrModal] = useState(undefined);
  const classes = useStyles();
  const context = useWeb3React();
  const [linked, setLinked] = useState(false);
  const [scanCheck, setScanCheck] = useState(null);

  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  const [state, setState] = React.useState({
    left: false,
  });
  React.useEffect(() => {
    if (linked === true) {
      clearInterval(scanCheck);
      console.log("CLEARED!!!!");
    }
  }, [linked]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const copyClick = () => {
    setSnackbarState(true);
    setSnackbarMessage("Copied");
  };

  const onEnjinSubmit = async () => {
    setTextLoader(true);
    let result;
    if (props.user && props.address) {
      try {
        let result1 = await axios.post(ROUTES.LINK_ENJIN, {
          address: props.user.address,
          userName: props.user.userIdentity
            ? props.user.userName
            : props.user.name,
        });
        // console.log(result1, "result of enjinConnect");
        if (result1) {
          if (result1.data.user) {
            props.updateUser(result1.data.user);
          }
          if (result1.data.isLinked === true) {
            setTextLoader(false);
            setSnackbarState(true);
            setSnackbarMessage("Your wallet is already linked with enjin.");
            setQrModal(false);
            setLinked(true);
            setUserData(result1.data);
            props.updateUser(result1.data.user);
            setEnjinText("Linked");
            return;
          } else {
            if (
              result1.data.isLinked === false &&
              result1.data.isValid === true
            ) {
              // console.log("hi");
              setSnackbarState(true);
              setSnackbarMessage(
                "Looks like your wallet is not linked, quickly login before its time to feed your tipply again."
              );
              setUserData(result1.data);
              // setQrModal(true);
              setTextLoader(false);
              context.deactivate();
              props.updateAddress(null);
            }
          }
          if (result1.data == "") {
            // console.log(586);
            setTextLoader(false);
            setSnackbarState(true);
            setSnackbarMessage(
              "An unexpected error occurred, please try again."
            );
          }
        }
      } catch (e) {
        // console.log(e);
        // console.log(8569);
        setSnackbarState(true);
        setSnackbarMessage("An unexpected error occurred, please try again.");

        setTextLoader(false);
      }
    } else {
      // console.log(1234);
      setSnackbarState(true);
      setTextLoader(false);
      setSnackbarMessage("An unexpected error occurred, please try again.");
    }
  };
  React.useEffect(() => {
    if (qrModal === false) {
      setTextLoader(false);
    }
  }, [qrModal]);

  React.useEffect(() => {
    console.log(props.address, "adress in navbar");
  }, [props.address]);

  const navButtonClickedHandler = (text) => {
    text = text.replace(/\s/g, "");
    if (text === "Home") {
      props.history.push("/");
    } else {
      props.history.push(text.toLowerCase());
    }
  };

  const onMetaMaskConnect = () => {
    let ethereum = window.ethereum;
    if (ethereum) {
      ethereum.enable();
      props.checkWeb3();
    } else {
      // alert(`METAMASK NOT INSTALLED!!`);
    }
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          ["Home", faHome],
          ["Wallet", faMoneyBill],
          ["Marketplace", faShoppingBasket],
          ["Inventory", faFileImage],
          ["Txn History", faHistory],
          ["Help", faQuestionCircle],
          ["About", faInfoCircle],
        ].map((text, index) => (
          <ListItem
            onClick={() => navButtonClickedHandler(text[0])}
            button
            key={text[0]}
          >
            <ListItemIcon>
              <FontAwesomeIcon color="white" icon={text[1]} />
            </ListItemIcon>
            <ListItemText primary={text[0]} />
          </ListItem>
        ))}
      </List>
    </div>
  );
  return (
    <div className={classes.navbar}>
      <Snackbar
        open={snackbarState}
        autoHideDuration={25}
        message={snackbarMessage}
        handleClose={() => setSnackbarState(false)}
      />
      <EnjinModal
        userData={userData}
        qrModal={qrModal}
        setQrModal={setQrModal}
        scanCheck={scanCheck}
      />
      <React.Fragment key={"left"}>
        <Grid alignItems="center" container justify="space-between">
          {/* <Grid xs="2" ali item>
              <Button>
                <Menu
                  style={{ fill: "white" }}
                  onClick={toggleDrawer("left", true)}
                />
              </Button>
            </Grid> */}
          {props.width !== "xs" ? (
            <Grid style={{ textAlign: "left" }} item>
              <img
                onClick={() => {
                  props.history.push("/fishtank");
                }}
                style={{ cursor: "pointer" }}
                height="22px"
                src={themeMode === "light" ? logo : logoDark}
              />
            </Grid>
          ) : (
            <Grid style={{ textAlign: "left" }} item>
              <img
                onClick={() => {
                  props.history.push("/fishtank");
                }}
                style={{ cursor: "pointer" }}
                height="15px"
                src={themeMode === "light" ? logo : logoDark}
              />
            </Grid>
          )}

          {props.history.location.pathname !== "/" ? (
            <Grid style={{ textAlign: "right" }} md={8} xs={12} item>
              {/* <div className={classes.walletAddress}>
                <img style={{ height: 22 }} src={metaMaskIcon} />
                <span>231ea....dasdew</span>
              </div> */}
              <span>
                <CopyToClipboard
                  text={
                    props.address !== null && props.address !== undefined
                      ? props.address
                      : ""
                  }
                  onCopy={copyClick}
                >
                  <Button
                    // onClick={onMetaMaskConnect}
                    className={classes.walletAddress}
                    style={
                      themeMode === "light"
                        ? { backgroundColor: "#FBBE3F" }
                        : { backgroundColor: "#474A5A" }
                    }
                    startIcon={
                      <img style={{ height: "20px" }} src={metaMaskIcon} />
                    }
                  >
                    {" "}
                    {props.address !== null && props.address !== undefined
                      ? props.address.slice(0, 5) +
                        "...." +
                        props.address.slice(
                          props.address.length - 5,
                          props.address.length
                        )
                      : "Loading..."}
                  </Button>
                </CopyToClipboard>
                {props.user && props.address ? (
                  <Tooltip
                    title={
                      props.user.userIdentity
                        ? "Click here to check if you are linked with enjin or not."
                        : "click here to link with enjin"
                    }
                  >
                    <Button
                      onClick={onEnjinSubmit}
                      className={classes.walletAddress}
                      style={{
                        margin: is960 ? "40px 6px 0 0" : "0 5px",
                        backgroundColor:
                          themeMode === "light" ? "#FBBE3F" : "#474A5A",
                        fontSize: is960 ? 10 : 13,
                      }}
                      startIcon={<img style={{ height: "20px" }} src={enj} />}
                    >
                      {" "}
                      {props.open_qrScan === false && textLoader === false ? (
                        props.user.userIdentity !== null &&
                        props.user.userIdentity !== undefined ? (
                          props.user.isLinked ? (
                            props.user.isLinked === true ? (
                              "Linked"
                            ) : (
                              "Link to enjin"
                            )
                          ) : (
                            enjinText
                          )
                        ) : (
                          "click to join enjin"
                        )
                      ) : (
                        <>
                          link to enjin
                          <CircularProgress
                            size={15}
                            className={classes.loaderStyles}
                          />
                        </>
                      )}
                    </Button>
                  </Tooltip>
                ) : (
                  ""
                )}
              </span>
              {is960 ? (
                ""
              ) : (
                <Button
                  onClick={() => navButtonClickedHandler("/leaderBoard")}
                  style={{ margin: is960 ? "50px 0 0 0" : 0 }}
                  className={
                    props.history.location.pathname === "/leaderBoard"
                      ? classes.navItemActive
                      : classes.navItemActive2
                  }
                >
                  Leaderboard
                </Button>
              )}
              <Button
                onClick={() => navButtonClickedHandler("/help")}
                style={{ margin: is960 ? "50px 0 0 0" : 0 }}
                className={
                  props.history.location.pathname === "/help"
                    ? classes.navItemActive
                    : classes.navItemActive2
                }
              >
                Help
              </Button>
              <Button
                onClick={() => navButtonClickedHandler("/about")}
                style={{ margin: is960 ? "50px 0 0 0" : 0 }}
                className={
                  props.history.location.pathname === "/about"
                    ? classes.navItemActive
                    : classes.navItemActive2
                }
              >
                About
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>

        {/* <SwipeableDrawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            <div className={classes.drawer}> {list("left")}</div>
          </SwipeableDrawer> */}
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.layout.user,
  address: state.layout.address,
  open_qrScan: state.layout.open_qrScan,
});

export default withRouter(
  withWidth()(
    connect(mapStateToProps, { checkWeb3, qrScan, updateUser, updateAddress })(
      SwipeableTemporaryDrawer
    )
  )
);
