import {
  ENABLE_FEED_ANIMATION,
  DISABLE_FEED_ANIMATION,
  ENABLE_FEED_SPEECH,
  DISABLE_FEED_SPEECH,
} from "../types";

export const enableFeedAnimation = (image) => async (dispatch) => {
  dispatch({
    type: ENABLE_FEED_ANIMATION,
    payload: image,
  });
};

export const disableFeedAnimation = () => async (dispatch) => {
  dispatch({
    type: DISABLE_FEED_ANIMATION,
  });
};

export const enableFeedSpeech = () => async (dispatch) => {
  dispatch({
    type: ENABLE_FEED_SPEECH,
  });
};

export const disableFeedSpeech = () => async (dispatch) => {
  dispatch({
    type: DISABLE_FEED_SPEECH,
  });
};
