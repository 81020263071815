import React, { useState, useEffect, useLayoutEffect } from "react";
import Tank from "../../Assets/tt_bowl.png";

import fishTankLight from "../../Assets/fishTankLight.gif";
import fishtankDark from "../../Assets/fishTankDark.gif";
import enj from "../../Assets/enjSvg.svg";
import whiteEnj from "../../Assets/EnjWhiteSvg.svg";
import EnjinModal from "../../Components/Common/Modal/enjinModal";
import {
  Grid,
  Button,
  Modal,
  CircularProgress,
  withWidth,
  Link,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import axios from "axios";
import { ROUTES } from "../../Utils/routes";
import Snackbar from "../../Components/Common/Snackbar/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import { EnjinUserName } from "../../Components/Common/Modal/Modal";
// import FullPageloader from "../../Components/Common/FullpageLoader/FullpageLoader";
import { connect } from "react-redux";
import {
  checkWeb3,
  checkWeb3WC,
  checkUser,
  addUser,
  emailCheck,
  qrScan,
  updateUser,
  updateAddress,
} from "../../redux/actions/layoutActions";
import { access_token_null, AT_PASS } from "../../redux/actions/pinAction";

import { getHistory } from "../../redux/actions/authActions";
import poweredEnjinWhite from "../../Assets/Enjin/white.svg";
import poweredEnjinColor from "../../Assets/Enjin/color.svg";
import { makeStyles } from "@material-ui/core/styles";

import MetaMask from "../../Assets/metamask-logo@2x.png";
import WalletConnect from "../../Assets/walletconnector.png";
import enjin from "../../Assets/enj.png";

import { useWeb3React } from "@web3-react/core";
import { walletconnect } from "../../Utils/connector";

const useStyles = makeStyles((theme) => ({
  justifyCenter: {
    justify: "center !important",
  },
  justifySB: {
    justify: "space-between!important",
  },
  loaderStyles: {
    color: theme.palette.text.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -8,
  },
  loaderStyles2: {
    color: theme.palette.text.primary,
    position: "relative",
    // top: "50%",
    // left: "50%",
    // marginTop: -8,
    // marginLeft: -8,
  },

  textPadding: {
    paddingLeft: "25px",
    "@media only screen and (max-width:601px)": {
      padding: " 0px !important",
    },
  },
  WidthStyleD: {
    height: "auto",
    width: "100%",
  },
  WidthStyleL: {
    height: "auto",
    width: "100%",
  },
  heading: {
    textAlign: "left",
    color: theme.palette.secondary.main,
    fontSize: "48px",
    margin: 0,
  },
  enjin: {
    color: theme.palette.secondary.other,
    display: "flex",
    justifyContent: "center",
    margin: "auto 0",
  },
  text: {
    color: "#BD4139",
    textAlign: "left",
    margin: "24px 0px",
    fontSize: "24px",
    display: "flex",
    flexDirection: "column",
    // width: "72%",
    "@media only screen and (max-width:400px)": {
      fontSize: "12px",
    },
    "@media (min-width:400px)and (max-width:1268px)": {
      fontSize: "18px",
    },
  },
  button: {
    color: "white",
    width: "80%",
    background: theme.palette.button.connect,
    padding: "10px",
    borderRadius: "24px",
    // "@media only screen and (max-width:600px)": {
    //   padding: "20px 30px !important",
    // },
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },
  connectWallet: {
    color: "white",
    width: "50%",
    background: theme.palette.button.connect,
    padding: "10px",
    borderRadius: "24px",
    "@media only screen and (max-width:450px)": {
      width: "70% !important",
    },
    "@media (min-width:450px) and (max-width:960px)": {
      width: "35% !important",
    },
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },

  modalConnect: {
    border: "none",
    outline: "none",
    position: "absolute",
    maxWidth: 320,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: " 48px 80px 16px 65px",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "fit-content",
    margin: "auto",

    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  modalStyles: {
    border: "none",
    outline: "none",
    position: "absolute",
    maxWidth: 320,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: " 48px 80px 16px 65px",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: 280,
    margin: "auto",
    color: theme.palette.secondary.other,
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },

  modalInput: {
    height: "40px",
    width: "95%",
    borderRadius: "4px",
    outline: "none",
    borderRadius: " 4px",
    borderColor: "white !important",
    boxShadow: "0px 2px 10px #00000029",
    border: "none !important",
    padding: "0 15px !important",
    color: theme.palette.secondary.other,
    backgroundColor: theme.palette.primary.other,
    "@media only screen and (max-width:408px)": {
      width: "90% !important",
      padding: "0px 10px !important",
    },
  },
  modalText: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  modalButton: {
    cursor: "pointer",
    width: "120px",
    height: "40px",
    color: "white",
    backgroundColor: theme.palette.button.connect,
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },
  buttonClass: {
    padding: "20px 60px",
    borderRadius: "0",
    textTransform: "capitalize",
    color: "white !important",
    backgroundColor: theme.palette.button.primary,
    height: "27px",
    "&:hover": {
      background: theme.palette.button.hover,
    },
    "@media only screen and (max-width: 330px)": {
      padding: "20px 40px !important",
    },
  },
  action: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    // "@media only screen and (max-width: 580px)": {
    //   padding: "0 50px !important",
    // },
    // "@media only screen and (max-width: 450px)": {
  },
  modalStyles1: {
    border: "none",
    outline: "none",
    position: "absolute",
    maxWidth: 350,
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.secondary.modalbody,
    padding: "0px",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "270px",
    margin: "auto",
  },
  loaderStyles: {
    color: "yellow",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -8,
  },
  borderColor: {
    borderRadius: "8px",
    border: "1px solid",
    outline: "none",
    borderColor: "gray",
    margin: "20px auto",
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
    height: "35px",
    fontSize: "15px",
  },
  color: {
    color: theme.palette.secondary.other,
    marginBottom: "10px",
  },

  modalTextColor: {
    color: theme.palette.secondary.other,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0px 70px",
    "@media only screen and (max-width: 530px)": {
      padding: "15px 10px !important",
      justifyContent: "center",
    },
  },
  headerClass: {
    display: "flex",
    justifyContent: "space-between",
    padding: " 10px 20px",
  },
  bodyclass: {
    backgroundColor: theme.palette.secondary.modalbody,
  },
  inputText: {
    margin: "5px",
    display: "flex",
    justifyContent: "center !important",
    alignContent: "center !important",
    alignItems: "center !important",
    color: theme.palette.secondary.other,
    "& .MuiInputBase-input": {
      color: `${theme.palette.secondary.other} !important`,
    },
  },
  buttonClass2: {
    padding: "20px 40px",
    borderRadius: "0",
    textTransform: "capitalize",
    color: "white !important",
    backgroundColor: theme.palette.button.primary,
    height: "27px",
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },
  action2: {
    display: "flex",
    justifyContent: "space-around",
  }, //   padding: "0 10px !important",
}));

const Home = (props) => {
  const is600 = useMediaQuery("(max-width:600px)");
  const [openModal, setOpenModal] = useState(false);
  const [openEnjinModal, setOpenEnjinModal] = useState(false);
  const [formEmail, setFormEmail] = useState("");
  const [emailValidation, setEmailValidation] = useState(false);
  const [formUsername, setFormUsername] = useState("");
  const [userData, setUserData] = useState({});

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [loaderState, setLoaderState] = useState(false);
  const [enjinLoginLoader, setEnjinLoginLoader] = useState(false);
  const [enjinSignupLoader, setEnjinSignupLoader] = useState(false);

  const [hide, setHide] = useState(true);
  const [wallet, setWallet] = useState(true);
  const [enjinLoader, setEnjinLoader] = useState(false);
  const context = useWeb3React();
  const [qrModal, setQrModal] = useState(false);
  const [linked, setLinked] = useState(false);
  const [scanCheck, setScanCheck] = useState(null);
  const [openUserName, setOpenUserName] = useState(false);
  const [enjinUser, setEnjinUser] = useState("");
  const [enjinAddress, setEnjinAddress] = useState("");

  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  useEffect(() => {
    props.emailCheck(props.unique);
    clearInterval(localStorage.getItem("intervalID"));
    props.getHistory("/");
    props.access_token_null();
    props.AT_PASS(true);
  }, []);

  useEffect(() => {
    // console.log("context changed", context);
    if (context.library) {
      props.checkWeb3WC({
        address: context.account,
        network: context.network,
      });
    }
  }, [context]);

  useEffect(() => {
    // console.log("address changed", props.address);
    if (props.address) {
      addressChanged();
    }
  }, [props.address]);

  React.useEffect(() => {
    if (qrModal === false && linked === true) {
      clearInterval(scanCheck);
      setEnjinLoader(false);
      console.log("CLEARED!!!");
    }
  }, [qrModal]);

  const addressChanged = async () => {
    await props.checkUser(props.address);
    if (!props.user) {
      if (userData) {
        setWallet(false);
      } else {
        setWallet(true);
      }
    } else {
      userFound();
    }
  };

  const emailChange = (e) => {
    let email =
      /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

    if (!email.test(e.target.value)) {
      setEmailValidation(false);
    } else {
      setEmailValidation(true);
    }

    setFormEmail(e.target.value);
  };

  const usernameChange = (e) => {
    setFormUsername(e.target.value);
  };

  const classes = useStyles();
  const themeMode = localStorage.getItem("themeMode");

  useEffect(() => {
    setTimeout(function () {
      setHide(false);
      console.log(hide, "hide value inside");
    }, 3000);
    setHide(true);
    console.log(hide, "hide value outside");
  }, [themeMode]);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const userFound = () => {
    props.history.push("/");
  };
  const connectWalletValue = () => {
    setWallet(!wallet);
  };
  const onSumbitForm = async () => {
    setLoaderState(true);
    if (formEmail === "" || formUsername === "") {
      setLoaderState(false);
      setSnackbarState(true);
      setSnackbarMessage("Please fill both the fields.");
    } else {
      if (emailValidation === true) {
        await props.addUser(formEmail, formUsername, props.address, enjinUser);
        setLoaderState(false);
      } else {
        setLoaderState(false);
        setSnackbarState(true);
        setSnackbarMessage("Please enter a valid email");
      }
    }
  };

  useEffect(() => {
    if (props.user) {
      if (
        props.user.userName !== "" &&
        props.user.userName !== null &&
        props.user.userName !== undefined
      ) {
        setEnjinUser(props.user.userName);
      }
    }
  }, []);
  const onMetaMaskConnect = async () => {
    if (!props.user) {
      if (props.address) {
        setOpenModal(true);
      } else {
        setSnackbarState(true);
        setSnackbarMessage("Unable to detect your wallet address.");
      }
    }
    let ethereum = window.ethereum;
    if (ethereum) {
      await ethereum.enable();
      props.checkWeb3();
      // await props.checkUser(props.address);
      // if (!props.user) {
      //   handleModalOpen();
      // } else {
      //   userFound();
      // }
    } else {
      // alert(`METAMASK NOT INSTALLED!!`);
    }
  };

  const onWalletConnect = async () => {
    if (!props.user) {
      if (props.address) {
        setOpenModal(true);
      } else {
        setSnackbarState(true);
        setSnackbarMessage("Unable to detect your wallet address.");
      }
    }
    await activate(walletconnect);
    console.log("context account", context);
    // alert("context", context);
    // await props.checkWeb3WC({
    // address: context.account,
    // network: context.network,
    // });
    // if (!props.user) {
    //   handleModalOpen();
    // } else {
    //   userFound();
    // }
    console.log("Current context", context);
  };

  const OnChangeFunction = (e) => {
    setEnjinUser(e.target.value);
  };
  const onEnjinConnect = async () => {
    setEnjinLoader(true);
    setOpenUserName(true);
    setWallet(false);
  };

  useEffect(() => {
    if (linked === true) {
      clearInterval(scanCheck);
      console.log("CLEARED!!!!");
    }
  }, [linked]);

  const loginEnjin = async () => {
    setEnjinLoginLoader(true);
    console.log(enjinUser, "userName");
    let loginResult, intervalReq;
    if (enjinUser !== "") {
      loginResult = await axios.post(ROUTES.ENJIN_LOGIN, {
        userName: enjinUser,
        address: props.user ? props.user.address : null,
      });
      console.log(loginResult, "resultttttttttttt");
      console.log(loginResult.data.address, "result address");
      if (loginResult && loginResult.data !== "") {
        if (loginResult.data.userExists === false) {
          setEnjinLoginLoader(false);
          setEnjinLoader(false);
          setWallet(false);
          setSnackbarState(true);
          setSnackbarMessage("This user doesn't exist.");
        } else {
          if (loginResult.data.isLinked === true) {
            setEnjinLoginLoader(false);
            setWallet(false);
            setEnjinLoader(false);
            setSnackbarState(true);
            setSnackbarMessage("You have successfully linked your wallet!");
            setLinked(true);
            setQrModal(false);
            setUserData(loginResult.data);
            props.updateUser(loginResult.data.user);
            props.updateAddress(loginResult.data.user.address);
            setEnjinAddress(loginResult.data.user.address);
          } else {
            if (loginResult.data.isLinked === false) {
              if (loginResult.data.userDetail) {
                setUserData(loginResult.data);
                setQrModal(true);
              }
              console.log("again");
              setEnjinLoginLoader(false);
              setEnjinLoader(false);
              if (loginResult.data.checkAddress === false) {
                console.log("hmmmmm");
                props.updateAddress(null);
                setEnjinAddress(null);
                setSnackbarState(true);
                setSnackbarMessage("You are linking with a wrong wallet");
              }
              setScanCheck(
                setInterval(async function () {
                  intervalReq = await axios.post(ROUTES.ENJIN_LOGIN, {
                    userName: enjinUser,
                    address: props.user ? props.user.address : null,
                  });
                  if (intervalReq) {
                    console.log(
                      intervalReq.data,
                      "result in interval",
                      "=====>>adreessss",
                      intervalReq.data.address
                    );
                    if (intervalReq.data.isLinked === true) {
                      setEnjinLoader(false);
                      setSnackbarState(true);
                      setSnackbarMessage(
                        "You have successfully linked your wallet!"
                      );
                      setWallet(false);
                      setLinked(true);
                      setQrModal(false);
                      setEnjinLoginLoader(false);
                      setUserData(intervalReq.data);
                      if (intervalReq.data.user) {
                        props.updateUser(intervalReq.data.user);
                        props.updateAddress(intervalReq.data.user.address);
                        setEnjinAddress(loginResult.data.user.address);
                      }
                    } else {
                      if (intervalReq.data.isLinked === false) {
                        if (
                          intervalReq.data.checkUser === false ||
                          intervalReq.data.checkAddress === false
                        ) {
                          console.log("here");
                          setLinked(false);
                          setQrModal(false);
                          props.updateUser(null);
                          setEnjinAddress(null);
                          setSnackbarState(true);
                          setSnackbarMessage(
                            "You are linking with a wrong wallet"
                          );
                          if (intervalReq.data.userDetail) {
                            setUserData(intervalReq.data);
                            setQrModal(true);
                          }
                        } else {
                          console.log(222332);
                          if (intervalReq.data.userDetail) {
                            setUserData(intervalReq.data);
                            setQrModal(true);
                          }
                          setEnjinLoginLoader(false);
                          setEnjinLoader(false);
                          if (intervalReq.data.user) {
                            props.updateUser(intervalReq.data.user);
                          }
                        }
                      }
                    }
                  } else {
                    setLinked(true);
                    setSnackbarState(true);
                    setSnackbarMessage(
                      "An unexpected error occurred, please try again."
                    );
                  }
                }, 5000)
              );
            }
          }
        }
      } else {
        setEnjinLoginLoader(false);
        setSnackbarState(true);
        setSnackbarMessage("An unexpected error occurred, please try again.");
      }
    } else {
      setEnjinLoginLoader(false);
      setSnackbarState(true);
      setSnackbarMessage("Please enter the user name first.");
    }
  };

  const signupEnjin = async () => {
    setEnjinSignupLoader(true);
    if (enjinUser !== "") {
      let intervalReq, signupResult;
      signupResult = await axios.post(ROUTES.ENJIN_CONNECT, {
        userName: enjinUser,
      });
      // console.log(signupResult, "signup result");
      if (signupResult || signupResult.data !== "") {
        props.updateUser(null);
        if (
          signupResult.data.userExists === true ||
          signupResult.data.userDetail === null
        ) {
          setEnjinSignupLoader(false);
          setSnackbarState(true);
          setQrModal(false);
          setLinked(true);
          setSnackbarMessage("This user already exists.");
        } else {
          if (signupResult.data.isLinked === true) {
            console.log(13);
            setEnjinAddress(signupResult.data.address);
            setQrModal(false);
            setUserData(signupResult.data);
            setEnjinSignupLoader(false);
            setEnjinLoader(false);
            setLinked(true);
            setFormUsername(enjinUser);
            setWallet(false);
            setOpenEnjinModal(true);
            setSnackbarState(true);
            setSnackbarMessage("You have successfully linked your wallet.");
            return;
          }
          if (signupResult.data.isLinked === false) {
            setEnjinSignupLoader(false);
            setEnjinLoader(false);
            setWallet(false);
            if (signupResult.data.userDetail) {
              setUserData(signupResult.data);
              setQrModal(true);
            }
            console.log(589588);
            setSnackbarState(true);
            setSnackbarMessage(
              "Scan or use the scan code to link your enjin wallet."
            );
            setScanCheck(
              setInterval(async function () {
                console.log("okkay ijm here");
                intervalReq = await axios.post(ROUTES.ENJIN_CONNECT, {
                  userName: enjinUser,
                });
                // console.log(1111, intervalReq);

                if (intervalReq) {
                  if (
                    intervalReq.data.isValid == false &&
                    intervalReq.data.userDetail !== null
                  ) {
                    setQrModal(false);
                    setWallet(false);
                    setEnjinSignupLoader(false);
                    setEnjinLoader(false);
                    setSnackbarState(true);
                    setSnackbarMessage(
                      "This address is already registered. You can login directly."
                    );
                    if (
                      intervalReq.data.userDetail !== null ||
                      intervalReq.data.userDetail !== "" ||
                      intervalReq.data.userDetail !== {}
                    ) {
                      setUserData(intervalReq.data);
                      setQrModal(true);
                    }
                  } else {
                    if (intervalReq.data.isLinked === true) {
                      setLinked(true);
                      setQrModal(false);
                      setUserData(intervalReq.data);
                      setEnjinSignupLoader(false);
                      setWallet(false);
                      setFormUsername(enjinUser);
                      setEnjinAddress(intervalReq.data.address);
                      setOpenEnjinModal(true);
                      setSnackbarState(true);
                      setSnackbarMessage(
                        "You have successfully linked your wallet."
                      );
                    } else {
                      if (intervalReq.data === "") {
                        setEnjinSignupLoader(false);
                        setSnackbarState(true);
                        setSnackbarMessage(
                          "An unexpected error occurred, please try again."
                        );
                        return;
                      }
                      if (
                        intervalReq.data.userDetail !== null ||
                        intervalReq.data.userDetail !== "" ||
                        intervalReq.data.userDetail !== {}
                      ) {
                        setLinked(false);
                      }
                    }
                  }
                }
              }, 5000)
            );
          }
        }
      } else {
        setEnjinSignupLoader(false);
        setSnackbarState(true);
        setSnackbarMessage("ted error occurred, please try again.");
      }
    } else {
      setEnjinSignupLoader(false);
      setSnackbarState(true);
      setSnackbarMessage("Please enter the user name first.");
    }
  };

  useEffect(() => {
    if (enjinAddress !== "") {
      props.updateAddress(enjinAddress);
    }
  }, [enjinAddress]);

  const onEnjinSubmit = async () => {
    setLoaderState(true);

    if (formEmail === "" || formUsername === "") {
      setLoaderState(false);
      setSnackbarState(true);
      setSnackbarMessage("Please fill both the fields.");
    } else {
      props.updateAddress(enjinAddress);
      console.log(userData, "enjin result in submit form");
      if (emailValidation === true) {
        await props.addUser(
          formEmail,
          formUsername,
          enjinAddress,
          userData,
          enjinUser
        );
        setLoaderState(false);
      } else {
        setLoaderState(false);
        setSnackbarState(true);
        setSnackbarMessage("Please enter a valid email");
      }
    }
  };

  useEffect(() => {
    //props.unique refers to unique email
    if (props.unique === 409) {
      setSnackbarState(true);
      setSnackbarMessage(
        "This email or address is already in use, please register with another email."
      );
    }
    props.emailCheck(props.unique);
  }, [props.unique]);

  let message = "processing";
  return (
    <Grid
      style={
        themeMode === "light"
          ? { backgroundColor: "#FFFFFF" }
          : { backgroundColor: "#3C3D49" }
      }
      direction="column"
      container
      justify="space-around"
    >
      <Snackbar
        open={snackbarState}
        message={snackbarMessage}
        handleClose={() => setSnackbarState(false)}
      />
      <EnjinModal
        userData={userData}
        qrModal={qrModal}
        setQrModal={setQrModal}
        scanCheck={scanCheck}
        setLinked={setLinked}
      />
      <Modal open={openUserName}>
        <div className={classes.modalStyles1}>
          <div className={classes.headerClass}>
            <div></div>
            <Button
              onClick={() => {
                setOpenUserName(false);
                setEnjinLoader(false);
                setEnjinLoginLoader(false);
                setEnjinSignupLoader(false);
                setLinked(true);
              }}
            >
              <CloseIcon />
            </Button>
          </div>
          <div className={classes.bodyclass}>
            <div className={classes.modalTextColor} style={{ padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                className={classes.color}
              >
                Enter a userName
              </div>
              <div className={classes.borderColor}>
                <TextField
                  type="String"
                  inputProps={{
                    maxLength: 40,
                  }}
                  className={classes.inputText}
                  onChange={OnChangeFunction}
                  value={enjinUser}
                  style={{ width: "150px" }}
                />
              </div>
            </div>
            <div className={classes.action2}>
              <Button
                onClick={loginEnjin}
                className={classes.buttonClass2}
                disabled={enjinSignupLoader === true ? true : false}
              >
                {enjinLoginLoader === true ? (
                  <CircularProgress
                    size={16}
                    className={classes.loaderStyles}
                  />
                ) : (
                  "Login"
                )}
              </Button>
              <Button
                onClick={signupEnjin}
                className={classes.buttonClass2}
                disabled={enjinLoginLoader === true ? true : false}
              >
                {enjinSignupLoader === true ? (
                  <CircularProgress
                    size={16}
                    className={classes.loaderStyles}
                  />
                ) : (
                  "signup"
                )}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={openModal}>
        <div className={classes.modalStyles}>
          <div style={{ position: "absolute", right: "14px", top: "14px" }}>
            <span
              onClick={() => {
                setOpenModal(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <CloseIcon
                style={
                  themeMode === "light"
                    ? { color: "black" }
                    : { color: "white" }
                }
              />
            </span>
          </div>
          <p className={classes.modalText}>Email</p>
          <input
            maxLength="45"
            onChange={emailChange}
            className={classes.modalInput}
            placeholder="Example@gmail.com"
          />
          <p className={classes.modalText}>Username</p>
          <input
            maxlength="18"
            onChange={usernameChange}
            value={formUsername}
            className={classes.modalInput}
            placeholder="Keith Cordae"
          />
          <div style={{ textAlign: "center", marginTop: 16 }}>
            <Button onClick={onSumbitForm} className={classes.modalButton}>
              {loaderState === true ? (
                <CircularProgress size={16} className={classes.loaderStyles} />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </Modal>

      <Modal open={openEnjinModal}>
        <div className={classes.modalStyles}>
          <div style={{ position: "absolute", right: "14px", top: "14px" }}>
            <span
              onClick={() => {
                setOpenEnjinModal(false);
                setLinked(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <CloseIcon
                style={
                  themeMode === "light"
                    ? { color: "black" }
                    : { color: "white" }
                }
              />
            </span>
          </div>
          <p className={classes.modalText}>Email</p>
          <input
            maxLength="45"
            onChange={emailChange}
            className={classes.modalInput}
            placeholder="Example@gmail.com"
          />
          <p className={classes.modalText}>Username</p>
          <input
            maxlength="18"
            onChange={usernameChange}
            disabled={enjinUser !== "" ? true : false}
            className={classes.modalInput}
            placeholder={enjinUser !== "" ? formUsername : "Keith Cordae"}
          />
          <div style={{ textAlign: "center", marginTop: 16 }}>
            <Button
              onClick={onEnjinSubmit}
              className={classes.modalButton}
              style={{ fontSize: 13, padding: 5, width: 194 }}
            >
              {loaderState === true ? (
                <CircularProgress size={16} className={classes.loaderStyles} />
              ) : (
                "Submit & link to enjin"
              )}
            </Button>
          </div>
        </div>
      </Modal>

      <Modal open={wallet}>
        <div
          style={{ padding: "10px", display: "flex", flexDirection: "column" }}
          className={classes.modalConnect}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              fontWeight: "bold",
            }}
          >
            {" "}
             <span style={{margin:"auto"}}>Select one wallet to Connect</span>
            <span
              onClick={connectWalletValue}
              style={{ cursor: "pointer" }}
            >
              <CloseIcon
                style={
                  themeMode === "light"
                    ? { color: "black" }
                    : { color: "white" }
                }
              />
            </span>
            
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 10
            }}
          >
            <Button
              onClick={onMetaMaskConnect}
              style={{ justifyContent: "center" }}
              className={classes.button}
            >
              <img style={{ height: "18px", marginRight: 5 }} src={MetaMask} />
              <span style={{ textTransform: "none" }}> MetaMask </span>
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              onClick={onWalletConnect}
              className={classes.button}
            >
              <img style={{ height: 14, marginRight: 5 }} src={WalletConnect} />
              <span style={{ textTransform: "none" }}>WalletConnect</span>
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              onClick={onMetaMaskConnect}
              className={classes.button}
            >
              <img style={{ height: "24px", marginRight: 7 }} src={enjin} />
              <span style={{ textTransform: "none" }}>
                {enjinLoader ? (
                  <CircularProgress
                    size={15}
                    className={classes.loaderStyles}
                  />
                ) : (
                  ""
                )}
                Enjin wallet
              </span>
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              onClick={onEnjinConnect}
              className={classes.button}
            >
              <img style={{ height: "24px", marginRight: 7 }} src={enjin} />
              <span style={{ textTransform: "none" }}>
                {enjinLoader ? (
                  <CircularProgress
                    size={15}
                    className={classes.loaderStyles}
                  />
                ) : (
                  ""
                )}
                Enjin web-link
              </span>
            </Button>
          </div>
        </div>
      </Modal>
      <Grid
        container
        item
        justify={
          props.width === "xs" ||
          props.width === "tb" ||
          props.width === "smc" ||
          props.width === "sm"
            ? "center"
            : "space-between"
        }
      >
        <Grid container item md={5}>
          <Grid
            container
            item
            direction="column"
            justify="center"
            className={classes.textPadding}
          >
            <p className={classes.text}>
              <div>
                {" "}
                <span style={{ color: "#DD8638" }}>TIPPLYTANK</span> IS THE PET
                SIMULATOR THAT
              </div>
              <div>
                {" "}
                COMBINES <span style={{ color: "#DD8638" }}>
                  DEFLATIONARY
                </span>{" "}
                ASSETS AND{" "}
              </div>
              <div>
                CLASSIC ENTERTAINMENT.
                <span style={{ color: "#DD8638" }}> NFT </span>ART, REAL
              </div>
              <div>
                {" "}
                CRYPTO PRIZE POOL. NEXT LEVEL
                <span style={{ color: "#DD8638" }}> GAMING.</span>
              </div>
            </p>
            <Button
              onClick={connectWalletValue}
              className={classes.connectWallet}
            >
              <span style={{ textTransform: "none" }}> Connect Wallet </span>
            </Button>
          </Grid>
        </Grid>
        <Grid style={{ textAlign: "center" }} item md={5}>
          {hide ? (
            <CircularProgress size={35} className={classes.loaderStyles} />
          ) : (
            <img
              className={
                themeMode === "light"
                  ? classes.WidthStyleL
                  : classes.WidthStyleD
              }
              src={themeMode === "light" ? fishTankLight : fishtankDark}
              alt="Tank"
            />
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          xl={12}
          justify="center"
          className={classes.enjin}
        >
          <Link
            href="https://enjin.io/powered-by-enjin/tipply-tank"
            rel="noreferrer"
            target="_blank"
            style={{ margin: "auto 0" }}
          >
            <img
              style={{ margin: "5px" }}
              // height={is600 ? 30 : 80}
              width={is600 ? 100 : 170}
              src={
                themeMode === "light" ? poweredEnjinColor : poweredEnjinWhite
              }
            />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  address: state.layout.address,
  user: state.layout.user,
  network: state.layout.network,
  unique: state.layout.Unique,
  open_qrScan: state.layout.open_qrScan,
});

export default withWidth()(
  connect(mapStateToProps, {
    checkWeb3,
    checkWeb3WC,
    checkUser,
    addUser,
    getHistory,
    emailCheck,
    access_token_null,
    AT_PASS,
    qrScan,
    updateUser,
    updateAddress,
  })(Home)
);
