import {
  ERC20_ABI,
  ENJ_ADDRESS,
  CENTRAL_WALLET,
  CENTRAL_WALLET_ABI,
} from "../Contracts/Address";
import Web3 from "web3";
import axios from "axios";
import { ROUTES } from "../Utils/routes";

export const checkAllowance = async (provider, senderAddress) => {
  try {
    let web3 = new Web3(provider);
    // console.log("checking allowance");
    // console.log("mint contracts address", senderAddress);

    let contract = new web3.eth.Contract(ERC20_ABI, ENJ_ADDRESS);
    // console.log("contract", senderAddress, CENTRAL_WALLET);
    let value = await contract.methods
      .allowance(senderAddress, CENTRAL_WALLET)
      .call();
    // console.log("mint contracts value", value);
    if (value > 0) {
      // console.log("mint contracts true");
      return true;
    } else {
      // console.log("mint contracts false");
      return false;
    }
  } catch (e) {
    console.log(e);
  }
};

export const giveApproval = async (
  provider,
  senderAddress,
  handleNext,
  setPageLoaderState
) => {
  let web3 = new Web3(provider);
  let contract = new web3.eth.Contract(ERC20_ABI, ENJ_ADDRESS);

  let maxAllowance = await contract.methods.totalSupply().call();
  let amountWei = maxAllowance;

  // console.log("mint contracts", senderAddress);

  await contract.methods
    .approve(CENTRAL_WALLET, amountWei)
    .send({ from: senderAddress })
    .on("transactionHash", (hash) => {
      // console.log("mint contracts", hash);
    })
    .on("confirmation", async function (confirmationNumber, receipt) {
      if (confirmationNumber === 1) {
        // console.log("mint contracts", receipt);
        handleNext();
        setPageLoaderState(false);
      }
    })
    .on("error", (error) => {
      // console.log("mint contracts", error);
      setPageLoaderState(false);
    });
};

export const transferMintFees = async (
  provider,
  senderAddress,
  ENJamount,
  userId,
  tipplyId,
  setPageLoaderState,
  close,
  getUserTipply
) => {
  let web3 = new Web3(provider);
  let contract = new web3.eth.Contract(CENTRAL_WALLET_ABI, CENTRAL_WALLET);

  let gasPrice = await getCurrentGasPrices();
  console.log("gasprice", gasPrice);
  // console.log("gasPrice high ===>>>", gasPrice.high);
  let amountWei = web3.utils.toWei(ENJamount.toString());
  // let txFees = (
  //   ((gasPrice.high * Math.pow(10, 9)) / Math.pow(10, 18)) *
  //   200000
  // ).toString(); //jumpnet change
  // console.log("transaction fees ===>>>", txFees);

  await contract.methods
    .transferMintFeas(amountWei)
    .send({
      from: senderAddress,
      value: Web3.utils.toWei("0", "ether"), //value put to zero in first paramet
    })
    .on("transactionHash", (hash) => {
      // console.log("daniyal", hash);

      let data = {
        userID: userId,
        tipplyID: tipplyId,
        userAddress: senderAddress,
        mintFeeHash: hash,
      };
      axios.post(ROUTES.SET_MINTING, data);
      getUserTipply(senderAddress);
    })
    .on("confirmation", async function (confirmationNumber, receipt) {
      if (confirmationNumber === 1) {
        setPageLoaderState(false);
        // console.log(receipt);
        // mintTipply(senderAddress);
        //is minting true query
        // let data = {
        //   userID: userId,
        //   tipplyID: tipplyId,
        //   userAddress: senderAddress,
        // };
        // axios.post(ROUTES.SET_MINTING, data);
        getUserTipply(senderAddress);

        close();
      }
    })
    .on("error", (error) => {
      // console.log(error);
      setPageLoaderState(false);
      //rejection and error on mint fee handling
    });
};

const getCurrentGasPrices = async () => {
  try {
    const res = await fetch("https://ethgasstation.info/json/ethgasAPI.json");
    const response = await res.json();

    // console.log("response gas price", response);
    let prices = {
      low: response.safeLow / 10,
      medium: response.average / 10,
      high: response.fast / 10,
    };
    return prices;
  } catch (e) {
    // console.log(e);
  }
};
