import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import copyicon from "../../../Assets/copyicon.svg";
import copyiconWhite from "../../../Assets/copyiconWhite.svg";

import togglebutton2 from "../../../Assets/togglebutton2.svg";
import toggleButton from "../../../Assets/togglebutton.svg";

import toggleWhite from "../../../Assets/toggleWhite.svg";
import toggleWhite2 from "../../../Assets/toggleWhite2.svg";
import { getHistory } from "../../../redux/actions/authActions";
import action from "../../../Assets/action.svg";
import actionLight from "../../../Assets/action2.svg";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";

import { Link } from "@material-ui/core";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  withWidth,
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@material-ui/core";
import "../Wallet/Wallete.css";

import Snackbar from "../../Common/Snackbar/Snackbar";
import FullpageLoader from "../../Common/FullpageLoader/FullpageLoader";
import dyt from "../../../Assets/dyt.png";
import enj from "../../../Assets/enj.svg";
import eth from "../../../Assets/ethsvg.svg";

const useStyles = makeStyles((theme) => ({
  currecnyDYT: {
    width: "22px",
    margin: "auto 5px 0px",
  },
  currencyENJ: {
    width: "21px",
    height: "21px",
    margin: "-1px 5px 0px",
  },
  currecnyETH: {
    width: "25px",
    height: "25px",
    margin: "-7px 5px 0px",
  },
  currecnyDYTCard: {
    width: "21px",
    height: "21px",
    margin: "-3px 3px 0 5px",
  },

  currencyENJCard: {
    width: "21px",
    margin: "-3px 5px 0 5px",
    height: "21px",
  },
  currecnyETHCard: {
    width: "25px",
    height: "25px",
    margin: "-5px 5px 0 5px",
  },
  Scroll: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 0,
    },
    html: {
      scrollbarWidth: "none",
    },
  },
  tr: {
    border: "5px solid red",
  },
  tableHeader: {
    background: theme.palette.primary.other,
    color: theme.palette.text.secondaryWallet,
    fontWeight: "bold",
    borderBottom: "none !important",
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  tableBody: {
    border: "none",
    background: theme.palette.primary.tabRowColor,
    color: theme.palette.text.secondary,
    fontSize: "14px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      padding: "2px",
      width: "0px",
    },
  },

  input: {
    height: "32px",
    borderRadius: "5px",
    textAlign: "center",
    backgroundColor: "inherit",
    outline: "none",
    border: "1px solid",
    borderColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },

  depositButton: {
    color: "#11d561",
    border: "none",
    background: theme.palette.primary.tabRowColor,
    fontSize: "14px",
  },
  withdrawButton: {
    color: " #FF8592",
    border: "none",
    background: theme.palette.primary.tabRowColor,
    fontSize: "14px",
  },

  MintColor: {
    color: "#3388FF",
    border: "none",
    background: theme.palette.primary.tabRowColor,
    fontSize: "14px",
  },

  cardTable: {
    marginBottom: 32,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
  offChainMargin: {
    margin: "auto 20px auto 0",
    "@media only screen and (max-width:960px)": {
      margin: "auto 26px auto 0 !important",
    },
  },
  toggleButtonClass: {
    color: theme.palette.text.secondaryWallet,
  },
}));

const Txnhistory = (props) => {
  // console.log("props.transactionData", typeof props.transactionData);
  // console.log("props.transactionDataOffChain", props.transactionDataOffChain);
  const classes = useStyles();
  const themeMode = localStorage.getItem("themeMode");
  const [tokens, setTokens] = useState({ ETH: 0, DYT: 0, ENJ: 0 });
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [pageLoaderState, setPageLoaderState] = useState(false);
  const [pageLoaderMessage, setPageLoaderMessage] = useState("");
  const [toggleValue, setToggleValue] = useState(false);
  const toggleValueHandler = () => {
    setToggleValue(!toggleValue);
  };

  let ETH = "ETH +";
  let ENJ = "ENJ";
  const copyClick = () => {
    setSnackbarState(true);
    setSnackbarMessage("Copied");
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: "#FBFBFD",
    },
  }))(TableRow);

  // useEffect(() => {
  //   console.log("props.transactionData", props.transactionData)
  //   console.log("props.transactionDataOffChain", props.transactionDataOffChain)
  // }, [props])

  return (
    <Grid
      style={{ padding: 0, justifyContent: "center" }}
      container
      justify="space-around"
    >
      <FullpageLoader
        open={pageLoaderState}
        message={pageLoaderMessage}
        // handleClose={() => setPageLoaderState(false)}
      />
      <Snackbar
        open={snackbarState}
        message={snackbarMessage}
        handleClose={() => setSnackbarState(false)}
      />

      <Grid item xs="12" md="10">
        {props.width === "xs" ||
        props.width === "smc" ||
        props.width === "sm" ? (
          <>
            <Grid
              item
              xs="12"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "10px 0 ",
              }}
              className={classes.toggleButtonClass}
            >
              {themeMode === "dark" ? (
                <img
                  onClick={toggleValueHandler}
                  src={!toggleValue ? toggleButton : togglebutton2}
                  style={{
                    width: "40px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <img
                  onClick={toggleValueHandler}
                  src={!toggleValue ? toggleWhite : toggleWhite2}
                  style={{ width: "40px", marginRight: "20px" }}
                />
              )}
              <span style={{ margin: "auto 0" }}>
                {!toggleValue ? "On-chain" : "Off-chain"}
              </span>
            </Grid>
            {!toggleValue ? (
              props.transactionData ? (
                props.transactionData.map((token) => {
                  return (
                    <Card className={classes.cardTable}>
                      <CardContent>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <b>Token:</b>
                          {token.symbol === "ETH + ENJ" ? (
                            <>
                              <img
                                src={eth}
                                className={classes.currecnyETHCard}
                              />
                              {ETH}
                              <img
                                src={enj}
                                className={classes.currencyENJCard}
                              />
                              {ENJ}
                            </>
                          ) : (
                            <>
                              <img
                                src={
                                  token.symbol === "DYT"
                                    ? dyt
                                    : token.symbol === "ETH"
                                    ? eth
                                    : token.symbol === "ENJ"
                                    ? enj
                                    : ""
                                }
                                className={
                                  token.symbol === "DYT"
                                    ? classes.currecnyDYTCard
                                    : token.symbol === "ETH"
                                    ? classes.currecnyETHCard
                                    : token.symbol === "ENJ"
                                    ? classes.currencyENJCard
                                    : ""
                                }
                              />
                            </>
                          )}
                          {!token.symbol
                            ? "-"
                            : token.symbol !== "ETH + ENJ"
                            ? token.symbol
                            : ""}
                        </div>
                        <Typography>
                          <b>Amount:</b> {!token.amount ? "-" : token.amount}
                        </Typography>
                        <Typography>
                          <b>Type: </b>
                          <span
                            className={
                              token.type.toLowerCase() === "Deposite"
                                ? classes.depositButton
                                : token.type.toLowerCase() === "mint"
                                ? classes.MintColor
                                : token.type === "Withdraw"
                                ? classes.withdrawButton
                                : classes.depositButton
                            }
                          >
                            {!token.type ? "-" : token.type}
                          </span>
                        </Typography>
                        <Typography>
                          <b>TxHash: </b>{" "}
                          {!token.TxHash
                            ? "-"
                            : token.TxHash.slice(0, 5) +
                              "...." +
                              token.TxHash.slice(
                                token.TxHash.length - 5,
                                token.TxHash.length
                              )}
                          <CopyToClipboard
                            text={token.TxHash}
                            onCopy={copyClick}
                          >
                            <img
                              src={
                                themeMode === "dark" ? copyicon : copyiconWhite
                              }
                              style={{
                                width: "13px",
                                marginLeft: "5px",
                                cursor: "pointer",
                              }}
                            />
                          </CopyToClipboard>
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Typography style={{ margin: "auto" }}>
                          <b>Action:</b>
                          {themeMode === "light" ? (
                            <Link
                              href={`https://etherscan.io/tx/${token.TxHash}`}
                              rel="noreferrer"
                            >
                              {" "}
                              <img
                                src={actionLight}
                                style={{ width: "13px" }}
                              />
                            </Link>
                          ) : (
                            <Link
                              href={`https://etherscan.io/tx/${token.TxHash}`}
                              target="_blank"
                            >
                              {" "}
                              <img src={action} style={{ width: "13px" }} />
                            </Link>
                          )}
                        </Typography>
                      </CardActions>
                    </Card>
                  );
                })
              ) : (
                <>
                  <Card className={classes.cardTable}>
                    <CardContent>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <b>Token:</b> {"-"}
                      </div>
                      <Typography>
                        <b> Type:</b> {"-"}
                      </Typography>
                      <Typography>
                        <b> Name:</b> {"-"}
                      </Typography>
                      <Typography>
                        <b> Amount:</b> {"-"}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Typography style={{ margin: "auto" }}>
                        <b> Date:</b>
                        {"-"}
                      </Typography>
                    </CardActions>
                  </Card>

                  <Card className={classes.cardTable}>
                    <CardContent>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <b>Token:</b> {"-"}
                      </div>
                      <Typography>
                        <b> Type:</b> {"-"}
                      </Typography>
                      <Typography>
                        <b> Name:</b> {"-"}
                      </Typography>
                      <Typography>
                        <b> Amount:</b> {"-"}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Typography style={{ margin: "auto" }}>
                        <b> Date:</b>
                        {"-"}
                      </Typography>
                    </CardActions>
                  </Card>

                  <Card className={classes.cardTable}>
                    <CardContent>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <b>Token:</b> {"-"}
                      </div>
                      <Typography>
                        <b> Type:</b> {"-"}
                      </Typography>
                      <Typography>
                        <b> Name:</b> {"-"}
                      </Typography>
                      <Typography>
                        <b> Amount:</b> {"-"}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Typography style={{ margin: "auto" }}>
                        <b> Date:</b>
                        {"-"}
                      </Typography>
                    </CardActions>
                  </Card>
                </>
              )
            ) : props.transactionDataOffChain.length !== 0 ? (
              props.transactionDataOffChain.map((token) => {
                return (
                  <Card className={classes.cardTable}>
                    <CardContent>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <b>Token:</b>{" "}
                        <img
                          src={
                            token.currency === "DYT"
                              ? dyt
                              : token.currency === "ETH"
                              ? eth
                              : token.currency === "ENJ"
                              ? enj
                              : ""
                          }
                          className={
                            token.currency === "DYT"
                              ? classes.currecnyDYTCard
                              : token.currency === "ETH"
                              ? classes.currecnyETHCard
                              : token.currency === "ENJ"
                              ? classes.currencyENJCard
                              : ""
                          }
                        />
                        {!token.currency ? "-" : token.currency}
                      </div>
                      <Typography>
                        <b> Type:</b>{" "}
                        {!token.typeOfEvent ? "-" : token.typeOfEvent}
                      </Typography>
                      <Typography>
                        <b> Name:</b> {!token.name ? "-" : token.name}
                      </Typography>
                      <Typography>
                        <b> Amount:</b> {!token.amount ? "-" : token.amount}
                      </Typography>
                      <Typography>
                        <b> Count:</b> {!token.count ? "-" : token.count}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Typography style={{ margin: "auto" }}>
                        <b> Date:</b>{" "}
                        {!token.date
                          ? "-"
                          : moment(token.date).format("DD-MM-YYYY")}
                      </Typography>
                    </CardActions>
                  </Card>
                );
              })
            ) : (
              <>
                <Card className={classes.cardTable}>
                  <CardContent>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <b>Token:</b> {"-"}
                    </div>
                    <Typography>
                      <b> Type:</b> {"-"}
                    </Typography>
                    <Typography>
                      <b> Name:</b> {"-"}
                    </Typography>
                    <Typography>
                      <b> Amount:</b> {"-"}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Typography style={{ margin: "auto" }}>
                      <b> Date:</b>
                      {"-"}
                    </Typography>
                  </CardActions>
                </Card>

                <Card className={classes.cardTable}>
                  <CardContent>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <b>Token:</b> {"-"}
                    </div>
                    <Typography>
                      <b> Type:</b> {"-"}
                    </Typography>
                    <Typography>
                      <b> Name:</b> {"-"}
                    </Typography>
                    <Typography>
                      <b> Amount:</b> {"-"}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Typography style={{ margin: "auto" }}>
                      <b> Date:</b>
                      {"-"}
                    </Typography>
                  </CardActions>
                </Card>

                <Card className={classes.cardTable}>
                  <CardContent>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <b>Token:</b> {"-"}
                    </div>
                    <Typography>
                      <b> Type:</b> {"-"}
                    </Typography>
                    <Typography>
                      <b> Name:</b> {"-"}
                    </Typography>
                    <Typography>
                      <b> Amount:</b> {"-"}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Typography style={{ margin: "auto" }}>
                      <b> Date:</b>
                      {"-"}
                    </Typography>
                  </CardActions>
                </Card>
              </>
            )}
          </>
        ) : (
          <>
            {toggleValue ? (
              <TableContainer
                style={{ maxHeight: 390 }}
                className={classes.Scroll}
              >
                <Table
                  stickyHeader
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0px 12px",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader} align="center">
                        Token
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center">
                        Name
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center">
                        Type
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center">
                        Amount
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center">
                        Count
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center">
                        Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {props.transactionDataOffChain == "" ? (
                    <>
                      <TableBody>
                        <StyledTableRow>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                        </StyledTableRow>
                      </TableBody>
                      <TableBody>
                        <StyledTableRow>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                        </StyledTableRow>
                      </TableBody>
                      <TableBody>
                        <StyledTableRow>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                        </StyledTableRow>
                      </TableBody>
                    </>
                  ) : (
                    <TableBody>
                      {props.transactionDataOffChain.map((token) => {
                        return (
                          <StyledTableRow>
                            <TableCell
                              className={classes.tableBody}
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={
                                    token.currency === "DYT"
                                      ? dyt
                                      : token.currency === "ETH"
                                      ? eth
                                      : token.currency === "ENJ"
                                      ? enj
                                      : ""
                                  }
                                  className={
                                    token.currency === "DYT"
                                      ? classes.currecnyDYT
                                      : token.currency === "ETH"
                                      ? classes.currecnyETH
                                      : token.currency === "ENJ"
                                      ? classes.currencyENJ
                                      : ""
                                  }
                                />
                                {!token.currency ? "-" : token.currency}
                              </div>
                            </TableCell>
                            <TableCell
                              className={classes.tableBody}
                              align="center"
                            >
                              {!token.name ? "-" : token.name}
                            </TableCell>
                            <TableCell
                              className={classes.tableBody}
                              align="center"
                            >
                              {!token.typeOfEvent ? "-" : token.typeOfEvent}
                            </TableCell>
                            <TableCell
                              className={classes.tableBody}
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {!token.amount ? "-" : token.amount}
                                {/* <img src={copyicon} style={{ width: '13px', marginLeft: '5px' }} /> */}
                              </div>
                            </TableCell>
                            <TableCell
                              className={classes.tableBody}
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {!token.count ? "-" : token.count}
                                {/* <img src={close} style={{ width: '18px', marginLeft: '5px' }} /> */}
                              </div>
                            </TableCell>
                            <TableCell
                              className={classes.tableBody}
                              align="center"
                            >
                              {!token.date
                                ? "-"
                                : moment(token.date).format("DD-MM-YYYY")}
                            </TableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            ) : (
              <TableContainer
                style={{ maxHeight: 390 }}
                className={classes.Scroll}
              >
                <Table
                  stickyHeader
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0px 12px",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader} align="center">
                        Token
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center">
                        Amount
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center">
                        Type
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center">
                        Tx Hash
                      </TableCell>
                      {/* <TableCell className={classes.tableHeader} align="center">
                                                                            Status
                                              </TableCell> */}
                      <TableCell className={classes.tableHeader} align="center">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {props.transactionData == "" ? (
                    <>
                      <TableBody>
                        <StyledTableRow>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {"-"}
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {"-"}
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                        </StyledTableRow>
                      </TableBody>

                      <TableBody>
                        <StyledTableRow>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {"-"}
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {"-"}
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                        </StyledTableRow>
                      </TableBody>

                      <TableBody>
                        <StyledTableRow>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {"-"}
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {"-"}
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.tableBody}
                            align="center"
                          >
                            {"-"}
                          </TableCell>
                        </StyledTableRow>
                      </TableBody>
                    </>
                  ) : (
                    <TableBody>
                      {props.transactionData.map((token) => {
                        return (
                          <StyledTableRow>
                            <TableCell
                              className={classes.tableBody}
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {token.symbol === "ETH + ENJ" ? (
                                  <>
                                    <img
                                      src={eth}
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        margin: "-6px 0px 0px 0px",
                                      }}
                                    />
                                    {ETH}
                                    <img
                                      src={enj}
                                      style={{
                                        width: "21px",
                                        margin: "-1px 5px 0 5px",
                                        height: "21px",
                                      }}
                                    />
                                    {ENJ}
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={
                                        token.symbol === "DYT"
                                          ? dyt
                                          : token.symbol === "ETH"
                                          ? eth
                                          : token.symbol === "ENJ"
                                          ? enj
                                          : ""
                                      }
                                      className={
                                        token.symbol === "DYT"
                                          ? classes.currecnyDYT
                                          : token.symbol === "ETH"
                                          ? classes.currecnyETH
                                          : token.symbol === "ENJ"
                                          ? classes.currencyENJ
                                          : ""
                                      }
                                    />
                                  </>
                                )}
                                {!token.symbol
                                  ? "-"
                                  : token.symbol !== "ETH + ENJ"
                                  ? token.symbol
                                  : ""}
                              </div>
                            </TableCell>
                            <TableCell
                              className={classes.tableBody}
                              align="center"
                            >
                              {!token.amount ? "-" : token.amount}
                            </TableCell>
                            <TableCell
                              className={
                                token.type.toLowerCase() === "deposite"
                                  ? classes.depositButton
                                  : token.type.toLowerCase() === "mint"
                                  ? classes.MintColor
                                  : token.type.toUpperCase() === "WITHDRAW"
                                  ? classes.withdrawButton
                                  : classes.depositButton
                              }
                              align="center"
                            >
                              {!token.type ? "-" : token.type}
                            </TableCell>
                            <TableCell
                              className={classes.tableBody}
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {!token.TxHash
                                  ? "-"
                                  : token.TxHash.slice(0, 5) +
                                    "...." +
                                    token.TxHash.slice(
                                      token.TxHash.length - 5,
                                      token.TxHash.length
                                    )}
                                <CopyToClipboard
                                  text={token.TxHash}
                                  onCopy={copyClick}
                                >
                                  <img
                                    src={
                                      themeMode === "dark"
                                        ? copyicon
                                        : copyiconWhite
                                    }
                                    style={{
                                      width: "13px",
                                      marginLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </CopyToClipboard>
                              </div>
                            </TableCell>
                            {/* <TableCell className={classes.tableBody} align="center">
                                                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                        Rejected
                                                                               <img src={close} style={{ width: '18px', marginLeft: '5px' }} />
                                                                                    </div>
                                                                                </TableCell> */}
                            <TableCell
                              className={classes.tableBody}
                              align="center"
                            >
                              {themeMode === "light" ? (
                                <Link
                                  href={`https://etherscan.io/tx/${token.TxHash}`}
                                  rel="noreferrer"
                                >
                                  {" "}
                                  <img
                                    src={actionLight}
                                    style={{ width: "13px" }}
                                  />
                                </Link>
                              ) : (
                                <Link
                                  href={`https://etherscan.io/tx/${token.TxHash}`}
                                  target="_blank"
                                >
                                  {" "}
                                  <img src={action} style={{ width: "13px" }} />
                                </Link>
                              )}
                            </TableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
            <Grid
              item
              xs="12"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "10px 0 0 0",
              }}
              className={classes.toggleButtonClass}
            >
              {themeMode === "dark" ? (
                <img
                  onClick={toggleValueHandler}
                  src={!toggleValue ? toggleButton : togglebutton2}
                  style={{ width: "40px", marginRight: "20px" }}
                />
              ) : (
                <img
                  onClick={toggleValueHandler}
                  src={!toggleValue ? toggleWhite : toggleWhite2}
                  style={{ width: "40px", marginRight: "20px" }}
                />
              )}
              <span>{!toggleValue ? "On-chain" : "Off-chain"}</span>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default withWidth()(connect("", { getHistory })(Txnhistory));
