import styled, { keyframes, css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import React from "react";

import "../RandomTipply/RandomTipply.css";

const useStyles = makeStyles((theme) => ({
  speechBubble: {
    position: "absolute",
    left: "65%",
    zIndex: 2,
    fontFamily: "Lemonada, cursive",
    fontSize: "16px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "100% 100%",
    margin: "0 auto",
    textAlign: "center",
    height: 0,
    boxSizing: "content-box",
    lineHeight: 1,
    width: "30%",
    color: theme.palette.secondary.other,
    paddingTop: "6%",
    paddingBottom: "20%",
    "@media only screen and (max-width:600px)": {
      width: "40% !important",
      fontSize: "12px",
    },
  },
}));

const fishAnimation = keyframes`
 0% {
  transform: rotateX(0deg) translate(0 , 0);
  transform-origin: center;
}
 25% {
  transform: rotateX(15deg) translate(50% , 0);
  transform-origin: center;
}
50% {
  transform: rotateX(0deg) translate(100% , 0);
  transform-origin: center;
}
 75% {
  transform: rotateX(-15deg) translate(50% , 0);
  transform-origin: center;
}
100%{
  transform: rotateX(0deg) translate(0 , 0);
  transform-origin: center;
}
`;

const fishFeedAnimation = keyframes`
0%{
  transform : translate(0,0) 
}
10%{
  transform : translate(5px,-10px) 
}
20%{
  transform : translate(10px,-20px)   
}
30%{
  transform : translate(15px,-30px)
}
40%{
  transform : translate(20px,-40px)
}
50%{
  transform : translate(25px,-50px)
}
60%{
  transform : translate(30px,-40px) 
}
70%{
  transform : translate(35px,-30px)
}
80%{
  transform : translate(40px,-20px)
}
90%{
  transform : translate(45px,-10px)
}
100%{
  transform : translate(50px,0)
}
`;

const Container = styled((props) => {
  {
    // console.log("test fish dead test", props.isDead);
  }
  return <div {...props}>{props.child}</div>;
})`
  ${(props) => {
    return props.isDead
      ? css` 
      position: absolute;
      transform: rotate(180deg) scaleX(-1) !important;
      // left: 50%;
      top: 350%;
      @media (min-width:1800px) and (max-width:7000px){
        top: 520% !important;
        left:calc(50% - 100px) !important;
      }

      @media (min-width: 850px) and (max-width: 960px) {
        top: 270% !important;
        transform: rotate(180deg) scaleX(-1) !important;
      }
      @media (min-width: 496px) and (max-width: 850px) {
        top: 240% !important;
        transform: rotate(180deg) scaleX(-1) !important;
      }
      @media (min-width: 496px) and (max-width: 496px) {
        top: 235% !important;
        transform: rotate(180deg) scaleX(-1) !important;
      }
      @media (min-width: 408px) and (max-width: 496px) {
        top: 230% !important;
        transform: rotate(180deg) scaleX(-1) !important;
      }
      @media (min-width: 300px) and (max-width: 408px) {
        top: 230% !important;
        transform: rotate(180deg) scaleX(-1) !important;
      }
      @media (min-width: 100px) and (max-width: 300px) {
        top: 212% !important;
        transform: rotate(180deg) scaleX(-1) !important;
      }
    }`
      : !props.isDead
      ? css`
          animation: ${fishAnimation} infinite 4s linear;
          position: absolute;
          top: 70%;
          // @media (min-width: 496px) and (max-width: 600px) {
          //   top: 100% !important;
          // }
          @media (min-width: 408px) and (max-width: 600px) {
            top: 90% !important;
          }
          @media (min-width: 100px) and (max-width: 300px) {
            top: 66% !important;
          }
        `
      : "";
  }}
`;

export const TestFish = (props) => {
  const [index, setIndex] = React.useState(1);
  React.useEffect(() => {
    shuffle(exclamations);
  }, [props.animationSpeech]);
  const classes = useStyles();
  let exclamations = [
    "Nom Nom",
    "I was starved, finally!!",
    "Yummy!!",
    "This tastes better today!",
    "Delicious!",
    "Hmm, i want more of this.",
    "Delectable!",
    "What a delight!",
    "More please.",
    "I can't get enough of this.",
    "Hmm, I am almost full now.",
    "Yayy! It is just what i was craving for.",
    "What a scrumptious meal!!",
    "Yummm, that was delish!!",
    "This food is divine!",
  ];
  const shuffle = (exclamations) => {
    let arrayLength = exclamations.length;
    setIndex(Math.floor(Math.random() * arrayLength));
    return exclamations[index];
  };

  return (
    <>
      {" "}
      {props.animationSpeech ? (
        <section>
          <div className={classes.speechBubble}>{exclamations[index]}</div>
        </section>
      ) : (
        ""
      )}
      <Container
        className="fish"
        isDead={props.isDead}
        child={props.children}
      />
    </>
  );
};
