import React from "react";

import { Grid } from "@material-ui/core";
import { getHistory } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import StatsHeader from "../../Components/Common/Statsbar/LeaderStatsBar";
import LeaderBoardTab from "./LeaderBoardTab";
import axios from "axios";
import Snackbar from "../../Components/Common/Snackbar/Snackbar";

import { ROUTES } from "../../Utils/routes";

const LeaderBoard = (props) => {
  const [snackbarState, setSnackbarState] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const [leadingFreeTipplys, setLeadingFreeTipplys] = React.useState([]);
  const [leadingPaidTipplys, setLeadingPaidTipplys] = React.useState([]);
  const [minted, setMinted] = React.useState([]);
  const [winnerTipplys, setWinnerTipplys] = React.useState([]);
  const [minted_Owner, setMinted_Owner] = React.useState([]);
  const [winnerTipplys_Owner, setWinnerTipplys_Owner] = React.useState([]);

  const [leadingFreeTipplysOwner, setLeadingFreeTipplysOwner] = React.useState(
    []
  );
  const [leadingPaidTipplysOwner, setLeadingPaidTipplysOwner] = React.useState(
    []
  );

  React.useEffect(() => {
    props.getHistory("/leaderBoard");

    const getTipplies = async () => {
      let response, response2, response3;
      try {
        response = await axios.get(ROUTES.LEADING_FREE_TIPPLYS);
        // console.log("freee", response.data.tipplies);
        setLeadingFreeTipplys(response.data.tipplies);
        setLeadingFreeTipplysOwner(response.data.owners);
      } catch (e) {
        console.log(e, "free error");
        
      }
      try {
        response2 = await axios.get(ROUTES.LEADING_PAID_TIPPLYS);
        // console.log("freee", response, response2, "paidd");
        setLeadingPaidTipplysOwner(response2.data.owners);
        setLeadingPaidTipplys(response2.data.tipplies);
      } catch (e) {
        console.log(e, "paid error");
       
      }
      try {
        response3 = await axios.get(ROUTES.LEADER_BOARD);
        // console.log(response3, "LEADERBOARD");
        setWinnerTipplys(response3.data.WinnerTipplys);
        setMinted(response3.data.mintedTipplys);
        setMinted_Owner(response3.data.Minted_owner);
        setWinnerTipplys_Owner(response3.data.Winner_owner);
      } catch (e) {
        console.log(e, "leaderboard error");
        
      }
    };
    getTipplies();
  }, []);

  return (
    <Grid style={{ width: "100%" }}>
      <Snackbar
        open={snackbarState}
        autoHideDuration={25}
        message={snackbarMessage}
        handleClose={() => setSnackbarState(false)}
      />
      <StatsHeader />
      <Grid container item xs={12} justify="center">
        <LeaderBoardTab
          leadingFreeTipplys={leadingFreeTipplys}
          leadingPaidTipplys={leadingPaidTipplys}
          leadingPaidTipplysOwner={leadingPaidTipplysOwner}
          leadingFreeTipplysOwner={leadingFreeTipplysOwner}
          minted={minted}
          winnerTipplys={winnerTipplys}
          minted_Owner={minted_Owner}
          Winner_Owner={winnerTipplys_Owner}
          setSnackbarState={setSnackbarState}
          setSnackbarMessage={setSnackbarMessage}
        />
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  historyTipply: state.auth.historyTipply,
});

export default connect(mapStateToProps, { getHistory })(LeaderBoard);
