import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import randomTipply from "../../../Assets/random_tipply.png";
import { LinearProgress, withWidth } from "@material-ui/core";

import styled, { keyframes, css } from "styled-components";

import { connect } from "react-redux";
import {
  enableFeedAnimation,
  disableFeedAnimation,
} from "../../../redux/actions/animationActions";

import Snackbar from "../../Common/Snackbar/Snackbar";
import RandomTipply from "../RandomTipply/RandomTipply";
import "./activated.css";

const useStyles = makeStyles((theme) => ({
  fish: {
    position: "absolute",
    top: "33%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    "@media (min-width:300px) and (max-width: 415px)": {
      top: "28% !important",
    },
  },

  activeFishText: {
    color: theme.palette.text.activeColor,
    margin: "0 15px ",
    backgroundColor: theme.palette.text.activebg,
    padding: 4,
    borderRadius: 12,
    fontWeight: "bold",
    "@media only screen and (max-width: 1085px)": {
      margin: "0 6px !important",
    },
    "@media only screen and (max-width: 750px)": {
      margin: "0 3px !important",
    },
    "@media only screen and (max-width: 600px)": {
      margin: "0 3px !important",
      fontSize: "13px !important",
    },
  },
  activeFishText2: {
    color: theme.palette.text.activeColor,
    margin: "0 15px ",
    backgroundColor: theme.palette.text.activebg,
    padding: 4,
    borderRadius: 12,
    fontWeight: "bold",
    "@media only screen and (max-width: 1085px)": {
      margin: "0 6px !important",
    },
    "@media only screen and (max-width: 750px)": {
      margin: "0 3px !important",
    },
    "@media only screen and (max-width: 600px)": {
      margin: "0 15px !important",
      fontSize: "13px !important",
    },
  },

  activeFishEnergy: {
    margin: "auto",
    marginTop: "12px",
    paddingBottom: "20px",
  },

  barDead: {
    background: "#000000",
  },
  barStarving: {
    background: "#CF0015",
  },
  barNormal: {
    background: "#FBBE3F",
  },
  barHealthy: {
    background: "#0EDF62",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 7,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    border: "1px solid white !important",
  },

  bar: {
    borderRadius: 7,
  },
}))(LinearProgress);

const ActivatedTipply = ({
  activeTipply,
  width,
  feedAnimation,
  tipply_type,
  level,
}) => {
  const classes = useStyles();
  var energyPercentage = activeTipply.energy;

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //Food and Food Feeding Animation for active tipply (start)
  let foodFeedAnimation;

  // useEffect(() => {
  //   if (tipply_type === "Free" && level === 50) {
  //     setSnackbarState(true);
  //     setSnackbarMessage("Your Tipply has won");
  //   }
  // }, [level]);

  if (width !== "xs" && width !== "sm" && width !== "smc" && width !== "tb") {
    foodFeedAnimation = keyframes`
   0%{
      transform : translate(0,0) 
   }
   100%{
     transform : translate(-25px,130px);
   }
`;
  }
  if (width === "tb" || width === "smc") {
    foodFeedAnimation = keyframes`
    0%{
       transform : translate(0,0) 
    }
    50%{
      transform : translate(-10px,20px) 
   }
    100%{
      transform : translate(-25px,200px);
    }
 `;
  }
  if (width === "sm") {
    foodFeedAnimation = keyframes`
    0%{
       transform : translate(0,0) 
    }
    20%{
      transform : translate(20px,10px);
    }
    100%{
      transform : translate(-20px,180px);
    }
 `;
  }

  if (width === "xs") {
    foodFeedAnimation = keyframes`
    0%{
       transform : translate(0,0) 
    }
    20%{
      transform : translate(20px,10px);
    }
    100%{
      transform : translate(-20px,150px);
    }
 `;
  }
  let Food;
  if (tipply_type === "paid") {
    Food = styled.img`
      animation: ${foodFeedAnimation} infinite 3s linear;
      position: absolute;
      height: 40px;
      margin-left: 32px;
      z-index: 100;
    `;
    Food.defaultProps = {
      src: feedAnimation && feedAnimation.animationImage,
    };
  }
  if (tipply_type === "Free") {
    Food = styled.img`
      position: absolute;
      height: 200px;
      margin-top: -55px;
      margin-left: -20px;
      z-index: 100;
    `;
    Food.defaultProps = {
      src: feedAnimation && feedAnimation.animationImage,
    };
  }

  //Food and Food Feeding Animation for active tipply (end)

  return (
    <div className={classes.fish}>
      <Snackbar
        open={snackbarState}
        message={snackbarMessage}
        handleClose={() => setSnackbarState(false)}
      />
      {feedAnimation && feedAnimation.animationState ? <Food /> : ""}
      <div style={{ margin: "-10px 0 0 0" }}>
        <span className={classes.activeFishText2}>{activeTipply.name}</span>
        {width === "sm" || width === "xs" ? (
          <>
            {" "}
            <br /> <br />
          </>
        ) : (
          ""
        )}
        <span className={classes.activeFishText}>
          Level: {activeTipply.level}
        </span>
        <span className={classes.activeFishText}>
          Age:{" "}
          {activeTipply.bornAt
            ? Math.round(
                (new Date().getTime() -
                  new Date(activeTipply.bornAt).getTime()) /
                  (1000 * 60 * 60 * 24)
              )
            : 0}
        </span>
      </div>

      <div className={classes.activeFishEnergy}>
        <BorderLinearProgress
          classes={{
            barColorPrimary:
              energyPercentage === 0
                ? classes.barDead
                : energyPercentage > 0 && energyPercentage <= 30
                ? classes.barStarving
                : energyPercentage > 30 && energyPercentage <= 69
                ? classes.barNormal
                : // : energyPercentage > 69 && energyPercentage <= 99
                  // ?
                  classes.barHealthy,
            // : "",
          }}
          variant="determinate"
          value={energyPercentage < 100 ? energyPercentage : 100}
        />
      </div>
      {/* <img height={128} src={randomTipply} /> */}
      {/* {feedAnimation && feedAnimation.animationSpeech ? (
        <section>
          <blockquote className={classes.speechBubble}>Yummy !!!</blockquote>
        </section>
      ) : (
        ""
      )} */}
      {activeTipply ? (
        <RandomTipply
          image={activeTipply.image}
          type="fishTank"
          tipply_type={tipply_type}
          id={activeTipply._id}
          isDead={activeTipply.isDead}
          animationSpeech={
            feedAnimation && feedAnimation.animationSpeech
              ? feedAnimation.animationSpeech
              : false
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  feedAnimation: state.animation.feedAnimation,
});

export default withWidth()(
  connect(mapStateToProps, {
    enableFeedAnimation,
    disableFeedAnimation,
  })(ActivatedTipply)
);
