import { GET_HISTORY, SET_CURRENT_USER, PIN_CHECK, PIN_NULL } from "../types";
import axios from "axios";
import { ROUTES } from "../../Utils/routes";
// import setAuthToken from "../../utils/setAuthToken";
import jwtDecode from "jwt-decode";
import sweetAlert from "sweetalert";
import { responsiveFontSizes } from "@material-ui/core";

//Register User
export const registerUser = (userData, history) => (dispatch) => { };


export const CheckPin = (address) => async (dispatch) => {
  let response;
  let res;
  try {
    response = await axios.get(`${ROUTES.GET_SECURITY_PIN}/${address}`);
    // console.log(response, "pinnn response in checkPin")
    if (response) { res = true }
  } catch (e) {
    res = false;
    // console.log(e.response.status, "pinnnnnnn");
  }
  dispatch({
    type: PIN_CHECK,
    payload: res,
  });
};

export const PinNull = () => {
  return async (dispatch) => {
    dispatch({
      type: PIN_NULL,
      payload: null,
    });
  };
};
//Login & get Token
export const loginUser = (userData) => (dispatch) => { };

//Set Current user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
export const getHistory = (history) => {
  return {
    type: GET_HISTORY,
    payload: history,
  };
};


//Log user out
export const logoutUser = () => (dispatch) => { };

//FB login Route
// export const fbLogin = res => dispatch =>{

//     //get token form response
//     const { accessToken } = res;

//     //Save to local storage
//     localStorage.setItem("jwtToken", "Bearer "+accessToken);

//     //decode token to get data
//     const decoded = jwtDecode(accessToken);
//     //set current user
//     dispatch(setCurrentUser(decoded));
//     sweetAlert({
//       title: "You are now logged in to Sweet Panda !",
//       icon: "success"
//     });
// }