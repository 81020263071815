import Web3 from "web3";

export default class getWalletAccount {
  static init = async () => {
    try {
      console.log("web3 ethereum ==>>> ", window.ethereum);
      if (!this.web3js) {
        this.web3js = new Web3(window.ethereum);
      }
      this.accounts = await this.web3js.eth.getAccounts();
      this.getNetwork = await this.web3js.eth.net.getNetworkType();
      return {
        address: this.accounts[0],
        network: this.getNetwork,
      };
    } catch (e) {
      console.error("ERROR init => ", e);
    }
  };
}
