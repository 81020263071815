import {
  CHECK_WEB3,
  CHECK_USER,
  ADD_USER,
  EMAIL_CHECK,
  DISABLE_RELOAD,
  OPEN_QRSCAN,
  UPDATE_ADDRESS,
  UPDATE_USER,
} from "../types";

const initState = {
  address: null,
  network: null,
  user: null,
  Unique: null,
  check: null,
  open_qrScan: false,
};

const LayoutReducer = (state = initState, action) => {
  switch (action.type) {
    case CHECK_WEB3:
      return {
        ...state,
        address: action.payload.address,
        network: action.payload.network,
      };

    case CHECK_USER:
      return {
        ...state,
        user: action.payload,
      };

    case OPEN_QRSCAN:
      return {
        ...state,
        open_qrScan: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,

        user: action.payload,
      };
    case UPDATE_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case ADD_USER:
      console.log(action.payload, ",In add user reducer case");
      if (action.payload !== 409) {
        return {
          ...state,
          user: action.payload.data.data,
          Unique: null,
        };
      } else {
        return {
          ...state,
          Unique: action.payload,
        };
      }
    default:
      return state;

    case EMAIL_CHECK:
      return {
        ...state,
        Unique: null,
      };
    case DISABLE_RELOAD:
      return {
        ...state,
        reload: false,
      };
  }
};

export default LayoutReducer;
