import ContractInits from "../config/contractsInit";
import {
  CHECK_WEB3,
  CHECK_USER,
  ADD_USER,
  EMAIL_CHECK,
  OPEN_QRSCAN,
  UPDATE_ADDRESS,
  UPDATE_USER,
} from "../types";
import { ROUTES } from "../../Utils/routes";
import axios from "axios";
import { createChainedFunction } from "@material-ui/core";

export const checkWeb3 = () => {
  return async (dispatch) => {
    dispatch({
      type: CHECK_WEB3,
      payload: await ContractInits.init(),
    });
  };
};

export const checkWeb3WC = (myPayload) => {
  return async (dispatch) => {
    dispatch({
      type: CHECK_WEB3,
      payload: myPayload,
    });
  };
};

export const qrScan = (value) => {
  return async (dispatch) => {
    dispatch({
      type: OPEN_QRSCAN,
      payload: value,
    });
  };
};

export const updateUser = (user) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_USER,
      payload: user,
    });
  };
};
export const updateAddress = (address) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_ADDRESS,
      payload: address,
    });
  };
};
export const checkUser = (address) => {
  return async (dispatch) => {
    try {
      let response = await axios.post(ROUTES.GET_USER_BY_ADDRESS, { address });

      dispatch({
        type: CHECK_USER,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: CHECK_USER,
        payload: null,
      });
    }
  };
};

export const addUser = (email, username, address, enjinData, enjinUsername) => {
  console.log(enjinUsername, enjinData, "enjinuserName");
  return async (dispatch) => {
    let req;
    if (enjinData) {
      try {
        req = await axios.post(ROUTES.ADD_USER, {
          email: email,
          name: username,
          address: address,
          enjinData: enjinData,
          userName: enjinUsername,
        });
        dispatch({
          type: ADD_USER,
          payload: req,
        });
      } catch (e) {
        req = e.response.status;
        console.log(req, "error status");
        dispatch({
          type: ADD_USER,
          payload: req,
        });
      }
    } else {
      try {
        req = await axios.post(ROUTES.ADD_USER, {
          email: email,
          name: username,
          address: address,
          enjinData: null,
          userName: null,
        });
        dispatch({
          type: ADD_USER,
          payload: req,
        });
      } catch (e) {
        if (e.response) {
          req = e.response.status;
        } else {
          req = null;
        }
        console.log(req, "error status");
        dispatch({
          type: ADD_USER,
          payload: req,
        });
      }
    }
  };
};

export const emailCheck = (unique) => {
  return async (dispatch) => {
    dispatch({
      type: EMAIL_CHECK,
      payload: unique,
    });
  };
};
