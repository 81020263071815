import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { ROUTES } from "../../Utils/routes";
import axios from "axios";

import { Grid, withWidth } from "@material-ui/core";
import SocialIcon from "../../Components/Common/SocialIcons/SocialIcons";

import Wallet from "../../Components/Wallet/Wallet/Wallet";
import Txnhistory from "../../Components/Wallet/TransactionHistory/TransactionHistory";
import Statsbar from "../../Components/Common/Statsbar/Statsbar";

import { connect } from "react-redux";
import { getWalletBalance } from "../../redux/actions/walletActions";
import { access_token_null } from "../../redux/actions/pinAction";
import { checkUser } from "../../redux/actions/layoutActions";
import { getHistory, CheckPin } from "../../redux/actions/authActions";

import {
  checkAllowanceDYT,
  checkAllowanceENJ,
} from "../../Contracts/depositFunctions";

import { useWeb3React } from "@web3-react/core";

import { walletconnect } from "../../Utils/connector";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  rootreal: {
    flexGrow: 1,
    backgroundColor: "inherit",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    [theme.breakpoints.down("sm")]: {
      borderRadius: "none",
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: "inherit",
    padding: 0,
  },
  ".MuiBox-root-20": {
    padding: 0,
  },
  tabs: {
    maxWidth: "540px",
    margin: "auto",
    background: theme.palette.primary.main,
    color: theme.palette.text.primary2,
  },

  transactionText: {
    color: theme.palette.text.primary,
  },

  socialIconSetting: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

function SimpleTabs(props) {
  const context = useWeb3React();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  // const themeMode = localStorage.getItem("themeMode");

  const [transactionData, setTransactionData] = useState("");
  const [transactionDataOffChain, setTransactionDataOffChain] = useState("");

  const [dytApproval, setDytApproval] = useState(false);
  const [enjApproval, setEnjApproval] = useState(false);

  const SetAllowance = async () => {
    setDytApproval(
      await checkAllowanceDYT(
        context.library ? context.library.provider : window.ethereum,
        props.address
      )
    );
    setEnjApproval(
      await checkAllowanceENJ(
        context.library ? context.library.provider : window.ethereum,
        props.address
      )
    );
  };

  useEffect(() => {
    if (context.library || window.ethereum) {
      SetAllowance();
    }
  }, [props.address]);

  useEffect(() => {
    if (context.library || window.ethereum) {
      SetAllowance();
    }
  }, [context.active]);

  useEffect(() => {
    // alert(context.active);
    // console.log(props.user._id, "user wallet me");
    if (context.library || window.ethereum) {
      props.getWalletBalance(
        context.library ? context.library.provider : window.ethereum,
        props.address
      );
    }
    props.getHistory("/wallet");
  }, []);

  const onWalletConnect = async () => {
    await context.activate(walletconnect);
    // console.log("context account", context);
    // alert("context", context);
    // await props.checkWeb3WC({
    // address: context.account,
    // network: context.network,
    // });
    // if (!props.user) {
    //   handleModalOpen();
    // } else {
    //   userFound();
    // }
    // console.log("Current context", context);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log(value, "value");
  };

  //txnhistory

  useEffect(() => {
    let OffChainBody = {
      userID: props.user._id,
    };
    const getDataOffChain = async () => {
      let responseOfTransaction = await axios.post(
        `${ROUTES.GET_USER_EVENTS}`,
        OffChainBody
      );
      setTransactionDataOffChain(responseOfTransaction.data.reverse());
    };
    getDataOffChain();
    let userInfo = {
      user: props.address,
    };
    const getData = async () => {
      let responseOfTransaction = await axios.post(
        `${ROUTES.GET_TRANSACTION_BY_USER}`,
        userInfo
      );

      setTransactionData(responseOfTransaction.data.data.reverse());
    };
    getData();
  }, []);

  return (
    <Grid
      xs={12}
      item
      className={
        props.width === "smc" || props.width === "xs" || props.width === "sm"
          ? classes.socialIconSetting
          : " "
      }
    >
      <Statsbar></Statsbar>
      <div style={{ padding: "16px" }}>
        <Grid
          container
          md={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <AppBar className={classes.tabs} position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              variant="fullWidth"
            >
              <Tab
                style={{ fontWeight: "bold", textTransform: "none" }}
                label="Wallet"
                {...a11yProps(0)}
              />
              <Tab
                style={{ fontWeight: "bold", textTransform: "none" }}
                label="Transaction History"
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
        </Grid>
        <TabPanel value={value} index={0}>
          <Wallet
            user={props.user}
            access_token_null={props.access_token_null}
            walletBalance={props.walletBalance}
            getWalletBalance={props.getWalletBalance}
            checkUser={props.checkUser}
            getHistory={props.getHistory}
            dytApproved={dytApproval}
            enjApproved={enjApproval}
            SetAllowance={SetAllowance}
            address={props.address}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Txnhistory
            access_token_null={props.access_token_null}
            userid={props.user._id}
            user={props.address}
            getHistory={props.getHistory}
            transactionData={transactionData}
            transactionDataOffChain={transactionDataOffChain}
          />
        </TabPanel>
      </div>
      <div>
        {props.width === "smc" ||
        props.width === "sm" ||
        props.width === "xs" ? (
          <SocialIcon />
        ) : (
          <></>
        )}
      </div>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  user: state.layout.user,
  address: state.layout.address,
  walletBalance: state.walletReducer.walletBalance,
});

export default withWidth()(
  connect(mapStateToProps, {
    getWalletBalance,
    checkUser,
    getHistory,
    access_token_null,
    CheckPin,
  })(SimpleTabs)
);
