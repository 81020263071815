import React, { useState, useEffect } from "react";

import RandomTipply from "../RandomTipply/RandomTipply";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import { useWeb3React } from "@web3-react/core";

import LegendBackground from "../../../Assets/tipply_background/legend.jpg";
import StandardBackground from "../../../Assets/tipply_background/standard.jpg";
import RareBackground from "../../../Assets/tipply_background/rare.jpg";
import GodlikeBackground from "../../../Assets/tipply_background/godlike.jpg";
import EpicBackground from "../../../Assets/tipply_background/epic.jpg";
import ExtraordinaryBackground from "../../../Assets/tipply_background/extraordinary.jpg";

import FullpageLoader from "../../Common/FullpageLoader/FullpageLoader";
import Snackbar from "../../Common/Snackbar/Snackbar";

import { connect } from "react-redux";
import { getWalletBalance } from "../../../redux/actions/walletActions";

// import { connect } from "react-redux";
// import { checkAllowanceRedux } from "../../../redux/actions/fishtankActions";
import {
  checkAllowance,
  giveApproval,
  transferMintFees,
} from "../../../Contracts/MintFunctions";
import domtoimage from "dom-to-image";
import axios from "axios";
import { ROUTES } from "../../../Utils/routes";
import "./mint.css";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// import { svg2png } from 'svg-png-converter'
import f2pLegendBackground from "../../../Assets/f2pbackground/legendary.jpg";
import f2pStandardBackground from "../../../Assets/f2pbackground/standard.jpg";
import f2pRareBackground from "../../../Assets/f2pbackground/rare.jpg";
import f2pGodlikeBackground from "../../../Assets/f2pbackground/godlike.jpg";
import f2pEpicBackground from "../../../Assets/f2pbackground/epic.jpg";
import f2pExtraordinaryBackground from "../../../Assets/f2pbackground/extraordinary.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  modalStyles: {
    border: "none",
    outline: "none",
    position: "absolute",
    maxWidth: "650px",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.secondary.modalbody,
    padding: "0px",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "400px",
    margin: "auto",
  },

  modalTextColor: {
    color: theme.palette.secondary.other,
    display: "flex",
    justifyContent: "space-around",
    padding: " 10px 600px 0 10px",
  },
  headerClass: {
    display: "flex",
    justifyContent: "space-between",
    padding: " 10px 0px 10px 15px",
    fontSize: "25px",
    backgroundColor: theme.palette.button.primary,
    color: theme.palette.text.primary,
  },
  bodyclass: {
    backgroundColor: theme.palette.secondary.modalbody,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  buttonClass: {
    padding: "20px 50px",
    borderRadius: "0",
    textTransform: "capitalize",
    color: "white !important",
    backgroundColor: theme.palette.button.primary,
    height: "27px",
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },
  stepper: {
    maxWidth: "200px",
    backgroundColor: theme.palette.secondary.modalbody,
  },
  tokenColor: {
    display: "flex",
    justifyContent: "center",
    fontSize: "21px",
    color: theme.palette.text.primary,
  },

  //canvas styling

  screenshotDiv: {
    margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 600px)',
    height: "600px",
    width: "600px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  confirmStyling: {
    margin: "auto",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    paddingBottom: "5px",
    "@media only screen and (max-width:959px)": {
      justifyContent: "center",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "black",
  },

  LegendBackground: {
    backgroundImage: `url(${LegendBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 600px)',
    height: "200px",
    width: "200px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  StandardBackground: {
    backgroundImage: `url(${StandardBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: "200px",
    width: "200px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  RareBackground: {
    backgroundImage: `url(${RareBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: "200px",
    width: "200px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  GodlikeBackground: {
    backgroundImage: `url(${GodlikeBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: "200px",
    width: "200px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  EpicBackground: {
    backgroundImage: `url(${EpicBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: "200px",
    width: "200px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  ExtraordinaryBackground: {
    backgroundImage: `url(${ExtraordinaryBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: "200px",
    width: "200px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  width1000: {
    height: "1000px !important",
    width: "1000px !important",
  },
  container: {
    position: "absolute",
    right: 50000,
  },
  //free to play backf=ground classes///

  f2pLegendBackground: {
    backgroundImage: `url(${f2pLegendBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 600px)',
    height: "250px",
    width: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pStandardBackground: {
    backgroundImage: `url(${f2pStandardBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 250px)',
    height: "250px",
    width: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pRareBackground: {
    backgroundImage: `url(${f2pRareBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 250px)',
    height: "250px",
    width: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pGodlikeBackground: {
    backgroundImage: `url(${f2pGodlikeBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 250px)',
    height: "250px",
    width: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pEpicBackground: {
    backgroundImage: `url(${f2pEpicBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 250px)',
    height: "250px",
    width: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pExtraordinaryBackground: {
    backgroundImage: `url(${f2pExtraordinaryBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 250px)',
    height: "250px",
    width: "250px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  //end of f2p backgrounds
  //end of canvas styling
}));

const MintTipply = (props) => {
  const context = useWeb3React();

  const classes = useStyles();
  const [imageSelected, setImageSelected] = useState("");
  const [public_id, setPublic_id] = useState(" ");
  const [category, setCategory] = useState("rare");

  const [allowance, setAllowance] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const [pageLoaderState, setPageLoaderState] = useState(false);
  const [pageLoaderMessage, setPageLoaderMessage] = useState("");

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    setCategory(props.category);
  }, [props.category]);

  useEffect(() => {
    SetAllowance();
  }, [props.address]);

  const SetAllowance = async () => {
    setAllowance(
      await checkAllowance(
        context.library ? context.library.provider : window.ethereum,
        props.address
      )
    );
    if (allowance) {
      setActiveStep(1);
    } else {
      setActiveStep(0);
    }
    // console.log("allowance ==>>", allowance);
  };

  const takeScreenshot = async () => {
    domtoimage
      .toPng(document.getElementById("canvas"))
      .then(function (dataUrl) {
        // let link = document.createElement("a");
        setImageSelected(dataUrl);
        // console.log(dataUrl, "dataaaaaaaaaaa");
        // setImageSelected(dataUrl);
        // link.download = "my-image-name.png";
        // link.href = dataUrl;
        // link.click();
        // console.log(dataUrl, "dataaaaaaaaaaaaa");
      });
  };
  const uploadImage = () => {
    const formData = new FormData();
    formData.append("file", imageSelected);
    formData.append("upload_preset", "nwtronv6");

    // console.log("image selected", imageSelected);

    const data = async () => {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/ddoon1hy6/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );
      const dataResponse = await response.json();
      // console.log(dataResponse.secure_url, "reponse mint cloudinary url");

      if (dataResponse.secure_url) {
        setPublic_id(dataResponse.secure_url !== "");
        console.log(
          "image selected url",
          dataResponse.secure_url,
          "image cloudinary url"
        );
        const obj = {
          userID: props.userId,
          tipplyID: props.id,
          mintImage: dataResponse.secure_url,
        };

        try {
          const request = await axios.post(ROUTES.MINT_TPPLY, obj);

          console.log(request, "obj request");
        } catch (e) {
          if (
            e.response.data.statusCode === 401 ||
            e.response.data.status === 401
          ) {
            // console.log(e.response, "reached inside");
            props.close();
            await props.getHistory("/fishtank");
            setSnackbarState(true);
            setSnackbarMessage(
              "Your password has been exipred, Please enter your password again"
            );
            // console.log("access_token_null called in marketplace food");

            props.access_token_null();
          }
        }
      }
    };
    data();
  };

  const mintHandler = async () => {
    let transferFees;
    if (category === "Standard Tipply" && props.tipply_type === "paid")
      transferFees = 5;
    if (category === "Rare Tipply" && props.tipply_type === "paid")
      transferFees = 10;
    if (category === "Epic Tipply" && props.tipply_type === "paid")
      transferFees = 25;
    if (category === "Legendary Tipply" && props.tipply_type === "paid")
      transferFees = 50;
    if (category === "Godlike Tipply" && props.tipply_type === "paid")
      transferFees = 75;
    if (category === "Extraordinary Tipply" && props.tipply_type === "paid")
      transferFees = 100;

    if (category === "F2P Standard" && props.tipply_type === "Free")
      transferFees = 0.1;
    if (category === "F2P Rare" && props.tipply_type === "Free")
      transferFees = 1;
    if (category === "F2P Epic" && props.tipply_type === "Free")
      transferFees = 3;
    if (category === "F2P Legendary" && props.tipply_type === "Free")
      transferFees = 5;
    if (category === "F2P Godlike" && props.tipply_type === "Free")
      transferFees = 10;
    if (category === "F2P Extraordinary" && props.tipply_type === "Free")
      transferFees = 25;

    await props.getWalletBalance(
      context.library ? context.library.provider : window.ethereum,
      props.address
    );

    let mintFees = props.walletBalance;

    if (Number(mintFees.ENJ) >= transferFees) {
      setPageLoaderState(true);
      setPageLoaderMessage("Deducting Mint Fees please wait...");
      transferMintFees(
        context.library ? context.library.provider : window.ethereum,
        props.address,
        transferFees,
        props.userId,
        props.id,
        setPageLoaderState,
        props.close,
        props.getUserTipply
      );
      takeScreenshot();
    } else {
      // console.log("mint fees", props);
      setSnackbarMessage(
        `${transferFees} ENJ is required in your wallet to mint this tipply`
      );
      setSnackbarState(true);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    uploadImage();
  }, [imageSelected]);

  //stepper

  function getSteps() {
    return ["", ""];
  }

  function HorizontalLinearStepper() {
    const classes = useStyles();
    const steps = getSteps();
    const [disabled, setDisabled] = React.useState(false);

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setAllowance(true);
    };

    const handleApprove = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    return (
      <div style={{ padding: "0 10px" }}>
        <FullpageLoader
          open={pageLoaderState}
          message={pageLoaderMessage}
          // handleClose={() => setPageLoaderState(false)}
        />
        <Snackbar
          open={snackbarState}
          message={snackbarMessage}
          handleClose={() => setSnackbarState(false)}
        />
        <div className={classes.tokenColor}>
          <div style={{ marginRight: "10px" }}>
            <b>
              {" "}
              {category === "Standard Tipply" && props.tipply_type === "paid"
                ? 5
                : category === "Rare Tipply" && props.tipply_type === "paid"
                ? 10
                : category === "Epic Tipply" && props.tipply_type === "paid"
                ? 25
                : category === "Legendary Tipply" &&
                  props.tipply_type === "paid"
                ? 50
                : category === "Godlike Tipply" && props.tipply_type === "paid"
                ? 75
                : category === "Extraordinary Tipply" &&
                  props.tipply_type === "paid"
                ? 100
                : category === "F2P Standard" && props.tipply_type === "Free"
                ? 0.1
                : category === "F2P Rare" && props.tipply_type === "Free"
                ? 1
                : category === "F2P Epic" && props.tipply_type === "Free"
                ? 3
                : category === "F2P Legendary" &&
                  props.tipply_type === "Free"
                ? 5
                : category === "F2P Godlike" && props.tipply_type === "Free"
                ? 10
                : category === "F2P Extraordinary" &&
                  props.tipply_type === "Free"
                ? 25
                : ""}{" "}
              ENJ
            </b>{" "}
          </div>{" "}
          <span>Token</span>
        </div>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              disabled={allowance}
              onClick={() => {
                setPageLoaderState(true);
                setPageLoaderMessage("Approving Your Wallet Please wait...");
                giveApproval(
                  context.library ? context.library.provider : window.ethereum,
                  props.address,
                  handleNext,
                  setPageLoaderState
                );
              }}
              className={classes.buttonClass}
              style={{ padding: "19px 35px", marginRight: "5px" }}
            >
              Approve
            </Button>
            <Button
              disabled={!allowance}
              onClick={mintHandler}
              style={{ marginLeft: "5px" }}
              className={classes.buttonClass}
            >
              Mint
            </Button>
          </div>
        </div>
      </div>
    );
  }

  //end of stepper
  return (
    <Modal open={props.open}>
      <div className={classes.modalStyles}>
        <div className={classes.headerClass}>
          <div>Confirmation</div>
          <Button onClick={props.close}>
            <CloseIcon />
          </Button>
        </div>
        <div className={classes.bodyclass}>
          <div className={classes.confirmStyling}>
            <div className={classes.container}>
              <div
                id="canvas"
                className={`${
                  category === "Standard Tipply" && props.tipply_type === "paid"
                    ? classes.StandardBackground
                    : category === "Rare Tipply" && props.tipply_type === "paid"
                    ? classes.RareBackground
                    : category === "Epic Tipply" && props.tipply_type === "paid"
                    ? classes.EpicBackground
                    : category === "Legendary Tipply" &&
                      props.tipply_type === "paid"
                    ? classes.LegendBackground
                    : category === "Godlike Tipply" &&
                      props.tipply_type === "paid"
                    ? classes.GodlikeBackground
                    : category === "Extraordinary Tipply" &&
                      props.tipply_type === "paid"
                    ? classes.ExtraordinaryBackground
                    : category === "F2P Standard" &&
                      props.tipply_type === "Free"
                    ? classes.f2pStandardBackground
                    : category === "F2P Rare" && props.tipply_type === "Free"
                    ? classes.f2pRareBackground
                    : category === "F2P Epic" && props.tipply_type === "Free"
                    ? classes.f2pEpicBackground
                    : category === "F2P Legendary" &&
                      props.tipply_type === "Free"
                    ? classes.f2pLegendBackground
                    : category === "F2P Godlike" && props.tipply_type === "Free"
                    ? classes.f2pGodlikeBackground
                    : category === "F2P Extraordinary" &&
                      props.tipply_type === "Free"
                    ? classes.f2pExtraordinaryBackground
                    : ""
                } ${classes.width1000} `}
              >
                <RandomTipply
                  id={props.id}
                  image={props.image}
                  type="screenshot"
                  tipply_type={props.tipply_type}
                  realSS={true}
                />
              </div>
            </div>
            <div
              className={
                category === "Standard Tipply" && props.tipply_type === "paid"
                  ? classes.StandardBackground
                  : category === "Rare Tipply" && props.tipply_type === "paid"
                  ? classes.RareBackground
                  : category === "Epic Tipply" && props.tipply_type === "paid"
                  ? classes.EpicBackground
                  : category === "Legendary Tipply" &&
                    props.tipply_type === "paid"
                  ? classes.LegendBackground
                  : category === "Godlike Tipply" &&
                    props.tipply_type === "paid"
                  ? classes.GodlikeBackground
                  : category === "Extraordinary Tipply" &&
                    props.tipply_type === "paid"
                  ? classes.ExtraordinaryBackground
                  : category === "F2P Standard" && props.tipply_type === "Free"
                  ? classes.f2pStandardBackground
                  : category === "F2P Rare" && props.tipply_type === "Free"
                  ? classes.f2pRareBackground
                  : category === "F2P Epic" && props.tipply_type === "Free"
                  ? classes.f2pEpicBackground
                  : category === "F2P Legendary" && props.tipply_type === "Free"
                  ? classes.f2pLegendBackground
                  : category === "F2P Godlike" && props.tipply_type === "Free"
                  ? classes.f2pGodlikeBackground
                  : category === "F2P Extraordinary" &&
                    props.tipply_type === "Free"
                  ? classes.f2pExtraordinaryBackground
                  : ""
              }
            >
              <RandomTipply
                id={props.id}
                image={props.image}
                type="screenshot"
                tipply_type={props.tipply_type}
                realSS={false}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            {" "}
            <HorizontalLinearStepper />{" "}
          </div>
        </div>
      </div>
    </Modal>
  );
};

// const mapStateToProps = (state) => ({
//   isUnlock: state.fishtank.isUnlock,
// });

// export default connect(mapStateToProps, {checkAllowanceRedux})(MintTipply);

const mapStateToProps = (state) => ({
  walletBalance: state.walletReducer.walletBalance,
  // address: state.layout.address,
});

export default connect(mapStateToProps, {
  getWalletBalance,
})(MintTipply);
