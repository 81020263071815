// export const BASE_URL = "https://tipply-server.herokuapp.com";
// export const BASE_URL = "http://localhost:5000";
export const BASE_URL = "https://api.tipplytank.com";
//  const BASE_URL = "https://test-back.tipplytank.com";
// export const BASE_URL = "http://localhost:4000";

export const ROUTES = {
  //User Routes
  GET_USER_BY_ADDRESS: `${BASE_URL}/user/getUserByAddress`,
  ADD_USER: `${BASE_URL}/user/addUser`,
  CHECK_SECURITY_PIN: `${BASE_URL}/user/getSecurityPin`,
  SIGN_TRANSACTION: `${BASE_URL}/user/signTransaction`,
  ENJIN_CONNECT: `${BASE_URL}/user/enjinConnect`,
  ENJIN_LOGIN: `${BASE_URL}/user/enjinLogin`,
  LINK_ENJIN: `${BASE_URL}/user/linkEnjin`,
   GET_USER_EVENTS: `${BASE_URL}/user/getUserEvents`,
   GET_SECURITY_PIN: `${BASE_URL}/user/getSecurityPin`,
   LOGIN : `${BASE_URL}/auth/login`,
   SET_SECURITY_PIN :`${BASE_URL}/user/setSecurityPin`,

  //Marketplace routes
  GET_MARKET_TIPPLY: `${BASE_URL}/marketplace/getTipply`,
  GET_MARKET_FOOD: `${BASE_URL}/marketplace/getFood`,
  BUY_TIPPLY: `${BASE_URL}/marketplace/buyTipply`,
  BUY_FOOD: `${BASE_URL}/marketplace/buyFood`,

  //Fishtank routes
  GET_STATS: `${BASE_URL}/fishtank/statsData`,
  GET_USER_TIPPLY: `${BASE_URL}/fishtank/getTipply`,
  GET_USER_FOOD: `${BASE_URL}/fishtank/getFood`,
  HATCH_EGG: `${BASE_URL}/fishtank/hatchEgg`,
  USE_TIPPLY: `${BASE_URL}/fishtank/updateTipply`,
  FLUSH_TIPPLY: `${BASE_URL}/fishtank/flushTipply`,
  MINT_TPPLY: `${BASE_URL}/fishtank/mintTipply`,
  SET_MINTING: `${BASE_URL}/fishtank/setMinting`,
  FEED_TIPPLY: `${BASE_URL}/fishtank/feedTipply`,
  NAME_TIPPLY: `${BASE_URL}/fishtank/nameTipply`,
  RENAME_TIPPLY: `${BASE_URL}/fishtank/renameTipply`,
  LEADER_BOARD: `${BASE_URL}/fishtank/leaderBoard`,
  LEADING_FREE_TIPPLYS: `${BASE_URL}/fishtank/leadingTippliesFree`,
  LEADING_PAID_TIPPLYS: `${BASE_URL}/fishtank/leadingTippliesPaid`,

  //Wallet routes
  ADD_TRANSACTION: `${BASE_URL}/transaction/addTransaction`,
  GET_TRANSACTION_BY_USER :`${BASE_URL}/transaction/getTransactionByUser`
};
