import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Button,
  Tooltip,
  TextField,
  CircularProgress,
  Modal,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import cn from "classnames";
import enj from "../../../Assets/enj.png";

import RandomTipply from "../RandomTipply/RandomTipply";
import egg from "../../../Assets/egg.1.png";

import axios from "axios";
import { ROUTES } from "../../../Utils/routes";
import { withRouter } from "react-router-dom";

import Snackbar from "../../Common/Snackbar/Snackbar";
import GenerateTipplyLoader from "../GenerateTipplyLoader/GenerateTipplyLoader";
import { CustomModal } from "../../Common/Modal/Modal";
import MintTipply from "../MintTipply/MintTipply";
import WinningModal from "../WinningModal/WinningModal";

import EditIcon from "@material-ui/icons/Edit";
import "./tipply.css";

const useStyles = makeStyles((theme) => ({
  cardflex: {
    display: "flex",
    paddingBottom: "20px",
    justifyContent: "center",
    "@media only screen and (max-width:1150px)": {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },

  MarginForNewName: {
    marginTop: "90px",
    fontWeight: "bold",
    marginBottom: "0px",
    color: theme.palette.text.primary,
    "@media (min-width:1800px) and (max-width:2520px)": {
      marginTop: "110px", // 80 75 67
    },
    "@media (min-width:3000px) and (max-width:3500px)": {
      marginTop: "110px", // 80 75 67
    },
    "@media (min-width:4900px) and (max-width:7000px)": {
      marginTop: "116px",
    },
    "@media (min-width:2900px) and (max-width:2906px)": {
      marginTop: "100px", // 80 75 67
    },
    "@media (min-width:2520px) and (max-width:2900px)": {
      marginTop: "98px", // 80 75 67
    },
  },
  marginForEgg: {
    marginTop: "10px",
    marginBottom: "0px",
    fontWeight: "bold",
    color: theme.palette.text.primary,

    "@media (min-width:1800px) and (max-width:2520px)": {
      marginTop: "18px", // 80 75 67
    },
    "@media (min-width:3000px) and (max-width:3499px)": {
      marginTop: "22px", // 80 75 67
    },
    "@media (min-width:4900px) and (max-width:7000px)": {
      marginTop: "38px",
    },
  },
  card: {
    margin: "5px",
    textAlign: "center",
    backgroundColor: "inherit",
    border: "0.5px solid",
    borderColor: "#B1B1B1",
    borderRadius: "0",
    minHeight: "240px",
    "@media only screen and (min-width:350px)": {
      flexWrap: "nowrap",
    },
  },

  cardActive: {
    margin: "5px",
    textAlign: "center",
    backgroundColor: "inherit",
    border: "1px solid",
    borderColor: "#b1b1b1",
    minHeight: "240px",
    borderRadius: "0",
    paddingTop: "5px",
    "@media only screen and (min-width:350px)": {
      flexWrap: "nowrap",
    },
  },

  cardText: {
    color: theme.palette.text.secondary,
    paddingBottom: "10px",
  },
  mediaHolder: {
    background: "radial-gradient(#00f,transparent);",
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    margin: 0,
  },
  media: {
    height: "100%",
    width: "auto !important",
    marginTop: "21px",
    "@media (min-width:1800px) and (max-width:2520px)": {
      marginTop: "31.8px", // 80 75 67
    },
    "@media (min-width:3000px) and (max-width:3480px)": {
      marginTop: "30px",
    },
    "@media (min-width:3480px) and (max-width:3499px)": {
      marginTop: "25px",
    },
    "@media (min-width:3499px) and (max-width:3500px)": {
      marginTop: "35px",
    },
    "@media (min-width:2900px) and (max-width:2906px)": {
      marginTop: "25px", // 80 75 67
    },
    "@media (min-width:2520px) and (max-width:2900px)": {
      marginTop: "30px", // 80 75 67
    },
  },
  cardButton: {
    // color: theme.palette.text.primary,
    textTransform: "none",
    color: "white",
    backgroundColor: theme.palette.button.primary,
    height: "27px",
    textTransform: "none",
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },
  marketplaceText: {
    cursor: "pointer",
    color: "#FBBE3F",
  },

  inputdiv: {
    border: "2px solid gray",
    display: "flex",
    fontWeight: "bold",
    justifyContent: "center",
    width: "max-content",
    padding: "3px",
    marginBottom: "10px",
    height: "20px",
    color: theme.palette.secondary.enj,
    "@media (min-width:1000px) and (max-width:1148px)": {
      width: "max-content",
    },
    "@media (min-width:960px) and (max-width:1000px)": {
      width: "max-content",
    },
    "@media (min-width:407px) and (max-width:500px)": {
      width: "max-content",
    },
  },
  editName: {
    width: "0.58em !important",
    fontSize: "1.3rem !important",
    height: "100%",
  },
  loaderStyles: {
    color: "yellow",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -8,
  },

  OuterClass: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: "16px",
  },
  inputText: {
    margin: "5px",
    display: "flex",
    justifyContent: "center !important",
    alignContent: "center !important",
    alignItems: "center !important",
    color: theme.palette.secondary.other,
    "& .MuiInputBase-input": {
      color: `${theme.palette.secondary.other} !important`,
    },
  },

  borderColor: {
    borderRadius: "8px",
    border: "1px solid",
    outline: "none",
    borderColor: "gray",
    margin: "20px auto",
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
    height: "35px",
    fontSize: "15px",
  },
  margin: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },
  modalStyles: {
    border: "none",
    outline: "none",
    position: "absolute",
    maxWidth: 350,
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.secondary.modalbody,
    padding: "0px",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: 230,
    margin: "auto",
  },
  color: {
    color: theme.palette.secondary.other,
    marginBottom: "10px",
  },
  modalTextColor: {
    color: theme.palette.secondary.other,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0px 5px",
    "@media only screen and (max-width: 530px)": {
      // padding: "15px 10px !important",
      justifyContent: "center",
    },
  },
  headerClass: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px",
  },
  bodyclass: {
    backgroundColor: theme.palette.secondary.modalbody,
  },
  buttonClass: {
    padding: "20px 40px",
    borderRadius: "0",
    textTransform: "capitalize",
    color: "white !important",
    backgroundColor: theme.palette.button.primary,
    height: "27px",
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },
  action: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "Center",
  },
}));

const Tipplys = (props) => {
  const classes = useStyles();

  const [activateTipplyLoader, setActivateTipplyLoader] = useState(false);

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [flushModalState, setFlushModalState] = useState(false);
  const [flushModalMessage, setFlushModalMessage] = useState("");
  const [flushTipplyID, setFlushTipplyID] = useState("");

  const [tipplyType, setTipplyType] = useState("");

  const [rename, setRename] = useState("");
  const [EditTipply, setEditTipply] = useState(false);
  const [loaderState, setLoaderState] = useState(false);

  const [loaderImage, setLoaderImage] = useState([
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ]);
  const [loaderID, setLoaderID] = useState("");
  const [openLoaderImage, setOpenLoaderImage] = useState(false);

  const [mintImage, setMintImage] = useState([
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ]);
  const [mintID, setMintID] = useState("");
  const [openMintImage, setOpenMintImage] = useState(false);
  const [openWinningModal, setOpenWinningModal] = useState(false);
  const [mintCategory, setMintCategory] = useState("rare");
  const [hatchCategory, setHatchCategory] = useState("");
  const [hatchDisable, setHatchDisable] = useState(false);
  const [tipplyID, setTipplyID] = useState("");

  useEffect(() => {
    if (props.winningState !== false) {
      setOpenWinningModal(true);
    }
  }, [props.winnerTipplys]);

  var tipplyData = [];
  if (props.tipplies) {
    tipplyData = props.tipplies;
  }

  const closeLoaderHandler = () => {
    setOpenLoaderImage(false);
  };

  const closeMintImageHandler = () => {
    setOpenMintImage(false);
  };

  const closeWinningImageHandler = () => {
    props.winning_modal_state(false);
    setOpenWinningModal(false);
  };

  const hatchEggHandler = async (tipplyID, type) => {
    // console.log(type,"type of tipply", "===>>>in tipply tipply")
    setTipplyType(type);
    let data = {
      userID: props.user._id,
      tipplyID: tipplyID,
    };
    try {
      setHatchDisable(true);
      let response = await axios.post(ROUTES.HATCH_EGG, data);
      // console.log(response, "response of ahtch tipply");
      setLoaderImage(response.data.data.image);
      setHatchCategory(response.data.data.category);
      setLoaderID(response.data.data._id);
      setOpenLoaderImage(true);
      setHatchDisable(false);
      setSnackbarState(true);
      setSnackbarMessage(response.data.msg);
    } catch (e) {
      // console.log(e, "error in hatch");
      if (
        e?.response?.data?.statusCode === 401 ||
        e?.response?.data?.status === 401
      ) {
        // console.log(e.response, "reached inside")
        props.getHistory("/fishtank");
        setSnackbarState(true);
        setSnackbarMessage(
          "Your password has been exipred, Please enter your password again"
        );
        // console.log("access_token_null CallEnd");
        props.access_token_null();
      } else {
        setSnackbarState(true);
        setSnackbarMessage(e.response.data.msg);
      }
    }
    await props.getUserTipply(props.user.address);
  };

  const activeTipplyHandler = async (tipplyID, type) => {
    setActivateTipplyLoader(true);
    setTipplyType(type);
    let data = {
      userID: props.user._id,
      tipplyID: tipplyID,
    };

    try {
      let response = await axios.post(ROUTES.USE_TIPPLY, data);
      setSnackbarState(true);
      setSnackbarMessage(response.data.msg);
    } catch (e) {
      if (
        e.response.data.statusCode === 401 ||
        e.response.data.status === 401
      ) {
        // console.log(e.response, "reached inside")
        props.getHistory("/fishtank");
        setSnackbarState(true);
        setSnackbarMessage(
          "Your password has been exipred, Please enter your password again"
        );
        // console.log("access_token_null CallEnd");
        props.access_token_null();
      } else {
        setActivateTipplyLoader(true);
        setSnackbarState(true);
        setSnackbarMessage(e.response.data.msg);
      }
    }
    await props.getUserTipply(props.user.address);
  };

  const flushTipplyHandler = async (tipplyID) => {
    setActivateTipplyLoader(false);
    let data = {
      userID: props.user._id,
      tipplyID: tipplyID,
    };
    try {
      let response = await axios.post(ROUTES.FLUSH_TIPPLY, data);
      await props.getUserTipply(props.user.address);
      setActivateTipplyLoader(false);
      setSnackbarState(true);
      setSnackbarMessage(response.data.msg);
    } catch (e) {
      if (
        e.response.data.statusCode === 401 ||
        e.response.data.status === 401
      ) {
        // console.log(e.response, "reached inside")
        props.getHistory("/fishtank");
        setSnackbarState(true);
        setSnackbarMessage(
          "Your password has been exipred, Please enter your password again"
        );
        // console.log("access_token_null CallEnd");
        props.access_token_null();
      } else {
        setSnackbarState(true);
        setSnackbarMessage(e.response.data.msg);
      }
    }
  };

  const editName = (e) => {
    setRename(e.target.value);
  };
  const SubmitName = async () => {
    setLoaderState(true);
    let data = {
      userID: props.user._id,
      name: rename,
      tipplyID: tipplyID,
    };
    // console.log(data, "Data");
    try {
      let response = await axios.post(ROUTES.RENAME_TIPPLY, data);
      await props.getUserTipply(props.user.address);
      setRename("");
      // console.log(response, "===");
      setLoaderState(false);
      setEditTipply(false);
    } catch (e) {
      setSnackbarState(true);
      setSnackbarMessage(e);
    }
  };
  return (
    <Grid container>
      <Snackbar
        open={snackbarState}
        message={snackbarMessage}
        handleClose={() => setSnackbarState(false)}
      />
      <CustomModal
        open={flushModalState}
        message={flushModalMessage}
        handleClose={() => {
          setFlushModalState(false);
        }}
        onConfirm={flushTipplyHandler}
        id={flushTipplyID}
      />
      <GenerateTipplyLoader
        getUserTipply={props.getUserTipply}
        address={props.user.address}
        open={openLoaderImage}
        close={closeLoaderHandler}
        image={loaderImage}
        category={hatchCategory}
        id={loaderID}
        userID={props.user._id}
        tipply_type={tipplyType}
      />

      {/* ScreenShot */}
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <MintTipply
          open={openMintImage}
          getHistory={props.getHistory}
          access_token_null={props.access_token_null}
          close={closeMintImageHandler}
          image={mintImage}
          category={mintCategory}
          id={mintID}
          userId={props.user._id}
          address={props.user.address}
          getWalletBalances={props.getWalletBalance}
          getUserTipply={props.getUserTipply}
          tipply_type={tipplyType}
        />
        {props.winnerTipplys.length > 0 ? (
          <WinningModal
            open={openWinningModal}
            close={closeWinningImageHandler}
            winnerTipplys={props.winnerTipplys}
            userId={props.user._id}
            address={props.user.address}
          />
        ) : (
          ""
        )}
      </Grid>

      {tipplyData.length === 0 ? (
        <p>
          {" "}
          You don't have any Tipply now buy it from{" "}
          <span
            className={classes.marketplaceText}
            onClick={() => {
              props.history.push("/marketplace");
            }}
          >
            {" "}
            Marketplace{" "}
          </span>
        </p>
      ) : (
        <>
          {tipplyData.map((tipply) => {
            return (
              <Grid item xs={12} sm={6}>
                <Card
                  className={!tipply.isEgg ? classes.cardActive : classes.card}
                >
                  {tipply.isEgg === false ? (
                    <RandomTipply
                      name={tipply.name}
                      image={tipply.image}
                      type="card"
                      id={tipply._id}
                      tipply_type={tipply.type}
                      realSS={false}
                    />
                  ) : (
                    <img className={classes.media} src={egg} />
                  )}
                  <Tooltip title="Click on the Edit pencil to edit your Tipply's name">
                    <p
                      className={
                        tipply.isEgg
                          ? classes.marginForEgg
                          : classes.MarginForNewName
                      }
                    >
                      {!tipply.name ? "-" : tipply.name}
                      {tipply.isEgg ? (
                        ""
                      ) : (
                        <EditIcon
                          className={classes.editName}
                          onClick={() => {
                            setEditTipply(true);
                            setTipplyID(tipply._id);
                            // setTipplyName(tipply.name);
                            setRename(tipply.name);
                          }}
                        />
                      )}
                    </p>
                  </Tooltip>
                  <CardContent style={{ padding: "0px 16px" }}>
                    <Typography
                      className={classes.cardText}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <br />
                      {tipply.bornAt
                        ? Math.round(
                            (new Date().getTime() -
                              new Date(tipply.bornAt).getTime()) /
                              (1000 * 60 * 60 * 24)
                          )
                        : 0}{" "}
                      Days
                      <br />
                      {tipply.level} Level
                      <br />
                      {Math.floor(tipply.energy)}% Energy
                    </Typography>
                  </CardContent>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Tooltip title="Minting Cost for this Tipply">
                      <div className={classes.inputdiv}>
                        <img
                          src={enj}
                          style={{ height: "20px", width: "auto" }}
                        />
                        <b>
                          {" "}
                          {tipply.category === "Standard Tipply" &&
                          tipply.type === "paid"
                            ? 5
                            : tipply.category === "Rare Tipply" &&
                              tipply.type === "paid"
                            ? 10
                            : tipply.category === "Epic Tipply" &&
                              tipply.type === "paid"
                            ? 25
                            : tipply.category === "Legendary Tipply" &&
                              tipply.type === "paid"
                            ? 50
                            : tipply.category === "Godlike Tipply" &&
                              tipply.type === "paid"
                            ? 75
                            : tipply.category === "Extraordinary Tipply" &&
                              tipply.type === "paid"
                            ? 100
                            : tipply.category === "F2P Standard" &&
                              tipply.type === "Free"
                            ? 0.1
                            : tipply.category === "F2P Rare" &&
                              tipply.type === "Free"
                            ? 1
                            : tipply.category === "F2P Epic" &&
                              tipply.type === "Free"
                            ? 3
                            : tipply.category === "F2P Legendary" &&
                              tipply.type === "Free"
                            ? 5
                            : tipply.category === "F2P Godlike" &&
                              tipply.type === "Free"
                            ? 10
                            : tipply.category === "F2P Extraordinary" &&
                              tipply.type === "Free"
                            ? 25
                            : ""}{" "}
                          ENJ
                        </b>
                      </div>
                    </Tooltip>
                  </div>

                  <div className={classes.cardflex}>
                    <Tooltip
                      title={
                        tipply.isEgg
                          ? "Click to hatch this egg"
                          : "Click to put this tipply in the tank"
                      }
                    >
                      <Button
                        onClick={
                          tipply.isEgg
                            ? () => {
                                hatchEggHandler(tipply._id, tipply.type);
                                setHatchCategory(tipply.category);
                              }
                            : () => activeTipplyHandler(tipply._id, tipply.type)
                        }
                        disabled={
                          (tipply.isActive && !tipply.isEgg) ||
                          (tipply.level === 100 &&
                            !tipply.isEgg &&
                            tipply.hasWon)
                        }
                        className={classes.cardButton}
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          margin: 4,
                        }}
                      >
                        {tipply.isEgg ? "Hatch" : "Use"}
                      </Button>
                    </Tooltip>
                    <Tooltip title="Click to flush this tipply">
                      <Button
                        onClick={() => {
                          setFlushModalMessage(
                            "Are you sure you want to flush your tipply? You won't be able to recover it again."
                          );
                          setFlushModalState(true);
                          setFlushTipplyID(tipply._id);

                          // flushTipplyHandler(tipply._id);
                        }}
                        disabled={tipply.isEgg || tipply.isMinting}
                        className={classes.cardButton}
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          margin: 4,
                        }}
                      >
                        Flush
                      </Button>
                    </Tooltip>
                    <Tooltip title="Click to mint this tipply">
                      <Button
                        onClick={() => {
                          setMintImage(tipply.image);
                          setMintID(tipply._id);
                          setMintCategory(tipply.category);
                          setTipplyType(tipply.type);
                          setOpenMintImage(true);
                        }}
                        disabled={
                          tipply.isDead ||
                          tipply.isEgg ||
                          tipply.isMinting ||
                          tipply.isMint ||
                          tipply.isFlush
                        }
                        className={classes.cardButton}
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          margin: 4,
                        }}
                      >
                        {tipply.isMinting
                          ? "Minting.."
                          : tipply.isMint
                          ? "Minted"
                          : tipply.isMinting && tipply.isMint
                          ? "Minted"
                          : "Mint"}
                      </Button>
                    </Tooltip>
                  </div>
                </Card>
              </Grid>
            );
          })}
          <Modal open={EditTipply}>
            <div className={classes.modalStyles}>
              <div className={classes.headerClass}>
                <div></div>
                <Button onClick={() => setEditTipply(false)}>
                  <CloseIcon />
                </Button>
              </div>
              <div className={classes.bodyclass}>
                <div className={classes.modalTextColor}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                    className={classes.color}
                  >
                    Rename your Tipply?
                  </div>
                  <div className={classes.borderColor}>
                    <TextField
                      type="String"
                      inputProps={{
                        maxLength: 12,
                      }}
                      className={classes.inputText}
                      onChange={editName}
                      value={rename}
                      onKeyPress={(e) =>
                        e.key == "Enter" ? SubmitName() : null
                      }
                      style={{ width: "150px" }}
                      disabled={loaderState}
                    />
                  </div>
                </div>
                <div className={classes.action}>
                  <Button onClick={SubmitName} className={classes.buttonClass}>
                    {loaderState === true ? (
                      <CircularProgress
                        size={16}
                        className={classes.loaderStyles}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </Grid>
  );
};

export default withRouter(Tipplys);
