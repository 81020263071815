import React from 'react';
export default (dynamicId, props) => (
    <svg
        id="spikes"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsxlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 458.8 438.1"
        {...props}>
        <defs>
            <linearGradient
                id={dynamicId}
                x1="280.9"
                y1="456.65"
                x2="707.6"
                y2="456.65"
                gradientUnits="userSpaceOnUse"
            >
                <stop class="startSpikesColor" offset="0" />
                <stop class="stopSpikesColor" offset="1" />
            </linearGradient>
        </defs>
        <title>spikes</title>
        <path id="spikes-inner"
            d="M666.1,570.9l-6.7,8.2,48.2,31.3Zm-22.8,83.9-24.4-42.2-8.8,4.8Zm-258.2-60c-.1.2-.3.4-.4.6a217,217,0,0,1-18.4,21.8,269.74,269.74,0,0,0,27.3-16.3l-8.1-6.6Zm4.2-48.9c-2.2-7.9-7.1-13.9-14.8-18.2l-43.3,39.6Zm-26.2-52.5c1.5-6.4-.8-13.6-7-21.5q-24.3,6-75.2,33Q339.55,492.15,363.1,493.4Zm-2.7-70.5c6.1-3.8,10-10.6,11.8-20.3l-53.4-7.5Zm254.5-78.7a302.09,302.09,0,0,1,23.6-35.7q-25.8,19.2-34.8,27.5ZM440,458.4c3.4-7.4,2.3-15-3.2-22.9-19.4,7.1-33.8,14.6-43.1,22.3C408.3,457.6,423.7,457.8,440,458.4Zm-108.3-157,63.7,60.5a27.61,27.61,0,0,0,14.3-13.7Q389.45,332,331.7,301.4ZM465,281.6l4.6,51.1c9.3,1.8,16.8,0,22.5-5.3ZM556,333c3.5,5.4,10.6,8.3,21.1,8.6.2-20.6,8.5-48.3,24.7-83.1Q567.45,305.9,556,333Z"
            transform="translate(-264.5 -240.4)" fill={`url(#${dynamicId})`} />
        <path id="spikes-shadow"
            d="M383.6,544.87a57.1,57.1,0,0,1-9.14-6.73q-15,10.64-32,22.92l41.14-16.19m-26.19-60.69c-.37-3.86-1.23-6.72-2.8-8.74-13.25,3.31-30.2,10.07-51.07,20.25l53.87-11.51m78.25-34.32c.26-3.92-.3-6.81-1.77-8.63a174.21,174.21,0,0,0-36.18,14.65l38-6m-68.35-43.55c-13.38-3.1-25.4-5.31-36.26-6.8l32.75,13.7c2.6-2.54,3.74-4.88,3.51-6.9m34.93-51.47a54.32,54.32,0,0,0,5-5.31q-19.12-15-52.74-33.33l47.74,38.64M479,329.65c4.39.3,7.6,0,9.63-.88q-11.35-22.38-20.91-38.69Z"
            transform="translate(-264.5 -240.4)"
            fill="#fff"
            fill-opacity="0.25099998712539673" />
        <path id="spikes-outline"
            d="M659.4,579l-3.3,3.7,67.2,40.1L669,567.3l-2.9,3.5,41.5,39.5L659.4,579m-36.8,31.5-3.7,2.1,24.4,42.2-33.2-37.4-4.5,2.4,55.5,58.7-38.5-68m-224.6-6-4.4-3.5a269.74,269.74,0,0,1-27.3,16.3,217,217,0,0,0,18.4-21.8,2.09,2.09,0,0,0,.4-.6c.1-.2.2-.3.3-.5l-4.1-3.3a265.42,265.42,0,0,1-23.5,33.2A268.19,268.19,0,0,0,398,604.5M599.6,333l4.2,3q9-8.4,34.8-27.5A315,315,0,0,0,615,344.2v.1l.5.3,3.8,2.8,27.5-47.3c-21.9,14.1-37.6,25-47.2,32.9M409.7,348.3c1.6-4.2,2-7.4,1.1-9.5q-23.25-14.25-97.9-51.6l71.9,76.3c3.2,1.3,6.8.8,10.6-1.5l-63.7-60.5Q389.45,332,409.7,348.3ZM388.2,552.9c1.6-.9,2-3.2,1.1-6.9l-58.1,21.4,43.3-39.6q-6-1.8-9.3.3L325.5,571l62.7-18.1m-34.1-50.3c4.2-1.1,7.2-4.1,9-9.3q-23.55-1.2-82.2,11.5,50.85-27,75.2-33c-3.3-3.2-7-4.6-11.1-4.1q-24.45,6.6-80.5,42.3l89.6-7.4m79.3-37.4c2.4-.7,4.6-2.9,6.6-6.8-16.3-.6-31.7-.8-46.3-.6,9.3-7.7,23.7-15.2,43.1-22.3a10.69,10.69,0,0,0-9.6-4Q398,444.85,386.7,460.3c14.6,1.2,30.2,2.8,46.7,4.9m58.7-137.8q3.3-3.75,2.7-7.5L461,267.3l.9,59.4c.5,2.9,3.1,4.9,7.7,5.9L465,281.5l27.1,45.9m62-1.1c-.9,1.7-.2,3.9,1.9,6.6q11.4-27.15,45.8-74.5c-16.2,34.9-24.5,62.6-24.7,83.2,3.9-.2,5.9-2.1,5.8-5.8q.6-30.6,30.8-95.4Q566.3,296.5,554.1,326.3ZM350.2,424.4c2.8.8,6.2.3,10.2-1.4l-41.6-27.8,53.4,7.5q-.75-7.8-6.6-8.4l-60.5-4.4Z"
            transform="translate(-264.5 -240.4)" />
        <path id="gills"
            d="M423.14,477.78l0-.47a49.76,49.76,0,0,1,1.58-9.4q-14.64,30.34,10.31,52.36c-.17-.31-.34-.7-.52-1-1.93-3.62-3.56-7.12-5.09-10.58,0-.08-.09-.2-.08-.27-4.74-11.08-6.69-21.23-6.24-30.62m22.41,35.77a6.91,6.91,0,0,0-.34-.71,69.61,69.61,0,0,1-6.78-35.91l.06-.7a68.38,68.38,0,0,1,1.44-9c0-.15.13-.34.15-.57-13.08,19.47-9.28,38.61,11.59,57.48-.53-.87-1.17-1.78-1.71-2.65a77.09,77.09,0,0,1-4.41-8M473,531.15c-.28-.28-.56-.63-.84-.91l.09.12L472,530a75.66,75.66,0,0,1-7.4-10.1c-7.77-12.74-11.71-28.07-11.62-45.9,0-3.85.21-7.88.59-12.07Q436.73,507.77,473,531.15Z"
            transform="translate(-264.5 -240.4)" fill="#070707" />
    </svg>
);
