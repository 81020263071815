import React, { useEffect } from "react";
import "./App.css";
import Layout from "./Layout/Layout";
import { BrowserRouter, Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { CheckPin, PinNull } from "./redux/actions/authActions";
import { access_token_null, AT_PASS } from "./redux/actions/pinAction";
import { ThemeProvider } from "@material-ui/core/styles";
import { getHistory } from "./redux/actions/authActions";
import { winning_modal_state } from "./redux/actions/fishtankActions";

import { darkTheme, lightTheme } from "./Config/materialUiTheme";
import Snackbar from "./Components/Common/Snackbar/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";

import {
  checkWeb3,
  checkUser,
  qrScan,
  updateUser,
} from "./redux/actions/layoutActions";

import Home from "./Containers/Home/Home";
import Wallet from "./Containers/Wallet/Wallet";
import Marketplace from "./Containers/Marketplace/Marketplace";
import FishTank from "./Containers/FishTank/FishTank";
import Txnhistory from "./Components/Wallet/TransactionHistory/TransactionHistory";
import Help from "./Containers/Help/Help";
import About from "./Containers/About/About";
import LeaderBoard from "./Containers/LeaderBoard/LeaderBoard";

import moonLight from "./Assets/switch/moonLight.svg";
import moonDark from "./Assets/switch/moonDark.svg";
import sunLight from "./Assets/switch/sunLight.svg";
import sunDark from "./Assets/switch/sunDark.svg";
import EnjinModal from "./Components/Common/Modal/enjinModal";
import setAuthToken from "./Utils/setAuthToken";

import { useState } from "react";

import { useWeb3React } from "@web3-react/core";
import { walletconnect } from "./Utils/connector";
import CopyToClipboard from "react-copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  mySwitch: {
    display: "flex",
    padding: "4px 8px",
    color: "white",
    position: "absolute",
    left: "240px",
    top: "16px",
    background: "#EFEFEF",
    borderRadius: "16px",
    "@media only screen and (max-width:960px)": {
      top: 9,
    },
    "@media only screen and (max-width:601px)": {
      color: "white",
      position: "absolute",
      top: "40px",
      left: "18px",
      background: "#EFEFEF",
      borderRadius: "16px",
    },

    "@media only screen and (max-width:350px)": {
      color: "white",
      position: "absolute",
      top: "40px",
      left: "5px",
      background: "#EFEFEF",
      borderRadius: "16px",
    },
  },

  mySwitchHome: {
    display: "flex",
    padding: "4px 8px",
    color: "white",
    position: "absolute",
    left: "250px",
    "@media only screen and (max-width:408px)": {
      left: 150,
    },
    top: "10px",
    background: "#EFEFEF",
    borderRadius: "16px",
  },
}));

function App(props) {
  const classes = useStyles();
  const [themeMode, setThemeMode] = useState(localStorage.getItem("themeMode"));

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [userIdentity, setUserIdentity] = useState(null);
  const context = useWeb3React();

  const handleHide = () => {
    setTimeout(function () {
      setSnackbarState(false);
    }, 200);
  };

  useEffect(() => {
    clearInterval(localStorage.getItem("intervalID"));
    // localStorage.clear();
    // window.location.reload();
    props.qrScan(false);
    try {
      if (localStorage.getItem("walletconnect")) {
        // console.log("wallet connect already connected");
        onWalletConnect();
      }
      let ethereum = window.ethereum;
      if (!ethereum || !ethereum.isMetaMask) {
        // setSnackbarState(true);
        // setSnackbarMessage(
        //   "Please install MetaMask extension to access this game."
        // );
      }
      if (ethereum) {
        ethereum.on("accountsChanged", props.checkWeb3);
        props.qrScan(false);
        // ethereum.enable();
        if (!props.address) props.checkWeb3();
        // alert(ethereum);
        console.log("ethereum ==>>", ethereum);
        // props.winning_modal_state(true);
        if (props.access_token !== null) {
          setAuthToken(`Bearer ${props.access_token}`);
        }
      } else {
        // alert("Metamask not installed!");
      }
      return () => {
        if (ethereum && ethereum.removeListener) {
          ethereum.removeListener("accountsChanged", props.checkWeb3);
        }
      };
    } catch (e) {}
  }, []);

  useEffect(() => {
    clearInterval(localStorage.getItem("intervalID"));
    try {
      if (props.network !== "main" && window.ethereum) {
        setSnackbarState(true);
        setSnackbarMessage("Please switch your network to main");
      }
      if (props.network === "main" && window.ethereum) {
        setSnackbarState(true);
        setSnackbarMessage("You are currently on Main Network.");
      }
    } catch (e) {}
  }, [props.network]);

  //Ahsan pin code
  useEffect(() => {
    clearInterval(localStorage.getItem("intervalID"));

    try {
      if (props.address) {
        props.PinNull();
        props.CheckPin(props.address);
        window.ethereum.on("accountsChanged", function (accounts) {
          localStorage.removeItem("account");
          // props.winning_modal_state(true);
          props.getHistory("/fishtank");
          props.CheckPin(props.address);
          // console.log(props.Pin, "pin in app");
          // console.log("access_token_null CallEnd");
          props.access_token_null();
          props.AT_PASS(true);
        });

        props.checkUser(props.address);
      }
    } catch (e) {}
  }, [props.address]);
  useEffect(() => {
    // console.log(props.user, "USERRRRRRRRRRRRRR1");
    if (props.user) {
      props.updateUser(props.user.result ? props.user.result : props.user);
      // console.log(props.user, "USERRRRRRRRRRRRRRR2");
    }
  }, [props.user]);

  const refreshCheck = localStorage.getItem("themeMode");
  if (refreshCheck) {
  } else {
    localStorage.setItem("themeMode", "light");
    setThemeMode("light");
  }

  const toggleThemeMode = () => {
    if (themeMode === "dark") {
      localStorage.setItem("themeMode", "light");
    } else {
      localStorage.setItem("themeMode", "dark");
    }
    setThemeMode(localStorage.getItem("themeMode"));
  };
  {
    themeMode === "dark"
      ? (document.getElementsByTagName("body")[0].style =
          "background-color : #3c3d49 ")
      : (document.getElementsByTagName("body")[0].style =
          "background-color : #ffffff ");
  }
  {
    // console.log(props.historyTipply, "history");
  }

  const onWalletConnect = async () => {
    await context.activate(walletconnect);
    // console.log("context account", context);
    // props.winning_modal_state(true);
    // alert("context", context);
    // await props.checkWeb3WC({
    // address: context.account,
    // network: context.network,
    // });
    // if (!props.user) {
    //   handleModalOpen();
    // } else {
    //   userFound();
    // }
    // console.log("Current context", context);
  };

  return (
    <>
      <ThemeProvider theme={themeMode === "dark" ? darkTheme : lightTheme}>
        <Snackbar
          open={snackbarState}
          message={snackbarMessage}
          // handleClose={() => setSnackbarState(false)}
          handleClose={handleHide}
        />
        <EnjinModal />
        <BrowserRouter>
          {/* <span>
          <Switch onChange={toggleThemeMode} checked={themeMode === "dark"} />
        </span> */}

          <span
            onClick={toggleThemeMode}
            className={
              props.historyTipply === "/"
                ? classes.mySwitchHome
                : classes.mySwitch
            }
          >
            <img
              style={{ marginRight: 6 }}
              src={themeMode === "dark" ? moonDark : moonLight}
            />
            <img src={themeMode === "dark" ? sunLight : sunDark} />
          </span>
          {props.user && props.address ? (
            <Layout>
              <Route exact path="/fishtank" component={FishTank} />
              <Route exact path="/marketplace" component={Marketplace} />
              <Route exact path="/wallet" component={Wallet} />
              <Route exact path="/txnhistory" component={Txnhistory} />
              <Route exact path="/help" component={Help} />
              <Route exact path="/about" component={About} />
              <Route exact path="/leaderBoard" component={LeaderBoard} />
              {props.historyTipply === "/fishtank" ||
              props.historyTipply === "/" ? (
                <Redirect to="/fishtank" />
              ) : (props.historyTipply === "/fishtank" &&
                  props.access_token === null) ||
                (props.historyTipply === "/wallet" &&
                  props.access_token === null) ||
                (props.historyTipply === "/about" &&
                  props.access_token === null) ||
                (props.historyTipply === "/help" &&
                  props.access_token === null) ||
                (props.historyTipply === "/leaderBoard" &&
                  props.access_token === null) ||
                (props.historyTipply === "/marketplace" &&
                  props.access_token === null) ||
                props.access_token === "error" ||
                (props.access_saved === true &&
                  props.access_token_pass === true) ||
                (props.Pin === true && props.access_token_pass === true) ||
                (props.Pin === false && props.access_token_saved === false) ||
                (props.access_token === null &&
                  props.access_token_pass === true &&
                  props.access_token_saved === false) ? (
                //ahsan pin code
                <Redirect to={"/fishtank"} />
              ) : props.historyTipply === "/wallet" ? (
                <Redirect to="/wallet" />
              ) : props.historyTipply === "/about" ? (
                <Redirect to="/about" />
              ) : props.historyTipply === "/help" ? (
                <Redirect to="/help" />
              ) : props.historyTipply === "/leaderBoard" ? (
                <Redirect to="/leaderBoard" />
              ) : props.historyTipply === "/txnhistory" ? (
                <Redirect to="/wallet" />
              ) : props.historyTipply === "/marketplace" ? (
                <Redirect to="/marketplace" />
              ) : (
                <Redirect to="/fishtank" />
              )}
            </Layout>
          ) : (
            <Layout>
              <Route exact path="/" component={Home} />
              <Redirect to="/" />
            </Layout>
          )}
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}
//ahsan pin code
const mapStateToProps = (state) => ({
  address: state.layout.address,
  user: state.layout.user,
  network: state.layout.network,
  historyTipply: state.auth.historyTipply,
  access_token: state.pin.access_token,
  Pin: state.auth.Pin,
  access_token_saved: state.pin.access_token_saved,
  open_qrScan: state.layout.open_qrScan,
});

export default connect(mapStateToProps, {
  checkWeb3,
  checkUser,
  CheckPin,
  PinNull,
  getHistory,
  access_token_null,
  AT_PASS,
  AT_PASS,
  winning_modal_state,
  qrScan,
  updateUser,
})(App);
