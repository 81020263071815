import { SET_CURRENT_USER, GET_HISTORY, PIN_CHECK, PIN_NULL } from "../types";

const initialState = {
  isAuthenticated: false,
  user: {},
  historyTipply: "/fishtank",
  Pin: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PIN_NULL:
      return {
        ...state,
        Pin: null,
      }
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case PIN_CHECK:
      return {
        ...state,
        Pin: action.payload,
      };
    case GET_HISTORY:
      return {
        ...state,
        historyTipply: action.payload,
      }
    default:
      return state;
  }
}
