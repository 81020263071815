import React from "react";
export default (dynamicId, props) => (
  <svg
    id="tail"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsxlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 181.9 246.9"
    {...props}
  >
    <defs>
      <linearGradient
        id={dynamicId}
        x1="489.61"
        y1="671.44"
        x2="412.31"
        y2="729.47"
        gradientTransform="translate(0 -200)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" class="startTailColor" />
        <stop offset="1" class="stopTailColor" />
      </linearGradient>
    </defs>
    <title>tailt</title>
    <g className="tailAnimate">
      <path
        id="tail-outline"
        d="M396.2,404.5l-17.6,14.3q51.45,62.1,48.4,117.8l12.6,1.2a10.58,10.58,0,0,1,3.5,2.8,12.28,12.28,0,0,1,2.5,9.3,12.6,12.6,0,0,1-12.3,11,9.7,9.7,0,0,1-1.7-.1,12.54,12.54,0,0,1-3.9-1.1l-1.2-.6c-2,9.4-3.6,16.7-4.8,22a197.14,197.14,0,0,1-8.7,28,158.86,158.86,0,0,1-9.4,19.5,213.3,213.3,0,0,1-15,22.8c12.1-2.8,23.8-6.1,35.3-10a268.22,268.22,0,0,0,44.4-19.6,219.93,219.93,0,0,0,25.6-16.6,20.28,20.28,0,0,1,1.8-1.3,218.35,218.35,0,0,0,23.6-21.2,246.12,246.12,0,0,0,16.2-19.5c2.2-3,4.2-6,6.2-9.1q4-6.15,7.5-12.6a207.2,207.2,0,0,0,11.3-23.8l-1-1.8a231.81,231.81,0,0,0-45.1-55.7,207.75,207.75,0,0,0-42.6-29.3c-2.2-1.2-4.4-2.3-6.7-3.4-.3-.1-.5-.3-.8-.3-1.8-.7-3.1-.5-3.7.8s-1.3,2.5-1.9,3.7a10.27,10.27,0,0,1-2.8,3.1,10.08,10.08,0,0,1-7.6,2.2,10.28,10.28,0,0,1-7-4,12.23,12.23,0,0,1-1.7-2.9v-7c0-.1.1-.2.1-.3.6-1.6,1.3-3.2,2.1-5-.8-.3-1.5-.6-2.2-.9l-43.4-12.4m-4.9,18.1,10.5-9.6,33.1,9.3q-4.65,12.3,4.8,17.7a14.24,14.24,0,0,0,7.6,3,14.45,14.45,0,0,0,11.3-3.2,15.82,15.82,0,0,0,4.2-4.8q4.2,2.25,8.1,4.8,5.85,3.6,11.1,7.5,21.6,15.6,34.5,29.3a215.62,215.62,0,0,1,29.3,39.7l.9,1.9a169.57,169.57,0,0,1-14.4,27.7c-1.3,2.2-2.6,4.3-4,6.4-3.5,5.1-7.2,10.1-11.2,15A200,200,0,0,1,496,589.5l-.4.4a207.54,207.54,0,0,1-24.8,19.6,219,219,0,0,1-28.4,16.1A252,252,0,0,1,416,636.4q-5.55,2.1-11.1,3.9c1.6-2.4,3.1-4.8,4.6-7.3.8-1.4,1.7-2.9,2.6-4.3l-.1-.1h.1a167.32,167.32,0,0,0,9.9-21,221.13,221.13,0,0,0,9.2-30.4c.7-3.4,1.4-6.9,2-10.3a17.56,17.56,0,0,0,11.3-4,18.05,18.05,0,0,0,7.1-12.6,17.52,17.52,0,0,0-3.9-13.9,18.36,18.36,0,0,0-8.1-6l-4.6-1.1a140.58,140.58,0,0,0-6.7-40.3,174.44,174.44,0,0,0-18.2-39.8A241.58,241.58,0,0,0,391.3,422.6Z"
        transform="translate(-378.6 -404.5)"
      />
      <path
        id="tail-inner"
        d="M401.8,413l-10.5,9.5a269.23,269.23,0,0,1,18.9,26.7A176.42,176.42,0,0,1,428.4,489a144.73,144.73,0,0,1,6.7,40.3l4.6,1.1a19,19,0,0,1,12,19.9,18.05,18.05,0,0,1-7.1,12.6,17.75,17.75,0,0,1-11.3,4c-.6,3.4-1.2,6.9-2,10.3a221.13,221.13,0,0,1-9.2,30.4,161,161,0,0,1-9.9,21v.1c-.9,1.4-1.7,2.8-2.6,4.3-1.5,2.5-3.1,4.9-4.6,7.3q5.55-1.8,11.1-3.9,13.5-4.8,26.4-10.8a225.89,225.89,0,0,0,28.4-16.1,207.54,207.54,0,0,0,24.8-19.6c.2-.1.3-.3.4-.4a209.64,209.64,0,0,0,21.1-22.2c4-4.9,7.7-9.9,11.2-15,1.4-2.1,2.8-4.2,4-6.4a186.77,186.77,0,0,0,14.4-27.7l-.9-1.9a225.15,225.15,0,0,0-29.3-39.7Q503.7,463,482.1,447.3q-5.4-3.9-11.1-7.5-3.9-2.55-8.1-4.8a15.82,15.82,0,0,1-4.2,4.8,14.73,14.73,0,0,1-11.3,3.2,14.56,14.56,0,0,1-7.6-3q-9.45-5.4-4.8-17.7Z"
        transform="translate(-378.6 -404.5)"
        fill={`url(#${dynamicId})`}
      />
      <g id="tail-shadow">
        <path
          d="M446,427.06l-33.16,25.79q3.93,6.67,7.13,13.55l43.59-30.65C458,432.65,452.13,429.82,446,427.06Z"
          transform="translate(-378.6 -404.5)"
          fill="#fff"
          fill-opacity="0.800000011920929"
        />
        <path
          d="M501.32,462.49q-7.49-6.35-16.35-12.86l-56.4,38.63a172.48,172.48,0,0,1,4.4,17.62Z"
          transform="translate(-378.6 -404.5)"
          fill="#fff"
          fill-opacity="0.6000000238418579"
        />
        <path
          d="M546,518.63l-1-1.82q-51.76,89.7-133.74,112.28h0l.11.11c-.88,1.4-1.64,2.82-2.51,4.22-1.47,2.43-3.07,5-4.65,7.37,3.67-1.26,7.45-2.5,11.14-4A252.72,252.72,0,0,0,441.8,626a214.29,214.29,0,0,0,28.33-16.13,196.17,196.17,0,0,0,24.78-19.58,1.81,1.81,0,0,1,.51-.34,211,211,0,0,0,21-22.25c4-4.84,7.66-9.92,11.21-15,1.43-2.14,2.75-4.29,4.07-6.44A165.63,165.63,0,0,0,546,518.63Z"
          transform="translate(-378.6 -404.5)"
          fill="#e1d09d"
        />
      </g>
    </g>
  </svg>
);
