import React from "react";
export default (dynamicId, props) => (
  <svg
    id="top-pick"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 99.2 61.8"
    {...props}
  >
    <defs>
      <linearGradient
        id={dynamicId}
        x1="76.34"
        y1="922.87"
        x2="37.99"
        y2="951.65"
        gradientTransform="translate(0 -898)"
        gradientUnits="userSpaceOnUse"
      >
        <stop class="startTopColor" offset="0" />
        <stop class="stopTopColor" offset="1" />
      </linearGradient>
    </defs>
    <title>topTest</title>
    <path
      id="top-pick-inner"
      d="M5.8,33.7l.7,27.1,22.3-9.5c-5.1-8-12.8-13.8-23-17.6M61.9,48.5l37.6,3.1Q84.8,21.3,65.3,9.8L61.9,48.5M28.8,51.3l33.1-2.9Q49.3,28.3,28.8,18.7Z"
      transform="translate(-0.3)"
      fill={`url(#${dynamicId})`}
    />
    <path
      id="top-pick-outline"
      d="M4.3,61.7l-.2.1M82.9,16.2A76.58,76.58,0,0,0,59,0L57.1,36.7A56.77,56.77,0,0,0,23.3,10.4l1.5,31.2C19.7,34.8,11.5,30.1.3,27.3L4.1,61.8l.2-.1,2.2-.9h0L5.8,33.7c10.2,3.8,17.9,9.6,23,17.6V18.7q20.55,9.6,33.1,29.8L65.3,9.8Q84.8,21.35,99.5,51.6V50A55.83,55.83,0,0,0,82.9,16.2Z"
      transform="translate(-0.3)"
    />
  </svg>
);
