import { createMuiTheme } from "@material-ui/core/styles";

export const lightTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 408,
      smc: 600,
      tb: 660,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#FFFFFF",
      borderColor: "#b7b7b7",
      other: "#FFFFFF",
      main2: "#fff",
      tabRowColor: "#fbfbfd",
      borderColorStats: "#fff",
    },
    secondary: {
      main: "#FBBE3F",
      other: "black",
      modalbody: "#FFFFFF",
      enj: "#FBBE3F",
    },

    text: {
      primary: "#000000",
      color: "#b4b4b4",
      primary3: "#656565",
      primary2: "#2a1b1b",
      secondary: "#656565",
      secondaryWallet: "black",
      btn: "#fccf72",
      nonbtn: "#635858",
      barColor: "black",
      activebg: "#fbbe3f",
      activeColor: "white",
    },

    button: {
      primary: "#FBBE3F",
      white: "white",
      modalbg: "#FBBE3F",
      hover: "#fb9c3f",
      withdraw: "#BE0000",
      connect: "#FBBE3F",
    },
  },
});

export const darkTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 408,
      smc: 600,
      tb: 660,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    type: "dark",
    primary: {
      borderColor: "#3c3d49",
      main2: "#3c3d49",
      main: "#3c3d49",
      other: "#474A5A",
      tabRowColor: "#3c3d49",
      borderColorStats: "#474A5A",
    },
    secondary: {
      main: "#FFFFFF",
      other: "#fff",
      modalbody: "#474a5a",
      enj: "#FBBE3F",
    },

    text: {
      primary: "#FFFFFF",
      color: "#FFFFFF",
      primary3: "#FFFFFF",
      primary2: "#FFFFFF",
      secondary: "#BEBEBE",
      btn: "#d9d9db",
      nonbtn: "#9a9b9e",
      barColor: "#BEBEBE",
      activebg: "#3c3d49",
      activeColor: "#ffffff",
      secondaryWallet: "#BEBEBE",
    },
    button: {
      modalbg: "#3d3f4a",
      primary: "#3D3F4A",
      white: "white",
      hover: "rgba(255, 255, 255, 0.08)",
      withdraw: "#BEBEBE",
      connect: "#474A5A",
    },
  },
});
