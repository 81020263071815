import React from "react";
export default (dynamicId, props) => (
  <svg
    version="1.1"
    id="body"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enable-background="new 0 0 100 100"
    xmlSpace="preserve"
    {...props}
  >
    <path
      id="body-outline"
      d="M61.6,8.8c-4.8-1.2-9.7-1.6-14.7-1.3C43.4,7.7,40,8.2,36.7,8.9c-4.9,0.9-9.6,2.6-14,5
	c-4.1,2.2-7.7,5-10.8,8.5c-1.3,1.4-2.4,2.8-3.5,4.4c0,0,0,0,0,0c-2.7,3.9-4.8,8.2-6.3,12.6c-1.6,4.9-2.2,9.9-1.6,15
	c0.4,3.4,1.3,6.7,2.6,9.8c0.5,1.2,1.1,2.3,1.7,3.4c2.5,4.4,5.5,8.4,9.1,12c2.7,2.7,5.5,5,8.7,7.1c0,0,0,0,0,0
	c0.7,0.5,1.5,0.9,2.2,1.4c6,3.5,12.8,5.7,20.1,6.6c6.1,0.7,12,0.5,17.7-0.8c6-1.3,11.9-3.7,17.4-7.1c1.5-0.9,3-2,4.4-3.1
	c0.5-0.4,1-0.9,1.5-1.3c3.7-3.4,6.8-7.3,9.2-11.8c2.2-4,3.4-8.3,3.7-12.9c0.1-1.2,0-2.5-0.4-3.7c-1.2-4.6-2.6-9.2-4.4-13.6
	c-1.8-4.4-3.9-8.5-6.5-12.5c-2.8-4.3-6.2-8-10.1-11.2c-1.4-1.1-2.8-2.1-4.3-3C69.6,11.4,65.7,9.8,61.6,8.8 M51.7,9.4
	c4.8,0.1,9.4,1,13.9,2.7c2.6,1,5.1,2.2,7.4,3.8c0.4,0.2,0.7,0.5,1.1,0.7c3.9,2.8,7.2,6.1,10,10c2.8,4,5.2,8.2,7.1,12.8
	c2,4.7,3.6,9.5,4.8,14.5c0.3,1.2,0.4,2.4,0.4,3.6c-0.1,2.7-0.6,5.3-1.5,7.8c-1.8,4.8-4.4,9-7.8,12.8c-1.8,2-3.7,3.8-5.8,5.4
	c-5.8,4.3-11.9,7.2-18.4,8.8c-5.4,1.3-11.1,1.6-17,1c-7.7-0.8-14.6-3.2-20.7-7c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2-0.1-0.3-0.2-0.5-0.3
	c-2.8-1.9-5.4-4-7.8-6.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.8-0.8-1.6-1.7-2.4-2.5c-3.1-3.5-5.6-7.5-7.6-11.7C6,64.4,6,64.3,5.9,64.1
	c-2-4.4-2.9-9-2.9-13.9c0-4.7,1-9.3,2.8-13.7c1.4-3.3,3.1-6.4,5.1-9.3c0.3-0.4,0.6-0.9,1-1.3c2.9-3.8,6.4-7,10.5-9.6
	c4.3-2.7,9-4.6,14-5.7c0.5-0.1,0.9-0.2,1.4-0.3C42.4,9.7,47,9.3,51.7,9.4z"
    />
    <linearGradient
      id={dynamicId}
      gradientUnits="userSpaceOnUse"
      x1="78.822"
      y1="70.5249"
      x2="58.4787"
      y2="55.2542"
      gradientTransform="matrix(1 0 0 -1 0 100)"
    >
      <stop offset="0" class="startBodyColor" />
      <stop offset="1" class="stopBodyColor" />
    </linearGradient>
    <path
      class="body-inner"
      fill={`url(#${dynamicId})`}
      d="M65.6,12c-4.5-1.7-9.1-2.5-13.9-2.7c-4.6-0.1-9.3,0.3-13.8,1.1
	c-0.4,0.1-0.9,0.2-1.4,0.3c-5,1.1-9.7,2.9-14,5.7C18.4,19,14.9,22.2,12,26c-0.3,0.4-0.6,0.9-1,1.3c-2.1,2.9-3.8,6-5.1,9.3
	C4,41,3.1,45.5,3.1,50.2C3,55.1,4,59.7,5.9,64.1C6,64.3,6,64.4,6.1,64.5c1.9,4.3,4.5,8.2,7.6,11.7c0.8,0.9,1.5,1.7,2.4,2.5
	c0.1,0.1,0.2,0.2,0.3,0.3c2.4,2.4,5,4.5,7.8,6.4c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.1,0.4,0.2c6.1,3.9,13,6.2,20.7,7
	c5.9,0.6,11.6,0.3,17-1c6.5-1.6,12.6-4.5,18.4-8.8c2.1-1.6,4-3.4,5.8-5.4c3.4-3.8,6.1-8,7.8-12.8c0.9-2.5,1.5-5.1,1.5-7.8
	c0-1.2-0.1-2.4-0.4-3.6c-1.2-4.9-2.8-9.8-4.8-14.5c-1.9-4.5-4.2-8.8-7.1-12.8c-2.8-3.9-6.1-7.2-10-10c-0.4-0.3-0.7-0.5-1.1-0.7
	C70.7,14.3,68.2,13,65.6,12z"
    />
    <g transform="matrix( 1, 0, 0, 1, 0,0) ">
      <g>
        <g id="Layer16_0_FILL">
          <path
            class="body-shadow"
            fill-opacity="0.2"
            d="M17.3,75.4c-0.2-0.1-0.4-0.3-0.6-0.4c-2.7-1.8-5.4-3.7-8.2-5.9c1.7,4.4,4.5,8.2,8.1,11.6
				c0.6,0.6,1.3,1.1,1.9,1.7c0.4,0.3,0.8,0.6,1.2,0.9c0.3,0.2,0.6,0.4,0.8,0.6c0.5,0.4,1,0.7,1.6,1.1c0.1,0,0.1,0.1,0.1,0.1
				c0,0,0,0,0.1,0c0.2,0.2,0.5,0.3,0.7,0.4c0.3,0.2,0.5,0.3,0.8,0.5c0.1,0,0.1,0.1,0.2,0.1c6.6,3.8,14.9,6.5,25,8.4
				c20.5-0.9,34.1-7.7,40.9-20.4C70.8,88,51,90.8,30.7,82.5c-0.6-0.3-1.3-0.5-1.9-0.8C24.9,79.9,21.1,77.9,17.3,75.4z"
          />
        </g>
      </g>
    </g>
  </svg>
);
