import React from "react";
export default (dynamicId, props) => (
    <svg id="eyes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.46 135.08" {...props}>
        <title>eyes</title>
        <g id="eyes-outline">
            <path
                d="M587.2,439.5a14.63,14.63,0,0,0-.9,1.3,74.42,74.42,0,0,1,16.3,23.3c.5,1.1,1,2.2,1.5,3.4a102,102,0,0,1,6.6,26.8c.8,8.4-.1,15.4-2.8,21s-6.6,9.7-11.5,12-11,1.6-18.2-2.3-13.5-9.8-18.8-18c-.5-.8-1.1-1.6-1.6-2.5,4.1,7.5,8.3,12.8,12.7,16a104.09,104.09,0,0,0,10.5,7,28,28,0,0,0,6.8,2.9,19.68,19.68,0,0,0,13.4-3.2,26.64,26.64,0,0,0,9.2-11.5c2.5-6.2,3.5-13.5,3.1-22-.7-11.6-4-23.3-9.8-35.2-4.5-8.2-9.9-14.6-16.5-19m-11.4,18.3c3.3-5.7,6.6-11.2,10.2-16.5-3.1,4.4-6,9.1-8.9,13.9-3.2,5.5-5.9,10.2-8,14.1a160.06,160.06,0,0,0-13.1,32c3.2-8.8,6.8-17.3,10.6-25.6a177.86,177.86,0,0,1,9.2-17.9M413.8,471c.2-1.5.4-2.9.6-4.4A60.14,60.14,0,0,1,429,435.3h0c-9.8,8-15.8,17.5-18.1,28.5-3.3,15.8-2,30.6,3.9,44.5a62.15,62.15,0,0,0,19.1,25.1,58.92,58.92,0,0,0,23.6,11.1,55.68,55.68,0,0,0,26.3,0,60.74,60.74,0,0,0,15.4-5.4c5.8-2.8,11.4-7.6,16.8-14.2a72.49,72.49,0,0,0,12.7-22.8q-13.5-10.35-42.9-30.3c.9.6,1.7,1.3,2.5,1.9q18.45,13.95,36,29.1a45.44,45.44,0,0,1-2.3,7.4c-4.6,11.1-12.4,19.7-23.3,25.9s-21.6,8.3-32.2,6.4a68.4,68.4,0,0,1-28.9-12.4,53.63,53.63,0,0,1-18.9-25A79.74,79.74,0,0,1,413.8,471Z"
                transform="translate(-409.12 -411)" fill-opacity="0.4" />
            <path
                d="M585.9,441.3q-5.25,7.95-10.2,16.5t-9.3,18c-3.8,8.3-7.4,16.8-10.6,25.6a32.39,32.39,0,0,0,1.7,3.2c.5.8,1,1.7,1.6,2.5,5.3,8.2,11.6,14.3,18.8,18s13.3,4.6,18.2,2.3a24.9,24.9,0,0,0,11.5-12c2.7-5.6,3.6-12.6,2.8-21a102,102,0,0,0-6.6-26.8c-.5-1.1-1-2.3-1.5-3.4A74.42,74.42,0,0,0,586,440.9c.1.1,0,.2-.1.4m1.6,5h0l.3.3a66,66,0,0,1,4.9,5.5,63.1,63.1,0,0,1,7,11.1,70.18,70.18,0,0,1,7.1,21.6,54.2,54.2,0,0,1,.6,5.8,40.69,40.69,0,0,1-2.4,17.6,23.33,23.33,0,0,1-7.3,9.7,16.17,16.17,0,0,1-10.6,3.3,15.48,15.48,0,0,1-6.2-1,33.24,33.24,0,0,1-11.2-6.5,57.46,57.46,0,0,1-10.5-12.2.1.1,0,0,1,.1-.1h0c4.1-9.5,8.5-18.7,13-27.6C577.3,464.2,582.3,455.1,587.5,446.3Z"
                transform="translate(-409.12 -411)" />
            <path
                d="M559.5,501.2l-.1.1a54.75,54.75,0,0,0,10.5,12.2,33.24,33.24,0,0,0,11.2,6.5,15.48,15.48,0,0,0,6.2,1,16.17,16.17,0,0,0,10.6-3.3,22.7,22.7,0,0,0,7.3-9.7,40.69,40.69,0,0,0,2.4-17.6c-.1-1.9-.3-3.8-.6-5.8,0,16.3-3.5,26.8-10.6,31.3q-13.65,9.75-36.9-14.7M418.9,464.8a55.1,55.1,0,0,0,3.3,34.9A56,56,0,0,0,438,520.6a69.56,69.56,0,0,0,17.3,10.7c4.5,2.5,12.4,3.1,23.6,1.7a46.53,46.53,0,0,0,11.3-2.6,45,45,0,0,0,16.4-10.3,49.53,49.53,0,0,0,11.6-16.9q-27.9,37.2-68.3,19.7-44.25-24.6-17.6-80.1c-.5-.3-1.1-.7-1.6-1A51.17,51.17,0,0,0,418.9,464.8Z"
                transform="translate(-409.12 -411)" fill="#b7cae8" />
            <path
                d="M587.5,446.3h0c-5.2,8.8-10.2,17.9-15.1,27.3-4.6,8.9-8.9,18.1-13,27.6h0c0,.1,0,.1-.1.1l.1-.1c15.6,16.3,27.8,21.2,36.8,14.8,7.1-4.6,10.7-15,10.6-31.3a73,73,0,0,0-7.1-21.6,69.51,69.51,0,0,0-7-11.1,62.3,62.3,0,0,0-4.9-5.5c-.1,0-.1-.1-.3-.2h0M475.8,472c-14.3-10-28.9-19.7-43.6-29.1q-26.7,55.35,17.6,80.1,40.35,17.55,68.3-19.7C504.2,492.5,490.1,482,475.8,472Z"
                transform="translate(-409.12 -411)" fill="#fff" />
            <path
                d="M586.5,440.7c-3.6,5.5-7.1,11.2-10.5,17s-6.4,11.8-9.3,17.9c-3.8,8.3-7.4,16.8-10.6,25.6a34.36,34.36,0,0,0,3.3,5.6c5.3,8.2,11.6,14.3,18.9,18.1s13.3,4.6,18.2,2.3,8.7-6.4,11.5-12.1,3.7-12.6,2.8-21a102,102,0,0,0-6.6-26.8c-.5-1.1-1-2.3-1.5-3.4a76.58,76.58,0,0,0-16.2-23.2m-27,60.5c4.1-9.5,8.5-18.8,13.1-27.8,4.9-9.4,9.9-18.5,15.1-27.3a67.1,67.1,0,0,1,12.2,16.8,70.5,70.5,0,0,1,7.8,27.4,40.69,40.69,0,0,1-2.4,17.6,22.38,22.38,0,0,1-7.3,9.6,16.17,16.17,0,0,1-10.6,3.3,15.48,15.48,0,0,1-6.2-1,33.24,33.24,0,0,1-11.2-6.5,52,52,0,0,1-10.5-12.1m-145-34.7c-.2,1.4-.4,2.9-.6,4.4a80.46,80.46,0,0,0,5,34,53.63,53.63,0,0,0,18.9,25,69.68,69.68,0,0,0,28.9,12.4c10.6,1.9,21.3-.2,32.2-6.4A51.7,51.7,0,0,0,522.1,510a49.12,49.12,0,0,0,2.4-7.4q-17.55-15.15-36-29.1c-9.3-7-18.8-13.7-28.5-20-10-6.5-20.1-12.7-30.5-18.7a60.81,60.81,0,0,0-15,31.7m7.9,33.1a55.1,55.1,0,0,1-3.3-34.9,52,52,0,0,1,11.6-22.9q22.95,14.55,45.3,30.1c14.4,10,28.4,20.5,42.2,31.3a48.31,48.31,0,0,1-12,17.1,49.8,49.8,0,0,1-18.9,11.1c-10.4,2.8-19.8,2.9-28,.3a64.45,64.45,0,0,1-21.9-11.8A53.1,53.1,0,0,1,422.4,499.6Z"
                transform="translate(-409.12 -411)" />
        </g>
       
        <g id="eye-left-pupil">
            <path id="eye-left-upper-color"
                class="pupilUpper"
                d="M583.9,468.6c1.9,1.3,3,3.4,3.5,6.3s.7,4.8.8,5.9.3,3.1.6,6.1a40.87,40.87,0,0,0-.1-4.9v-1a56,56,0,0,0-3.2-16.2c-1.7-4.4-3.5-6.6-5.6-6.6s-3.7,2.2-5,6.6a53.7,53.7,0,0,0-1.3,16.2,54.35,54.35,0,0,0,.8,7.3l.3,2.1v.2c.1.6.3,1.5.6,2.6v-5.4a63,63,0,0,1,.8-9.5,22.43,22.43,0,0,0,.1,3.8,42.11,42.11,0,0,0,.9,5.5c1.2,5.2,2.5,7.9,4.1,8.1s2.8-1.2,3.6-3.9a35.92,35.92,0,0,0,1.2-7.2,51.29,51.29,0,0,0-.5-6.5,67.19,67.19,0,0,0-1.6-9.5"
                transform="translate(-409.12 -411)" fill="#003c77" />
            <path
                d="M583.1,472.1a1.27,1.27,0,0,0-.1-.6,18.68,18.68,0,0,0-1-4.5c-.6-1.3-1.3-1.9-2-1.9s-1.1,1-1.5,2.5a14.19,14.19,0,0,0-.2,5.1v.1a16.68,16.68,0,0,0,1.3,4.9c.6,1.4,1.2,2,1.8,2s1.1-.9,1.4-2.4a14.3,14.3,0,0,0,.3-5.2"
                transform="translate(-409.12 -411)" fill="#fff" />
            <path
                d="M583,471.5a1.27,1.27,0,0,1,.1.6,15,15,0,0,1-.3,5.3c-.3,1.5-.8,2.3-1.4,2.4s-1.2-.6-1.8-2a16.68,16.68,0,0,1-1.3-4.9v-.1a27.42,27.42,0,0,0,1,16c.9,2.2,1.8,3.4,2.8,3.4s1.9-1.1,2.5-3.4a22.48,22.48,0,0,0,.5-8,29.26,29.26,0,0,0-1.5-8.1,2.28,2.28,0,0,0-.6-1.2"
                transform="translate(-409.12 -411)" />
            <path id="eye-left-lower-color"
                class="pupilLower"
                d="M587.4,474.9c-.5-2.9-1.6-5-3.5-6.3.8,3.6,1.4,6.7,1.8,9.4a51.29,51.29,0,0,1,.5,6.5,26.89,26.89,0,0,1-1.2,7.2q-1.35,4.05-3.6,3.9c-1.5-.2-2.9-2.9-4.1-8.1a42.11,42.11,0,0,1-.9-5.5c-.1-1-.1-2.3-.1-3.8a63,63,0,0,0-.8,9.5v5.4c.4,1.4.9,2.7,1.3,4,1.7,4.5,3.6,6.7,5.7,6.7s3.8-2.2,5-6.7a39.68,39.68,0,0,0,1.3-10.3c-.3-3-.5-5-.6-6.1s-.3-2.9-.8-5.8"
                transform="translate(-409.12 -411)" fill="#06c" />
            <path
                d="M575.5,493.1c.4,1.4.9,2.7,1.3,4,1.7,4.5,3.6,6.7,5.7,6.7s3.8-2.2,5-6.7a39.68,39.68,0,0,0,1.3-10.3,40.87,40.87,0,0,0-.1-4.9"
                transform="translate(-409.12 -411)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="4" />
            <path
                d="M588.8,481a56,56,0,0,0-3.2-16.2c-1.7-4.4-3.5-6.6-5.6-6.6s-3.7,2.2-5,6.6a53.7,53.7,0,0,0-1.3,16.2,54.35,54.35,0,0,0,.8,7.3l.3,2.1v.2c.1.6.3,1.5.6,2.6"
                transform="translate(-409.12 -411)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="4" />
        </g>
        <g id="eye-right-pupil">
            <path id="eye-right-upper-color"
                class="pupilUpper"
                d="M458,501.8c0-.6.1-2.5.4-5.9a50.5,50.5,0,0,1,2.1-10.4,20.28,20.28,0,0,0-.2,4.1,34.11,34.11,0,0,0,1,6c1.5,5.7,3.4,8.6,5.9,8.8s4.4-1.2,6-4.3a19.24,19.24,0,0,0,2.6-7.8,35,35,0,0,0-.1-7.2,99.39,99.39,0,0,0-2.1-10.3,9.06,9.06,0,0,1,5.1,6.9,51.49,51.49,0,0,1,.8,6.5q0,1.8.3,6.6a45.53,45.53,0,0,0,.4-5.3,3.55,3.55,0,0,0-.2-1l.2-.1q0-10.35-3.6-17.7c-2.4-4.9-5.2-7.3-8.5-7.3s-6.2,2.4-8.5,7.3a41.37,41.37,0,0,0-3.5,17.7,51,51,0,0,0,.6,8,18.46,18.46,0,0,0,.4,2.3c0,.1.1.2.1.3a22.23,22.23,0,0,0,.8,2.8"
                transform="translate(-409.12 -411)" fill="#003c77" />
            <path
                d="M464.9,473.9a11,11,0,0,0-.8,5.5v.1a14.27,14.27,0,0,0,1.5,5.4c.8,1.5,1.7,2.2,2.8,2.1s1.9-1,2.6-2.6a13.13,13.13,0,0,0,.9-5.8v-.7a12.23,12.23,0,0,0-1.4-4.9c-.8-1.5-1.8-2.1-3-2C466.5,471.3,465.6,472.2,464.9,473.9Z"
                transform="translate(-409.12 -411)" fill="#fff" />
            <path
                d="M464.1,479.4a19.73,19.73,0,0,0-1.8,8.8,19.43,19.43,0,0,0,1.8,8.8c1.2,2.4,2.6,3.7,4.3,3.7s3.1-1.2,4.3-3.7a19.73,19.73,0,0,0,1.8-8.8,20.18,20.18,0,0,0-1.8-8.9c-.2-.5-.5-.9-.7-1.3v.7a13.38,13.38,0,0,1-.9,5.8c-.7,1.6-1.5,2.5-2.6,2.6s-2-.6-2.8-2.1a14.27,14.27,0,0,1-1.5-5.4A.31.31,0,0,1,464.1,479.4Z"
                transform="translate(-409.12 -411)" />
            <path id="eye-right-lower-color"
                class="pupilLower"
                d="M458.4,495.9c-.3,3.4-.4,5.3-.4,5.9.5,1.5,1,2.9,1.6,4.3,2.4,4.9,5.2,7.4,8.5,7.4s6.1-2.5,8.5-7.4a34.82,34.82,0,0,0,3.1-11.2q-.3-4.95-.3-6.6a53.68,53.68,0,0,0-.8-6.5,9.06,9.06,0,0,0-5.1-6.9,99.39,99.39,0,0,1,2.1,10.3,35,35,0,0,1,.1,7.2,22.91,22.91,0,0,1-2.6,7.8c-1.6,3-3.6,4.4-6,4.3s-4.4-3.1-5.9-8.8a34.11,34.11,0,0,1-1-6,20.28,20.28,0,0,1,.2-4.1A55,55,0,0,0,458.4,495.9Z"
                transform="translate(-409.12 -411)" fill="#06c" />
            <path
                d="M458,501.8c.5,1.5,1,2.9,1.6,4.3,2.4,4.9,5.2,7.4,8.5,7.4s6.1-2.5,8.5-7.4a34.82,34.82,0,0,0,3.1-11.2,45.53,45.53,0,0,0,.4-5.3"
                transform="translate(-409.12 -411)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="4" />
            <path
                d="M480.2,488.5q0-10.35-3.6-17.7c-2.4-4.9-5.2-7.3-8.5-7.3s-6.2,2.4-8.5,7.3a41.37,41.37,0,0,0-3.5,17.7,51,51,0,0,0,.6,8,18.46,18.46,0,0,0,.4,2.3c0,.1.1.2.1.3a18.34,18.34,0,0,0,.8,2.8"
                transform="translate(-409.12 -411)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="4" />
        </g>
        <path id="eye-brows" d="M437.5,411l-17.4,13,112.3,77.8,5.8-3.4Zm112,84.1,4.3,2.6,38.4-73.9L580.8,413l-31.3,82.1"
            transform="translate(-409.12 -411)" />
    </svg>
);
