import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import { getHistory } from "../../redux/actions/authActions";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.text.primary,
    textAlign: "center",
  },
  content: {
    color: theme.palette.text.secondary,
    padding: "25px 30px 25px 30px",
    textAlign: "center",
  },
}));

const About = (props) => {
  useEffect(() => {
    props.getHistory("/about");
  }, []);
  const classes = useStyles();
  return (
    <div
      style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto" }}
    >
      <h2 className={classes.heading}>About</h2>
      <p className={classes.content}>
        Version 1.0 - Official Release January 31, 2021 <br />
        Copyright © 2021 DoYourTip S.A de C.V. <br />
        <Link style={{ color: "blue" }} href={"http://doyourtip.io/"}>
          DoYourTip.io
        </Link>
        <br />
        Developed by Xord 2020-2021 <br />
        Powered by DoYourTip <br />
        contact@doyourtip.io <br />
        <Link
          style={{ color: "blue" }}
          href={"https://doyourtip.io/disclaimer.html#PP"}
        >
          Terms & conditions
        </Link>
        <br />
        {/* You are currently playing the beta version of Tipply Tank using the Kovan Test Network. The beta version is using Test Network tokens.
        Tipply Tank by <Link style={{ color: 'blue' }} href={'http://doyourtip.io/'}>DoYourTip.io</Link> */}
      </p>
    </div>
  );
};
const mapStateToProps = (state) => ({
  historyTipply: state.auth.historyTipply,
});

export default connect(mapStateToProps, { getHistory })(About);
