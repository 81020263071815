import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, Link } from "react-router-dom";

import { ReactComponent as FishTank } from "../../../Assets/Drawer/fish-tank.svg";
import { ReactComponent as MarketPlace } from "../../../Assets/Drawer/shopping-cart.svg";
import { ReactComponent as Wallet } from "../../../Assets/Drawer/wallet.svg";

import { ReactComponent as FishTankDark } from "../../../Assets/Drawer/fish-tank-dark.svg";
import { ReactComponent as MarketPlaceDark } from "../../../Assets/Drawer/shopping-cart-dark.svg";
import { ReactComponent as WalletDark } from "../../../Assets/Drawer/wallet-dark.svg";
import withWidth from "@material-ui/core/withWidth";
import SocialIcon from "../SocialIcons/SocialIcons";
import { ReactComponent as Trophy } from "../../../Assets/Drawer/white.svg";
import { ReactComponent as TrophyLight } from "../../../Assets/Drawer/trophyLight.svg";

// import FishTankActive from "../../../Assets/Drawer/fish-tank_active.svg";
// import MarketPlaceActive from "../../../Assets/Drawer/shopping-cart_active.svg";
// import WalletActive from "../../../Assets/Drawer/wallet_active.svg";

// import facebook from "../../../Assets/social/facebook.png";
// import linkedin from "../../../Assets/social/linked in.png";
// import twitter from "../../../Assets/social/Twitter.png";

import "./drawer.css";

import { Grid, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  drawer: {
    // background: theme.palette.primary.main,
  },
  drawerItem: {
    textAlign: "center",
    textDecoration: "none",
    padding: 32,
    cursor: "pointer",
    color: theme.palette.text.barColor,
  },
  activeDrawerItem: {
    background: theme.palette.primary.other,
    textAlign: "center",
    textDecoration: "none",
    padding: 32,
    boxShadow: "-18px 2px 8px 10px #00000033",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderRight: "none",
    width: "100.33%",
    zIndex: 1,
    color: theme.palette.secondary.main,
  },
  drawerText: {
    fontWeight: "bold",
  },
  activeDrawerImage: {
    "& svg": {
      fill: theme.palette.secondary.main,
    },
  },

  topbar: {
    // background: theme.palette.primary.main,
  },
  topbarItem: {
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer",
    color: theme.palette.text.secondary,
    padding: 24,
  },
  topbarItem2: {
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer",
    color: theme.palette.text.secondary,
    padding: "6px",
    fontSize: "13px",
    "@media only screen and (max-width:480px)": {
      fontSize: 11,
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  activeTopbarItem: {
    background: theme.palette.primary.other,
    textAlign: "center",
    textDecoration: "none",
    boxShadow: "0px -22px 40px -14px #00000033",
    color: theme.palette.secondary.main,
    borderBottom: "none",
    padding: "24px",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    height: "100.33%",
    zIndex: 1,
  },
  activeTopbarItem2: {
    background: theme.palette.primary.other,
    textAlign: "center",
    textDecoration: "none",
    boxShadow: "0px -22px 40px -14px #00000033",
    color: theme.palette.secondary.main,
    borderBottom: "none",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    height: "100.33%",
    zIndex: 1,
    fontSize: "13px",
    "@media only screen and (max-width:480px)": {
      fontSize: 11,
    },
    padding: "6px",
  },
}));

const MyDrawer = (props) => {
  const themeMode = localStorage.getItem("themeMode");
  const is960 = useMediaQuery("(max-width:960px)");
  const is480 = useMediaQuery("(max-width:480px)");
  const navItemClicked = (path) => {
    props.history.push(path);
  };
  const navItems = [
    ["Fish Tank", "/fishtank", <FishTank />, <FishTankDark />],
    ["Marketplace", "/marketplace", <MarketPlace />, <MarketPlaceDark />],
    ["Wallet", "/wallet", <Wallet />, <WalletDark />],
  ];
  const navItems960 = [
    ["Fish Tank", "/fishtank", <FishTank />, <FishTankDark />],
    ["Marketplace", "/marketplace", <MarketPlace />, <MarketPlaceDark />],
    ["Wallet", "/wallet", <Wallet />, <WalletDark />],
    ["Leaderboard", "/leaderBoard", <TrophyLight />, <Trophy />],
  ];

  const classes = useStyles();

  const navigation = is960 ? (
    props.width === "xs" || props.width === "sm" ? (
      <Grid
        className={classes.topbar}
        direction="row"
        container
        item
        justify="space-around"
      >
        {navItems960.map((navItem) => {
          return (
            <Grid
              key={navItem[0]}
              className={
                props.history.location.pathname === navItem[1]
                  ? classes.activeTopbarItem2
                  : classes.topbarItem2
              }
              onClick={() => navItemClicked(navItem[1])}
              item
              xs={3}
            >
              <div
                className={
                  props.history.location.pathname == navItem[1]
                    ? classes.activeDrawerImage
                    : ""
                }
              >
                {themeMode === "light" ? navItem[2] : navItem[3]}
              </div>
              <div>{navItem[0]}</div>
            </Grid>
          );
        })}
      </Grid>
    ) : (
      <Grid
        container
        className={classes.drawer}
        direction="column"
        // item
        justify="center"
      >
        {navItems960.map((navItem) => {
          return (
            <Grid
              key={navItem[0]}
              onClick={() => navItemClicked(navItem[1])}
              className={
                props.history.location.pathname === navItem[1]
                  ? [classes.activeDrawerItem, "padding"]
                  : classes.drawerItem
              }
              item
            >
              <div
                className={
                  props.history.location.pathname === navItem[1]
                    ? classes.activeDrawerImage
                    : ""
                }
              >
                {themeMode === "light" ? navItem[2] : navItem[3]}
              </div>
              <div className={classes.drawerText}>{navItem[0]}</div>
            </Grid>
          );
        })}

        <SocialIcon />
      </Grid>
    )
  ) : props.width === "xs" || props.width === "sm" ? (
    <Grid
      className={classes.topbar}
      direction="row"
      container
      item
      justify="space-around"
    >
      {navItems.map((navItem) => {
        return (
          <Grid
            key={navItem[0]}
            className={
              props.history.location.pathname === navItem[1]
                ? classes.activeTopbarItem2
                : classes.topbarItem2
            }
            onClick={() => navItemClicked(navItem[1])}
            item
            xs={3}
          >
            <div
              className={
                props.history.location.pathname === navItem[1]
                  ? classes.activeDrawerImage
                  : ""
              }
            >
              {themeMode === "light" ? navItem[2] : navItem[3]}
            </div>
            <div>{navItem[0]}</div>
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <Grid
      container
      className={classes.drawer}
      direction="column"
      // item
      justify="center"
    >
      {navItems.map((navItem) => {
        return (
          <Grid
            key={navItem[0]}
            onClick={() => navItemClicked(navItem[1])}
            className={
              props.history.location.pathname === navItem[1]
                ? [classes.activeDrawerItem, "padding"]
                : classes.drawerItem
            }
            item
          >
            <div
              className={
                props.history.location.pathname === navItem[1]
                  ? classes.activeDrawerImage
                  : ""
              }
            >
              {themeMode === "light" ? navItem[2] : navItem[3]}
            </div>
            <div className={classes.drawerText}>{navItem[0]}</div>
          </Grid>
        );
      })}

      <SocialIcon />
    </Grid>
  );

  return <>{navigation}</>;
};

export default withRouter(withWidth()(MyDrawer));
