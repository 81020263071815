export const walletConnectChecker = async (
  context,
  GetWalletAccount,
  walletconnect
) => {
  let connectedWallet;
  try {
    if (window.ethereum) {
      await window.ethereum.enable();
      connectedWallet = await GetWalletAccount.init();
    } else {
      await context.activate(walletconnect);
      connectedWallet = { address: context.account };
    }
    return connectedWallet;
  } catch (e) {
    // console.log("balle balle ==>>", e);
    return e;
  }
};
