import {GET_MARKET_TIPPLY,GET_MARKET_FOOD} from '../types'

const initialState = {
    tipplies : [],
    food : []
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_MARKET_TIPPLY:
        return {
          ...state,
         tipplies : action.payload
        };
        case GET_MARKET_FOOD:
          return {
            ...state,
           food : action.payload
          };
      default:
        return state;
    }
  }