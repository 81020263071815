import TestBodySVG from "../../../Assets/tipply_parts5/casual/body";

import styled, { keyframes, css } from "styled-components";
import React from "react";

import "../RandomTipply/RandomTipply.css";
import "../RandomTipply/CardTipply.css";
import "../RandomTipply/Loader.css";
import "../RandomTipply/Screenshot.css";
import "../RandomTipply/screenshot1000.css";

var colorFins = {
  casual: [
    ["#62AFFB", "#0066CC", "#003C77"],
    ["#9DEA1F", "#55800D", "#213205"],
    ["#F82102", "#E2A900", "#AA8400"],
    ["#FBBBD3", "#D22E91", "#A82474"],
    ["#E5262C", "#6D0003", "#400002"],
  ],
  nerdyweirdo: [
    ["#62AFFB", "#0066CC", "#003C77"],
    ["#9DEA1F", "#55800D", "#213205"],
    ["#F82102", "#E2A900", "#AA8400"],
    ["#FBBBD3", "#D22E91", "#A82474"],
    ["#E5262C", "#6D0003", "#400002"],
  ],
  sporty: [
    ["#62AFFB", "#0066CC", "#003C77"],
    ["#9DEA1F", "#55800D", "#213205"],
    ["#F82102", "#E2A900", "#AA8400"],
    ["#FBBBD3", "#D22E91", "#A82474"],
    ["#E5262C", "#6D0003", "#400002"],
  ],
  agroevil: [
    ["#62AFFB", "#0066CC", "#003C77"],
    ["#9DEA1F", "#55800D", "#213205"],
    ["#F82102", "#E2A900", "#AA8400"],
    ["#FBBBD3", "#D22E91", "#A82474"],
    ["#E5262C", "#6D0003", "#400002"],
  ],
  cutysexy: [
    ["#62AFFB", "#0066CC", "#003C77"],
    ["#9DEA1F", "#55800D", "#213205"],
    ["#F82102", "#E2A900", "#AA8400"],
    ["#FBBBD3", "#D22E91", "#A82474"],
    ["#E5262C", "#6D0003", "#400002"],
  ],

  //free
  free_casual: [
    ["#e18e62", "#753013", "#5d260f"],
    ["#bec786", "#3c431a", "#282c11"],
    ["#fa5442", "#671007", "#510e04"],
    ["#f4f4f4", "#666666", "#515151"],
    ["#e6b267", "#ca8724", "#734e17"],
  ],
  free_nerdyweirdo: [
    ["#e18e62", "#753013", "#5d260f"],
    ["#bec786", "#3c431a", "#282c11"],
    ["#fa5442", "#671007", "#510e04"],
    ["#f4f4f4", "#666666", "#515151"],
    ["#e6b267", "#ca8724", "#734e17"],
  ],
  free_sporty: [
    ["#e18e62", "#753013", "#5d260f"],
    ["#bec786", "#3c431a", "#282c11"],
    ["#fa5442", "#671007", "#510e04"],
    ["#f4f4f4", "#666666", "#515151"],
    ["#e6b267", "#ca8724", "#734e17"],
  ],
  free_agroevil: [
    ["#e18e62", "#753013", "#5d260f"],
    ["#bec786", "#3c431a", "#282c11"],
    ["#fa5442", "#671007", "#510e04"],
    ["#f4f4f4", "#666666", "#515151"],
    ["#e6b267", "#ca8724", "#734e17"],
  ],
  free_cutysexy: [
    ["#e18e62", "#753013", "#5d260f"],
    ["#bec786", "#3c431a", "#282c11"],
    ["#fa5442", "#671007", "#510e04"],
    ["#f4f4f4", "#666666", "#515151"],
    ["#e6b267", "#ca8724", "#734e17"],
  ],
};

const finAnimation = keyframes`
//  0% {
//   transform:rotate(3deg);
//   transform-origin: center;
// }
25%{
  transform:rotate(2deg);
  transform-origin: center;
}
50%{
  transform:rotate(1deg);
  transform-origin: center;
}
75%{
  transform:rotate(-1deg);
  transform-origin: center;
}
100%{
  transform:rotate(-2deg);
  transform-origin: center;
}
`;

const Container = styled((props) => {
  return <props.svg {...props} />;
})`
  ${(props) => {
    return props.featureType === 0 && props.tipply_type === "paid"
      ? css`
          .finAnimate {
            animation: ${props.animate
              ? css`
                  ${finAnimation} infinite 1s linear;
                `
              : ""};
          }
          .startFinsColor {
            stop-color: ${colorFins.casual[props.colorType][0]};
          }
          .stopFinsColor {
            stop-color: ${colorFins.casual[props.colorType][1]};
          }
          .leftFin {
            fill: ${colorFins.casual[props.colorType][2]};
          }
        `
      : props.featureType === 1 && props.tipply_type === "paid"
      ? css`
          .finAnimate {
            animation: ${props.animate
              ? css`
                  ${finAnimation} infinite 1s linear;
                `
              : ""};
          }
          .startFinsColor {
            stop-color: ${colorFins.nerdyweirdo[props.colorType][0]};
          }
          .stopFinsColor {
            stop-color: ${colorFins.nerdyweirdo[props.colorType][1]};
          }
          .leftFin {
            fill: ${colorFins.nerdyweirdo[props.colorType][2]};
          }
        `
      : props.featureType === 2 && props.tipply_type === "paid"
      ? css`
          .finAnimate {
            animation: ${props.animate
              ? css`
                  ${finAnimation} infinite 1s linear;
                `
              : ""};
          }
          .startFinsColor {
            stop-color: ${colorFins.sporty[props.colorType][0]};
          }
          .stopFinsColor {
            stop-color: ${colorFins.sporty[props.colorType][1]};
          }
          .leftFin {
            fill: ${colorFins.sporty[props.colorType][2]};
          }
        `
      : props.featureType === 3 && props.tipply_type === "paid"
      ? css`
          .finAnimate {
            animation: ${props.animate
              ? css`
                  ${finAnimation} infinite 1s linear;
                `
              : ""};
          }
          .startFinsColor {
            stop-color: ${colorFins.agroevil[props.colorType][0]};
          }
          .stopFinsColor {
            stop-color: ${colorFins.agroevil[props.colorType][1]};
          }
          .leftFin {
            fill: ${colorFins.agroevil[props.colorType][2]};
          }
        `
      : props.featureType === 4 && props.tipply_type === "paid"
      ? css`
          .finAnimate {
            animation: ${props.animate
              ? css`
                  ${finAnimation} infinite 1s linear;
                `
              : ""};
          }
          .startFinsColor {
            stop-color: ${colorFins.cutysexy[props.colorType][0]};
          }
          .stopFinsColor {
            stop-color: ${colorFins.cutysexy[props.colorType][1]};
          }
          .leftFin {
            fill: ${colorFins.cutysexy[props.colorType][2]};
          }
        `
      : props.featureType === 0 && props.tipply_type === "Free"
      ? css`
          .finAnimate {
            animation: ${props.animate
              ? css`
                  ${finAnimation} infinite 1s linear;
                `
              : ""};
          }
          .startFinsColor {
            stop-color: ${colorFins.free_casual[props.colorType][0]};
          }
          .stopFinsColor {
            stop-color: ${colorFins.free_casual[props.colorType][1]};
          }
          .leftFin {
            fill: ${colorFins.free_casual[props.colorType][2]};
          }
        `
      : props.featureType === 1 && props.tipply_type === "Free"
      ? css`
          .finAnimate {
            animation: ${props.animate
              ? css`
                  ${finAnimation} infinite 1s linear;
                `
              : ""};
          }
          .startFinsColor {
            stop-color: ${colorFins.free_nerdyweirdo[props.colorType][0]};
          }
          .stopFinsColor {
            stop-color: ${colorFins.free_nerdyweirdo[props.colorType][1]};
          }
          .leftFin {
            fill: ${colorFins.free_nerdyweirdo[props.colorType][2]};
          }
        `
      : props.featureType === 2 && props.tipply_type === "Free"
      ? css`
          .finAnimate {
            animation: ${props.animate
              ? css`
                  ${finAnimation} infinite 1s linear;
                `
              : ""};
          }
          .startFinsColor {
            stop-color: ${colorFins.free_sporty[props.colorType][0]};
          }
          .stopFinsColor {
            stop-color: ${colorFins.free_sporty[props.colorType][1]};
          }
          .leftFin {
            fill: ${colorFins.free_sporty[props.colorType][2]};
          }
        `
      : props.featureType === 3 && props.tipply_type === "Free"
      ? css`
          .finAnimate {
            animation: ${props.animate
              ? css`
                  ${finAnimation} infinite 1s linear;
                `
              : ""};
          }
          .startFinsColor {
            stop-color: ${colorFins.free_agroevil[props.colorType][0]};
          }
          .stopFinsColor {
            stop-color: ${colorFins.free_agroevil[props.colorType][1]};
          }
          .leftFin {
            fill: ${colorFins.free_agroevil[props.colorType][2]};
          }
        `
      : props.featureType === 4 && props.tipply_type === "Free"
      ? css`
                  .finAnimate {
                    animation: ${
                      props.animate
                        ? css`
                            ${finAnimation} infinite 1s linear;
                          `
                        : ""
                    };
                  }
                  .startFinsColor {
                    stop-color: ${colorFins.free_cutysexy[props.colorType][0]};
                  }
                  .stopFinsColor {
                    stop-color: ${colorFins.free_cutysexy[props.colorType][1]};
                  }
                  .leftFin {
                    fill: ${colorFins.free_cutysexy[props.colorType][2]};s
                  }
                `
      : "";
  }}
`;

export const TestFins = ({
  SVG,
  featureType,
  colorType,
  type,
  animate,
  tipply_type,
  realSS,
}) => {
  return (
    <Container
      svg={SVG}
      featureType={featureType}
      colorType={colorType}
      animate={animate}
      tipply_type={tipply_type}
      className={
        type === "fishTank" && featureType === 0
          ? "finsC"
          : type === "fishTank" && featureType === 1
          ? "finsN"
          : type === "fishTank" && featureType === 2
          ? "finsS"
          : type === "fishTank" && featureType === 3
          ? "finsA"
          : type === "fishTank" && featureType === 4
          ? "finsCS"
          : type === "card" && featureType === 0
          ? "finsCardC"
          : type === "card" && featureType === 1
          ? "finsCardN"
          : type === "card" && featureType === 2
          ? "finsCardS"
          : type === "card" && featureType === 3
          ? "finsCardA"
          : type === "card" && featureType === 4
          ? "finsCardCS"
          : type === "loader" && featureType === 0
          ? "finsLoaderC"
          : type === "loader" && featureType === 1
          ? "finsLoaderN"
          : type === "loader" && featureType === 2
          ? "finsLoaderS"
          : type === "loader" && featureType === 3
          ? "finsLoaderA"
          : type === "loader" && featureType === 4
          ? "finsLoaderCS"
          : featureType === 0 && !realSS
          ? "finsScreenshotC"
          : featureType === 1 && !realSS
          ? "finsScreenshotN"
          : featureType === 2 && !realSS
          ? "finsScreenshotS"
          : featureType === 3 && !realSS
          ? "finsScreenshotA"
          : featureType === 4 && !realSS
          ? "finsScreenshotCS"
          : featureType === 0 && realSS
          ? "finsScreenshot1000C"
          : featureType === 1 && realSS
          ? "finsScreenshot1000N"
          : featureType === 2 && realSS
          ? "finsScreenshot1000S"
          : featureType === 3 && realSS
          ? "finsScreenshot1000A"
          : featureType === 4 && realSS
          ? "finsScreenshot1000CS"
          : ""
      }
    />
  );
};
