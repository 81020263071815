import React from "react";
export default (dynamicId, props) => (
    <svg id="spikes" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 95.3 139.36" {...props}>
        <defs>
            <linearGradient id={dynamicId} x1="631.52" y1="533.03" x2="554.22" y2="591.05"
                gradientTransform="translate(0 -200)" gradientUnits="userSpaceOnUse">
                <stop class="startSpikesColor" offset="0" />
                <stop class="stopSpikesColor" offset="1" />
            </linearGradient>
        </defs>
        <title>spikes</title>
        <path id="spikes-inner"
            d="M480.1,471.3c7.7-2.2,12.8-8.1,15.4-17.7-10.1-10.9-23-16.1-38.6-15.8C460.4,453.5,468.2,464.7,480.1,471.3Z"
            transform="translate(-451.6 -431.34)" fill={`url(#${dynamicId})`} />
        <path id="spikes-outline"
            d="M495.5,453.6c1.4-7.1.4-11.9-3-14.6-15.3-8.3-29-9.9-40.9-4.6-.1,15.8,5.1,28,15.5,36.4a19.88,19.88,0,0,0,13,.5c-12-6.6-19.7-17.8-23.3-33.5C472.5,437.5,485.4,442.7,495.5,453.6Z"
            transform="translate(-451.6 -431.34)" />
        <path id="spikes-shadow"
            d="M493.1,458.43c1.1-.1,1.9-1.71,2.4-4.81-10-10.91-22.9-16.22-38.6-15.82C473.2,443.11,485.3,449.91,493.1,458.43Z"
            transform="translate(-451.6 -431.34)" fill="#fff" fill-opacity="0.25" />
        <g id="spikes-gills">
            <path
                d="M492.1,524c.1,2.2.4,4.4.7,6.6a84.64,84.64,0,0,0,2.3,10.9,91.78,91.78,0,0,0,7,17.4l6.4,10.8a129.29,129.29,0,0,1-11.2-29.4q-1.35-5.25-2.4-10.8a175.67,175.67,0,0,1-2.5-18.5l-.2,12.5v.5m27.2-30.8c0,.5.1.9.1,1.4.2,2.5.3,4.9.5,7.3l.3,2.1c.4,3.8.9,7.5,1.5,11,.2,1.3.4,2.6.7,3.9q.75,3.6,1.5,6.9c3.1,11.9,7.5,21.6,13.4,29.3l8.4,8.6.2.3h0q-10.8-13.8-18.6-39.9c-.6-2.2-1.3-4.4-1.9-6.7-.3-1.2-.6-2.5-1-3.8-.9-3.7-1.8-7.6-2.7-11.6-.5-2.5-1.1-5-1.5-7.6-.1-.3-.1-.7-.2-1-.3-1.5-.6-3-.8-4.5,0,1.4,0,2.8.1,4.3m-11.7,16.4c-.3-2.4-.6-4.8-.8-7.3l-.2,12.6v.2c0,.9.1,1.9.1,2.8.1,1.6.2,3.3.4,4.9a93.36,93.36,0,0,0,1.9,11,81.11,81.11,0,0,0,8.8,22.4l5.9,8.3c-5.2-7.8-9.3-18.4-12.4-31.9-.8-3.5-1.5-7.1-2.1-10.9-.2-1.6-.5-3.1-.7-4.8C508.3,514.6,508,512.1,507.7,509.6Z"
                transform="translate(-451.6 -431.34)" fill="#fff" fill-opacity="0.2" />
            <path
                d="M492.9,530.6c-.3-2.2-.6-4.4-.7-6.6v-.5a74.73,74.73,0,0,1,.2-12.5,109.75,109.75,0,0,0-2.7,21.4,53.56,53.56,0,0,0,.9,11.6q3.3,18.45,18.6,26.7c-.2-.3-.4-.7-.6-1a115.17,115.17,0,0,1-6.2-10.5.6.6,0,0,1-.1-.4,91.78,91.78,0,0,1-7-17.4,56.28,56.28,0,0,1-2.4-10.8m26.6-36c0-.5-.1-.9-.1-1.4,0-1.5-.1-2.9-.1-4.5-.2,1.5-.4,3-.7,4.5-.1.7-.2,1.4-.3,2-.3,2.3-.5,4.5-.8,6.6a119.45,119.45,0,0,0-.4,15.6c0,1.4.1,2.8.2,4.2.2,2.5.5,4.9.9,7.3,3,19.3,12.6,31.2,28.7,35.5a7,7,0,0,0-.9-.6h0l-.2-.3a48.93,48.93,0,0,1-8.4-8.6c-5.9-7.6-10.3-17.4-13.4-29.3a69.88,69.88,0,0,1-1.5-6.9c-.3-1.3-.5-2.6-.7-3.9-.6-3.5-1.1-7.1-1.5-11l-.3-2.1c-.2-2.2-.4-4.6-.5-7.1m-12.8,7.9c-.4,1.2-.8,2.3-1.1,3.5a103.51,103.51,0,0,0-2.3,10.1,80.75,80.75,0,0,0-1,9.5,54.25,54.25,0,0,0,.8,11.6c2.4,12.9,9.9,22.9,22.5,29.9-.7-.8-1.3-1.6-2-2.4a60.14,60.14,0,0,1-5.4-7.8,3.33,3.33,0,0,0-.3-.6,79.89,79.89,0,0,1-8.8-22.4,93.36,93.36,0,0,1-1.9-11c-.2-1.6-.3-3.3-.4-4.9a26.2,26.2,0,0,1-.1-2.8V515a2,2,0,0,0-.1-.7v-4c0-2.6.1-5.2.3-7.9h0v-.5C506.8,502.1,506.8,502.3,506.7,502.5Z"
                transform="translate(-451.6 -431.34)" fill="#070707" />
        </g>
    </svg>
);
