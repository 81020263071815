import React from "react";
export default (dynamicId, props) => (
  <svg
    id="fins"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsxlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 222.41 131.44"
    {...props}
  >
    <defs>
      <linearGradient
        id={dynamicId}
        x1="683.28"
        y1="505.1"
        x2="605.98"
        y2="563.12"
        gradientTransform="translate(0 -200)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" class="startFinsColor" />
        <stop offset="1" class="stopFinsColor" />
      </linearGradient>
    </defs>
    <title>fins</title>

    <g className="finAnimate">
      <path
        id="fin-small"
        d="M535.3,499.9h-.1c-5.3,16.9-5.5,26.9-.6,29.9s11.2,3.8,18.7,2.1,22-15.1,43.4-40.4l-5.4,1.7c-15.5,4.1-34.2,6.4-56,6.7"
        transform="translate(-393.29 -432.3)"
        fill={`url(#${dynamicId})`}
      />
      <path
        id="fin-shadow"
        d="M532.9,461.3l-1.1-.5h-1A418.24,418.24,0,0,0,500.4,494a285.35,285.35,0,0,1-34.6,35q-19.35,16.5-46,18.4,20.55,13.5,45.2-5.6t39.2-39.6C513.9,488.5,523.5,474.8,532.9,461.3Z"
        transform="translate(-393.29 -432.3)"
        fill={`url(#${dynamicId})`}
      />
      <path
        id="fins-outline"
        d="M615.4,485.3c.1-.1.2-.3.3-.4-6.8,2.5-13.1,4.7-19,6.6-21.4,25.3-35.8,38.8-43.4,40.4s-13.7,1-18.7-2.1-4.7-13,.6-29.9h-.1l-11.5-.8c-.8,3.7-1.8,7.9-2.8,12.5-3.1,13.6,1,22.8,12.3,27.5s21,4.6,29.2-.6c7.6-4.6,25.3-22.4,53.1-53.2M405.6,511.8q-.3-27.45,27.4-79.5-54.3,81.75-34.4,111.3c13.3,19.7,31.9,24.9,55.9,15.8s50.2-42,78.8-98.5a1,1,0,0,0-.4.5c-9.4,13.5-19,27.2-28.7,40.8s-22.8,26.9-39.2,39.6-31.5,14.6-45.2,5.6h0C410.5,541.9,405.8,530,405.6,511.8Z"
        transform="translate(-393.29 -432.3)"
      />
      <path
        id="fn-large"
        d="M433,432.3c-18.4,34.7-27.5,61.3-27.4,79.5s4.8,30,14.1,35.5h0v0q26.7-1.95,46-18.4a278.48,278.48,0,0,0,34.6-35,418.24,418.24,0,0,1,30.4-33.2l-7.5-.2h-1.6c-2.9-.1-4.9-.3-6-.3C490.7,457.7,463.1,448.4,433,432.3Z"
        transform="translate(-393.29 -432.3)"
        fill={`url(#${dynamicId})`}
      />
    </g>
  </svg>
);
