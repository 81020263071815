import TestBodySVG from "../../../Assets/tipply_parts5/casual/body";

import styled, { keyframes } from "styled-components";
import React from "react";

import "../RandomTipply/RandomTipply.css";
import "../RandomTipply/CardTipply.css";
import "../RandomTipply/Loader.css";
import "../RandomTipply/Screenshot.css";
import "../RandomTipply/screenshot1000.css";

var colorBody = {
  casual: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],
  nerdyweirdo: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],
  sporty: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],
  agroevil: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],
  cutysexy: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],


  //free
  free_casual: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
  free_nerdyweirdo: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
  free_sporty: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
  free_agroevil: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
  free_cutysexy: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
};

const Container = styled((props) => {
  // console.log(" ==========>>> ", props.tipply_type, "color type", props.colorType, "typee in body test")
  return <props.svg {...props} />;

})`
  ${(props) => {
    return props.tipply_type === "paid"
      ? ` .startBodyColor{ 
       stop-color : ${colorBody.casual[props.colorType][0]};
      }
      .stopBodyColor { 
       stop-color : ${colorBody.casual[props.colorType][1]};
    }`: props.tipply_type === "Free"
        ? ` .startBodyColor{ 
              stop-color : ${colorBody.free_casual[props.colorType][0]};
                 }
              .stopBodyColor { 
                  stop-color : ${colorBody.free_casual[props.colorType][1]};
                 }`

        : "";
  }}
`;

export const TestBody = ({ SVG, featureType, colorType, type, tipply_type, realSS }) => {
  return (
    <Container
      svg={SVG}
      featureType={featureType}
      colorType={colorType}
      tipply_type={tipply_type}
      realSS={realSS}
      className={
        type === "fishTank"
          ? "fishBody"
          : type === "card"
            ? "fishBodyCard"
            : type === "loader"
              ? "fishBodyLoader"
              : !realSS
                ? "fishBodyScreenshot"
                : "fishBodyScreenshot1000"
      }
    />
  );
};
