import React from "react";
import { Modal, useMediaQuery, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { CopyToClipboard } from "react-copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  QrModal: {
    border: "none",
    outline: "none",
    position: "absolute",
    maxWidth: 320,
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: "10px 30px",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "70%",
    margin: "auto",
    fontSize: 16,
    backgroundColor: theme.palette.secondary.modalbody,
    color: theme.palette.text.primary3,
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
    "@media only screen and (max-width:960px)": {
      fontSize: 13,
    },
  },
}));

function EnjinModal(props) {
  const classes = useStyles();
  const is960 = useMediaQuery("(max-width:960px)");

  const themeMode = localStorage.getItem("themeMode");
  // const copyClick = () => {
  //   props.setSnackbarState(true);
  //   props.setSnackbarMessage("Copied");
  // };
  React.useEffect(() => {
    if (props.userData) {
      // console.log(props.userData.userDetail, "user in modal");
    }
  }, [props.userData]);

  return (
    <Modal open={props.qrModal}>
      <div className={classes.QrModal}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            onClick={() => {
              props.setQrModal(false);
              clearInterval(props.scanCheck);
              props.setLinked(true);
            }}
          >
            <CloseIcon
              style={{
                cursor: "pointer",
                color: themeMode === "light" ? "black" : "white",
              }}
            />
          </div>
        </div>
        <div
          style={{
            margin: "10px 0",
            fontWeight: 600,
            fontSize: is960 ? 16 : 18,
          }}
        >
          SCAN OR COPY THE SCAN CODE TO LINK{" "}
        </div>

        {props.userData && props.userData.userDetail ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <span>
              QR CODE:
              <CopyToClipboard
                text={
                  props.userData.userDetail.enjin_QR_code
                    ? props.userData.userDetail.enjin_QR_code
                    : props.userData.userDetail.linkingCode
                }
                // onCopy={copyClick}
              >
                <span style={{ cursor: "pointer", color: "green" }}>
                  {"  "}
                  {props.userData.userDetail.enjin_QR_code
                    ? props.userData.userDetail.enjin_QR_code
                    : props.userData.userDetail.linkingCode}
                </span>
              </CopyToClipboard>
            </span>
            <img
              style={{ width: "100%", height: "100%", margin: 5 }}
              src={
                props.userData.userDetail.Qr_link
                  ? props.userData.userDetail.Qr_link
                  : props.userData.userDetail.linkingCodeQr
              }
            />
          </div>
        ) : (
          <CircularProgress size={35} className={classes.loaderStyles} />
        )}
      </div>
    </Modal>
  );
}

export default EnjinModal;
