import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  // TextField,
  Button,
  withWidth,
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@material-ui/core";
import "./Wallete.css";

import { giveApprovalToken } from "../../../Contracts/depositFunctions";
import { DYT_ADDRESS, ENJ_ADDRESS } from "../../../Contracts/Address";

import { walletConnectChecker } from "../../../Functions/functions";
import {
  withdrawETH,
  withdrawDYT,
  withdrawENJ,
} from "../../../Contracts/withdrawFunctions";
import {
  depositETH,
  depositDYT,
  depositENJ,
} from "../../../Contracts/depositFunctions";

import Snackbar from "../../Common/Snackbar/Snackbar";
import FullpageLoader from "../../Common/FullpageLoader/FullpageLoader";

import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

import GetWalletAccount from "../../../redux/config/getWalletAccount";
import { walletconnect } from "../../../Utils/connector";

const useStyles = makeStyles((theme) => ({
  tr: {
    border: "5px solid red",
  },
  tableHeader: {
    color: theme.palette.text.secondaryWallet,
    fontWeight: "bold",
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  tableBody: {
    border: "none",
    background: theme.palette.primary.tabRowColor,
    color: theme.palette.text.secondary,
    fontSize: "16px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      padding: "2px",
      width: "0px",
    },
  },

  input: {
    height: "32px",
    borderRadius: "5px",
    textAlign: "center",
    backgroundColor: "inherit",
    outline: "none",
    border: "1px solid",
    borderColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },

  depositButton: {
    color: "#0EDF62",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "none",
  },
  withdrawButton: {
    color: theme.palette.button.withdraw,
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "none",
  },

  cardTable: {
    marginBottom: 32,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
}));

const Wallet = (props) => {
  const context = useWeb3React();

  // console.log("DYT and ENJ approval", props.dytApproved, props.enjApproved);
  const classes = useStyles();
  const [tokens, setTokens] = useState({ ETH: 0, DYT: 0, ENJ: 0 });

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [pageLoaderState, setPageLoaderState] = useState(false);
  const [pageLoaderMessage, setPageLoaderMessage] = useState("");

  const amountChangeHandler = (e, i) => {
    const newTokens = { ...tokens };
    newTokens[e.target.id] = e.target.value;
    tableData[i].amount = e.target.value;
  };

  const calc = (value) => {
    if (value) {
      let number = Number(value);
      let rounded = Number(value).rounded;

      let with3decimals = number.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0];
      // var num = theform.original.value, rounded = theform.rounded
      // var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      rounded = with3decimals;
      return rounded;
    } else {
      return value;
    }
  };

  const depositHandler = async (tokenName, e, i) => {
    if (Number(tableData[i].amount) <= Number(tableData[i].WalletBalance)) {
      if (
        (tableData[i].amount >= 0.0001 && tokenName === "ETH") ||
        (tableData[i].amount >= 0.01 && tokenName === "DYT") ||
        (tableData[i].amount >= 0.01 && tokenName === "ENJ")
      ) {
        setPageLoaderState(true);
        setPageLoaderMessage("Processing Deposit Please wait...");
        if (tokenName === "ETH")
          await depositETH(
            context.library ? context.library.provider : window.ethereum,
            tableData[i].amount,
            props.address,
            props.checkUser,
            setPageLoaderState,
            setPageLoaderMessage
          );
        if (tokenName === "DYT")
          await depositDYT(
            context.library ? context.library.provider : window.ethereum,
            tableData[i].amount,
            props.address,
            props.checkUser,
            setPageLoaderState,
            setPageLoaderMessage
          );
        if (tokenName === "ENJ")
          await depositENJ(
            context.library ? context.library.provider : window.ethereum,
            tableData[i].amount,
            props.address,
            props.checkUser,
            setPageLoaderState,
            setPageLoaderMessage
          );

        // tableData[i].amount = "";
        props.getWalletBalance(
          context.library ? context.library.provider : window.ethereum,
          props.address
        );
      } else {
        setSnackbarState(true);
        setSnackbarMessage("Please increase the amount to deposit");
      }
    } else {
      setSnackbarState(true);
      setSnackbarMessage("You don't have enough balance in your wallet");
    }
  };

  const WithdrawHandler = async (tokenName, e, i) => {
    if (Number(tableData[i].amount) <= Number(tableData[i].TTBalance)) {
      if (
        (tableData[i].amount >= 0.0001 && tokenName === "ETH") ||
        (tableData[i].amount >= 0.01 && tokenName === "DYT") ||
        (tableData[i].amount >= 0.01 && tokenName === "ENJ")
      ) {
        setPageLoaderState(true);
        setPageLoaderMessage("Processing Withdraw Please wait...");
        if (tokenName === "ETH") {
          await withdrawETH(
            context.library ? context.library.provider : window.ethereum,
            tableData[i].amount,
            props.address,
            props.checkUser,
            setPageLoaderState,
            setPageLoaderMessage
          );
        }
        if (tokenName === "DYT")
          await withdrawDYT(
            context.library ? context.library.provider : window.ethereum,
            tableData[i].amount,
            props.address,
            props.checkUser,
            setPageLoaderState,
            setPageLoaderMessage
          );
        if (tokenName === "ENJ")
          await withdrawENJ(
            context.library ? context.library.provider : window.ethereum,
            tableData[i].amount,
            props.address,
            props.checkUser,
            setPageLoaderState,
            setPageLoaderMessage
          );

        props.getWalletBalance(
          context.library ? context.library.provider : window.ethereum,
          props.address
        );
      } else {
        setSnackbarState(true);
        setSnackbarMessage("Please increase the amount to withdraw");
      }
    } else {
      setSnackbarState(true);
      setSnackbarMessage("You don't have enough balance in your TT Wallet");
    }
  };

  var tableData = [
    { Token: "DYT", TTBalance: 0, WalletBalance: 0, amount: 0 },
    { Token: "ETH", TTBalance: 0, WalletBalance: 0, amount: 0 },
    { Token: "ENJ", TTBalance: 0, WalletBalance: 0, amount: 0 },
  ];
  useEffect(() => {}, [props.walletBalance]);

  if (props.user) {
    tableData = [
      {
        Token: "DYT",
        TTBalance: props.user.DYT_amount,
        WalletBalance: props.walletBalance.DYT,
        amount: 0,
      },
      {
        Token: "ETH",
        TTBalance: props.user.ETH_amount,
        WalletBalance: props.walletBalance.ETH,
        amount: 0,
      },
      {
        Token: "ENJ",
        TTBalance: props.user.ENJ_amount,
        WalletBalance: props.walletBalance.ENJ,
        amount: 0,
      },
    ];
  }

  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: "#FBFBFD",
    },
  }))(TableRow);
  return (
    <Grid
      style={{ padding: 0, justifyContent: "center" }}
      container
      justify="space-around"
    >
      <FullpageLoader
        open={pageLoaderState}
        message={pageLoaderMessage}
        // handleClose={() => setPageLoaderState(false)}
      />
      <Snackbar
        open={snackbarState}
        message={snackbarMessage}
        handleClose={() => setSnackbarState(false)}
      />

      <Grid item xs="12" md="10">
        {props.width !== "xs" &&
        props.width !== "smc" &&
        props.width !== "sm" ? (
          <TableContainer>
            <Table
              style={{ borderCollapse: "separate", borderSpacing: "0px 12px" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader} align="center">
                    Token
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="center">
                    TT Balance
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="center">
                    Wallet Balance
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="center">
                    Amount
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((token, i) => {
                  return (
                    <StyledTableRow>
                      <TableCell className={classes.tableBody} align="center">
                        {token.Token}
                      </TableCell>
                      <TableCell className={classes.tableBody} align="center">
                        {token.TTBalance ? calc(token.TTBalance) : 0}
                      </TableCell>
                      <TableCell className={classes.tableBody} align="center">
                        {token.WalletBalance ? calc(token.WalletBalance) : 0}
                      </TableCell>
                      <TableCell className={classes.tableBody} align="center">
                        <input
                          id={token.Token}
                          onChange={(e) => amountChangeHandler(e, i)}
                          type="number"
                          className={classes.input}
                        />
                      </TableCell>
                      <TableCell className={classes.tableBody} align="center">
                        {(token.Token === "DYT" && props.dytApproved) ||
                        (token.Token === "ENJ" && props.enjApproved) ||
                        token.Token === "ETH" ? (
                          <>
                            {" "}
                            <Button
                              id={token.Token}
                              onClick={async (e) => {
                                let connectedWallet;
                                if (window.ethereum) {
                                  await window.ethereum.enable();
                                  connectedWallet =
                                    await GetWalletAccount.init();
                                } else {
                                  await context.activate(walletconnect);

                                  connectedWallet = {
                                    address: context.account,
                                  };
                                  setSnackbarState(true);
                                  setSnackbarMessage(
                                    "Make sure your wallet is connected before you make any deposit"
                                  );
                                }

                                if (
                                  Web3.utils.toChecksumAddress(
                                    connectedWallet.address
                                  ) ==
                                  Web3.utils.toChecksumAddress(props.address)
                                ) {
                                  depositHandler(token.Token, e, i);
                                } else {
                                  setSnackbarState(true);
                                  setSnackbarMessage(
                                    "Make sure your wallet address is same as tipplytank address."
                                  );
                                }
                              }}
                              className={classes.depositButton}
                            >
                              Deposit
                            </Button>
                            <Button
                              id={token.Token}
                              onClick={async (e) => {
                                let connectedWallet;
                                if (window.ethereum) {
                                  await window.ethereum.enable();
                                  connectedWallet =
                                    await GetWalletAccount.init();
                                } else {
                                  await context.activate(walletconnect);
                                  connectedWallet = {
                                    address: context.account,
                                  };
                                  setSnackbarState(true);
                                  setSnackbarMessage(
                                    "Make sure your wallet is connected before you make any withdraw"
                                  );
                                }
                                if (
                                  Web3.utils.toChecksumAddress(
                                    connectedWallet.address
                                  ) ==
                                  Web3.utils.toChecksumAddress(props.address)
                                ) {
                                  WithdrawHandler(token.Token, e, i);
                                } else {
                                  setSnackbarState(true);
                                  setSnackbarMessage(
                                    "Make sure your wallet address is same as tipplytank address."
                                  );
                                }
                              }}
                              className={classes.withdrawButton}
                            >
                              Withdraw
                            </Button>
                          </>
                        ) : (
                          <Button
                            onClick={async () => {
                              let connectedWallet = await walletConnectChecker(
                                context,
                                GetWalletAccount,
                                walletconnect
                              );
                              // connectedWallet = await GetWalletAccount.init();

                              if (
                                Web3.utils.toChecksumAddress(
                                  connectedWallet.address
                                ) == Web3.utils.toChecksumAddress(props.address)
                              ) {
                                setSnackbarState(true);
                                setSnackbarMessage(
                                  "Wait until your wallet gets approved."
                                );
                                giveApprovalToken(
                                  context.library
                                    ? context.library.provider
                                    : window.ethereum,
                                  setPageLoaderState,
                                  setPageLoaderMessage,
                                  props.SetAllowance,
                                  props.address,
                                  token.Token === "DYT"
                                    ? DYT_ADDRESS
                                    : ENJ_ADDRESS
                                );
                              } else {
                                setSnackbarState(true);
                                setSnackbarMessage(
                                  "Make sure your wallet address is same as tipplytank address."
                                );
                              }
                            }}
                            className={classes.depositButton}
                          >
                            Approve
                          </Button>
                        )}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          tableData.map((token, i) => {
            return (
              <Card className={classes.cardTable}>
                <CardContent>
                  <Typography>Token : {token.Token}</Typography>
                  <Typography>
                    TT Balance : {token.TTBalance ? calc(token.TTBalance) : 0}
                  </Typography>
                  <Typography>
                    Wallet Balance :
                    {token.WalletBalance ? calc(token.WalletBalance) : 0}
                  </Typography>
                  <Typography>
                    Amount :{" "}
                    <input
                      id={token.Token}
                      onChange={(e) => amountChangeHandler(e, i)}
                      type="number"
                      className={classes.input}
                      style={{ width: "107px" }}
                    />
                  </Typography>
                </CardContent>
                <CardActions>
                  <Typography style={{ margin: "auto" }}>
                    Actions :
                    {(token.Token === "DYT" && props.dytApproved) ||
                    (token.Token === "ENJ" && props.enjApproved) ||
                    token.Token === "ETH" ? (
                      <>
                        <Button
                          id={token.Token}
                          onClick={async (e) => {
                            let connectedWallet;
                            if (window.ethereum) {
                              await window.ethereum.enable();
                              connectedWallet = await GetWalletAccount.init();
                            } else {
                              await context.activate(walletconnect);
                              connectedWallet = {
                                address: context.account,
                              };
                              setSnackbarState(true);
                              setSnackbarMessage(
                                "Make sure your wallet is connected before you make any deposit."
                              );
                            }
                            // alert(context.account + " and " + props.address);
                            if (
                              Web3.utils.toChecksumAddress(
                                connectedWallet.address
                              ) == Web3.utils.toChecksumAddress(props.address)
                            ) {
                              depositHandler(token.Token, e, i);
                            } else {
                              setSnackbarState(true);
                              setSnackbarMessage(
                                "Make sure your wallet address is same as tipplytank address."
                              );
                            }
                          }}
                          className={classes.depositButton}
                        >
                          Deposit
                        </Button>
                        <Button
                          id={token.Token}
                          onClick={async (e) => {
                            let connectedWallet;
                            if (window.ethereum) {
                              await window.ethereum.enable();
                              connectedWallet = await GetWalletAccount.init();
                            } else {
                              await context.activate(walletconnect);
                              connectedWallet = {
                                address: context.account,
                              };
                              setSnackbarState(true);
                              setSnackbarMessage(
                                "Make sure your wallet is connected before you make any withdraw"
                              );
                            }
                            if (
                              Web3.utils.toChecksumAddress(
                                connectedWallet.address
                              ) == Web3.utils.toChecksumAddress(props.address)
                            ) {
                              WithdrawHandler(token.Token, e, i);
                            } else {
                              setSnackbarState(true);
                              setSnackbarMessage(
                                "Make sure your wallet address is same as tipplytank address."
                              );
                            }
                          }}
                          className={classes.withdrawButton}
                        >
                          Withdraw
                        </Button>
                      </>
                    ) : (
                      <Button
                        onClick={async () => {
                          let connectedWallet = await walletConnectChecker(
                            context,
                            GetWalletAccount,
                            walletconnect
                          );
                          // connectedWallet = await GetWalletAccount.init();

                          if (
                            Web3.utils.toChecksumAddress(
                              connectedWallet.address
                            ) == Web3.utils.toChecksumAddress(props.address)
                          ) {
                            setSnackbarState(true);
                            setSnackbarMessage(
                              "Wait until your wallet gets approved."
                            );
                            giveApprovalToken(
                              context.library
                                ? context.library.provider
                                : window.ethereum,
                              setPageLoaderState,
                              setPageLoaderMessage,
                              props.SetAllowance,
                              props.address,
                              token.Token === "DYT" ? DYT_ADDRESS : ENJ_ADDRESS
                            );
                          } else {
                            setSnackbarState(true);
                            setSnackbarMessage(
                              "Make sure your wallet address is same as tipplytank address."
                            );
                          }
                        }}
                        className={classes.depositButton}
                      >
                        Approve
                      </Button>
                    )}
                  </Typography>
                </CardActions>
              </Card>
            );
          })
        )}
      </Grid>
    </Grid>
  );
};

export default withWidth()(Wallet);
