import React from "react";
import { Modal, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import toilet from "../../../Assets/toilet.svg";
import toiletL from "../../../Assets/toiletL.svg";
const useStyles = makeStyles((theme) => ({
  modalStyles: {
    border: "none",
    outline: "none",
    position: "absolute",
    maxWidth: 560,
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.secondary.modalbody,
    padding: "0px",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "380px",
    margin: "auto",
  },
  color: {
    color: theme.palette.secondary.other,
    marginBottom: "10px",
  },
  modalTextColor: {
    color: theme.palette.secondary.other,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0px 70px",
    "@media only screen and (max-width: 530px)": {
      padding: "15px 10px !important",
      justifyContent: "center",
    },
  },
  headerClass: {
    display: "flex",
    justifyContent: "space-between",
    padding: " 10px 30px",
  },
  bodyclass: {
    backgroundColor: theme.palette.secondary.modalbody,
  },
  buttonClass: {
    padding: "20px 60px",
    borderRadius: "0",
    textTransform: "capitalize",
    color: "white !important",
    backgroundColor: theme.palette.button.primary,
    height: "27px",
    "&:hover": {
      background: theme.palette.button.hover,
    },
    "@media only screen and (max-width: 330px)": {
      padding: "20px 40px !important",
    },
  },
  action: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-around",
    padding: "0 125px",
    marginBottom: "20px",
    "@media only screen and (max-width: 580px)": {
      padding: "0 50px !important",
    },
    "@media only screen and (max-width: 450px)": {
      padding: "0 10px !important",
    },
  },
}));

export const CustomModal = (props) => {
  const classes = useStyles();
  const themeMode = localStorage.getItem("themeMode");
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <div className={classes.modalStyles}>
        <div className={classes.headerClass}>
          <div></div>
          <Button onClick={props.handleClose}>
            <CloseIcon />
          </Button>
        </div>
        <div className={classes.bodyclass}>
          <div className={classes.modalTextColor}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              {themeMode === "dark" ? (
                <img src={toilet} />
              ) : (
                <img src={toiletL} />
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              className={classes.color}
            >
              Want To Flush?
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {props.message}
            </div>
          </div>
          <div className={classes.action}>
            <Button
              onClick={() => {
                props.onConfirm(props.id);
                props.handleClose();
              }}
              className={classes.buttonClass}
            >
              Yes
            </Button>
            <Button onClick={props.handleClose} className={classes.buttonClass}>
              No
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
