import axios from 'axios'
import { ROUTES } from '../../Utils/routes'
import { GET_MARKET_TIPPLY, GET_MARKET_FOOD } from '../types'

export const getMarketTipply = () => async (dispatch) => {
    let response = await axios.get(ROUTES.GET_MARKET_TIPPLY)
    dispatch({
        type: GET_MARKET_TIPPLY,
        payload: response.data
    })

}

export const getMarketFood = () => async (dispatch) => {
    let response = await axios.get(ROUTES.GET_MARKET_FOOD)
    dispatch({
        type: GET_MARKET_FOOD,
        payload: response.data
    })
} 