import React from "react";
export default (dynamicId, props) => (
    <svg id="top" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" viewBox="0 0 233.6 133.3" {...props}>
        <defs>
            <linearGradient id={dynamicId} x1="534.01" y1="697.92" x2="456.71" y2="755.94"
                gradientTransform="translate(0 -200)" gradientUnits="userSpaceOnUse">
                <stop class="startTopColor" offset="0" />
                <stop class="stopTopColor" offset="1" />
            </linearGradient>
        </defs>
        <title>top</title>
        <path id="top-outline"
            d="M383,564.5l-.4.1.3,2.4.1-2.5M489.2,433.7q-29.7,13.95-50,36.4-27.6,30.6-31.9,70.8a8.6,8.6,0,0,0-.1,1.6c0,.5-.1,1-.1,1.5l2-.4,2.5-.4c18.7-43.7,40.6-74.5,65.6-92.7a213.15,213.15,0,0,0,2.8,81.4q28.5-51.3,73.4-66-10.35,34.8-9.3,66.6c10.3-19,30.6-31.3,60.7-36.9-15,19.1-20.8,36.5-17.5,52.5h0l4.7,1.6q2.25-33.75,24.2-60.3-44.1.15-64.5,22.9a258.51,258.51,0,0,1,12.9-60.1Q512,465.85,489.1,507.3A200.14,200.14,0,0,1,489.2,433.7Z"
            transform="translate(-382.6 -433.7)" />
        <path id="top-inner"
            d="M587.4,548c-3.4-15.9,2.5-33.4,17.5-52.5-30.1,5.5-50.4,17.8-60.7,36.9L587.4,548M479.9,531.9l64.2.6c-.8-21.2,2.3-43.3,9.3-66.6q-45,14.7-73.5,66m-68.4,11.3h0l68.4-11.3a213,213,0,0,1-2.8-81.4Q439.6,477.65,411.5,543.2Z"
            transform="translate(-382.6 -433.7)" fill={`url(#${dynamicId})`} />
    </svg>
);
