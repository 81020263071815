import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import { getHistory } from "../../redux/actions/authActions";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.text.primary,
    textAlign: "center",
  },
  content: {
    color: theme.palette.text.secondary,
    padding: "25px 30px 25px 30px",
  },
}));

const Help = (props) => {
  useEffect(() => {
    props.getHistory("/help");
  }, []);
  const classes = useStyles();
  return (
    <>
      <div style={{ textAlign: "left" }}>
        <h2 className={classes.heading}>Help</h2>
        <p className={classes.content}>
          Welcome to the Tipply Tank guide! Tipply is a blowfish and our
          DoYourTip mascot. Are you ready to take care of your own unique Tipply
          NFT? <br />
          In Tipply Tank (TT) v1.0 you can breed, feed and grow your own unique
          Tipplys. Mint your Tipplys as NFTs to trade and collect them. The goal
          of the surviving part of the game is to level up your Tipply as fast
          as you can. Compete with players from all over the world to win DYT
          tokens worth thousands of dollars! Do you have the attitude to grow
          your Tipply faster than everyone else? <br />
          for more information read this article <br />
          <Link
            style={{ color: "blue" }}
            href={
              "https://medium.com/@DoYourTip/tipply-tank-guide-21124b069143"
            }
          >
            Tipply Guide
          </Link>
        </p>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  historyTipply: state.auth.historyTipply,
});

export default connect(mapStateToProps, { getHistory })(Help);
