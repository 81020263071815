import TestBodySVG from "../../../Assets/tipply_parts5/casual/body";

import styled, { keyframes } from "styled-components";
import React from "react";

import "../RandomTipply/RandomTipply.css";
import "../RandomTipply/CardTipply.css";
import "../RandomTipply/Loader.css";
import "../RandomTipply/Screenshot.css";
import "../RandomTipply/screenshot1000.css";

var colorSpikes = {
  casual: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],
  nerdyweirdo: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],
  sporty: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],
  agroevil: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],
  cutysexy: [
    ["#62AFFB", "#0066CC"],
    ["#9DEA1F", "#55800D"],
    ["#F82102", "#E2A900"],
    ["#FBBBD3", "#D22E91"],
    ["#E5262C", "#6D0003"],
  ],

  //free
  free_casual: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
  free_nerdyweirdo: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
  free_sporty: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
  free_agroevil: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
  free_cutysexy: [
    ["#e18e62", "#753013"],
    ["#bec786", "#bec786"],
    ["#fa5442", "#671007"],
    ["#f4f4f4", "#666666"],
    ["#e6b267", "#ca8724"],
  ],
};

const Container = styled((props) => {
  return <props.svg {...props} />;
})`
  ${(props) => {
    return props.featureType === 0 && props.tipply_type === "paid"
      ? `  .startSpikesColor { 
                stop-color : ${colorSpikes.casual[props.colorType][0]};
               }
               .stopSpikesColor { 
                stop-color : ${colorSpikes.casual[props.colorType][1]};
    }`
      : props.featureType === 1 && props.tipply_type === "paid"
        ? `  .startSpikesColor { 
                    stop-color : ${colorSpikes.nerdyweirdo[props.colorType][0]};
                   }
                   .stopSpikesColor { 
                    stop-color : ${colorSpikes.nerdyweirdo[props.colorType][1]};
         }`
        : props.featureType === 2 && props.tipply_type === "paid"
          ? `  .startSpikesColor { 
                        stop-color : ${colorSpikes.sporty[props.colorType][0]};
                       }
                       .stopSpikesColor { 
                        stop-color : ${colorSpikes.sporty[props.colorType][1]};
         }`
          : props.featureType === 3 && props.tipply_type === "paid"
            ? `  .startSpikesColor { 
                            stop-color : ${colorSpikes.agroevil[props.colorType][0]
            };
                           }
                           .stopSpikesColor { 
                            stop-color : ${colorSpikes.agroevil[props.colorType][1]
            };
             }`
            : props.featureType === 4 && props.tipply_type === "paid"
              ? `  .startSpikesColor { 
                                stop-color : ${colorSpikes.cutysexy[props.colorType][0]
              };
                               }
                               .stopSpikesColor { 
                                stop-color : ${colorSpikes.cutysexy[props.colorType][1]
              };
                 }`
              : props.featureType === 0 && props.tipply_type === "Free"
                ? `  .startSpikesColor { 
                        stop-color : ${colorSpikes.free_casual[props.colorType][0]};
                       }
                       .stopSpikesColor { 
                        stop-color : ${colorSpikes.free_casual[props.colorType][1]};
            }`
                : props.featureType === 1 && props.tipply_type === "Free"
                  ? `  .startSpikesColor { 
                            stop-color : ${colorSpikes.free_nerdyweirdo[props.colorType][0]};
                           }
                           .stopSpikesColor { 
                            stop-color : ${colorSpikes.free_nerdyweirdo[props.colorType][1]};
                 }`
                  : props.featureType === 2 && props.tipply_type === "Free"
                    ? `  .startSpikesColor { 
                                stop-color : ${colorSpikes.free_sporty[props.colorType][0]};
                               }
                               .stopSpikesColor { 
                                stop-color : ${colorSpikes.free_sporty[props.colorType][1]};
                 }`
                    : props.featureType === 3 && props.tipply_type === "Free"
                      ? `  .startSpikesColor { 
                                    stop-color : ${colorSpikes.free_agroevil[props.colorType][0]
                      };
                                   }
                                   .stopSpikesColor { 
                                    stop-color : ${colorSpikes.free_agroevil[props.colorType][1]
                      };
                     }`
                      : props.featureType === 4 && props.tipply_type === "Free"
                        ? `  .startSpikesColor { 
                                        stop-color : ${colorSpikes.free_cutysexy[props.colorType][0]
                        };
                                       }
                                       .stopSpikesColor { 
                                        stop-color : ${colorSpikes.free_cutysexy[props.colorType][1]
                        };
                         }`
                        : "";
  }}
`;

export const TestSpikes = ({ SVG, featureType, colorType, type, tipply_type, realSS }) => {
  return (
    <Container
      svg={SVG}
      featureType={featureType}
      colorType={colorType}
      tipply_type={tipply_type}
      className={
        type === "fishTank" && featureType === 0
          ? "spikesC"
          : type === "fishTank" && featureType === 1
            ? "spikesN"
            : type === "fishTank" && featureType === 2
              ? "spikesS"
              : type === "fishTank" && featureType === 3
                ? "spikesA"
                : type === "fishTank" && featureType === 4
                  ? "spikesCS"
                  : type === "card" && featureType === 0
                    ? "spikesCardC"
                    : type === "card" && featureType === 1
                      ? "spikesCardN"
                      : type === "card" && featureType === 2
                        ? "spikesCardS"
                        : type === "card" && featureType === 3
                          ? "spikesCardA"
                          : type === "card" && featureType === 4
                            ? "spikesCardCS"
                            : type === "loader" && featureType === 0
                              ? "spikesLoaderC"
                              : type === "loader" && featureType === 1
                                ? "spikesLoaderN"
                                : type === "loader" && featureType === 2
                                  ? "spikesLoaderS"
                                  : type === "loader" && featureType === 3
                                    ? "spikesLoaderA"
                                    : type === "loader" && featureType === 4
                                      ? "spikesLoaderCS"
                                      : featureType === 0 && !realSS
                                        ? "spikesScreenshotC"
                                        : featureType === 1 && !realSS
                                          ? "spikesScreenshotN"
                                          : featureType === 2 && !realSS
                                            ? "spikesScreenshotS"
                                            : featureType === 3 && !realSS
                                              ? "spikesScreenshotA"
                                              : featureType === 4 && !realSS
                                                ? "spikesScreenshotCS"
                                                : featureType === 0 && realSS
                                                  ? "spikesScreenshot1000C"
                                                  : featureType === 1 && realSS
                                                    ? "spikesScreenshot1000N"
                                                    : featureType === 2 && realSS
                                                      ? "spikesScreenshot1000S"
                                                      : featureType === 3 && realSS
                                                        ? "spikesScreenshot1000A"
                                                        : featureType === 4 && realSS
                                                          ? "spikesScreenshot1000CS"
                                                          : ""
      }
    />
  );
};
