import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { CheckPin, PinNull } from "../../redux/actions/authActions";
import { ReactComponent as TipplyIcon } from "../../Assets/Tabs/tipplyIcon.svg";
import { ReactComponent as FoodIcon } from "../../Assets/Tabs/foodIcon.svg";
import { ReactComponent as ClotheIcon } from "../../Assets/Tabs/clotheIcon.svg";
import { ReactComponent as TankIcon } from "../../Assets/Tabs/tankIcon.svg";
import EnterPin from "../AuthPopups/EnterPin";
import { RegisterPin } from "../AuthPopups/RegisterPin";
import Tank from "../../Assets/tt_bowl.png";
import { Grid, LinearProgress, withWidth, Modal } from "@material-ui/core";
import Snackbar from "../../Components/Common/Snackbar/Snackbar";
import EnjinModal from "../../Components/Common/Modal/enjinModal";
import {
  EnterPinAction,
  setPin,
  access_token_null,
  AT_PASS,
} from "../../redux/actions/pinAction";
import Tipplys from "../../Components/Inventory/Tipplys/Tipplys";
import Food from "../../Components/Inventory/Food/Food";
import ActivatedTipply from "../../Components/Inventory/ActivatedTipply/ActivatedTipply";
import Statsbar from "../../Components/Common/Statsbar/Statsbar";
import SocialIcons from "../../Components/Common/SocialIcons/SocialIcons";

import { qrScan } from "../../redux/actions/layoutActions";
import { connect } from "react-redux";

import {
  getUserTipply,
  getUserFood,
} from "../../redux/actions/fishtankActions";

import { getWalletBalance } from "../../redux/actions/walletActions";
import { getHistory } from "../../redux/actions/authActions";
import { winning_modal_state } from "../../redux/actions/fishtankActions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  margintop: {
    marginTop: "50px",
  },
  root: {
    flexGrow: 1,
    backgroundColor: "inherit",
    borderRadius: 16,
  },

  // ".MuiTab-wrapper": {
  //   display: "flex",
  // },

  tabsDiv: {
    // backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 2px 10px #00000029 !important",
  },

  tabs: {
    backgroundColor: theme.palette.primary.other,
    color: theme.palette.text.primary,
    height: "calc(100vh - 230px)",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      "& .MuiBox-root": {
        padding: "24px 0px",
        "@media (min-width:350px) and (max-width:408px)": {
          padding: "24px 35px",
        },
      },
    },
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  tabPanel: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    margin: "0 0 5px 0",
  },
  tabButton: {
    minWidth: 72,
    padding: 0,
    textTransform: "none",
    "& span": {
      flexDirection: "row",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },

  tabIcon: {
    margin: 0,
    padding: 8,
    [theme.breakpoints.down("xs")]: {
      height: 14,
    },
  },
  tabIconActive: {
    "& .a": { fill: theme.palette.text.primary },
    margin: 0,
    padding: 8,
    [theme.breakpoints.down("xs")]: {
      height: 14,
    },
  },

  fishtankDiv: {
    textAlign: "center",
    position: "relative",
  },

  fish: {
    position: "absolute",
    left: "calc(50% - 130px)",
    top: "calc(50% - 110px)",
    "@media (max-width: 400px)": {
      left: "calc(50% - 101px)",
    },
  },

  tankImage: {
    margin: "auto",
    width: "80%",
    "@media (min-width:960px) and (max-width: 1200px)": {
      width: "100% !important",
    },
    "@media (min-width:600px) and (max-width: 960px)": {
      width: "60% !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "@media only screen and (max-width: 340px)": {
      width: "103% !important",
    },
  },

  activeFishText: {
    color: "#ffffff",
    margin: "0px 32px",
    backgroundColor: "#F4C595",
    padding: 4,
    borderRadius: 12,
    fontWeight: "bold",
    "@media (max-width: 400px)": {
      margin: "-16px 16px",
    },
  },
  activeFishEnergy: {
    margin: "auto",
    marginTop: "12px",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 7,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    border: "2px solid white !important",
  },

  bar: {
    borderRadius: 7,
    backgroundColor: "#F4C595",
  },
}))(LinearProgress);

function SimpleTabs(props) {
  const width = props.width;
  const [intervalID, setIntervalID] = useState(0);
  const [Tipplies, setTipplies] = useState(props.tipplies.tipplies);
  const [PinShowState, setPinShowState] = useState(true);
  const [winnerTipplys, setWinningTipplys] = useState({});
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const themeMode = localStorage.getItem("themeMode");
  var activeTipply;

  useEffect(() => {
    let tipplyData = [];
    if (props.tipplies.tipplies) {
      let ActiveTipplies = props.tipplies.tipplies.filter(
        (tipply) => tipply.isActive === true
      );
      let WinnerTipplys = props.tipplies.tipplies.filter(
        (tipply) => tipply.hasWon === true
      );
      setWinningTipplys(WinnerTipplys);
      // console.log(WinnerTipplys, "winnerTipply");
      let Inactiveipplies = props.tipplies.tipplies.filter(
        (tipply) => !tipply.isActive && !tipply.hasWon
      );
      if (WinnerTipplys) {
        tipplyData = [...ActiveTipplies, ...WinnerTipplys, ...Inactiveipplies];
      } else {
        tipplyData = [...ActiveTipplies, ...Inactiveipplies];
      }
      setTipplies(tipplyData);
    }
  }, [props.tipplies.tipplies]);

  if (props.tipplies.tipplies) {
    activeTipply = props.tipplies.tipplies.find((tipply) => {
      return tipply.isActive === true;
    });
  }

  useEffect(() => {
    clearInterval(intervalID);
    console.log(
      "address changed ==>>>",
      props.address,
      "user address=>",
      props.user.address
    );
    getUserTipplyCron();
    return () => {
      clearInterval(intervalID);
    };
  }, [props.user.address]);

  useEffect(() => {
    if (props.user) {
      props.getUserTipply(props.user.address);
      props.getUserFood(props.user.address);
      // console.log("sdf", props.food)
    }
  }, [props.user]);

  const getUserTipplyCron = () => {
    const id = setInterval(() => {
      props.getUserTipply(props.user.address);
    }, 60000);
    // console.log("iddd ---", id);
    setIntervalID(id);
    localStorage.setItem("intervalID", id);
  };

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabClicked = (index) => {
    setActiveTab(index);
  };
  if (activeTipply) {
    // console.log(activeTipply.bornAt, "born at in fish tank");
  }
  //ahsan code
  useEffect(() => {
    if (props.Pin === null) {
      props.CheckPin(props.address);
    }
    props.history.push("/fishtank");
    props.getHistory("/fishtank");
    // console.log(PinShowState, "and", props.Pin, "pin show ");
  }, []);
  //ahsan code
  useEffect(() => {
    if (props.Pin === null) {
      props.CheckPin(props.address);
    }
    if (props.Pin === true || props.Pin === false) {
      setPinShowState(true);
      // console.log(PinShowState, "and", props.Pin, "pin show ");
    }
  }, [props.Pin]);

  //ashan pin code
  return (
    <Grid className={classes.root} container item justify="center">
      <Snackbar
        open={snackbarState}
        message={snackbarMessage}
        handleClose={() => setSnackbarState(false)}
      />
      {props.address && props.user ? (
        <EnjinModal
          user={props.user}
          setSnackbarState={setSnackbarState}
          setSnackbarMessage={setSnackbarMessage}
          qrScan={props.qrScan}
          open_qrScan={props.open_qrScan}
        />
      ) : (
        ""
      )}
      {PinShowState ? (
        <>
          {props.Pin === true && props.access_token_pass === true ? (
            <EnterPin
              address={props.address}
              getHistory={props.getHistory}
              history={props.history}
              PinShowState={PinShowState}
              ReduxPin={props.Pin}
              PinNull={props.PinNull}
              setPinShowState={setPinShowState}
              EnterPin={props.EnterPinAction}
              access_token={props.access_token}
              AT_NULL={props.access_token_null}
              access_token_pass={props.access_token_pass}
              AT_PASS={props.AT_PASS}
            />
          ) : props.Pin === false ? (
            <RegisterPin
              getHistory={props.getHistory}
              address={props.address}
              PinShowState={PinShowState}
              history={props.history}
              ReduxPin={props.Pin}
              PinNull={props.PinNull}
              setPinShowState={setPinShowState}
              setPin={props.setPin}
              setPinReq={props.setPinReq}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      <Statsbar></Statsbar>

      <Grid style={{ padding: 16 }} container item xs={12}>
        <Grid justify="center" direction="column" container item xs={12} md={6}>
          <Grid item>
            <div className={classes.tankImage}>
              <div className={classes.fishtankDiv}>
                {activeTipply ? (
                  <ActivatedTipply
                    activeTipply={activeTipply}
                    width={props.width}
                    tipply_type={activeTipply.type}
                    // tipply_type="paid"
                  />
                ) : (
                  ""
                )}
                <img style={{ width: "100%" }} src={Tank} />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          className={width === "sm" ? classes.margintop : ""}
        >
          <div className={classes.tabsDiv}>
            <AppBar className={classes.tabPanel} position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                scrollButtons={true}
                aria-label="simple tabs example"
                style={{ boxShadow: "0px 2px 10px #00000029" }}
                variant="fullWidth"
              >
                <Tab
                  className={classes.tabButton}
                  label="Tipplys"
                  {...a11yProps(0)}
                  onClick={() => tabClicked(0)}
                  icon={
                    <TipplyIcon
                      className={
                        activeTab === 0
                          ? classes.tabIconActive
                          : classes.tabIcon
                      }
                    />
                  }
                />
                <Tab
                  onClick
                  className={classes.tabButton}
                  label="Foods"
                  {...a11yProps(1)}
                  onClick={() => tabClicked(1)}
                  icon={
                    <FoodIcon
                      className={
                        activeTab === 1
                          ? classes.tabIconActive
                          : classes.tabIcon
                      }
                    />
                  }
                />
                <Tab
                  className={classes.tabButton}
                  label="Clothes"
                  {...a11yProps(2)}
                  onClick={() => tabClicked(2)}
                  icon={
                    <ClotheIcon
                      className={
                        activeTab === 2
                          ? classes.tabIconActive
                          : classes.tabIcon
                      }
                    />
                  }
                />
                <Tab
                  className={classes.tabButton}
                  label="Tanks"
                  {...a11yProps(3)}
                  onClick={() => tabClicked(3)}
                  icon={
                    <TankIcon
                      className={
                        activeTab === 3
                          ? classes.tabIconActive
                          : classes.tabIcon
                      }
                    />
                  }
                />
              </Tabs>
            </AppBar>
            <TabPanel className={classes.tabs} value={value} index={0}>
              <Tipplys
                access_token_null={props.access_token_null}
                tipplies={Tipplies}
                winnerTipplys={winnerTipplys}
                winning_modal_state={props.winning_modal_state}
                user={props.user}
                winningState={props.winningState}
                address={props.address}
                getHistory={props.getHistory}
                getUserTipply={props.getUserTipply}
                getWalletBalance={props.getWalletBalance}
              />
            </TabPanel>
            <TabPanel className={classes.tabs} value={value} index={1}>
              <Food
                tipplies={props.tipplies}
                access_token_null={props.access_token_null}
                Food={props.Food}
                getHistory={props.getHistory}
                CheckPin={props.CheckPin}
                address={props.address}
                history={props.history}
                user={props.user}
                getUserTipply={props.getUserTipply}
                getUserFood={props.getUserFood}
              />
            </TabPanel>
            <TabPanel className={classes.tabs} value={value} index={2}>
              Clothes coming soon
            </TabPanel>
            <TabPanel className={classes.tabs} value={value} index={3}>
              Tanks coming soon
            </TabPanel>
          </div>
        </Grid>
      </Grid>
      {props.width == "sm" || props.width == "xs" ? <SocialIcons /> : <></>}
    </Grid>
  );
}

//ahsan pincode
const mapStateToProps = (state) => ({
  tipplies: state.fishtank.tipplies,
  Food: state.fishtank.food.Food,
  user: state.layout.user,
  Pin: state.auth.Pin,
  access_token_pass: state.pin.access_token_pass,
  address: state.layout.address,
  access_token: state.pin.access_token,
  setPinReq: state.pin.setPinReq,
  reloadstate: state.layout.reload,
  open_qrScan: state.layout.open_qrScan,
  winningState: state.fishtank.winning_modal_state,
});

export default withWidth()(
  connect(mapStateToProps, {
    getUserTipply,
    getUserFood,
    getWalletBalance,
    getHistory,
    CheckPin,
    PinNull,
    EnterPinAction,
    setPin,
    access_token_null,
    AT_PASS,
    winning_modal_state,
    qrScan,
  })(SimpleTabs)
);
