import {
  ENABLE_FEED_ANIMATION,
  DISABLE_FEED_ANIMATION,
  ENABLE_FEED_SPEECH,
  DISABLE_FEED_SPEECH,
} from "../types";

const initialState = {
  feedAnimation: {
    animationState: false,
    animationImage: null,
    animationSpeech: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ENABLE_FEED_ANIMATION:
      // console.log("feed animation reducer");
      return {
        ...state,
        feedAnimation: {
          ...state.feedAnimation,
          animationState: true,
          animationImage: action.payload,
        },
      };

    case DISABLE_FEED_ANIMATION:
      return {
        ...state,
        feedAnimation: {
          ...state.feedAnimation,
          animationState: false,
          animationImage: null,
        },
      };

    case ENABLE_FEED_SPEECH:
      return {
        ...state,
        feedAnimation: {
          ...state.feedAnimation,
          animationSpeech: true,
        },
      };

    case DISABLE_FEED_SPEECH:
      // console.log("feed animation reducer");
      return {
        ...state,
        feedAnimation: {
          ...state.feedAnimation,
          animationSpeech: false,
        },
      };
    default:
      return state;
  }
}
