import axios from "axios";
import { ROUTES } from "../../Utils/routes";
import {
  GET_USER_TIPPLY,
  GET_USER_FOOD,
  CHECK_ALLOWANCE,
  ENABLE_FEED_ANIMATION,
  DISABLE_FEED_ANIMATION,
  WINNING_MODAL_STATE,
} from "../types";

// import { checkAllowance } from "../../Contracts/MintFunctions";

export const getUserTipply = (address) => async (dispatch) => {
  let response = await axios.get(
    `${ROUTES.GET_USER_TIPPLY}?address=${address}`
  );
  dispatch({
    type: GET_USER_TIPPLY,
    payload: response.data,
  });
};

export const getUserFood = (address) => async (dispatch) => {
  let response = await axios.get(`${ROUTES.GET_USER_FOOD}?address=${address}`);
  dispatch({
    type: GET_USER_FOOD,
    payload: response.data,
  });
};

export const winning_modal_state = (state) => {
  return async (dispatch) => {
    dispatch({
      type: WINNING_MODAL_STATE,
      payload: state,
    });
  };
};

// export const checkAllowanceRedux = (address) => async (dispatch) => {
//   let response = await checkAllowance(address);

//   dispatch({
//     type: CHECK_ALLOWANCE,
//     payload: response.data,
//   });
// };
