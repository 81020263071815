import React from 'react';
export default (dynamicId, props) => (
    <svg id="spikes" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 357.7 335.5" {...props}>
        <defs>
            <linearGradient id={dynamicId} x1="339.75" y1="464.2" x2="685.25" y2="464.2"
                gradientUnits="userSpaceOnUse">
                <stop class="startSpikesColor" offset="0" />
                <stop class="stopSpikesColor" offset="1" />
            </linearGradient>
        </defs>
        <title>spikes</title>
        <path id="spikes-inner"
            d="M666.3,567.8l-6.7,8.2,17.6,7.1Zm-41.5,57.5L619,609.6l-8.8,4.8,14.6,10.9m-240-32.9c-5.5,7.5-9.8,13.5-13.1,18.1a130,130,0,0,0,22-12.5l-8.1-6.6-.3.5Zm5.7-51.3-14.7-15.6-21.1,26,35.8-10.4m-58.8-58.5a241.8,241.8,0,0,0,32,7.7l-6-21.5c-11.2,4.9-19.9,9.5-26,13.8m30.8-61.9,9.9-16.9L343,400.5l19.5,20.2M603.9,333l6.8,5,6.2-13.6-13,8.6M440.1,455.4q-4.05-7.95-2.4-23.1L415,449.1c4.3,2.4,12.6,4.5,25.1,6.3M379,332.9l19,26.8c5.6-1.5,9.9-5.4,12.6-11.6-7.4-6-17.9-11.1-31.6-15.2m194.4,6.2c.2-15.8.6-27.8,1.3-36-6.4,5.7-12.5,15.1-18.5,28.4,2.7,5.5,8.5,8,17.2,7.6m-99.7-35.8-4,26.3c9.1,3.1,16.8,1.9,23.2-3.4Z"
            transform="translate(-325 -295)" fill={`url(#${dynamicId})`} />
        <path id="spikes-outline"
            d="M659.5,576l-3.3,3.7,26.5,7.9-13.6-23.3-2.9,3.5,10.9,15.3L659.5,576m-36.7,31.4-3.7,2.1,5.8,15.7-14.5-10.9-4.5,2.4,22.3,13.8-5.4-23.1m-251.1,3.1c3.3-4.6,7.7-10.6,13.1-18.1.2-.2.3-.4.5-.6s.2-.3.3-.5l-4.1-3.3c-7,11.7-12.1,20.3-15.4,25.8,8.8-.7,19.5-4.8,32-12.3l-4.4-3.5a130,130,0,0,1-22,12.5M599.8,330l4.1,3,13-8.6L610.7,338l4.1,3c3.1-10.4,4.6-17.1,4.4-20.1L599.8,330M410.6,348a12.7,12.7,0,0,0,.1-8.8c-13.2-7.6-26.4-11.4-39.6-11.2l17,31.4c2.3,1.1,5.6,1.2,9.8.3L379,332.9C392.7,337,403.2,342.1,410.6,348ZM375.8,525.4l-2.3-6.6-26.2,37.9,50-11.6-6.8-4-35.8,10.4,21.1-26.1m-12.1-35.1a241.8,241.8,0,0,1-32-7.7c6.1-4.3,14.8-8.9,26-13.8A7.84,7.84,0,0,0,355,462c-13.5,7.7-23.5,15-30,21.7l41,14.9c1.1-2.5.3-5.3-2.3-8.3M415,449.1l22.7-16.8a18.23,18.23,0,0,0-5.6-5.8c-11.6,8.7-20.2,16.6-25.8,23.8,6.5,4.3,17.1,8.4,31.8,12.4a18.2,18.2,0,0,0,2.1-7.3c-12.6-1.8-20.9-3.9-25.2-6.3M554.6,321.4c-.8,2.8-.3,6.2,1.5,10.1,6-13.3,12.2-22.8,18.5-28.4-.6,8.2-1,20.2-1.3,36,3.5-.2,5.5-2.3,6.1-6.1-.4-14.6-.9-26.5-1.6-35.7-9.1,4.8-16.8,12.8-23.2,24.1m-61.7,4.9a6.84,6.84,0,0,0,2.5-6.3l-23.7-25-8.5,28a7.85,7.85,0,0,0,6.6,6.6l4-26.3ZM372.5,403.8c.9-2.9.9-5.1,0-6.8H333.7l24.4,28.1a6.62,6.62,0,0,0,4.5-4.4L343,400.5Z"
            transform="translate(-325 -295)" />
        <path id="gills"
            d="M423.38,474.73a.94.94,0,0,0-.05-.51,48.24,48.24,0,0,1,1.58-9.39c-9.77,20.22-6.43,37.62,10.21,52.3-.18-.31-.35-.7-.52-1-1.93-3.62-3.56-7.12-5.1-10.58,0-.08-.08-.19-.07-.27-4.55-11-6.59-21.18-6.05-30.53m22.32,35.72a2.73,2.73,0,0,0-.35-.63A69.56,69.56,0,0,1,438.56,474l.06-.62a69.89,69.89,0,0,1,1.43-9c0-.16.14-.35.16-.58-13.09,19.47-9.29,38.6,11.6,57.39-.54-.87-1.18-1.78-1.71-2.64-1.51-2.65-3-5.36-4.4-8.12m27.47,17.68c-.29-.28-.56-.64-.84-.92l.09.12-.18-.31a82.24,82.24,0,0,1-7.41-10c-7.77-12.74-11.71-28.07-11.62-45.89,0-3.85.21-7.88.59-12Q437,504.72,473.17,528.13Z"
            transform="translate(-325 -295)" fill="#070707" />
        <path id="spikes-shadow"
            d="M387.45,540.5a12,12,0,0,1-7.41-5.43l-17,10.82-5,3.8,1.83-.85,27.58-8.34M359,472a116.11,116.11,0,0,0-25.31,10.35l27.82-.69A23.91,23.91,0,0,0,359,472m79-35.69L417.3,449.1l23.28-1.7A17.24,17.24,0,0,0,438,436.34m-67.87-31c-4.67-.58-12.88-1.84-24.71-3.89l22,10.23a14.2,14.2,0,0,0,2.73-6.34m38.35-55.1c-8.6-7.06-17.21-11.86-25.71-14.37L403.93,355a10.43,10.43,0,0,0,4.55-4.78m72.61-21.88a12.09,12.09,0,0,0,9.43-3.13,232.9,232.9,0,0,0-15.62-18ZM561.21,331a4.64,4.64,0,0,0,4.75,2c3.68-13.26,5.91-21.91,6.8-26C568.5,313.06,564.58,321.08,561.21,331Z"
            transform="translate(-325 -295)" fill="#fff" fill-opacit="0.25099998712539673" />
    </svg>
);