import React from 'react';
export default (dynamicId, props) => (
    <svg
        id="top"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsxlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 200.1 133.5"
        {...props}
    >

        <defs>
            <linearGradient id={dynamicId} x1="539.03" y1="674.29" x2="461.73" y2="732.32"
                gradientTransform="translate(0 -200)" gradientUnits="userSpaceOnUse">
                <stop class="startTopColor" offset="0" />
                <stop class="stopTopColor" offset="1" />
            </linearGradient>
        </defs>
        <title>top</title>
        <path id="top-inner"
            d="M410.5,510.4,412,565l45-19.1c-10.5-16.1-25.9-27.9-46.5-35.5m113.3,29.7,75.8,6.3q-29.7-61-68.9-84.3l-6.9,78M458,545.7l65.8-5.6Q486.15,483,420.5,445.7,458.3,512,458,545.7Z"
            transform="translate(-399.5 -433.4)" fill={`url(#${dynamicId})`} />
        <path id="top-outline"
            d="M566.1,475a157,157,0,0,0-48.2-32.7l-3.8,73.9q-29.25-47.7-107.4-82.8Q431,472.1,445,521.5v.1q-15.6-16.8-45.5-24.1l7.6,69.4,4.9-2h0l-1.5-54.6c20.6,7.6,36,19.4,46.5,35.4l1-.1q.3-33.75-37.5-100,65.55,37.2,103.3,94.4l6.9-78q39.15,23.25,68.9,84.3c0-.5-.1-1-.1-1.5a9.15,9.15,0,0,0-.1-1.6Q594.75,503.45,566.1,475Z"
            transform="translate(-399.5 -433.4)" />
    </svg>
);

