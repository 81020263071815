import React from 'react';

export default (dynamicId, props) => (
    <svg
        id="eyes"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 208.58 148.22"
        {...props}
    >
        <title>eyes</title>
        <g id="eye-right">
            <g id="eye-right-outline">
                <g id="Layer20_0_FILL" data-name="Layer20 0 FILL">
                    <path
                        d="M550.2,474.6a27,27,0,0,0-1.6,4.8,58,58,0,0,0-.4,21.3c2.3,15.5,7.9,28.6,16.8,39.2q1.65,2,3.3,3.6a27.5,27.5,0,0,0,10.5,6.7c7.6,2.1,13.8-.2,18.6-6.7.5-.6.9-1.3,1.4-2a31.68,31.68,0,0,0,2.1-4.1q4.05-9.6,2.7-23.4a73.37,73.37,0,0,0-2.7-13.5,69.72,69.72,0,0,0-7.8-17.1,45.07,45.07,0,0,0-7.2-8.7l-.3-.3c-9.4-8.2-18.6-10.7-27.4-7.7-3.6,1.1-6.2,3.7-8,7.9m5.8,3.5a9.5,9.5,0,0,1,1-1.7,5.67,5.67,0,0,1,2-1.8.77.77,0,0,1,.3-.2,18.59,18.59,0,0,1,14.8.2c4,1.6,8,4.5,12.2,8.6a45.94,45.94,0,0,1,10.1,17.1,56,56,0,0,1,2.6,11.4,60.47,60.47,0,0,1,.5,8.3,14.77,14.77,0,0,1-.1,2.1,35.49,35.49,0,0,1-.6,4.7h0l-.3,1.2a25.83,25.83,0,0,1-3.8,8.8c-.3.5-.7.9-1,1.4a14.89,14.89,0,0,1-7,5.3,12,12,0,0,1-7.5,0,27.07,27.07,0,0,1-5.1-2.2,20.5,20.5,0,0,1-4.5-3.4c-.2-.2-.5-.4-.6-.7l-1.9-2.1V535c-.1-.1-.1-.1-.1-.2l-.5-.7-.6-.6h.1c-6.7-8.8-11-19.4-12.8-31.9C551.7,492,552.7,484.1,556,478.1Z"
                        transform="translate(-395.36 -424.75)" fill="#030303" />
                    <path
                        d="M599.4,522.1c0-.7.1-1.4.1-2.1a19.45,19.45,0,0,1-.5,2c-.8,3.4-.9,5-.2,4.8v-.1a34,34,0,0,0,.6-4.6m-31.6,12.4c-.6-.3-1.2-.7-1.8-1h-.1l.6.6.5.7c0,.1.1.1.1.2Z"
                        transform="translate(-395.36 -424.75)" fill="#dfdfdf" />
                    <path
                        d="M569,537.2c.2.2.4.5.6.7a17.79,17.79,0,0,0,4.5,3.4,22.47,22.47,0,0,0,5.1,2.2,12,12,0,0,0,7.5,0,14.43,14.43,0,0,0,7-5.3,12.13,12.13,0,0,0,1-1.4,27.94,27.94,0,0,0,3.8-8.8l.3-1.2c0,.1-.1.2-.1.3a22.78,22.78,0,0,1-4.5,6.3,16.28,16.28,0,0,1-12,4.7,36.44,36.44,0,0,1-14.5-3.5l-.7.5v.1Z"
                        transform="translate(-395.36 -424.75)" fill="#b7cae8" />
                    <path
                        d="M566,533.5a16.18,16.18,0,0,1,1.8,1,34.82,34.82,0,0,0,14.5,3.5c5,0,9-1.6,12-4.7a22.78,22.78,0,0,0,4.5-6.3c0-.1.1-.1.1-.3h0v.1c-.7.2-.6-1.4.2-4.8.2-.7.3-1.4.5-2a60.47,60.47,0,0,0-.5-8.3,56,56,0,0,0-2.6-11.4q-15.45-29.4-40.3-22.3c-3.3,6-4.3,13.9-2.9,23.5C555,514.1,559.3,524.7,566,533.5Z"
                        transform="translate(-395.36 -424.75)" fill="#fff" />
                    <path
                        d="M557,476.4a9.5,9.5,0,0,0-1,1.7c16.6-4.7,30.1,2.7,40.3,22.3a45.16,45.16,0,0,0-10.1-17.1,37.56,37.56,0,0,0-12.2-8.6,19,19,0,0,0-14.8-.2c-.1,0-.2.1-.3.2A5,5,0,0,0,557,476.4Z"
                        transform="translate(-395.36 -424.75)" fill="#dadada" />
                </g>
            </g>
            <g id="eye-right-pupil">

                <path d="M594.2,514.1H594a1.42,1.42,0,0,1,.2.6A.64.64,0,0,0,594.2,514.1Z"
                    transform="translate(-395.36 -424.75)" fill="#79c795" />

                <path d="M554.1,507.1l.3-.1-.3.1Z" transform="translate(-395.36 -424.75)" fill="teal" />


                <path d="M554.4,507h0Z" transform="translate(-395.36 -424.75)" fill="#303" />
                <path d="M593.7,520.6v-.3l-.6.1v.1c.1.7.2,2,.5,3.7.1-1,.1-2,.1-3.1a1.42,1.42,0,0,0-.2-.6Z"
                    transform="translate(-395.36 -424.75)" fill="#2d0001" />

                <path d="M592,516.7a24,24,0,0,1,1,3.8l.6-.1a15.88,15.88,0,0,0-.7-3.9Z"
                    transform="translate(-395.36 -424.75)" fill="#a20" />

                <path id="eye-right-lower-color"
                    class="pupilLower"
                    d="M575.3,521.3v-.2l-.7-.1a18,18,0,0,0-.8,3.9,33.27,33.27,0,0,0-.2,3.4,14.37,14.37,0,0,0,1.8,2.5c2.4,2.8,5.1,4.3,8.2,4.3a8.58,8.58,0,0,0,7.5-4.3,15.61,15.61,0,0,0,2.5-6.4,27.25,27.25,0,0,1-.5-3.7v-.1a27.18,27.18,0,0,0-1-3.8c-.6-1.8-2.3-3.1-5-4a31.16,31.16,0,0,1,2.3,6,11.89,11.89,0,0,1,.4,4.1,9.28,9.28,0,0,1-2.2,4.5,6.13,6.13,0,0,1-5.4,2.5c-2.2-.1-4.1-1.8-5.8-5.1A17.93,17.93,0,0,1,575.3,521.3Z"
                    transform="translate(-395.36 -424.75)" />

                <path d="M575.3,521.1a5.64,5.64,0,0,1,.1-2.2c-.3.7-.6,1.5-.8,2.1Z" transform="translate(-395.36 -424.75)"
                    fill="#f30" />

                <path id="eye-right-upper-color"
                    class="pupilUpper"
                    d="M578.1,506.8a10.42,10.42,0,0,0-4.1,3.5,15.93,15.93,0,0,0-2.6,10.3,22.08,22.08,0,0,0,.8,4.6c.2.4.3.9.5,1.3v.1a7.75,7.75,0,0,0,.8,1.6,33.27,33.27,0,0,1,.2-3.4,20.83,20.83,0,0,1,.8-3.9,18,18,0,0,1,.8-2.1,5.64,5.64,0,0,0-.1,2.2v.2a12,12,0,0,0,1.1,3.5c1.6,3.3,3.5,5,5.8,5.1a5.91,5.91,0,0,0,5.4-2.5,10.29,10.29,0,0,0,2.2-4.5,11.68,11.68,0,0,0-.4-4.1,54.23,54.23,0,0,0-2.3-6c2.7.8,4.4,2.2,5,4l.9-.3a15.77,15.77,0,0,0-3.3-6.1,12.45,12.45,0,0,0-4.3-3.4,8.6,8.6,0,0,0-3.9-.9,17.67,17.67,0,0,0-3.3.8m.4,8.6a3.89,3.89,0,0,1,.4-3.2c.2-.3.4-.5.6-.8a2.56,2.56,0,0,1,1.9-.8,3.28,3.28,0,0,1,2.7,1.1,2.65,2.65,0,0,1,.5.6,6.25,6.25,0,0,1,1,2.2,4.62,4.62,0,0,1,.7.7,8.28,8.28,0,0,1,2,5.1,7.38,7.38,0,0,1-1.3,5,4.43,4.43,0,0,1-3.8,2.1,5.21,5.21,0,0,1-4.1-2.1,7.91,7.91,0,0,1-1.9-5A7.25,7.25,0,0,1,578.5,515.4Z"
                    transform="translate(-395.36 -424.75)" />

                <path
                    d="M578.5,515.4h0a7.51,7.51,0,0,0-1.3,5.1,8.27,8.27,0,0,0,1.9,5,5.21,5.21,0,0,0,4.1,2.1,4.55,4.55,0,0,0,3.8-2.1,7.53,7.53,0,0,0,1.3-5,8,8,0,0,0-2-5.1,2.34,2.34,0,0,0-.7-.7.76.76,0,0,0,.1.4,4.44,4.44,0,0,1-.7,3.4,2.65,2.65,0,0,1-2.3,1.4,3,3,0,0,1-2.6-1.2A7.26,7.26,0,0,1,578.5,515.4Z"
                    transform="translate(-395.36 -424.75)" />

                <path
                    d="M579.5,511.4c.2.2.3.4.5.6a3.84,3.84,0,0,0,2.8,1.3,2.14,2.14,0,0,0,1.8-.9c-.2-.2-.3-.4-.5-.6a3.28,3.28,0,0,0-2.7-1.1,2.28,2.28,0,0,0-1.9.7m-.9-6.1a3,3,0,0,0-.5,1.5,9.92,9.92,0,0,1,3.5-.7,8.6,8.6,0,0,1,3.9.9,7.43,7.43,0,0,0-1.3-2.1,3.35,3.35,0,0,0-2.9-1.2A3.24,3.24,0,0,0,578.6,505.3Z"
                    transform="translate(-395.36 -424.75)" fill="#fff" />
            </g>

        </g>
        <g id="eye-left">
            <g id="eye-left-outline">
                <path
                    d="M395.4,505.1c.5,10.9,2.7,20.3,6.6,28.3,4,8.3,10.2,16.1,18.8,23.5a68.33,68.33,0,0,0,29.1,14.8q28.2,5.1,47.8-10.3t19.2-42c-.3-17.7-4.7-30.9-13.4-39.6s-15.9-14.4-21.4-16.8-13.2-4.3-23.1-5.7c-8.9-1.2-19-.8-30.3,1.3-11.6,2-20.2,7.8-26,17.3a51.93,51.93,0,0,0-7.3,29.2m9.6,27.3c-3.7-7.6-5.7-16.5-6.2-26.7a47.44,47.44,0,0,1,6.9-27.6c5.4-8.9,13.5-14.4,24.4-16.3,10.7-1.9,20.2-2.3,28.6-1.2a71.4,71.4,0,0,1,27.3,9.8,53.62,53.62,0,0,1,14,11.9c10.3,12.2,15.2,25.6,14.7,40.1-.5,14.7-6.6,26.6-18.1,35.5q-19.8,15.3-46.3,10.5a64.13,64.13,0,0,1-27.5-13.9C414.7,547.6,408.7,540.2,405,532.4Z"
                    transform="translate(-395.36 -424.75)" fill-opacity="0.4000000059604645" />
                <path
                    d="M398.8,505.7c.5,10.2,2.5,19.1,6.2,26.7,3.8,7.8,9.7,15.2,17.8,22.2a64.13,64.13,0,0,0,27.5,13.9c17.7,3.2,33.2-.3,46.3-10.5,11.5-9,17.6-20.8,18.1-35.5.6-14.5-4.3-27.9-14.7-40.1a53.62,53.62,0,0,0-14-11.9,73.1,73.1,0,0,0-27.3-9.8c-8.4-1.1-17.9-.7-28.6,1.2-10.9,1.9-19,7.3-24.4,16.3a46.93,46.93,0,0,0-6.9,27.5m18.6-31.9c.1-.1.1-.1.3-.1h0q15.45-12.15,36.3-8.7a77.52,77.52,0,0,1,17.8,5.2A68.19,68.19,0,0,1,502.1,495a70.27,70.27,0,0,1,3.8,6.3,40.86,40.86,0,0,1,4.5,15.7h0c0,.7.1,1.4.1,2.1a50.34,50.34,0,0,1-1.5,13,38.79,38.79,0,0,1-4,9.9,39.48,39.48,0,0,1-16.9,16.7,47.56,47.56,0,0,1-23.2,5.5,58.36,58.36,0,0,1-43.5-21.3c-.2-.3-.4-.5-.7-.9s-.9-1-1.3-1.5a71,71,0,0,1-5-7.6,76.14,76.14,0,0,1-8-19.9v-.1a.1.1,0,0,0-.1-.1c-.5-2.1-.8-4.2-1.1-6.4-1.5-10.7.8-19.7,6.7-27A29.12,29.12,0,0,1,417.4,473.8Z"
                    transform="translate(-395.36 -424.75)" fill="#030303" />
                <path d="M414.3,532.8a90.33,90.33,0,0,0,5,7.6l1.6,1.3-6.6-8.9m3.4-59.2Z"
                    transform="translate(-395.36 -424.75)" fill="#fff" />
                <path
                    d="M415,526.5c-2.8-4.6-5.7-9.2-8.7-13.7l-.1-.1.1.1v.1a74.78,74.78,0,0,0,8,19.9l6.6,8.9-1.6-1.3c.4.5.9,1,1.3,1.5.2.3.5.6.7.9a56.84,56.84,0,0,0,19.9,15.6,58,58,0,0,0,23.6,5.7,45.45,45.45,0,0,0,23.2-5.5,39.48,39.48,0,0,0,16.9-16.7,52.63,52.63,0,0,0,4-9.9h-.1c-1.7,4.1-3.2,7-4.6,8.7a52.33,52.33,0,0,1-7.5,6.4c-3.7,2.6-9.5,5.1-17.7,7.6a58.35,58.35,0,0,1-24.3,2.3A39.73,39.73,0,0,1,432,546.9,95.1,95.1,0,0,1,415,526.5Z"
                    transform="translate(-395.36 -424.75)" fill="#b7cae8" />
                <path
                    d="M406.4,512.8c3,4.5,5.9,9.1,8.7,13.7a92.19,92.19,0,0,0,17,20.3,39.73,39.73,0,0,0,22.7,10.1,56.74,56.74,0,0,0,24.3-2.3c8.1-2.5,14-5,17.7-7.6a47.17,47.17,0,0,0,7.5-6.4c1.4-1.7,2.9-4.6,4.6-8.7h.1a46.33,46.33,0,0,0,1.5-13c0-.7-.1-1.4-.1-2.1h0q-28.65-65.25-98.5-37.6c-5.9,7.3-8.2,16.3-6.7,27,.3,2.2.6,4.3,1.1,6.4,0,.1,0,.1.1.2m45.1,21.9h1.3v.2C452.3,534.8,451.9,534.8,451.5,534.7Z"
                    transform="translate(-395.36 -424.75)" fill="#f4f4f4" />
                <path d="M452.8,534.7h-1.3c.4.1.8.1,1.3.2Z" transform="translate(-395.36 -424.75)" fill="#eab300" />
                <path
                    d="M417.7,473.7h0a.37.37,0,0,0-.3.1,36.49,36.49,0,0,0-5.6,5.5q69.9-27.75,98.5,37.6a44.71,44.71,0,0,0-4.5-15.7,52.72,52.72,0,0,0-3.8-6.3,68.86,68.86,0,0,0-30.3-24.8,82.84,82.84,0,0,0-17.8-5.2c-13.8-2.3-25.9.6-36.2,8.8Z"
                    transform="translate(-395.36 -424.75)" fill="#dadada" />
            </g>
            <g id="eye-left-pupil">

                <path d="M468.9,499.3l-.3.1.3.9Z" transform="translate(-395.36 -424.75)" fill="#79c795" />

                <path
                    id="eye-right-lower-color-2"
                    class="pupilLower"
                    data-name="eye-right-lower-color"
                    d="M455.6,509.9a15,15,0,0,1-11.3,3.8c-4.6-.2-8.2-2.8-10.9-7.9a15.64,15.64,0,0,1-1.8-5.4,8.37,8.37,0,0,1,.4-3.7,30.95,30.95,0,0,0-4.8,14.7,23.09,23.09,0,0,0,3.1,3.9,22.45,22.45,0,0,0,31.8,0,20.53,20.53,0,0,0,5.9-10.1,40.07,40.07,0,0,1-.7-6,21.79,21.79,0,0,0-1.5-5.8c-1-2.8-4.1-4.9-9.5-6.2a37.2,37.2,0,0,1,3.8,9.3,18.09,18.09,0,0,1,.3,6.5Q460.1,505.85,455.6,509.9Z"
                    transform="translate(-395.36 -424.75)" />

                <path
                    id="eye-right-upper-color-2"
                    class="pupilUpper"
                    data-name="eye-right-upper-color"
                    d="M446.4,476.8a22.53,22.53,0,0,0-22.5,22.5,23.62,23.62,0,0,0,1.1,7.2,18,18,0,0,0,.8,2.1c0,.1.1.1.1.2a11.39,11.39,0,0,0,1.4,2.6,27.15,27.15,0,0,1,.8-5.3,26.13,26.13,0,0,1,4-9.4,8.37,8.37,0,0,0-.4,3.7,15.64,15.64,0,0,0,1.8,5.4c2.7,5.1,6.3,7.7,10.9,7.9a15.13,15.13,0,0,0,11.3-3.8c2.9-2.7,4.6-5,5-7a14.49,14.49,0,0,0-.3-6.5,45.58,45.58,0,0,0-3.8-9.3c5.4,1.3,8.5,3.4,9.5,6.2a37.49,37.49,0,0,1,1.5,5.8,40.07,40.07,0,0,0,.7,6,26.73,26.73,0,0,0,.8-4.8l-.3-.9.3-.1a21.67,21.67,0,0,0-6.6-15.9,22.79,22.79,0,0,0-16.1-6.6m-7.5,14.3a10.73,10.73,0,0,1,7.9-3.3,11.31,11.31,0,0,1,8,19.3,10.79,10.79,0,0,1-8,3.3,10.57,10.57,0,0,1-7.9-3.3,10.79,10.79,0,0,1-3.3-8A11,11,0,0,1,438.9,491.1Z"
                    transform="translate(-395.36 -424.75)" />

                <path
                    d="M446.8,487.9a10.57,10.57,0,0,0-7.9,3.3,11.35,11.35,0,0,0,0,16,10.73,10.73,0,0,0,7.9,3.3,11,11,0,0,0,8-3.3,10.79,10.79,0,0,0,3.3-8,11,11,0,0,0-3.3-8A10.57,10.57,0,0,0,446.8,487.9Z"
                    transform="translate(-395.36 -424.75)" />

                <path id="Layer18_0_1_STROKES" data-name="Layer18 0 1 STROKES"
                    d="M427.3,511.3a9.65,9.65,0,0,1-1.4-2.6c0-.1-.1-.1-.1-.2a18,18,0,0,1-.8-2.1,23.62,23.62,0,0,1-1.1-7.2,22.5,22.5,0,0,1,45,0m0,1.2a20.08,20.08,0,0,1-.8,4.8,21,21,0,0,1-5.9,10.1,22.45,22.45,0,0,1-31.8,0,19,19,0,0,1-3.1-3.9"
                    transform="translate(-395.36 -424.75)"
                    fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4px" />

                <path id="eye-left-glow"
                    d="M453.4,490.6a7.09,7.09,0,0,0-2.6-5.1,7.4,7.4,0,0,0-5.4-1.8,6.94,6.94,0,0,0-5.2,2.4,5.67,5.67,0,0,0-1.4,5.1,8.63,8.63,0,0,0,2.9,4.9,7.07,7.07,0,0,0,5.3,2,6.6,6.6,0,0,0,4.8-2.4A6.69,6.69,0,0,0,453.4,490.6Z"
                    transform="translate(-395.36 -424.75)" fill="#fff" />
            </g>
        </g>
        <path id="eye-brows"
            d="M590.9,459.6c-7.7-15.1-15.3-23-23-23.7s-11.8,6.5-12.4,21.5C563,443.7,574.7,444.4,590.9,459.6Zm-110.3-8.5c-11.2-18.4-23.9-27.1-38.1-26.3s-30,12.6-47,35.4Q439.45,425.85,480.6,451.1Z"
            transform="translate(-395.36 -424.75)" />
    </svg>
);