import React from "react";
export default (dynamicId, props) => (
  <svg
    id="tail"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsxlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 184.6 229"
    {...props}
  >
    <defs>
      <linearGradient
        id={dynamicId}
        x1="493.4"
        y1="-238.54"
        x2="416.1"
        y2="-180.52"
        gradientTransform="translate(0 704)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" class="startTailColor" />
        <stop offset="1" class="stopTailColor" />
      </linearGradient>
    </defs>
    <title>tailt</title>
    <g className="tailAnimate">
      <path
        id="tail-outline"
        d="M444.4,430.6a238.32,238.32,0,0,1,9.3,36l-75.2,25.3,78,30.7c-.3.1-.7,2.2-1.2,6.5a168,168,0,0,1-5.6,25.3,181.58,181.58,0,0,1-9.5,23.8,136,136,0,0,1-9.1,16.3,156.5,156.5,0,0,1-13.8,18.3,263.89,263.89,0,0,0,28.3-3.9,176.18,176.18,0,0,0,36.2-11.1,159.11,159.11,0,0,0,21.3-11.1,8.51,8.51,0,0,0,1.4-.9,157.08,157.08,0,0,0,20.4-15.6,163.19,163.19,0,0,0,14.6-15.4c1.9-2.3,3.7-4.7,5.5-7.3,2.5-3.3,4.8-6.7,7.1-10.2a189.48,189.48,0,0,0,11-19.8l-.6-1.8a238.11,238.11,0,0,0-29.4-56.6,189.87,189.87,0,0,0-34.8-36.8,201,201,0,0,0-43.6-26.9,232.72,232.72,0,0,0-32.8-11.6,219,219,0,0,1,22.5,46.8m9.2,3.3a258.7,258.7,0,0,0-19.7-40.7C446,396.8,459,403,473.1,412A172.14,172.14,0,0,1,509,442.9c10,11.3,17.9,21.8,23.6,31.3a227.52,227.52,0,0,1,18.9,40.1l.5,1.9a135.34,135.34,0,0,1-13.9,22.7c-1.2,1.7-2.4,3.4-3.8,5.1-3.2,4.2-6.6,8.2-10,12a156.55,156.55,0,0,1-18.5,16.8c-.1.1-.2.3-.3.4a164.56,164.56,0,0,1-21.2,13.9,136.54,136.54,0,0,1-23.4,10.4,197.68,197.68,0,0,1-21.3,5.9c-3.1.7-6.1,1.4-9,2,1.5-2,2.9-4,4.3-6,.8-1.1,1.5-2.3,2.3-3.5h.1a155.63,155.63,0,0,0,9.6-17.5,186.46,186.46,0,0,0,10.1-26,179,179,0,0,0,7.1-34.5,9.86,9.86,0,0,1,.3-2.4c.2-2.6.1-3.9-.4-4l-60-19.2,60-17c-.5-3.8-1.1-7.6-1.8-11.5A191.34,191.34,0,0,0,453.6,433.9Z"
        transform="translate(-378.5 -383.8)"
      />
      <path
        id="tail-inner"
        d="M433.9,393.3A258.7,258.7,0,0,1,453.6,434a193.34,193.34,0,0,1,8.6,29.8c.7,3.8,1.3,7.6,1.8,11.5l-60,17,60.1,19.1c.4.1.6,1.4.4,4a9.86,9.86,0,0,0-.3,2.4,171.76,171.76,0,0,1-7.1,34.5,186.46,186.46,0,0,1-10.1,26,173.39,173.39,0,0,1-9.6,17.5h-.1c-.8,1.2-1.5,2.4-2.3,3.5-1.4,2-2.8,4-4.3,6,2.9-.6,5.9-1.3,9-2a177,177,0,0,0,21.3-5.9A133.18,133.18,0,0,0,484.4,587a164.56,164.56,0,0,0,21.2-13.9c.1-.1.1-.2.3-.4a140.94,140.94,0,0,0,18.5-16.8c3.5-3.8,6.8-7.8,10-12,1.3-1.7,2.6-3.4,3.8-5.1a135.34,135.34,0,0,0,13.9-22.7l-.5-1.9a227.52,227.52,0,0,0-18.9-40.1c-5.7-9.5-13.6-19.9-23.6-31.3a172.14,172.14,0,0,0-35.9-30.9C459.1,403.1,446,396.8,433.9,393.3Z"
        transform="translate(-378.5 -383.8)"
        fill={`url(#${dynamicId})`}
      />
      <path
        id="tail-shadow"
        d="M483.68,419.79l-28.12,18.35c1.58,4.38,3,8.78,4.18,13.13l36.63-21.1C492.36,426.56,488.12,423.16,483.68,419.79Zm39.17,39.94q-5.16-6.8-11.35-14l-49.38,21.3,2.48,9.28-58.55,15.86,23.76,7.56Zm28.92,57-.55-1.78Q502.44,588.08,437,596.41l-.09,0,.07.12c-.81,1.13-1.55,2.38-2.37,3.51-1.37,2-2.85,4-4.31,6,3-.6,6-1.16,9-1.94a168.5,168.5,0,0,0,21.43-5.88,147.62,147.62,0,0,0,23.46-10.37,142.11,142.11,0,0,0,21-14c.13-.15.33-.17.43-.23A161.75,161.75,0,0,0,524,556.69c3.56-3.77,6.89-7.8,10-11.89,1.31-1.71,2.52-3.37,3.75-5.11A148.57,148.57,0,0,0,551.77,516.75Z"
        transform="translate(-378.5 -383.8)"
        fill="#fff"
        opacity="0.8"
        isolation="isolate"
      />
    </g>
  </svg>
);
