import React, { useState, useEffect } from "react";
import axios from "axios";
import { ROUTES } from "../../../Utils/routes";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  statsDiv: {
    textAlign: "center",
    display: "flex",
    // flexDirection: "column",
    justifyContent: "space-evenly",
    "@media only screen and (max-width:600px)": {
      flexWrap: "wrap",
      flexDirection: "column",
    },
    borderStyle: "solid",
    borderRightColor: theme.palette.primary.borderColorStats,
    borderLeftColor: theme.palette.primary.borderColorStats,
    borderBottomColor: theme.palette.primary.borderColorStats,
    borderTopColor: theme.palette.primary.borderColorStats,
  },
  middleBox1: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
    "@media only screen and (max-width:600px)": {
      flexWrap: "wrap",
      flexDirection: "column",
    },
    borderStyle: "solid",
    borderRightColor: "gray",
    borderLeftColor: "gray",
    borderBottomColor: theme.palette.primary.borderColorStats,
    borderTopColor: theme.palette.primary.borderColorStats,
  },
  middleBox: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
    "@media only screen and (max-width:600px)": {
      flexWrap: "wrap",
      flexDirection: "column",
    },
    borderStyle: "solid",
    borderRightColor: "gray",
    borderLeftColor: theme.palette.primary.borderColorStats,
    borderBottomColor: theme.palette.primary.borderColorStats,
    borderTopColor: theme.palette.primary.borderColorStats,
  },
  statsText: {
    margin: 0,
  },

  StatsNumber: {
    display: "flex",
    justifyContent: "center",
  },
  topStatsBar: {
    boxShadow: "none !important",
    color: theme.palette.text.secondary,
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    fontSize: "0.799rem",
    padding: "30px 0px",
    "@media only screen and (max-width:620px)": {
      display: "flex",
      flexWrap: "wrap",
      padding: "40px 10px",
    },
  },
}));

const StatsHeader = () => {
  const [stats, setStats] = useState({});
  const classes = useStyles();

  const getStats = async () => {
    let response = await axios.get(ROUTES.GET_STATS);
    setStats(response.data);
  };
  useEffect(() => {
    getStats();
  }, []);
  return (
    <div>
      {/* {Object.keys(stats).length !== 0 ? ( */}
      <Grid className={classes.topStatsBar} container item xs={12}>
        <Grid item xs={3} justify="center">
          <Tooltip title="Total paid born tipplys in the game">
            <div className={classes.statsDiv}>
              <span className={classes.statsText}>P-Born Tipplys</span>
              <span
                style={{ fontWeight: "bold" }}
                className={classes.statsNumber}
              >
                {stats.paidBorn}
              </span>
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Total minted tipplys in the game">
            <div className={classes.middleBox1}>
              <span className={classes.statsText}>Minted Tipplys</span>
              <span
                style={{ fontWeight: "bold" }}
                className={classes.statsNumber}
              >
                {stats.minted}
              </span>
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Total flushed tipplys in the game">
            <div className={classes.middleBox}>
              <span className={classes.statsText}>Flushed Tipply</span>
              <span
                style={{ fontWeight: "bold" }}
                className={classes.statsNumber}
              >
                {stats.flushed}
              </span>
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Total free born  tipplys">
            <div className={classes.statsDiv}>
              <span className={classes.statsText}>F-Born Tipplys</span>
              <span
                style={{ fontWeight: "bold" }}
                className={classes.statsNumber}
              >
                {stats.freeBorn}
              </span>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
      {/* ) : (
        <Grid className={classes.topStatsBar} container item xs={12}>
          <Grid item xs={3} justify="center">
            <Tooltip title="Total born tipplys in the game">
              <div className={classes.statsDiv}>
                <span className={classes.statsText}>P-Born Tipplys</span>
                <span
                  style={{ fontWeight: "bold" }}
                  className={classes.statsText}
                >
                  0
                </span>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Total minted tipplys in the game">
              <div className={classes.middleBox1}>
                <span className={classes.statsText}>Minted Tipplys</span>
                <span
                  style={{ fontWeight: "bold" }}
                  className={classes.statsText}
                >
                  0
                </span>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Total flushed tipplys in the game">
              <div className={classes.middleBox}>
                <span className={classes.statsText}>Flushed Tipplys</span>
                <span
                  style={{ fontWeight: "bold" }}
                  className={classes.statsText}
                >
                  0
                </span>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Current dead tipplys of the game">
              <div className={classes.statsDiv}>
                <span className={classes.statsText}>F-Born Tippplys</span>
                <span
                  style={{ fontWeight: "bold" }}
                  className={classes.statsText}
                >
                  0
                </span>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      )} */}
    </div>
  );
};
export default StatsHeader;
