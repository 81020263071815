import TestBodySVG from "../../../Assets/tipply_parts5/casual/body";

import styled, { keyframes } from "styled-components";
import React from "react";

import "../RandomTipply/RandomTipply.css";
import "../RandomTipply/CardTipply.css";
import "../RandomTipply/Loader.css";
import "../RandomTipply/Screenshot.css";
import "../RandomTipply/screenshot1000.css";
var colorEyes = {
    casual: [
        ["#003C77", "#0066CC"],
        ["#3C5909", "#55800D"],
        ["#AA2200", "#FF3300"],
        ["#6C204B", "#D33196"],
        ["#2D0001", "#ED292F"],
    ],
    nerdyweirdo: [
        ["#003C77", "#0066CC"],
        ["#3C5909", "#55800D"],
        ["#AA2200", "#FF3300"],
        ["#6C204B", "#D33196"],
        ["#2D0001", "#ED292F"],
    ],
    sporty: [
        ["#003C77", "#0066CC"],
        ["#3C5909", "#55800D"],
        ["#AA2200", "#FF3300"],
        ["#6C204B", "#D33196"],
        ["#2D0001", "#ED292F"],
    ],
    agroevil: [
        ["#003C77", "#0066CC"],
        ["#3C5909", "#55800D"],
        ["#AA2200", "#FF3300"],
        ["#6C204B", "#D33196"],
        ["#2D0001", "#ED292F"],
    ],
    cutysexy: [
        ["#003C77", "#0066CC"],
        ["#3C5909", "#55800D"],
        ["#AA2200", "#FF3300"],
        ["#6C204B", "#D33196"],
        ["#2D0001", "#ED292F"],
    ],

    //free
    free_casual: [
        ["#e18e62", "#753013"],
        ["#bec786", "#bec786"],
        ["#fa5442", "#671007"],
        ["#f4f4f4", "#666666"],
        ["#e6b267", "#ca8724"],
    ],
    free_nerdyweirdo: [
        ["#e18e62", "#753013"],
        ["#bec786", "#bec786"],
        ["#fa5442", "#671007"],
        ["#f4f4f4", "#666666"],
        ["#e6b267", "#ca8724"],
    ],
    free_sporty: [
        ["#e18e62", "#753013"],
        ["#bec786", "#bec786"],
        ["#fa5442", "#671007"],
        ["#f4f4f4", "#666666"],
        ["#e6b267", "#ca8724"],
    ],
    free_agroevil: [
        ["#e18e62", "#753013"],
        ["#bec786", "#bec786"],
        ["#fa5442", "#671007"],
        ["#f4f4f4", "#666666"],
        ["#e6b267", "#ca8724"],
    ],
    free_cutysexy: [
        ["#e18e62", "#753013"],
        ["#bec786", "#bec786"],
        ["#fa5442", "#671007"],
        ["#f4f4f4", "#666666"],
        ["#e6b267", "#ca8724"],
    ],
};


const Container = styled((props) => {
    return <props.svg {...props} />;
})`
  ${(props) => {
        return props.featureType === 0 && props.tipply_type === "paid"
            ? `.pupilUpper {
                fill: ${colorEyes.casual[props.colorType][0]};
            }
             .pupilLower {
                fill:  ${colorEyes.casual[props.colorType][1]};
    }`
            : props.featureType === 1 && props.tipply_type === "paid"
                ? ` .pupilUpper {
                    fill: ${colorEyes.nerdyweirdo[props.colorType][0]};
                  }
                     .pupilLower {
                    fill:  ${colorEyes.nerdyweirdo[props.colorType][1]};
         }`
                : props.featureType === 2 && props.tipply_type === "paid"
                    ? ` .pupilUpper {
                        fill: ${colorEyes.sporty[props.colorType][0]};
                      }
                         .pupilLower {
                        fill:  ${colorEyes.sporty[props.colorType][1]};
         }`
                    : props.featureType === 3 && props.tipply_type === "paid"
                        ? `.pupilUpper {
                        fill: ${colorEyes.agroevil[props.colorType][0]};
                      }
                         .pupilLower {
                        fill:  ${colorEyes.agroevil[props.colorType][1]};
         }`
                        : props.featureType === 4 && props.tipply_type === "paid"
                            ? `.pupilUpper {
                        fill: ${colorEyes.cutysexy[props.colorType][0]};
                      }
                         .pupilLower {
                        fill:  ${colorEyes.cutysexy[props.colorType][1]};
         }`
                            : props.featureType === 0 && props.tipply_type === "Free"
                                ? `.pupilUpper {
                                fill: ${colorEyes.free_casual[props.colorType][0]};
                            }
                             .pupilLower {
                                fill:  ${colorEyes.free_casual[props.colorType][1]};
                    }`
                                : props.featureType === 1 && props.tipply_type === "Free"
                                    ? ` .pupilUpper {
                                    fill: ${colorEyes.free_nerdyweirdo[props.colorType][0]};
                                  }
                                     .pupilLower {
                                    fill:  ${colorEyes.free_nerdyweirdo[props.colorType][1]};
                         }`
                                    : props.featureType === 2 && props.tipply_type === "Free"
                                        ? ` .pupilUpper {
                                        fill: ${colorEyes.free_sporty[props.colorType][0]};
                                      }
                                         .pupilLower {
                                        fill:  ${colorEyes.free_sporty[props.colorType][1]};
                         }`
                                        : props.featureType === 3 && props.tipply_type === "Free"
                                            ? `.pupilUpper {
                                        fill: ${colorEyes.free_agroevil[props.colorType][0]};
                                      }
                                         .pupilLower {
                                        fill:  ${colorEyes.free_agroevil[props.colorType][1]};
                         }`
                                            : props.featureType === 4 && props.tipply_type === "Free"
                                                ? `.pupilUpper {
                                        fill: ${colorEyes.free_cutysexy[props.colorType][0]};
                                      }
                                         .pupilLower {
                                        fill:  ${colorEyes.free_cutysexy[props.colorType][1]};
                         }`
                                                : "";
    }}
`;

export const TestEyes = ({ SVG, featureType, colorType, type, tipply_type, realSS }) => {
    return (
        <Container
            svg={SVG}
            featureType={featureType}
            colorType={colorType}
            tipply_type={tipply_type}
            className={
                type === "fishTank" && featureType === 0
                    ? "eyesC"
                    : type === "fishTank" && featureType === 1
                        ? "eyesN"
                        : type === "fishTank" && featureType === 2
                            ? "eyesS"
                            : type === "fishTank" && featureType === 3
                                ? "eyesA"
                                : type === "fishTank" && featureType === 4
                                    ? "eyesCS"
                                    : type === "card" && featureType === 0
                                        ? "eyesCardC"
                                        : type === "card" && featureType === 1
                                            ? "eyesCardN"
                                            : type === "card" && featureType === 2
                                                ? "eyesCardS"
                                                : type === "card" && featureType === 3
                                                    ? "eyesCardA"
                                                    : type === "card" && featureType === 4
                                                        ? "eyesCardCS"
                                                        : type === "loader" && featureType === 0
                                                            ? "eyesLoaderC"
                                                            : type === "loader" && featureType === 1
                                                                ? "eyesLoaderN"
                                                                : type === "loader" && featureType === 2
                                                                    ? "eyesLoaderS"
                                                                    : type === "loader" && featureType === 3
                                                                        ? "eyesLoaderA"
                                                                        : type === "loader" && featureType === 4
                                                                            ? "eyesLoaderCS"
                                                                            : featureType === 0 && !realSS
                                                                                ? "eyesScreenshotC"
                                                                                : featureType === 1 && !realSS
                                                                                    ? "eyesScreenshotN"
                                                                                    : featureType === 2 && !realSS
                                                                                        ? "eyesScreenshotS"
                                                                                        : featureType === 3 && !realSS
                                                                                            ? "eyesScreenshotA"
                                                                                            : featureType === 4 && !realSS
                                                                                                ? "eyesScreenshotCS"
                                                                                                : featureType === 0 && realSS
                                                                                                    ? "eyesScreenshot1000C"
                                                                                                    : featureType === 1 && realSS
                                                                                                        ? "eyesScreenshot1000N"
                                                                                                        : featureType === 2 && realSS
                                                                                                            ? "eyesScreenshot1000S"
                                                                                                            : featureType === 3 && realSS
                                                                                                                ? "eyesScreenshot1000A"
                                                                                                                : featureType === 4 && realSS
                                                                                                                    ? "eyesScreenshot1000CS"
                                                                                                                    : ""
            }
        />
    );
};
