import React from "react";
export default (dynamicId, props) => (
    <svg id="eyes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.5 127.75" {...props}>
        <title>eyes</title>
        <g id="eye-right">
            <g id="eye-right-outline">
                <path
                    d="M462.8,467.2c-.6,0-1.1-.1-1.7-.1q-34.65,3.75-53.7-4.7,49.5-23.7,96.2-.5c1.3-20.9-11-30.6-36.9-29.3q-29.1,1.5-65.7,25.9c-.1.1-.2.1-.3.2a34.78,34.78,0,0,1-9.4-8.9,42.5,42.5,0,0,0,11.4,26.7,78.29,78.29,0,0,1-29-28.7c3,17,6.7,30.5,11.2,40.3l-6.2.3c5.7,14.8,14.2,26,25.3,33.7l-10.9.3q46.2,53.4,83,31.4a73.44,73.44,0,0,1,49.3-8.9c.1-14.8-1.4-27.6-4.8-38.3a44.13,44.13,0,0,0-9.9-17.8C498,475,482,467.7,462.8,467.2m5.5,15.7c2.5.2,4.9.5,7.2.8a57.22,57.22,0,0,1,21.6,7.4l2.1,1.2a57.18,57.18,0,0,1,12,13.3c.4.7.9,1.4,1.3,2.2a65.53,65.53,0,0,1,5.9,13.2,102.17,102.17,0,0,1,4.3,20.9c-10.9-2.8-20.1-3.8-27.5-2.8a25.12,25.12,0,0,0-2.7.4c-.4.1-.8.2-1.1.3l-10,3.1A25.57,25.57,0,0,0,479,544l-1.3.3a47.12,47.12,0,0,1-26.9,5,64.77,64.77,0,0,1-17.3-4.4c-16.1-6.4-28.6-27-37.8-61.6a15.48,15.48,0,0,0,3.8,2.4q14.7,6.9,58.4-2.4a44.08,44.08,0,0,1,5.5-.6,17.1,17.1,0,0,1,3.2,0A9.85,9.85,0,0,0,468.3,482.9Z"
                    transform="translate(-373.7 -432.48)" />
                <path
                    d="M499.1,492.3l9.2,14.1c1,1.8,2,3.5,2.9,5.1a63.44,63.44,0,0,1,6.8,21.2c.4,2.8,1.9,5.8,4.5,9.1a103.1,103.1,0,0,0-4.3-21,65,65,0,0,0-5.9-13.2,23.92,23.92,0,0,0-1.3-2.1,56.09,56.09,0,0,0-11.9-13.2m-6.8,47.1a3.42,3.42,0,0,0-1.8-.4q-1.2,0-9.6,3.9c.1,0,.2-.1.4-.1l10-3.1c.2-.2.6-.2,1-.3m-67.6-7q-10.8-9.75-13.2-15.9c-1.6-4.1-3.4-8.4-5.4-13-1.8-4.1-3.1-7.2-3.8-9.2-.4-.9-2.5-4.5-6.4-11q13.65,51.9,37.8,61.6a64.11,64.11,0,0,0,17.2,4.4,47.12,47.12,0,0,0,26.9-5l1.1-.6c.1-.1.2-.1.4-.2-6.1,2.8-14.9,3.3-26.4,1.4S431.9,538.9,424.7,532.4Zm-25.1-46.7a13.89,13.89,0,0,1-3.7-2.4c3.9,6.4,6,10.1,6.4,11,.7,2,2,5.1,3.8,9.2,2,4.6,3.8,8.9,5.4,13s6,9.4,13.2,15.9,16.6,10.7,28,12.6,20.3,1.4,26.4-1.4c-.1.1-.2.1-.4.2a24.43,24.43,0,0,1,2.4-1c-.1,0-.2.1-.4.1q8.4-3.9,9.6-3.9a3,3,0,0,1,1.8.4,25.12,25.12,0,0,1,2.7-.4c7.5-.9,16.7,0,27.5,2.9-2.7-3.3-4.2-6.3-4.5-9.1a61.25,61.25,0,0,0-6.8-21.2c-.9-1.7-1.9-3.4-2.9-5.1l-9.2-14.1-2.1-1.2a57.22,57.22,0,0,0-21.6-7.4q-3.45-.6-7.2-.9c-.5,0-1.1-.1-1.6-.1h-3.3a31.78,31.78,0,0,0-5.5.7C428.8,489.6,409.4,490.3,399.6,485.7Z"
                    transform="translate(-373.7 -432.48)" fill="#dadada" />
            </g>
            <g id="eye-right-pupil">
                <path id="eye-right-upper-color"
                    class="pupilUpper"
                    d="M436.7,515.7a25,25,0,0,0,2.1,7.8,10,10,0,0,0,1,2.1c0,.1.1.1.1.2a12.28,12.28,0,0,0,1.8,2.6,40.66,40.66,0,0,1,.3-6,30.68,30.68,0,0,1,3.1-10.9,9.8,9.8,0,0,0,0,4.2,21,21,0,0,0,2.5,5.7c3.4,5.2,7.6,7.6,12.4,7.2a16.26,16.26,0,0,0,11.4-5.8c2.8-3.4,4.2-6.3,4.4-8.5a19.69,19.69,0,0,0-1-7.1,44.72,44.72,0,0,0-5.2-9.7c5.8.7,9.4,2.6,10.7,5.6a34.07,34.07,0,0,1,2.3,6.2,52.9,52.9,0,0,0,1.4,6.5,31.43,31.43,0,0,0,.3-5.4,3.55,3.55,0,0,1-.4-.9l.3-.2a24.22,24.22,0,0,0-8.8-16.7,21.65,21.65,0,0,0-17.4-5,23.48,23.48,0,0,0-16,9.4,24.51,24.51,0,0,0-5.3,18.7"
                    transform="translate(-373.7 -432.48)" fill="#003c77" />
                <path
                    d="M451.5,504.5a12.06,12.06,0,0,1,8-4.8,10.87,10.87,0,0,1,8.8,2.5,11.56,11.56,0,0,1,4.3,8.4,12.84,12.84,0,0,1-2.5,9.3,11.85,11.85,0,0,1-7.9,4.8,10.93,10.93,0,0,1-8.8-2.6,13,13,0,0,1-1.9-17.6Z"
                    transform="translate(-373.7 -432.48)" fill="#003c77" />
                <path
                    d="M459.5,499.8a11.34,11.34,0,0,0-8,4.8,12.71,12.71,0,0,0-2.5,9.3,12.41,12.41,0,0,0,4.4,8.4,11.3,11.3,0,0,0,8.8,2.6,11.63,11.63,0,0,0,7.9-4.8,12.71,12.71,0,0,0,2.5-9.3,12.32,12.32,0,0,0-4.3-8.4A10.59,10.59,0,0,0,459.5,499.8Z"
                    transform="translate(-373.7 -432.48)" />
                <path
                    d="M466.8,501.9a7.91,7.91,0,0,0-3.3-5.2,7.21,7.21,0,0,0-5.9-1.3,7.6,7.6,0,0,0-5.1,3.4,6.85,6.85,0,0,0-.9,5.9,9.23,9.23,0,0,0,3.5,5,7.5,7.5,0,0,0,5.8,1.4,6.77,6.77,0,0,0,4.8-3.2A8.3,8.3,0,0,0,466.8,501.9Z"
                    transform="translate(-373.7 -432.48)" fill="#fff" />
                <path id="eye-right-lower-color"
                    class="pupilLower"
                    d="M441.7,528.5a22.76,22.76,0,0,0,3.8,3.8,21.68,21.68,0,0,0,17.5,5.1,22.64,22.64,0,0,0,15.8-9.5,24.68,24.68,0,0,0,5.1-12,52.9,52.9,0,0,1-1.4-6.5,27.65,27.65,0,0,0-2.3-6.2c-1.3-3-4.9-4.9-10.7-5.6a49.59,49.59,0,0,1,5.2,9.7,20,20,0,0,1,1,7.1c-.1,2.2-1.6,5-4.4,8.5a16.26,16.26,0,0,1-11.4,5.8c-4.8.4-8.9-2-12.4-7.2a21,21,0,0,1-2.5-5.7,9.8,9.8,0,0,1,0-4.2,30.68,30.68,0,0,0-3.1,10.9C441.6,525.9,441.5,527.9,441.7,528.5Z"
                    transform="translate(-373.7 -432.48)" fill="#06c" />
                <path
                    d="M441.7,528.5a15.5,15.5,0,0,1-1.8-2.6c0-.1-.1-.1-.1-.2a10,10,0,0,1-1-2.1,25,25,0,0,1-2.1-7.8,26.14,26.14,0,0,1,5-18.6,23.12,23.12,0,0,1,16-9.4,21.65,21.65,0,0,1,17.4,5,23.84,23.84,0,0,1,8.8,16.7"
                    transform="translate(-373.7 -432.48)" fill="none" stroke="#000" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="4" />
                <path
                    d="M484.1,510.5a24.49,24.49,0,0,1-.3,5.4,25.5,25.5,0,0,1-5.1,12c-4.1,5.5-9.3,8.7-15.8,9.5a21.58,21.58,0,0,1-17.5-5.1,27.29,27.29,0,0,1-3.8-3.8"
                    transform="translate(-373.7 -432.48)" fill="none" stroke="#000" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="4" />
            </g>
        </g>
        <g id="eye-left">
            <g id="eye-left-outline">
                <path
                    d="M588.1,533.4a17.1,17.1,0,0,0-5.3,2.2h4.3q35.55-1.5,19.9-45.2a35.33,35.33,0,0,1,.9,5.1,34.27,34.27,0,0,0,.7,6.4,24.32,24.32,0,0,1,.5,5.3c0,.2-.1,1.7-.2,4.4a15.46,15.46,0,0,0,0,3.1,6.63,6.63,0,0,1-.8,3.1c-.4.8-1,2.2-2,4.1a15.56,15.56,0,0,1-5.2,6.4c-2.8,1.8-7,3.5-12.8,5.1m-7.4-47.8c-.3,0-.5.1-.8.1q-20.55,6.45-15.5,53.4h0a2.77,2.77,0,0,1,.1.9c1.8-2.4,7.1-3.8,16-4.2l1.8-.1a.9.9,0,0,0,.5-.1h0a16.07,16.07,0,0,1,5.3-2.2c5.8-1.6,10.1-3.4,12.7-5.2a17.14,17.14,0,0,0,5.2-6.4,45.12,45.12,0,0,0,2-4.1,6.63,6.63,0,0,0,.8-3.1,15.46,15.46,0,0,1,0-3.1c.1-2.8.2-4.2.2-4.4a34,34,0,0,0-.5-5.3,45.6,45.6,0,0,1-.7-6.4,35.33,35.33,0,0,0-.9-5.1c-.1-.2-.1-.4-.2-.5-1.4-3.8-3.1-7.9-5-12.1-2.4,3.8-9.4,6.4-21,7.9"
                    transform="translate(-373.7 -432.48)" fill="#dadada" />
                <path
                    d="M570,448.9c-6-1-10.7,1.3-13.9,6.9a25.79,25.79,0,0,0-3,18.5c7.9-12.8,19.5-14.6,35-5.3a18.44,18.44,0,0,1-2.3,2.5h-.2a28.54,28.54,0,0,1-10.7,6.5l-.1.1c-12.8,4.7-16.3,26.6-10.5,65.5,5.4-3.8,13.3-5.3,23.8-4.4,2.6.3,5.3.7,8.2,1.1a28,28,0,0,0,4,.3q19.2.3,24.9-37.4l-.5.5c0-.2.1-.3.1-.5a16.49,16.49,0,0,1-8.3,5.2q9.75-11.1,4.8-32.4c-.1.1-.1.2-.2.3V476c-1.3,2.9-4.1,4.6-8.4,4.9,3.9-8.6,2.6-19-4-31.3v.3c0-.1-.1-.2-.1-.3.1,11.3-3.4,19.1-10.5,23.3l-.2-1.4c4.1-7.2,3.4-13.6-2-19.1v.1l-.1-.1a44.26,44.26,0,0,1-3.7,10.1,49.32,49.32,0,0,0-5.6-5.6,33.09,33.09,0,0,0-16.5-8m9.9,36.8c.2,0,.5-.1.8-.1,11.7-1.5,18.7-4.1,21-7.9a125.17,125.17,0,0,1,5,12.1c.1.2.1.4.2.5q15.6,43.65-19.9,45.2h-4.3a1.09,1.09,0,0,0-.5.1l-1.8.1c-8.9.4-14.2,1.8-16,4.2h0c-.1-.9-.2-1.7-.3-2.5a10,10,0,0,1,.2,1.6h0Q559.35,492.2,579.9,485.7Z"
                    transform="translate(-373.7 -432.48)" />
            </g>
            <g id="eye-left-pupil">
                <path id="eye-left-upper-color"
                    class="pupilUpper"
                    d="M584.5,511.3a20.27,20.27,0,0,0-.4-5.4c-.7-2.1-1.6-4.5-2.8-7.2,3.1.5,5,1.8,5.6,4.1.8,2.2,1.3,3.8,1.5,4.6,0,.9.2,2.5.5,4.9a27.48,27.48,0,0,0,.2-4.1v-.8l.1-.1a22.3,22.3,0,0,0-4.8-12.5c-2.7-3.1-5.7-4.3-9.2-3.6s-6.4,3.1-8.6,7.3a26.87,26.87,0,0,0-2.7,14,17.59,17.59,0,0,0,1.2,5.8,7.49,7.49,0,0,0,.5,1.6c0,.1.1.1.1.2a11.39,11.39,0,0,1,.9,1.9,29,29,0,0,1,.2-4.5,27,27,0,0,1,1.8-8.3,12.64,12.64,0,0,0-.2,3.2,21.57,21.57,0,0,0,1.5,4.3c1.8,3.9,4,5.7,6.5,5.4a8,8,0,0,0,6-4.5,16.5,16.5,0,0,0,2.1-6.3"
                    transform="translate(-373.7 -432.48)" fill="#003c77" />
                <path
                    d="M575,497.2a4.85,4.85,0,0,0-2.7,2.6,6.34,6.34,0,0,0-.5,4.3v.1a5.93,5.93,0,0,0,1.8,3.7,3.4,3.4,0,0,0,3.1,1,3.75,3.75,0,0,0,2.4-2.5,7.11,7.11,0,0,0,.9-4.6v-.5a5.93,5.93,0,0,0-1.8-3.3A3.4,3.4,0,0,0,575,497.2Z"
                    transform="translate(-373.7 -432.48)" fill="#fff" />
                <path
                    d="M580.8,502.3a8.18,8.18,0,0,0-.8-.9v.5a7.24,7.24,0,0,1-.9,4.6,3.75,3.75,0,0,1-2.4,2.5,3.48,3.48,0,0,1-3.1-1,5.93,5.93,0,0,1-1.8-3.7v-.1h0a14,14,0,0,0-1.5,6.9,11.23,11.23,0,0,0,2.5,6.3,4.59,4.59,0,0,0,4.5,1.9,6.93,6.93,0,0,0,4.4-3.6,14.61,14.61,0,0,0,1.3-7,10.87,10.87,0,0,0-2.2-6.4"
                    transform="translate(-373.7 -432.48)" />
                <path id="eye-left-lower-color"
                    class="pupilLower"
                    d="M584.1,506a20.27,20.27,0,0,1,.4,5.4,15.09,15.09,0,0,1-2.3,6.4,8.19,8.19,0,0,1-6,4.5c-2.5.3-4.7-1.5-6.5-5.4a21.57,21.57,0,0,1-1.5-4.3,9.24,9.24,0,0,1,.2-3.2,34.54,34.54,0,0,0-1.8,8.3,29,29,0,0,0-.2,4.5,15.41,15.41,0,0,0,2.3,2.9c2.6,3.1,5.6,4.4,9,3.6s6.3-3.1,8.5-7.3a21.77,21.77,0,0,0,2.6-9,30.16,30.16,0,0,1-.5-4.9,44.38,44.38,0,0,0-1.5-4.6c-.6-2.2-2.5-3.6-5.6-4.1a60.14,60.14,0,0,1,2.9,7.2"
                    transform="translate(-373.7 -432.48)" fill="#06c" />
                <path
                    d="M566.5,522.1a15.41,15.41,0,0,0,2.3,2.9c2.6,3.1,5.6,4.4,9,3.6s6.3-3.1,8.5-7.3a21.77,21.77,0,0,0,2.6-9,27.48,27.48,0,0,0,.2-4.1"
                    transform="translate(-373.7 -432.48)" fill="none" stroke="#000" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="4" />
                <path
                    d="M589.1,507.4a22.3,22.3,0,0,0-4.8-12.5c-2.7-3.1-5.7-4.3-9.2-3.6s-6.4,3.1-8.6,7.3a26.87,26.87,0,0,0-2.7,14,17.59,17.59,0,0,0,1.2,5.8,7.49,7.49,0,0,0,.5,1.6c0,.1.1.1.1.2a11.39,11.39,0,0,1,.9,1.9"
                    transform="translate(-373.7 -432.48)" fill="none" stroke="#000" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="4" />
            </g>
        </g>
    </svg>
);
