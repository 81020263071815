import TestBodySVG from "../../../Assets/tipply_parts5/casual/body";

import styled, { keyframes } from "styled-components";
import React from "react";

import "../RandomTipply/RandomTipply.css";
import "../RandomTipply/CardTipply.css";
import "../RandomTipply/Loader.css";
import "../RandomTipply/Screenshot.css";
import "../RandomTipply/screenshot1000.css";

var colorTop = {
    casual: [
        ["#62AFFB", "#0066CC"],
        ["#9DEA1F", "#55800D"],
        ["#F82102", "#E2A900"],
        ["#FBBBD3", "#D22E91"],
        ["#E5262C", "#6D0003"],
    ],
    nerdyweirdo: [
        ["#62AFFB", "#0066CC"],
        ["#9DEA1F", "#55800D"],
        ["#F82102", "#E2A900"],
        ["#FBBBD3", "#D22E91"],
        ["#E5262C", "#6D0003"],
    ],
    sporty: [
        ["#62AFFB", "#0066CC"],
        ["#9DEA1F", "#55800D"],
        ["#F82102", "#E2A900"],
        ["#FBBBD3", "#D22E91"],
        ["#E5262C", "#6D0003"],
    ],
    agroevil: [
        ["#62AFFB", "#0066CC"],
        ["#9DEA1F", "#55800D"],
        ["#F82102", "#E2A900"],
        ["#FBBBD3", "#D22E91"],
        ["#E5262C", "#6D0003"],
    ],
    cutysexy: [
        ["#62AFFB", "#0066CC"],
        ["#9DEA1F", "#55800D"],
        ["#F82102", "#E2A900"],
        ["#FBBBD3", "#D22E91"],
        ["#E5262C", "#6D0003"],
    ],

    //free
    free_casual: [
        ["#e18e62", "#753013"],
        ["#bec786", "#bec786"],
        ["#fa5442", "#671007"],
        ["#f4f4f4", "#666666"],
        ["#e6b267", "#ca8724"],
    ],
    free_nerdyweirdo: [
        ["#e18e62", "#753013"],
        ["#bec786", "#bec786"],
        ["#fa5442", "#671007"],
        ["#f4f4f4", "#666666"],
        ["#e6b267", "#ca8724"],
    ],
    free_sporty: [
        ["#e18e62", "#753013"],
        ["#bec786", "#bec786"],
        ["#fa5442", "#671007"],
        ["#f4f4f4", "#666666"],
        ["#e6b267", "#ca8724"],
    ],
    free_agroevil: [
        ["#e18e62", "#753013"],
        ["#bec786", "#bec786"],
        ["#fa5442", "#671007"],
        ["#f4f4f4", "#666666"],
        ["#e6b267", "#ca8724"],
    ],
    free_cutysexy: [
        ["#e18e62", "#753013"],
        ["#bec786", "#bec786"],
        ["#fa5442", "#671007"],
        ["#f4f4f4", "#666666"],
        ["#e6b267", "#ca8724"],
    ],
};

const Container = styled((props) => {
    return <props.svg {...props} />;
})`
  ${(props) => {
        return props.featureType === 0 && props.tipply_type === "paid"
            ? `
            .startTopColor { 
                stop-color : ${colorTop.casual[props.colorType][0]};
               }
               .stopTopColor { 
                stop-color : ${colorTop.casual[props.colorType][1]};
    }`
            : props.featureType === 1 && props.tipply_type === "paid"
                ? `  .startTopColor { 
                    stop-color : ${colorTop.nerdyweirdo[props.colorType][0]};
                   }
                   .stopTopColor { 
                    stop-color : ${colorTop.nerdyweirdo[props.colorType][1]};
         }`
                : props.featureType === 2 && props.tipply_type === "paid"
                    ? `.startTopColor { 
                        stop-color : ${colorTop.sporty[props.colorType][0]};
                       }
                       .stopTopColor { 
                        stop-color : ${colorTop.sporty[props.colorType][1]};
         }`
                    : props.featureType === 3 && props.tipply_type === "paid"
                        ? `.startTopColor { 
                        stop-color : ${colorTop.agroevil[props.colorType][0]};
                       }
                       .stopTopColor { 
                        stop-color : ${colorTop.agroevil[props.colorType][1]};
         }`
                        : props.featureType === 4 && props.tipply_type === "paid"
                            ? `.startTopColor { 
                        stop-color : ${colorTop.cutysexy[props.colorType][0]};
                       }
                       .stopTopColor { 
                        stop-color : ${colorTop.cutysexy[props.colorType][1]};
         }`
                            : props.featureType === 0 && props.tipply_type === "Free"
                                ? `
                            .startTopColor { 
                                stop-color : ${colorTop.free_casual[props.colorType][0]};
                               }
                               .stopTopColor { 
                                stop-color : ${colorTop.free_casual[props.colorType][1]};
                    }`
                                : props.featureType === 1 && props.tipply_type === "Free"
                                    ? `  .startTopColor { 
                                    stop-color : ${colorTop.free_nerdyweirdo[props.colorType][0]};
                                   }
                                   .stopTopColor { 
                                    stop-color : ${colorTop.free_nerdyweirdo[props.colorType][1]};
                         }`
                                    : props.featureType === 2 && props.tipply_type === "Free"
                                        ? `.startTopColor { 
                                        stop-color : ${colorTop.free_sporty[props.colorType][0]};
                                       }
                                       .stopTopColor { 
                                        stop-color : ${colorTop.free_sporty[props.colorType][1]};
                         }`
                                        : props.featureType === 3 && props.tipply_type === "Free"
                                            ? `.startTopColor { 
                                        stop-color : ${colorTop.free_agroevil[props.colorType][0]};
                                       }
                                       .stopTopColor { 
                                        stop-color : ${colorTop.free_agroevil[props.colorType][1]};
                         }`
                                            : props.featureType === 4 && props.tipply_type === "Free"
                                                ? `.startTopColor { 
                                        stop-color : ${colorTop.free_cutysexy[props.colorType][0]};
                                       }
                                       .stopTopColor { 
                                        stop-color : ${colorTop.free_cutysexy[props.colorType][1]};
                         }`
                                                : "";
    }}
`;

export const TestTop = ({ SVG, featureType, colorType, type, tipply_type, realSS }) => {
    return (
        <Container
            svg={SVG}
            featureType={featureType}
            colorType={colorType}
            tipply_type={tipply_type}
            className={
                type === "fishTank" && featureType === 0
                    ? "topC"
                    : type === "fishTank" && featureType === 1
                        ? "topN"
                        : type === "fishTank" && featureType === 2
                            ? "topS"
                            : type === "fishTank" && featureType === 3
                                ? "topA"
                                : type === "fishTank" && featureType === 4
                                    ? "topCS"
                                    : type === "card" && featureType === 0
                                        ? "topCardC"
                                        : type === "card" && featureType === 1
                                            ? "topCardN"
                                            : type === "card" && featureType === 2
                                                ? "topCardS"
                                                : type === "card" && featureType === 3
                                                    ? "topCardA"
                                                    : type === "card" && featureType === 4
                                                        ? "topCardCS"
                                                        : type === "loader" && featureType === 0
                                                            ? "topLoaderC"
                                                            : type === "loader" && featureType === 1
                                                                ? "topLoaderN"
                                                                : type === "loader" && featureType === 2
                                                                    ? "topLoaderS"
                                                                    : type === "loader" && featureType === 3
                                                                        ? "topLoaderA"
                                                                        : type === "loader" && featureType === 4
                                                                            ? "topLoaderCS"
                                                                            : featureType === 0 && !realSS
                                                                                ? "topScreenshotC"
                                                                                : featureType === 1 && !realSS
                                                                                    ? "topScreenshotN"
                                                                                    : featureType === 2 && !realSS
                                                                                        ? "topScreenshotS"
                                                                                        : featureType === 3 && !realSS
                                                                                            ? "topScreenshotA"
                                                                                            : featureType === 4 && !realSS
                                                                                                ? "topScreenshotCS"
                                                                                                : featureType === 0 && realSS
                                                                                                    ? "topScreenshot1000C"
                                                                                                    : featureType === 1 && realSS
                                                                                                        ? "topScreenshot1000N"
                                                                                                        : featureType === 2 && realSS
                                                                                                            ? "topScreenshot1000S"
                                                                                                            : featureType === 3 && realSS
                                                                                                                ? "topScreenshot1000A"
                                                                                                                : featureType === 4 && realSS
                                                                                                                    ? "topScreenshot1000CS"
                                                                                                                    : ""
            }
        />
    );
};
