import React from 'react';
export default (dynamicId, props) => (
    <svg id="eyes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 342.74 178.97" {...props}>
        <title>eyes</title>
        <g id="body-band">
            <path
                d="M593.76,429h-.3q-144-29.7-307.2,37.5c-.6,1.2-1.1,2.5-1.6,3.8q174.3-64.35,311.8-33.4h0l.3.3c1.6-.4,2-1.7,1-3.8C596.56,431.2,595.26,429.7,593.76,429Z"
                transform="translate(-284.16 -377.33)" fill="#b4b4b4" />
            <path
                d="M575.26,404.3h0c-1.7-1.1-2.2-1.6-1.6-1.5a92.77,92.77,0,0,0-33.1-19.5q-103.65-20.85-222.9,38.8c-5.6,2.7-11.9,9.7-18.9,21.1,1.1-.5.7.1-1.1,1.8q139.2-68.85,277.6-40.7"
                transform="translate(-284.16 -377.33)" fill="#fff" />
            <path d="M575.16,404.2h0l.1.1c0-.1,0-.1-.1-.1" transform="translate(-284.16 -377.33)" fill="#fff" />
            <path
                d="M583.56,413.2c-.5-.7-1.1-1.3-1.6-2q-155.85-29-287.7,39.5c-2.6,4.7-5.2,9.9-8,15.8q163.2-67.2,307.2-37.5h.3A106.73,106.73,0,0,0,583.56,413.2Z"
                transform="translate(-284.16 -377.33)" fill="#fff" />
            <path id="band-inner"
                d="M580.76,409.9c-.49-.51-1-1-1.4-1.6-1.3-1.4-2.7-2.8-4.1-4.1q-138.3-28-277.7,40.8c-1.1,1.8-2.2,3.8-3.3,5.8Q426.11,382.1,582,411.3c-.4-.5-.8-.9-1.2-1.4Z"
                transform="translate(-284.16 -377.33)" fill="#09c" />
            <path id="band-outline"
                d="M580.76,410h0c.4.4.7.9,1.1,1.3.5.6,1.1,1.3,1.6,2a100.35,100.35,0,0,1,10.3,15.8,9.67,9.67,0,0,1,3.9,4.3c1,2.1.7,3.4-1,3.8l-.3-.3h0Q459,406,284.66,470.2c.5-1.3,1.1-2.5,1.6-3.8,2.8-5.8,5.4-11.1,8-15.8,1.1-2,2.2-4,3.3-5.8.4-.6.7-1.2,1.1-1.8,7-11.2,13.3-18.2,19-20.9q119.25-59.7,222.9-38.9a92.77,92.77,0,0,1,33.1,19.5c.5.5,1,.9,1.5,1.4"
                transform="translate(-284.16 -377.33)"
                fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <g id="body-sweat">
            <path
                d="M397.73,426.13a22.45,22.45,0,0,0-2.37,6.5c-.39,2.1.06,3.5,1.16,4.1s1.9.5,2.07-.7c-.9-.8-1.39-2.4-1.2-4.7.07-1.7.13-3.4.34-5.2m3.71,7.4c-.16-.8-.49-1.2-1-1.1s-.82.4-.66,1.2a1,1,0,0,0,1.06.8c.52-.05.7-.4.6-.9Zm-.47-10.4c-.56.9-.74,1.6-.35,2.3a20.86,20.86,0,0,1,2.66,10.3c0,3.4-1.75,4.3-5,2.7-4.64-2.3-5.66-5.7-3.15-10.3l3.24-5.4h-1.15l-3.13,3.9a12.65,12.65,0,0,0-2,4.7c-1.24,5.6,1.11,9.5,6.92,11.5a4,4,0,0,0,1.45.3c7.17-1,7.79-8,2-21-.42-.2-.92.25-1.49,1Zm-.4-7.2c.09-.8-1.67-3.8-5.16-8.9.89,4.8,2,10.5,3.49,17.2a70.89,70.89,0,0,0,1.67-8.3Z"
                transform="translate(-284.16 -377.33)" fill="#fff" fill-opacity="0.6000000238418579" />
            <path
                d="M380.08,500.89a57.87,57.87,0,0,0-3.42,9c-.83,2.92-.84,4.87-.16,5.7s1.3.69,1.79-1c-.53-1.11-.5-3.34.23-6.53.47-2.36,1-4.73,1.56-7.23m.93,10.28c.08-1.11-.06-1.66-.51-1.52s-.71.55-.79,1.66c0,.7.16,1.11.59,1.11s.66-.49.71-1.19Zm2.25-14.39a5.56,5.56,0,0,0-.84,3.2,48.38,48.38,0,0,1-.6,14.31c-.89,4.72-2.4,6-4.46,3.75-2.92-3.19-2.83-7.92.22-14.31l3.81-7.5h-.87l-3.35,5.42a32,32,0,0,0-2.68,6.53c-2.35,7.78-1.57,13.19,2.3,16a1.61,1.61,0,0,0,1,.42c5.66-1.39,7.9-11.11,6.79-29.17C384.39,495.06,383.92,495.61,383.26,496.72Zm1.52-10c.27-1.11-.3-5.28-1.64-12.36C382.6,481,382,489,381.42,498.25c1.95-6.25,3.1-10.14,3.38-11.53Z"
                transform="translate(-284.16 -377.33)" fill="#fff" fill-opacity="0.6000000238418579" />
            <path
                d="M414.6,475.32a75.87,75.87,0,0,1,2.44,9.37c.55,3,.44,4.82-.22,5.41s-1.19.28-1.46-1.42c.49-.77.59-2.88.16-6.23-.27-2.23-.54-4.6-.92-7.13m-1.47,9.29c0-1.07.16-1.56.54-1.3s.54.72.54,1.79c0,.67-.21,1-.54.89s-.54-.61-.54-1.38ZM412,470.47a5.66,5.66,0,0,1,.49,3.24,49.16,49.16,0,0,0-.38,13.65c.49,4.71,1.74,6.33,3.7,4.83,2.82-2.15,3-6.63.76-13.53l-2.88-8.17.76.25,2.61,6.07a40.94,40.94,0,0,1,2,6.93c1.58,8,.55,12.76-3,14.39a.74.74,0,0,1-.87,0q-7.34-4.41-4.08-29.43c0-.12.4.54.89,1.77Zm-.71-9.86c-.16-1.13.6-4.76,2.23-11.18.06,6.44.06,14.07,0,23-1.3-6.44-2.06-10.43-2.23-11.82Z"
                transform="translate(-284.16 -377.33)" fill="#fff" fill-opacity="0.6000000238418579" />
            <path
                d="M567.38,398.63a12.61,12.61,0,0,1,1.72,3c.39,1,.31,1.64-.19,2s-.84.23-1.07-.33c.42-.37.46-1.12.15-2.24a10.36,10.36,0,0,0-.61-2.39m-1,3.41c0-.37.11-.56.38-.56s.42.19.42.56-.15.38-.42.38a.35.35,0,0,1-.41-.27.38.38,0,0,1,0-.15Zm-.73-4.86c.34.42.5.75.38,1.08a9,9,0,0,0-.31,4.81c.35,1.59,1.23,2,2.61,1.26,2-1.07,2.15-2.66.5-4.76l-2-2.53h.43a16.73,16.73,0,0,1,1.84,1.83,6.07,6.07,0,0,1,1.42,2.15c1.11,2.61.38,4.44-2.18,5.42a1.65,1.65,0,0,1-.65.14c-3.45-.47-4.41-3.74-2.92-9.82.23-.18.49,0,.85.38Zm-.5-3.36c-.12-.37.42-1.78,1.57-4.16v8a36.38,36.38,0,0,1-1.6-3.88Z"
                transform="translate(-284.16 -377.33)" fill="#fff" fill-opacity="0.6000000238418579" />
            <path
                d="M554.38,403a15,15,0,0,1,2.41,4c.52,1.31.42,2.19-.21,2.63s-1.15.31-1.47-.44c.53-.56.63-1.57.21-3a23.78,23.78,0,0,0-.94-3.19M553,407.57c0-.5.16-.75.53-.75s.57.25.57.75c0,.31-.21.5-.57.5a.48.48,0,0,1-.53-.43Zm-1-6.51c.48.56.69,1,.53,1.44a11.61,11.61,0,0,0-.37,6.45c.47,2.13,1.68,2.69,3.57,1.69,2.67-1.44,2.88-3.57.68-6.39l-2.78-3.38h.73c.84.75,1.68,1.56,2.52,2.44a9.05,9.05,0,0,1,1.89,2.88c1.52,3.51.52,5.95-2.94,7.27a2.09,2.09,0,0,1-.89.19c-4.72-.63-6-5-4-13.16C551.12,400.3,551.49,400.56,552,401.06Zm-.68-4.45c-.16-.5.58-2.38,2.15-5.58.05,3,.05,6.58,0,10.78-1.26-2.88-2-4.64-2.17-5.2Z"
                transform="translate(-284.16 -377.33)" fill="#fff" fill-opacity="0.6000000238418579" />
            <path
                d="M360,420.93a12.42,12.42,0,0,0-1.4,3.92c-.22,1.29.06,2.14.76,2.57s1.17.31,1.26-.43a3.61,3.61,0,0,1-.79-2.93,28,28,0,0,1,.17-3.13m2.33,4.47c-.11-.49-.31-.73-.67-.73s-.44.24-.34.73a.57.57,0,0,0,.61.49c.31,0,.46-.18.4-.51ZM362,419c-.33.55-.44,1-.14,1.4a13,13,0,0,1,1.72,6.31q0,3.12-3,1.65c-2.91-1.41-3.56-3.49-2.06-6.24l1.92-3.37h-.7c-.62.8-1.25,1.59-1.87,2.45a6.92,6.92,0,0,0-1.19,2.82c-.71,3.42.77,5.81,4.35,7.1a2.49,2.49,0,0,0,.85.18q6.56-.91,1-12.85c-.24-.19-.53.06-.88.55Zm-.31-4.41c.05-.49-1-2.33-3.24-5.45.59,2.94,1.35,6.43,2.3,10.53a41.66,41.66,0,0,0,.94-5.08Z"
                transform="translate(-284.16 -377.33)" fill="#fff" fill-opacity="0.6000000238418579" />
        </g>
        <g id="eye-right">
            <g id="eye-right-outline">
                <path
                    d="M504.5,432.5c-6.1-2.8-14.9-5-26.3-6.6-10.2-1.3-22-.8-35.3,1.5-13.6,2.4-24.2,9-31.8,20a63.15,63.15,0,0,0-11.4,33.9c-.5,12.6,1.2,23.6,4.9,32.8a83.14,83.14,0,0,0,18.1,27.4q12.45,12.9,52.8,14.8c1.4-2,6.3-5.9,15-11.6s24.5-12.8,47.7-21.2c.4-.4,1-8.7,1.9-25s-3.2-30.7-12.5-43.2-16.9-20-23.1-22.8m-60.2-1.3c12.5-2.2,23.6-2.7,33.3-1.4a76.82,76.82,0,0,1,30.7,11.3c6,3.7,12.6,10.9,19.8,21.5s10.5,21.5,9.8,32.8-1.3,19.8-1.6,25.5q-42.75,12.3-61.5,31.9c-24.8-2.5-40.9-7.1-48.3-14-8.7-8.1-14.8-16.7-18.4-25.8-3.5-8.8-5.1-19.1-4.6-31a59,59,0,0,1,10.8-32C421.5,439.7,431.5,433.4,444.3,431.2Z"
                    transform="translate(-284.16 -377.33)" fill-opacity="0.4000000059604645" />
                <path
                    d="M477.7,429.8c-9.7-1.3-20.8-.8-33.3,1.4q-19.2,3.3-30,18.9a59.87,59.87,0,0,0-10.8,32c-.5,11.9,1.1,22.2,4.6,31,3.6,9.1,9.7,17.7,18.4,25.8q11.1,10.2,48.3,14,18.75-19.65,61.5-31.9c.3-5.7.9-14.2,1.6-25.5s-2.5-22.3-9.8-32.8-13.8-17.7-19.8-21.5a77.56,77.56,0,0,0-30.7-11.4M428.4,445a.37.37,0,0,1,.3-.1v-.1q19.2-14.1,43-10a85.72,85.72,0,0,1,20.2,6,70.82,70.82,0,0,1,32.8,28.8,55.61,55.61,0,0,1,3.7,7.3,46.51,46.51,0,0,1,3.8,18.3v.1a80.34,80.34,0,0,1,.2,8.1v14.5c-18.8,2.5-38.8,12.3-59.8,29.5a65.75,65.75,0,0,1-21.7-3.8c-8-2.7-14.2-6.4-18.5-11.3s-6.6-7.5-6.8-7.8a20.28,20.28,0,0,1-1.3-1.8,88.18,88.18,0,0,1-5.2-8.8,83.68,83.68,0,0,1-7.3-23.2h0v-.1c-.3-2.5-.6-5-.7-7.5q-.15-3.15,0-6a40.54,40.54,0,0,1,10.4-25.3A29.68,29.68,0,0,1,428.4,445Z"
                    transform="translate(-284.16 -377.33)" fill="#030303" />
                <path d="M424.1,522.3l1.6,1.5-6.8-10.3a65.54,65.54,0,0,0,5.2,8.8m4.6-77.5h0v0Z"
                    transform="translate(-284.16 -377.33)" fill="#fff" />
                <path
                    d="M410.9,476.7q-.15,3,0,6c.1,2.5.4,5,.7,7.5h0v.1h-.1a83.68,83.68,0,0,0,7.3,23.2l6.8,10.3-1.6-1.5c.4.6.9,1.2,1.3,1.8.2.4,2.5,3,6.8,7.8s10.5,8.6,18.5,11.3a65.75,65.75,0,0,0,21.7,3.8Q429.55,540.1,410.9,476.7Z"
                    transform="translate(-284.16 -377.33)" fill="#b7cae8" />
                <path
                    d="M421.3,451.4a40.54,40.54,0,0,0-10.4,25.3q18.45,63.3,61.5,70.4c21-17.2,41-27,59.8-29.5V503.1a80.34,80.34,0,0,0-.2-8.1h0q-26.7-75.9-110.7-43.6m42.2,64.4v.2c-.5-.1-1-.1-1.5-.2h1.5m30.4-29.2v.5a23.48,23.48,0,0,1,.1,2.6h-.3l.2-3.1Z"
                    transform="translate(-284.16 -377.33)" fill="#f4f4f4" />
                <path d="M493.9,487.1v-.5l-.2,3.1h.3a23.48,23.48,0,0,0-.1-2.6M463.4,516v-.2h-1.5A7.72,7.72,0,0,1,463.4,516Z"
                    transform="translate(-284.16 -377.33)" fill="#eab300" />
                <path d="M493.9,486.7v-.1h0Z" transform="translate(-284.16 -377.33)" fill="#63c" />
                <path
                    d="M428.8,444.9h0c-.2,0-.3,0-.3.1a48.86,48.86,0,0,0-7.1,6.4q83.85-32.25,110.8,43.6a47.25,47.25,0,0,0-3.8-18.3,55.61,55.61,0,0,0-3.7-7.3,70.4,70.4,0,0,0-32.8-28.8,83,83,0,0,0-20.2-6c-15.9-2.7-30.2.7-43,10Z"
                    transform="translate(-284.16 -377.33)" fill="#dadada" />
            </g>
            <g id="eye-right-pupil">
                <path id="eye-right-lower-color"
                    class="pupilLower"
                    d="M466.4,500.8a9.27,9.27,0,0,1,.4-4.2,28.76,28.76,0,0,0-4.4,10.4c-.7,3.4-1,5.3-.9,5.9a27.5,27.5,0,0,0,3.5,4.3,25,25,0,0,0,35.3,0,24.38,24.38,0,0,0,6.6-11.2,57.89,57.89,0,0,1-.8-6.7c0-1.2-.6-3.3-1.6-6.5s-4.6-5.4-10.5-6.9a47.48,47.48,0,0,1,4.3,10.3,17.77,17.77,0,0,1,.3,7.2c-.4,2.2-2.2,4.8-5.5,7.8s-7.4,4.4-12.5,4.3-9.1-3.1-12.1-8.8A14.35,14.35,0,0,1,466.4,500.8Z"
                    transform="translate(-284.16 -377.33)" fill="#06c" />
                <path id="eye-right-upper-color"
                    class="pupilUpper"
                    d="M482.7,474.6a24.92,24.92,0,0,0-25,25,23.55,23.55,0,0,0,1.3,8,20.94,20.94,0,0,0,.8,2.3c0,.1.1.2.2.3a16.06,16.06,0,0,0,1.5,2.8c-.1-.6.2-2.5.9-5.9a28.76,28.76,0,0,1,4.4-10.4,9.07,9.07,0,0,0-.4,4.2,19.29,19.29,0,0,0,2,6c3,5.7,7,8.6,12.1,8.8a16.78,16.78,0,0,0,12.5-4.3c3.3-3,5.1-5.6,5.5-7.8a17.77,17.77,0,0,0-.3-7.2,47.48,47.48,0,0,0-4.3-10.3q9,2.25,10.5,6.9a27.43,27.43,0,0,1,1.6,6.5,57.89,57.89,0,0,0,.8,6.7,30.79,30.79,0,0,0,.8-5.3c-.1-.3-.2-.7-.3-1l.3-.2a24.17,24.17,0,0,0-7.4-17.7,23.73,23.73,0,0,0-17.5-7.4"
                    transform="translate(-284.16 -377.33)" fill="#003c77" />

                <path
                    d="M470.7,499.4a11.64,11.64,0,0,1,3.7-8.8,12.17,12.17,0,0,1,8.8-3.7,11.64,11.64,0,0,1,8.8,3.7,12.17,12.17,0,0,1,3.7,8.8,11.64,11.64,0,0,1-3.7,8.8,12.58,12.58,0,0,1-8.8,3.7,11.64,11.64,0,0,1-8.8-3.7A11.79,11.79,0,0,1,470.7,499.4Z"
                    transform="translate(-284.16 -377.33)" fill="#003c77" />

                <path
                    d="M507.7,499.6l-.3.2c.1.3.2.7.3,1Z" transform="translate(-284.16 -377.33)" fill="#79c795" />

                <path
                    d="M461.5,513a11.21,11.21,0,0,1-1.5-2.8c-.1-.1-.1-.2-.2-.3a15.55,15.55,0,0,1-.8-2.3,29.76,29.76,0,0,1-1.3-8,25,25,0,0,1,50,0"
                    transform="translate(-284.16 -377.33)"
                    fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4px" />
                <path
                    d="M507.7,500.8a30.79,30.79,0,0,1-.8,5.3,24.38,24.38,0,0,1-6.6,11.2,23.86,23.86,0,0,1-17.6,7.3,24.47,24.47,0,0,1-17.7-7.3,27.5,27.5,0,0,1-3.5-4.3"
                    transform="translate(-284.16 -377.33)"
                    fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4px" />
                <path
                    d="M474.3,490.5a12.58,12.58,0,0,0-3.7,8.8,11.64,11.64,0,0,0,3.7,8.8,12.58,12.58,0,0,0,8.8,3.7,11.64,11.64,0,0,0,8.8-3.7,12.17,12.17,0,0,0,3.7-8.8,11.64,11.64,0,0,0-3.7-8.8,12.17,12.17,0,0,0-8.8-3.7A12.77,12.77,0,0,0,474.3,490.5Z"
                    transform="translate(-284.16 -377.33)" />
                <g id="Layer12_0_FILL" data-name="Layer12 0 FILL">
                    <path
                        d="M477.5,496.1a8.41,8.41,0,0,0,5.8,2.2,7.37,7.37,0,0,0,5.4-2.6,7.47,7.47,0,0,0,1.9-5.8,7.93,7.93,0,0,0-2.9-5.6,8.13,8.13,0,0,0-6-2,7.8,7.8,0,0,0-5.8,2.7,6.07,6.07,0,0,0-1.5,5.7A9.33,9.33,0,0,0,477.5,496.1Z"
                        transform="translate(-284.16 -377.33)" fill="#fff" />
                </g>
            </g>
        </g>
        <g id="eye-left">
            <g id="eye-left-outline">
                <path d="M583,530c0,.1.1.1.2.2h-.1l-.1-.2m-.5-.6c-.3-.3-.5-.7-.8-1h0c.2.3.4.5.6.8Z"
                    transform="translate(-284.16 -377.33)" fill="#dfdfdf" />
                <path
                    d="M584.2,522.2h0m-14.9-39.4c1.7,12,4.4,21.5,8.2,28.5,19.8.8,35.1,4.3,45.9,10.6a119.72,119.72,0,0,0,0-17c-.5-5.3-.8-8.5-.9-9.7a76.48,76.48,0,0,0-3-14.1c-11.9-24.2-27.6-33.4-46.9-27.5C568.8,461.1,567.7,470.9,569.3,482.8Z"
                    transform="translate(-284.16 -377.33)" fill="#f4f4f4" />
                <path
                    d="M575.2,439.4q-6.15,2.25-9.3,9.9a31.41,31.41,0,0,0-1.9,5.9,74.83,74.83,0,0,0-.4,26.4c2.2,16.4,6.9,28.7,13.9,36.9,20.9,0,36.5,2.9,46.6,8.6,1.5-5.5,2.4-14.9,2.8-28.2-.1-7.6-.8-13.5-2-17.5a88.68,88.68,0,0,0-9.1-21.2,53,53,0,0,0-8.3-10.8c-.2-.1-.3-.3-.4-.4-11-10.2-21.7-13.3-31.9-9.6m-2.5,14.3a21.16,21.16,0,0,1,1.1-2.1,7.45,7.45,0,0,1,2.4-2.3c0-.1.1-.2.3-.2a20.9,20.9,0,0,1,17.3.2c4.6,2,9.3,5.6,14.1,10.7a57,57,0,0,1,11.8,21.2,73.1,73.1,0,0,1,3,14.1c.1,1.2.4,4.4.9,9.7a119.63,119.63,0,0,1,0,17c-.1.9-.1,1.8-.2,2.7-10.5-6.5-25.3-10-44.3-10.7l-1.4-2.6c-3.8-6.9-6.5-16.4-8.2-28.5S568.8,461.1,572.7,453.7Z"
                    transform="translate(-284.16 -377.33)" fill="#030303" />
                <path d="M577.5,511.3l1.4,2.6c19,.7,33.8,4.2,44.3,10.7.1-.9.1-1.8.2-2.7C612.6,515.6,597.3,512,577.5,511.3Z"
                    transform="translate(-284.16 -377.33)" fill="#b7cae8" />
                <path
                    d="M573.8,451.6a21.16,21.16,0,0,0-1.1,2.1c19.3-5.8,35,3.3,46.9,27.5A59.32,59.32,0,0,0,607.8,460c-4.8-5.1-9.5-8.7-14.1-10.7a20.9,20.9,0,0,0-17.3-.2c-.1,0-.2.1-.3.2A5.65,5.65,0,0,0,573.8,451.6Z"
                    transform="translate(-284.16 -377.33)" fill="#dadada" />
            </g>
            <g id="eye-left-pupil">
                <path id="eye-left-lower-color"
                    class="pupilLower"
                    d="M600.8,483.5a36,36,0,0,1,2.8,7.2,17.72,17.72,0,0,1,.4,5c-.1,1.5-1.1,3.4-3,5.5a8.53,8.53,0,0,1-7.1,3c-3-.1-5.4-2.2-7.3-6.2a15,15,0,0,1-1.5-4.2,14,14,0,0,1,0-2.9,23.39,23.39,0,0,0-2.2,7.3,19,19,0,0,0-.3,4.1,16.55,16.55,0,0,0,2.2,3q4.5,5.1,10.5,5.1a12.46,12.46,0,0,0,10.1-5.1,17.29,17.29,0,0,0,3.5-7.8c-.4-2.3-.6-3.9-.7-4.7a16.52,16.52,0,0,0-1.2-4.5c-.6-2.2-2.7-3.8-6.2-4.8"
                    transform="translate(-284.16 -377.33)" fill="#06c" />
                <path id="eye-left-upper-color"
                    class="pupilUpper"
                    d="M604.3,480.5q-4.5-5.1-10.5-5.1a12.46,12.46,0,0,0-10.1,5.1,18.61,18.61,0,0,0-3.6,12.4,24.22,24.22,0,0,0,1,5.6,5.23,5.23,0,0,0,.6,1.6c0,.1.1.1.1.2a7.33,7.33,0,0,0,1,2,19,19,0,0,1,.3-4.1,21,21,0,0,1,2.2-7.3,7,7,0,0,0,0,2.9,15,15,0,0,0,1.5,4.2c1.9,4,4.3,6,7.3,6.2a8.53,8.53,0,0,0,7.1-3c1.8-2.1,2.8-3.9,3-5.5a17.19,17.19,0,0,0-.4-5,36,36,0,0,0-2.8-7.2q5.25,1.5,6.3,4.8a16.52,16.52,0,0,1,1.2,4.5c.1.8.3,2.4.7,4.7a23.4,23.4,0,0,0,.3-3.8,1.08,1.08,0,0,1-.3-.7l.2-.1a20.59,20.59,0,0,0-5.1-12.4"
                    transform="translate(-284.16 -377.33)" fill="#003c77" />
                <path
                    d="M597.1,482.2a4.76,4.76,0,0,0-3.8-1.4,3.62,3.62,0,0,0-3.1,1.9,5.15,5.15,0,0,0-.7,3.9v.1a7,7,0,0,0,2,3.8A4.18,4.18,0,0,0,595,492a4,4,0,0,0,3-1.8,5.46,5.46,0,0,0,.9-4v-.5A7.34,7.34,0,0,0,597.1,482.2Z"
                    transform="translate(-284.16 -377.33)" fill="#fff" />
                <path
                    d="M598.9,486.1a5.35,5.35,0,0,1-.9,4,4,4,0,0,1-3,1.8,4.18,4.18,0,0,1-3.5-1.5,6.71,6.71,0,0,1-2-3.8v-.1h-.1a9.3,9.3,0,0,0-1.8,6.2,9.74,9.74,0,0,0,2.4,6.2,6.82,6.82,0,0,0,5.3,2.6,6.44,6.44,0,0,0,5.1-2.6,8.34,8.34,0,0,0,1.8-6.2,9.84,9.84,0,0,0-2.5-6.2l-.9-.9a.75.75,0,0,0,.1.5"
                    transform="translate(-284.16 -377.33)" />
                <path
                    d="M582.7,502.3a12.22,12.22,0,0,1-1-2c-.1,0-.1-.1-.1-.2a5.23,5.23,0,0,1-.6-1.6,31,31,0,0,1-1-5.6,18.61,18.61,0,0,1,3.6-12.4,12.46,12.46,0,0,1,10.1-5.1q6,0,10.5,5.1a19.05,19.05,0,0,1,4.9,12.4"
                    transform="translate(-284.16 -377.33)"
                    fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4px" />
                <path
                    d="M609.3,493.7c-.1,1.3-.2,2.5-.3,3.8a17.29,17.29,0,0,1-3.5,7.8,12.46,12.46,0,0,1-10.1,5.1q-6,0-10.5-5.1a16.55,16.55,0,0,1-2.2-3"
                    transform="translate(-284.16 -377.33)"
                    fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4px" />
            </g>
        </g>
    </svg>
);