import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import {
  enableFeedAnimation,
  disableFeedAnimation,
  enableFeedSpeech,
  disableFeedSpeech,
} from "../../../redux/actions/animationActions";
import { getHistory } from "../../../redux/actions/authActions";
import snail from "../../../Assets/snail.png";
import worm from "../../../Assets/worm.png";

import axios from "axios";
import { ROUTES } from "../../../Utils/routes";
import { withRouter } from "react-router-dom";

import Snackbar from "../../Common/Snackbar/Snackbar";

import Tooltip from "@material-ui/core/Tooltip";

import SnailGIF from "../../../Assets/GIF/snail.gif";
import WormGIF from "../../../Assets/GIF/worm.gif";

import flakesGIF from "../../../Assets/freeFood/flakes.gif";
import pelletsGIF from "../../../Assets/freeFood/pellets.gif";

const useStyles = makeStyles((theme) => ({
  time: {
    // borderRight: "0.5px solid #B1B1B1",
    borderBottom: "0.5px solid #B1B1B1",
    margin: 0,
    padding: 4,
  },
  card: {
    margin: "5px",
    textAlign: "center",
    padding: "15px 0",
    backgroundColor: "inherit",
    border: "0.5px solid",
    borderColor: "#B1B1B1",
    minHeight: "240px",
  },
  cardHeading: {
    padding: 0,
    margin: 0,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  cardText: {
    color: theme.palette.text.secondary,
  },
  mediaHolder: {
    background: "radial-gradient(#00f,transparent);",
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    margin: 0,
  },
  media: {
    height: "100%",
    width: "100%",
    "@media only screen and (max-width:408px)": {
      width: "50% !important",
    },
  },
  cardButton: {
    // color: theme.palette.text.primary,
    textTransform: "none",
    color: "white !important",
    backgroundColor: theme.palette.button.primary,
    height: "27px",
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },

  loaderStyles: {
    color: theme.palette.text.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -8,
  },

  marketplaceText: {
    cursor: "pointer",
    color: "#FBBE3F",
  },
}));

const Food = (props) => {
  const [countValue, setCountValue] = useState(false);
  const classes = useStyles();

  const [feedTipplyLoader, setFeedTipplyLoader] = useState("");
  const [useDisable, setUseDisable] = useState(false);

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [foodData, setFoodData] = useState([]);
  const [foodCount, setFoodCount] = useState(false);

  const removeZeroCount = (foodDataParam) => {
    let arr = foodDataParam.filter((food) => food.count !== 0);
    return arr;
  };

  var activeTipply = {};

  if (props.tipplies.tipplies) {
    activeTipply = props.tipplies.tipplies.find((tipply) => {
      return tipply.isActive === true;
    });
  }
  const feedTipplyHandler = async (foodID, count, foodName, foodLimit) => {
    setUseDisable(true);
    if (activeTipply !== undefined) {
      if (activeTipply.type === "Free" && activeTipply.level >= 50) {
        setUseDisable(true);
        setSnackbarState(true);
        setSnackbarMessage(
          "Your free tipply in the tank has won!! No more feeding is required, you will receive one paid egg with 100 Snails & 400 Worms as a reward. Enjoy playing."
        );
        setUseDisable(false);
      } else {
        if (
          (activeTipply.type === "Free" &&
            foodName !== "Flakes" &&
            foodName !== "Pellets") ||
          (activeTipply.type === "paid" && foodName === "Pellets") ||
          (activeTipply.type === "paid" && foodName === "Flakes")
        ) {
          setSnackbarState(true);
          setUseDisable(true);
          setSnackbarMessage(
            "Refrain from feeding free food to paid tipplys and paid food to free tipplys."
          );
          setUseDisable(false);
          // console.log(useDisable, "use disable");
        } else {
          if (foodLimit === 0) {
            setFeedTipplyLoader(foodID);
            setUseDisable(true);

            if (activeTipply.energy >= 100) {
              setUseDisable(true);
              setSnackbarState(true);
              setSnackbarMessage(
                "Your Tipply's energy is full, please refrain from overfeeding."
              );
              setUseDisable(false);
              setFeedTipplyLoader("");
            } else {
              if (activeTipply.energy <= 0) {
                setSnackbarState(true);
                setSnackbarMessage(
                  "Cannot feed dead the tipply, to continue with the game you can flush the current tipply and activate another hatched one."
                );
                setFeedTipplyLoader("");
              } else {
                let data = {
                  userID: props.user._id,
                  tipplyID: activeTipply._id,
                  foodID: foodID,
                };
                if (foodName === "Snail") {
                  props.enableFeedAnimation(SnailGIF);
                }
                if (foodName === "Worm") {
                  props.enableFeedAnimation(WormGIF);
                }
                if (foodName === "Flakes") {
                  props.enableFeedAnimation(flakesGIF);
                }
                if (foodName === "Pellets") {
                  props.enableFeedAnimation(pelletsGIF);
                }
                let response;
                setTimeout(async function () {
                  props.disableFeedAnimation();
                  props.enableFeedSpeech();
                  response = await axios.post(ROUTES.FEED_TIPPLY, data);
                  //   if (response.data.response.status === 406) {
                  //       setSnackbarMessage(response.data.response.msg);
                  //       setSnackbarState(true);
                  //   }
                  //   if (response.data.response.status === 200) {
                  //     setSnackbarMessage(response.data.response.msg);
                  //     setSnackbarState(true);
                  //   }
                  // if (response.data.response.status === 400) {
                  setSnackbarMessage(response.data.response.msg);
                  setSnackbarState(true);
                  // }
                  props.getUserTipply(props.user.address);
                  await props.getUserFood(props.user.address);

                  setFoodData(props.Food);
                  setFoodCount(!foodCount);

                  setTimeout(function () {
                    props.disableFeedSpeech();
                    setUseDisable(false);
                    setUseDisable(false);
                  }, 300);
                }, 2900);
              }

              setFeedTipplyLoader("");
            }
          } else {
            setSnackbarMessage("Max per hour limit has been reached");
            setSnackbarState(true);
            setUseDisable(false);
          }
        }
      }
    } else {
      setSnackbarState(true);
      setSnackbarMessage("No tipply is currently in activated.");
      setUseDisable(false);
    }
  };

  useEffect(() => {
    if (props.Food) {
      setFoodData(removeZeroCount(props.Food));
    }
  }, [props.Food]);

  const feedTimeGenerator = (feedlimit, maxhour, maxtime) => {
    if (feedlimit.length < maxhour) {
      return 0;
    } else {
      let smallestTimestamp = Math.min.apply(null, feedlimit);

      let difference = (Date.now() - smallestTimestamp) / 1000 / 60;

      let nextFed = maxtime - difference;

      if (nextFed < 0) {
        // setFeedTipplyLoader("");
        return 0;
      } else {
        // setFeedTipplyLoader(nextFed);
        return Math.round(nextFed);
      }
    }
  };

  return (
    <Grid container>
      <Snackbar
        open={snackbarState}
        message={snackbarMessage}
        handleClose={() => setSnackbarState(false)}
      />
      {foodData.length === 0 ? (
        <p>
          {" "}
          You don't have any food now buy it from{" "}
          <span
            className={classes.marketplaceText}
            onClick={() => {
              props.history.push("/marketplace");
            }}
          >
            {" "}
            Marketplace{" "}
          </span>
        </p>
      ) : (
        foodData.map((food) => {
          return (
            <>
              <Grid item xs={12} sm={6}>
                <Card className={classes.card}>
                  <Grid justify="space-around" container>
                    <Grid item xs={6} sm={4}>
                      <img
                        className={classes.media}
                        src={food.ID.image === " " ? worm : food.ID.image}
                      />
                    </Grid>
                  </Grid>
                  <p className={classes.cardHeading}>{food.ID.name}</p>
                  <CardContent style={{ padding: "16px" }}>
                    <Typography
                      className={classes.cardText}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <p style={{ margin: 6 }}>+{food.ID.energy} Energy unit</p>
                      <p style={{ margin: 6 }}>
                        Max {food.ID.maxhour} per {food.ID.maxTime} min can be
                        fed
                      </p>
                      <p style={{ margin: 6 }}>
                        Available quantity: {food.count}{" "}
                      </p>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                      <Tooltip
                        title={
                          "You will be able to feed your active tipply in  " +
                          feedTimeGenerator(
                            food.feedlimit,
                            food.ID.maxhour,
                            food.ID.maxTime
                          ) +
                          " minutes"
                        }
                      >
                        <Button
                          disabled={useDisable}
                          onClick={() => {
                            feedTipplyHandler(
                              food.ID._id,
                              food.count,
                              food.ID.name,
                              feedTimeGenerator(
                                food.feedlimit,
                                food.ID.maxhour,
                                food.ID.maxTime
                              )
                            );
                          }}
                          className={classes.cardButton}
                          style={{ marginLeft: "auto", marginRight: "auto" }}
                        >
                          {feedTipplyLoader === food.ID._id && !countValue ? (
                            <CircularProgress
                              size={16}
                              className={classes.loaderStyles}
                            />
                          ) : (
                            ""
                          )}
                          Use
                        </Button>
                      </Tooltip>
                    </div>
                  </CardActions>
                </Card>
              </Grid>
            </>
          );
        })
      )}
    </Grid>
  );
};

export default withWidth()(
  connect("", {
    enableFeedAnimation,
    disableFeedAnimation,
    enableFeedSpeech,
    disableFeedSpeech,
  })(Food)
);
