import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { Button, TextField } from "@material-ui/core";
import Snackbar from "../../Components/Common/Snackbar/Snackbar";
import setAuthToken from "../../Utils/setAuthToken";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
  loaderStyles: {
    color: "yellow",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -8,
  },

  OuterClass: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: "16px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "black",
    color: "white",
  },
  inputText: {
    marginTop: "3px",
    display: "flex",
    justifyContent: "center !important",
    alignContent: "center !important",
    alignItems: "center !important",
    color: "white !important",
    "& .MuiInputBase-input": {
      margin: "10px !important",
    },
  },

  borderColor: {
    borderRadius: "8px",
    border: "1px solid",
    outline: "none",
    borderColor: "gray",
    margin: "20px auto",
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
    height: "35px",
    color: "white",
    fontSize: "15px",
  },
  margin: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },
}));
const EnterPin = (props) => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarState, setSnackbarState] = useState(false);
  const [pin, setPin] = useState("");
  const [toggle, setToggle] = useState(false);
  const classes = useStyles();
  const [loaderState, setLoaderState] = useState(false);

  // useEffect(() => {
  //     if (props.access_token_saved === true) {
  //         props.setPinShowState(false);
  //     }
  // }, [props.access_token_saved])

  useEffect(() => {
    // console.log("pin toggle in useeffect", toggle);
    if (toggle) {
      if (props.access_token !== null && props.access_token !== "error") {
        // console.log(props.access_token, "pinnnnnnnnn accesstoken");
        setPin("");
        setLoaderState(false);
        props.PinNull(); //setting pin value back to null
        props.setPinShowState(false); //closing backdrop
        setAuthToken(`Bearer ${props.access_token}`);
        props.AT_PASS(false);
      } else {
        // console.log("pinnnn error ")
        setSnackbarState(true);
        setSnackbarMessage("Incorrect password, try again.");
        setLoaderState(false);
        setPin("");
        // console.log(toggle, "pin toggle in else part ")
        setToggle(!toggle);
      }
    }
    // console.log(toggle, "pinn toggle after if part")
  }, [props.access_token]);

  const pinHandler = (e) => {
    setPin(e.target.value);
  };
  const CheckPin = async () => {
    setLoaderState(true);
    setToggle(true);
    await props.EnterPin(props.address, pin);
    if (props.access_token === "error") {
      setSnackbarState(true);
      setSnackbarMessage("Incorrect password, try again.");
      setLoaderState(false);
      setPin("");
      // console.log(toggle, "pin toggle in else part ")
      setToggle(false);
    }
  };
  return (
    <div>
      <Snackbar
        open={snackbarState}
        message={snackbarMessage}
        handleClose={() => setSnackbarState(false)}
        autoHideDuration={3000}
      />
      <Backdrop className={classes.backdrop} open={props.PinShowState}>
        <div className={classes.OuterClass}>
          <div className={classes.margin}>Enter your password.</div>
          <div className={classes.borderColor}>
            <TextField
              type="password"
              inputProps={{
                maxLength: 12,
              }}
              className={classes.inputText}
              onChange={pinHandler}
              value={pin}
              onKeyPress={(e) => (e.key == "Enter" ? CheckPin() : null)}
              style={{ width: "150px", color: "white" }}
              disabled={loaderState}
            />
          </div>
          <div className={classes.margin}>
            <Button style={{ color: "white" }} onClick={CheckPin}>
              {loaderState === true ? (
                <CircularProgress size={16} className={classes.loaderStyles} />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  access_token: state.pin.access_token,
  access_token_saved: state.pin.access_token_saved,
});

export default connect(mapStateToProps, {})(EnterPin);
