import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import adultTipply from "../../Assets/adult_tipply.png";
import babyTipply from "../../Assets/baby_tipply.png";
import copyicon from "../../Assets/copyicon.svg";
import copyiconWhite from "../../Assets/copyiconWhite.svg";
import "./leader.css";
import { Image } from "cloudinary-react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Grid,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Box,
  AppBar,
  Tooltip,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    color: theme.palette.secondary.other,
    "@media only screen and (max-width:460px)": {
      fontSize: "12px !important",
    },
  },
  header: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "flex-end !important",
    },
  },
  fontClass: {
    fontSize: "14px",
    textTransform: "capitalize",
    // minWidth: 15,
    "@media only screen and (max-width:520px)": {
      fontSize: "12px !important",
    },
    "@media (min-width:520px) and (max-width:960px)": {
      fontSize: "13px !important",
    },
    "@media only screen and (max-width:1100px)": {
      minWidth: 0,
    },
    // "@media only screen and (min-width: 500px)": {},
  },
  cardText: {
    color: theme.palette.text.secondary,
    fontSize: "0.799rem",
  },
  card: {
    margin: "5px 5px 0 5px",
    textAlign: "center",
    backgroundColor: "inherit",
    border: "0.5px solid",
    borderColor: "#B1B1B1",
    borderRadius: "0",
    padding: "5px",
    minHeight: "240px",
    "@media only screen and (min-width:350px)": {
      flexWrap: "nowrap",
    },
  },
  leaderHeading: {
    // margin: "24px auto -3px 30px",
    marginBottom: -36,
    marginTop: 15,
    marginLeft: 50,
    fontWeight: "bold",
    fontSize: "20px",
    "@media only screen and (max-width:690px)": {
      fontSize: 17,
      margin: "20px auto -33px 6px",
    },
  },
  leaderHeading2: {
    marginBottom: -36,
    marginTop: 15,
    marginLeft: 50,
    fontWeight: "bold",
    fontSize: "20px",
    "@media only screen and (max-width:690px)": {
      fontSize: 17,
      margin: "auto",
    },
  },
  Scroll: {
    overflow: "auto",
    width: "100%",
    height: "calc(100vh - 230px)",
    "&::-webkit-scrollbar": {
      width: 0,
    },
    html: {
      scrollbarWidth: "none",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const LeaderBoardTab = (props) => {
  const isMobile = useMediaQuery("(max-width:650px)");
  const isSlim = useMediaQuery("(max-width:620px)");
  const isSmall = useMediaQuery("(max-width:425px)");
  const is490 = useMediaQuery("(max-width:490px)");
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const themeMode = localStorage.getItem("themeMode");

  const copyClick = () => {
    // props.setSnackbarState(true);
    // props.setSnackbarMessage("Copied");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const trim = (address) => {
    let trimedAddress =
      address.slice(0, 5) +
      "...." +
      address.slice(address.length - 5, address.length);
    return trimedAddress;
  };

  // const copyClick = () => {
  //   setSnackbarState(true);
  //   setSnackbarMessage("Copied");
  // };

  return (
    <Grid className={classes.root}>
      <Grid item xs={11} direction="row" justify="center" alignItems="center">
        {isSlim ? (
          <>
            {" "}
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "8px",
              }}
              className={classes.leaderHeading2}
            >
              Leaderboard
            </span>
          </>
        ) : (
          ""
        )}{" "}
        <AppBar position="static" className={classes.header}>
          {isSlim ? (
            ""
          ) : (
            <span className={classes.leaderHeading}>Leaderboard</span>
          )}{" "}
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            // label="Leader Board"
          >
            <Tab
              label={isSmall ? "Paid" : "Paid Tipplys"}
              {...a11yProps(0)}
              className={classes.fontClass}
            />
            <Tab
              label={isSmall ? "Free" : "Free Tipplys"}
              {...a11yProps(1)}
              className={classes.fontClass}
            />
            <Tab
              label={isSmall ? "Minted" : "Minted Tipplys"}
              {...a11yProps(2)}
              className={classes.fontClass}
            />
            <Tab
              label="Hall of Fame"
              {...a11yProps(3)}
              className={classes.fontClass}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} className={classes.Scroll}>
          <Grid
            container
            item
            xl={12}
            spacing={2}
            justify="space-evenly"
            padding="30px 0"
          >
            {props.leadingPaidTipplys.length !== 0
              ? props.leadingPaidTipplys.map((paid, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} xl={3} lg={3}>
                      <Card className={classes.card}>
                        {paid.isMint ? (
                          <Image
                            cloudName="ddoon1hy6"
                            publicId={paid.mintImage}
                            style={{
                              width: is490 ? 120 : 150,
                              height: is490 ? 120 : 150,
                            }}
                          />
                        ) : (
                          <img
                            src={
                              paid.bornAt
                                ? Math.round(
                                    (new Date().getTime() -
                                      new Date(paid.bornAt).getTime()) /
                                      (1000 * 60 * 60 * 24)
                                  ) > 20
                                  ? adultTipply
                                  : babyTipply
                                : babyTipply
                            }
                            style={{
                              width: isMobile ? "100px" : "150px",
                              height: isMobile ? "100px" : "150px",
                            }}
                          />
                        )}
                        <CardContent>
                          <p
                            style={{
                              margin: 0,
                              fontSize: isMobile ? "0.98rem" : 20,
                            }}
                          >
                            {" "}
                            {paid.name}{" "}
                          </p>
                          <Typography
                            className={classes.cardText}
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ padding: "0px !important" }}
                          >
                            <br />
                            {/* <b>Owner_add</b>:{" "} */}
                            {props.leadingPaidTipplysOwner.length === 0
                              ? "No address"
                              : props.leadingPaidTipplysOwner[index] ===
                                "No User"
                              ? props.leadingPaidTipplysOwner[index]
                              : trim(props.leadingPaidTipplysOwner[index])}
                            <CopyToClipboard
                              text={
                                props.leadingPaidTipplysOwner.length === 0
                                  ? "No address"
                                  : props.leadingPaidTipplysOwner[index] ===
                                    "No User"
                                  ? props.leadingPaidTipplysOwner[index]
                                  : props.leadingPaidTipplysOwner[index]
                              }
                              onCopy={copyClick}
                            >
                              <Tooltip title="click here to copy the owner address">
                                <img
                                  src={
                                    themeMode === "dark"
                                      ? copyicon
                                      : copyiconWhite
                                  }
                                  style={{
                                    width: "13px",
                                    marginLeft: "5px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Tooltip>
                            </CopyToClipboard>
                            <br />
                            {paid.bornAt
                              ? Math.round(
                                  (new Date().getTime() -
                                    new Date(paid.bornAt).getTime()) /
                                    (1000 * 60 * 60 * 24)
                                )
                              : 0}{" "}
                            days
                            <br /> {paid.level} level
                            <br />
                            {Math.floor(paid.energy)}% energy <br />
                            {paid.category} <br />
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })
              : "Loading....."}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.Scroll}>
          <Grid
            container
            item
            xl={12}
            spacing={2}
            justify="center"
            padding="30px 0"
          >
            {props.leadingFreeTipplys.length !== 0
              ? props.leadingFreeTipplys.map((free, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} xl={3} lg={3}>
                      <Card className={classes.card}>
                        {free.isMint ? (
                          <Image
                            cloudName="ddoon1hy6"
                            publicId={free.mintImage}
                            style={{
                              width: is490 ? 120 : 150,
                              height: is490 ? 120 : 150,
                            }}
                          />
                        ) : (
                          <img
                            src={
                              free.bornAt
                                ? Math.round(
                                    (new Date().getTime() -
                                      new Date(free.bornAt).getTime()) /
                                      (1000 * 60 * 60 * 24)
                                  ) > 20
                                  ? adultTipply
                                  : babyTipply
                                : babyTipply
                            }
                            style={{
                              width: isMobile ? "100px" : "150px",
                              height: isMobile ? "100px" : "150px",
                            }}
                          />
                        )}
                        <CardContent>
                          <p
                            style={{
                              margin: 0,
                              fontSize: isMobile ? "0.98rem" : 20,
                            }}
                          >
                            {free.name}
                          </p>
                          <Typography
                            className={classes.cardText}
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ padding: "0px !important" }}
                          >
                            <br />
                            {/* <b>Owner_add</b>:{" "} */}
                            {props.leadingFreeTipplysOwner.length === 0
                              ? "No address"
                              : props.leadingFreeTipplysOwner[index] ===
                                "No User"
                              ? props.leadingFreeTipplysOwner[index]
                              : trim(props.leadingFreeTipplysOwner[index])}
                            <CopyToClipboard
                              text={
                                props.leadingFreeTipplysOwner.length === 0
                                  ? "No address"
                                  : props.leadingFreeTipplysOwner[index] ===
                                    "No User"
                                  ? props.leadingFreeTipplysOwner[index]
                                  : props.leadingFreeTipplysOwner[index]
                              }
                              onCopy={copyClick}
                            >
                              <Tooltip title="click here to copy the owner address">
                                <img
                                  src={
                                    themeMode === "dark"
                                      ? copyicon
                                      : copyiconWhite
                                  }
                                  style={{
                                    width: "13px",
                                    marginLeft: "5px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Tooltip>
                            </CopyToClipboard>
                            <br />{" "}
                            {free.bornAt
                              ? Math.round(
                                  (new Date().getTime() -
                                    new Date(free.bornAt).getTime()) /
                                    (1000 * 60 * 60 * 24)
                                )
                              : 0}{" "}
                            days
                            <br /> {free.level} level
                            <br />
                            {free.category} <br />
                            {Math.floor(free.energy)}% energy
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })
              : "Loading....."}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.Scroll}>
          <Grid
            container
            item
            xl={12}
            spacing={2}
            justify="center"
            padding="30px 0"
          >
            {props.minted.length !== 0
              ? props.minted.map((minted, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} xl={3} lg={3}>
                      <Card className={classes.card}>
                        {minted.isMint ? (
                          <Image
                            cloudName="ddoon1hy6"
                            publicId={minted.mintImage}
                            style={{
                              width: is490 ? 120 : 150,
                              height: is490 ? 120 : 150,
                            }}
                          />
                        ) : (
                          <img
                            src={
                              minted.bornAt
                                ? Math.round(
                                    (new Date().getTime() -
                                      new Date(minted.bornAt).getTime()) /
                                      (1000 * 60 * 60 * 24)
                                  ) > 20
                                  ? adultTipply
                                  : babyTipply
                                : babyTipply
                            }
                            style={{
                              width: isMobile ? "100px" : "150px",
                              height: isMobile ? "100px" : "150px",
                            }}
                          />
                        )}
                        <CardContent>
                          <p
                            style={{
                              margin: 0,
                              fontSize: isMobile ? "0.98rem" : 20,
                            }}
                          >
                            {minted.name}
                          </p>
                          <Typography
                            className={classes.cardText}
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ padding: "0px !important" }}
                          >
                            <br />
                            {/* <b>Owner_add</b>:{" "} */}
                            {props.minted_Owner.length === 0
                              ? "No address"
                              : props.minted_Owner[index] === "No User"
                              ? props.minted_Owner[index]
                              : trim(props.minted_Owner[index])}
                            <CopyToClipboard
                              text={
                                props.minted_Owner.length === 0
                                  ? "No address"
                                  : props.minted_Owner[index] === "No User"
                                  ? props.minted_Owner[index]
                                  : props.minted_Owner[index]
                              }
                              onCopy={copyClick}
                            >
                              <Tooltip title="click here to copy the owner address">
                                <img
                                  src={
                                    themeMode === "dark"
                                      ? copyicon
                                      : copyiconWhite
                                  }
                                  style={{
                                    width: "13px",
                                    marginLeft: "5px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Tooltip>
                            </CopyToClipboard>
                            <br />{" "}
                            {minted.bornAt
                              ? Math.round(
                                  (new Date().getTime() -
                                    new Date(minted.bornAt).getTime()) /
                                    (1000 * 60 * 60 * 24)
                                )
                              : 0}{" "}
                            days
                            <br /> {minted.level} level
                            <br />
                            {minted.category} <br />
                            {Math.floor(minted.energy)}% energy
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })
              : "Loading....."}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3} className={classes.Scroll}>
          <Grid
            container
            item
            xl={12}
            spacing={2}
            justify="center"
            padding="30px 0"
          >
            {props.winnerTipplys.length !== 0
              ? props.winnerTipplys.map((winner, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} xl={3} lg={3}>
                      <Card className={classes.card}>
                        {winner.isMint ? (
                          <Image
                            cloudName="ddoon1hy6"
                            publicId={winner.mintImage}
                            style={{
                              width: is490 ? 120 : 150,
                              height: is490 ? 120 : 150,
                            }}
                          />
                        ) : (
                          <img
                            src={
                              winner.bornAt
                                ? Math.round(
                                    (new Date().getTime() -
                                      new Date(winner.bornAt).getTime()) /
                                      (1000 * 60 * 60 * 24)
                                  ) > 20
                                  ? adultTipply
                                  : babyTipply
                                : babyTipply
                            }
                            style={{
                              width: isMobile ? "100px" : "150px",
                              height: isMobile ? "100px" : "150px",
                            }}
                          />
                        )}
                        <CardContent>
                          <p
                            style={{
                              margin: 0,
                              fontSize: isMobile ? "0.98rem" : 20,
                            }}
                          >
                            {winner.name}
                          </p>
                          <Typography
                            className={classes.cardText}
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ padding: "0px !important" }}
                          >
                            <br />
                            {/* <b>Owner_add</b>:{" "} */}
                            {props.Winner_Owner.length === 0
                              ? "No address"
                              : props.Winner_Owner[index] === "No User"
                              ? props.Winner_Owner[index]
                              : trim(props.Winner_Owner[index])}
                            <CopyToClipboard
                              text={
                                props.Winner_Owner.length === 0
                                  ? "No address"
                                  : props.Winner_Owner[index] === "No User"
                                  ? props.Winner_Owner[index]
                                  : props.Winner_Owner[index]
                              }
                              onCopy={copyClick}
                            >
                              <Tooltip title="click here to copy the owner address">
                                <img
                                  src={
                                    themeMode === "dark"
                                      ? copyicon
                                      : copyiconWhite
                                  }
                                  style={{
                                    width: "13px",
                                    marginLeft: "5px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Tooltip>
                            </CopyToClipboard>
                            <br />{" "}
                            {winner.bornAt
                              ? Math.round(
                                  (new Date().getTime() -
                                    new Date(winner.bornAt).getTime()) /
                                    (1000 * 60 * 60 * 24)
                                )
                              : 0}{" "}
                            days
                            <br /> {winner.level} level
                            <br />
                            {winner.category} <br />
                            {Math.floor(winner.energy)}% energy
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })
              : "Loading....."}
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
};
export default LeaderBoardTab;
