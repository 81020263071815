import React from 'react';
export default (dynamicId, props) => (
    <svg id="top" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" viewBox="0 0 219.4 127" {...props}>
        <defs>
            <linearGradient id={dynamicId} x1="565" y1="678.2" x2="487.7" y2="736.22"
                gradientTransform="translate(0 -200)" gradientUnits="userSpaceOnUse">
                <stop class="startTopColor" offset="0" />
                <stop class="stopTopColor" offset="1" />
            </linearGradient>
        </defs>

        <title>top</title>
        <path id="top-inner"
            d="M406.8,504.9,430,559.5l37.4-19.1q-25.2-24.15-60.6-35.5m125.1,29.7,78.2,6.3q-54-61-102.4-84.3l24.2,78m-64.5,5.8,64.6-5.8Q490.3,494,441.3,474.5Z"
            transform="translate(-390.7 -436.9)" fill={`url(#${dynamicId})`} />
        <path id="top-outline"
            d="M426,561.5l.3,2.4.1-2.5-.4.1m126.5-88.4c-22.8-16.9-44.6-28.9-65.3-36.2l25.6,73.9q-39.6-39.6-89.2-52.9l28,62.9q-23.7-20.4-60.9-28.8L426,561.5l.3-.1,3.8-1.9-.1-.1-23.2-54.5q35.4,11.4,60.5,35.5l-26.1-65.8q49.05,19.5,90.7,60.1l-24.2-78.1q48.45,23.25,102.4,84.3c-.2-.5-.5-1.1-.7-1.6s-4-7.4-11.5-20.8S575.3,490,552.5,473.1Z"
            transform="translate(-390.7 -436.9)" />
        <path id="top-shadow" d="M507.09,456l1.43,3.6a395.46,395.46,0,0,1,94.6,80.7h6.4Q555.48,479.05,507.09,456Z"
            transform="translate(-390.7 -436.9)" fill="#fff" />
    </svg>
);