import React from "react";
export default (dynamicId, props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 127.05 94.9"
    {...props}
  >
    <defs>
      <linearGradient
        id={dynamicId}
        x1="578.51"
        y1="1420.55"
        x2="528.95"
        y2="1457.76"
        gradientTransform="translate(-398.5 -1489)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" class="startFinsColor" />
        <stop offset="1" class="stopFinsColor" />
      </linearGradient>
    </defs>
    <title>finsporty</title>
    <g className="finAnimate">
      <path
        id="fins-outline"
        d="M126.3,57c.39-.64.77-1.28,1.22-1.92a60.73,60.73,0,0,1-9.17,3.85L91.08,62.6l-.71-.07H88.9a241.42,241.42,0,0,0,.57,33.37,50.58,50.58,0,0,0,9.88-6.67A132.76,132.76,0,0,0,126.3,57M94,62.6a85.61,85.61,0,0,0,23.42-3.47A352.51,352.51,0,0,1,93.45,86.85c.07-8.72.19-16.81.52-24.25M.48,67.22A57.91,57.91,0,0,0,13.37,67q37.35-5.1,70.84-33.24A384.32,384.32,0,0,1,8.82,61.32c.7-1.35,1.41-2.64,2.12-4,.19-.39.38-.71.57-1v-.06c.58-1.09,1.16-2.19,1.67-3.21C24.22,33,35.57,15.63,47.38,1,29.86,12.36,14.21,34.37.48,67.22Z"
        transform="translate(-0.48 -1)"
      />
      <path
        id="fin-small"
        class="leftFin"
        d="M117.45,59.13A85.61,85.61,0,0,1,94,62.6c-.32,7.38-.45,15.53-.51,24.25A342.11,342.11,0,0,0,117.45,59.13Z"
        transform="translate(-0.48 -1)"
        fill="#003c77"
      />
      <path
        id="fin-large"
        d="M10.87,57.4c-.64,1.28-1.41,2.63-2.12,3.92a392.5,392.5,0,0,0,75.4-27.6,65.92,65.92,0,0,1-7.77-3.4C65.86,24.68,56.17,15,47.25,1Q29.55,22.95,13.12,53.1c-.58,1-1.16,2.12-1.67,3.21v.06A7.08,7.08,0,0,1,10.87,57.4Z"
        transform="translate(-0.48 -1)"
        fill={`url(#${dynamicId})`}
      />
      <path
        id="fins-shadow"
        d="M10.94,57.4c-.71,1.35-1.48,2.63-2.12,3.92A384.53,384.53,0,0,0,84.21,33.66Q44.09,49.83,11.38,56.31A6.72,6.72,0,0,1,10.94,57.4Z"
        transform="translate(-0.48 -1)"
        fill="#fff"
      />
    </g>
  </svg>
);
