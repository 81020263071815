import React from "react";
export default (dynamicId, props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 125.04 95.52"
    {...props}
  >
    <defs>
      <linearGradient
        id={dynamicId}
        x1="581.15"
        y1="-247.05"
        x2="531.87"
        y2="-284.04"
        gradientTransform="matrix(1, 0, 0, -1, -400.57, -327.9)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" class="startFinsColor" />
        <stop offset="1" class="stopFinsColor" />
      </linearGradient>
    </defs>
    <title>finnerdy</title>
    <g className="finAnimate">
      <path
        id="fin-small"
        class="leftFin"
        d="M115.05,33.61,84.79,37.49c-.5,6.78-.69,14.27-.69,22.47Q101.62,46.31,115.05,33.61Z"
        transform="translate(-2.96 -0.48)"
        fill="#001c37"
      />
      <path
        id="fin-outline"
        d="M84.73,37.49l-6.64-.13a163,163,0,0,0,.76,31,69.29,69.29,0,0,0,12.7-6.46A144.9,144.9,0,0,0,128,29.54a100.18,100.18,0,0,1-13,4.07Q101.6,46.3,84.1,59.9c-.07-8.2.19-15.63.63-22.41m-64-37C12.65,12.37,7.28,26.9,4.75,44.14l8.59-1,.64-5h0A285.83,285.83,0,0,1,20.74.48M71.08,42.14A203.92,203.92,0,0,0,91.42,9.52q-15,17.15-31.2,32.94l10.86-.32m-57.8,1.74.06-.71-8.59,1,8.53-.26M3.74,52.47h0A199.4,199.4,0,0,0,3.36,82.7C3.62,87,4,91.48,4.5,96a102.07,102.07,0,0,0,16.68-8.46c2.46-1.55,4.93-3.16,7.33-4.84,4.35-3,8.59-6.27,12.69-9.63A185.79,185.79,0,0,0,69.5,44.2L56.81,45.82q-7.68,7.36-15.54,14.34c-8.72,7.81-17.75,15.3-27,22.54-.95.77-1.89,1.48-2.84,2.19V82.7c0-1,.06-2,.06-3V78.18c0-1.43.07-2.85.07-4.27.19-7.75.56-15.24,1.13-22.54h-.38l-7.45,1ZM78.09,37.36l6.64.13L115,33.61a98.65,98.65,0,0,0,13-4.07"
        transform="translate(-2.96 -0.48)"
      />
      <path
        id="fin-large"
        d="M13,51.31l-.32.06c-.57,7.3-1,14.86-1.14,22.54,0,1.42-.06,2.84-.06,4.27v1.48c0,1-.06,2-.06,3v2.19c.94-.71,1.89-1.48,2.84-2.19,9.22-7.24,18.26-14.73,27-22.54q7.88-7,15.54-14.34L13,51.31M20.61.48A311.53,311.53,0,0,0,14,38.13h0l-.64,5v.71l46.81-1.42Q76.36,26.67,91.36,9.52c-5.37.78-9.1,1.3-11.24,1.49a7.74,7.74,0,0,0-1.9,2.13c.7-1.36,1-2,.7-2C61.29,12.24,41.9,8.68,20.61.48"
        transform="translate(-2.96 -0.48)"
        fill={`url(#${dynamicId})`}
      />
      <path
        id="fin-cut"
        d="M12.65,51.37l.32-.06-.7.06h.38m.69-7.49h-.06l-8.53.26L6.08,49c-.06,0,.76-.06,2.46-.06,3.29-.07,10.87-.65,22.75-1.62,6.31-.38,11.37-.83,15.09-1.22,2-.26,4.36-.52,6.95-.84C61.42,44.4,65.84,44,66.53,44h.32c2.53-.78,4-1.42,4.3-1.81l-10.93.32ZM6.08,49l-1.2,3.36,7.45-1,.7-.06,43.78-5.49L69.5,44.2l.07-.06-3-.19c-.7,0-5.06.45-13.21,1.29-2.59.32-4.92.58-6.94.84-3.73.39-8.78.84-15.1,1.22-11.88,1-19.46,1.55-22.74,1.62A13.24,13.24,0,0,0,6.08,49Z"
        transform="translate(-2.96 -0.48)"
        fill="#ff6c6c"
      />
    </g>
  </svg>
);
