import {
  ERC20_ABI,
  DYT_ADDRESS,
  ENJ_ADDRESS,
  CENTRAL_WALLET,
  CENTRAL_WALLET_ABI,
} from "../Contracts/Address";
import Web3 from "web3";
// import axios from "axios";
// import { ROUTES } from "../Utils/routes";

// export const depositETH = async (
//   amount,
//   context,
//   checkUser,
//   setPageLoaderState,
//   setPageLoaderMessage
// ) => {
//   let txhash;
//   let web3 = new Web3(window.web3.currentProvider);
//   web3.eth
//     .sendTransaction({
//       to: CENTRAL_WALLET,
//       from: ,
//       value: Web3.utils.toWei(amount, "ether"),
//     })
//     .then(async (result) => {
//       setTimeout(() => {
//         setPageLoaderState(false);
//         checkUser(context.account);
//         setPageLoaderMessage("");
//       }, 10000);
//     })
//     .catch((error) => {
//       setPageLoaderState(false);
//       setPageLoaderMessage("");
//     });
// };

//Deposit ETH To central wallet
export const depositETH = async (
  provider,
  amount,
  address,
  checkUser,
  setPageLoaderState,
  setPageLoaderMessage
) => {
  // let txhash;
  let web3 = new Web3(provider);

  let amountWei = web3.utils.toWei(amount.toString());

  let contract = new web3.eth.Contract(CENTRAL_WALLET_ABI, CENTRAL_WALLET);

  // console.log("contract address", CENTRAL_WALLET);
  await contract.methods
    .depositAmount(amountWei, 0)
    .send({ from: address, value: Web3.utils.toWei(amount, "ether") })
    .then(async (result) => {
      // console.log("reseult ==>>", result);
      setTimeout(() => {
        // console.log("10 secs have passed");
        setPageLoaderState(false);
        checkUser(address);
        setPageLoaderMessage("");
      }, 10000);
    })
    .catch((error) => {
      setPageLoaderState(false);
      setPageLoaderMessage("");
    });
};

//Deposit DYT to central wallet
export const depositDYT = async (
  provider,
  amount,
  address,
  checkUser,
  setPageLoaderState,
  setPageLoaderMessage
) => {
  let txhash;
  let web3 = new Web3(provider);

  let amountWei = web3.utils.toWei(amount.toString());

  let contract = new web3.eth.Contract(CENTRAL_WALLET_ABI, CENTRAL_WALLET);

  // console.log("contract address", CENTRAL_WALLET);
  await contract.methods
    .depositAmount(amountWei, 1)
    .send({ from: address })
    .then(async (result) => {
      // console.log("reseult ==>>", result);
      setTimeout(() => {
        // console.log("10 secs have passed");
        setPageLoaderState(false);
        checkUser(address);
        setPageLoaderMessage("");
      }, 10000);
    })
    .catch((error) => {
      setPageLoaderState(false);
      setPageLoaderMessage("");
    });
};

//deposit ENJ to central wallet
export const depositENJ = async (
  provider,
  amount,
  address,
  checkUser,
  setPageLoaderState,
  setPageLoaderMessage
) => {
  let txhash;
  let web3 = new Web3(provider);

  let amountWei = web3.utils.toWei(amount.toString());

  let contract = new web3.eth.Contract(CENTRAL_WALLET_ABI, CENTRAL_WALLET);

  await contract.methods
    .depositAmount(amountWei, 2)
    .send({ from: address })
    .then(async (result) => {
      setTimeout(() => {
        setPageLoaderState(false);
        checkUser(address);
        setPageLoaderMessage("");
      }, 10000);
    })
    .catch((error) => {
      setPageLoaderState(false);
      setPageLoaderMessage("");
    });
};

export const checkAllowanceENJ = async (provider, senderAddress) => {
  let web3 = new Web3(provider);
  // console.log("checking allowance");
  // console.log("mint contracts address", senderAddress);
  let contract = new web3.eth.Contract(ERC20_ABI, ENJ_ADDRESS);
  let value = await contract.methods
    .allowance(senderAddress, CENTRAL_WALLET)
    .call();
  // console.log("mint contracts value", value);
  if (value > 0) {
    // console.log("mint contracts true");
    return true;
  } else {
    // console.log("mint contracts false");
    return false;
  }
};

export const checkAllowanceDYT = async (provider, senderAddress) => {
  let web3 = new Web3(provider);
  // console.log("checking allowance");
  // console.log("mint contracts address", senderAddress);
  let contract = new web3.eth.Contract(ERC20_ABI, DYT_ADDRESS);
  let value = await contract.methods
    .allowance(senderAddress, CENTRAL_WALLET)
    .call();
  // console.log("mint contracts value", value);
  if (value > 0) {
    // console.log("mint contracts true");
    return true;
  } else {
    // console.log("mint contracts false");
    return false;
  }
};

export const giveApprovalToken = async (
  provider,
  setPageLoaderState,
  setPageLoaderMessage,
  SetAllowance,
  senderAddress,
  tokenAddress
) => {
  let web3 = new Web3(provider);
  setPageLoaderState(true);
  setPageLoaderMessage("Approving Please Wait...");
  // console.log("Approving ===>>>", senderAddress, tokenAddress);
  // console.log(web3);
  let contract = new web3.eth.Contract(ERC20_ABI, tokenAddress);

  let maxAllowance = await contract.methods.totalSupply().call();
  let amountWei = maxAllowance;

  // console.log("mint contracts", senderAddress);

  await contract.methods
    .approve(CENTRAL_WALLET, amountWei)
    .send({ from: senderAddress })
    .on("transactionHash", (hash) => {
      // console.log("mint contracts", hash);
    })
    .on("confirmation", async function (confirmationNumber, receipt) {
      if (confirmationNumber === 1) {
        // console.log("mint contracts", receipt);
        SetAllowance();
        setPageLoaderState(false);
        setPageLoaderMessage("");
      }
    })
    .on("error", (error) => {
      // console.log("mint contracts", error);
      setPageLoaderState(false);
      setPageLoaderMessage("");
    });
};
