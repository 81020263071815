import React, { useState, useEffect } from "react";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import RandomTipply from "../RandomTipply/RandomTipply";
import { Button, TextField } from "@material-ui/core";
import { ROUTES } from "../../../Utils/routes";

import LegendBackground from "../../../Assets/tipply_background/legend.jpg";
import StandardBackground from "../../../Assets/tipply_background/standard.jpg";
import RareBackground from "../../../Assets/tipply_background/rare.jpg";
import GodlikeBackground from "../../../Assets/tipply_background/godlike.jpg";
import EpicBackground from "../../../Assets/tipply_background/epic.jpg";
import ExtraordinaryBackground from "../../../Assets/tipply_background/extraordinary.jpg";

import f2pLegendBackground from "../../../Assets/f2pbackground/legendary.jpg";
import f2pStandardBackground from "../../../Assets/f2pbackground/standard.jpg";
import f2pRareBackground from "../../../Assets/f2pbackground/rare.jpg";
import f2pGodlikeBackground from "../../../Assets/f2pbackground/godlike.jpg";
import f2pEpicBackground from "../../../Assets/f2pbackground/epic.jpg";
import f2pExtraordinaryBackground from "../../../Assets/f2pbackground/extraordinary.jpg";

import "./generateTipplyLoader.css";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "black",
  },

  buttonStyles: {
    color: "white !important",
    marginTop: "10px",
  },

  inputText: {
    marginTop: "3px",
    color: "white !important",
  },
  screenshotDiv: {
    margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  confirmStyling: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    paddingBottom: "5px",
    "@media only screen and (max-width:959px)": {
      justifyContent: "center",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "black",
  },

  LegendBackground: {
    backgroundImage: `url(${LegendBackground})`,
    margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  StandardBackground: {
    backgroundImage: `url(${StandardBackground})`,
    margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  RareBackground: {
    backgroundImage: `url(${RareBackground})`,
    margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  GodlikeBackground: {
    backgroundImage: `url(${GodlikeBackground})`,
    margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  EpicBackground: {
    backgroundImage: `url(${EpicBackground})`,
    margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  ExtraordinaryBackground: {
    backgroundImage: `url(${ExtraordinaryBackground})`,
    margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 200px)',
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  //free to play backf=ground classes///

  f2pLegendBackground: {
    backgroundImage: `url(${f2pLegendBackground})`,
    // margin: "auto",
    // height:'calc(50% - 14px)',
    // width:'calc(100% - 600px)',
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pStandardBackground: {
    backgroundImage: `url(${f2pStandardBackground})`,
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pRareBackground: {
    backgroundImage: `url(${f2pRareBackground})`,
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pGodlikeBackground: {
    backgroundImage: `url(${f2pGodlikeBackground})`,
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pEpicBackground: {
    backgroundImage: `url(${f2pEpicBackground})`,
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  f2pExtraordinaryBackground: {
    backgroundImage: `url(${f2pExtraordinaryBackground})`,
    height: 200,
    width: 200,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  //end of f2p backgrounds
}));

export default function SimpleBackdrop(props) {
  const classes = useStyles();
  const [category, setCategory] = useState("Rare Tipply");
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [loaderState, setLoaderState] = useState(false);

  useEffect(() => {
    setName("Tipply#" + Math.floor(Math.random() * 100 + 1));
  }, [props.open]);

  useEffect(() => {
    // console.log(category, "category generate")
    setCategory(props.category);
  }, [props.category]);

  const nameChangeHandler = (e) => {
    setName(e.target.value);
    setValue(e.target.value);
  };

  const confirmHandler = async () => {
    setLoaderState(true);
    let data = {
      userID: props.userID,
      name: name,
      tipplyID: props.id,
    };
    let response = await axios.post(ROUTES.NAME_TIPPLY, data);
    setValue("");
    setLoaderState(false);
    props.close();
  };

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={props.open}
        onClick={props.handleClose}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              position: "inherit",
              padding: "0px 10px",
            }}
          >
            Congratulations, you have generated your new tipply! <br /> Please
            give her a name now.
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              id="myCanvas"
              className={
                category === "Standard Tipply" && props.tipply_type === "paid"
                  ? classes.StandardBackground
                  : category === "Rare Tipply" && props.tipply_type === "paid"
                  ? classes.RareBackground
                  : category === "Epic Tipply" && props.tipply_type === "paid"
                  ? classes.EpicBackground
                  : category === "Legendary Tipply" &&
                    props.tipply_type === "paid"
                  ? classes.LegendBackground
                  : category === "Godlike Tipply" &&
                    props.tipply_type === "paid"
                  ? classes.GodlikeBackground
                  : category === "Extraordinary Tipply" &&
                    props.tipply_type === "paid"
                  ? classes.ExtraordinaryBackground
                  : category === "F2P Standard" && props.tipply_type === "Free"
                  ? classes.f2pStandardBackground
                  : category === "F2P Rare" && props.tipply_type === "Free"
                  ? classes.f2pRareBackground
                  : category === "F2P Epic" && props.tipply_type === "Free"
                  ? classes.f2pEpicBackground
                  : category === "F2P Legendary" && props.tipply_type === "Free"
                  ? classes.f2pLegendBackground
                  : category === "F2P Godlike" && props.tipply_type === "Free"
                  ? classes.f2pGodlikeBackground
                  : category === "F2P Extraordinary" &&
                    props.tipply_type === "Free"
                  ? classes.f2pExtraordinaryBackground
                  : ""
              }
            >
              <RandomTipply
                image={props.image}
                type="loader"
                tipply_type={props.tipply_type}
                realSS={false}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TextField
              onChange={nameChangeHandler}
              label="Name Your Tipply"
              className={classes.inputText}
              value={value}
              onKeyPress={(e) =>
                e.key == "Enter"
                  ? async () => {
                      await confirmHandler();
                      props.getUserTipply(props.address);
                      props.close();
                      setName("");
                    }
                  : null
              }
              style={{ color: "white" }}
              inputProps={{
                maxLength: 12,
              }}
            />
            <Button
              onClick={async () => {
                await confirmHandler();
                props.getUserTipply(props.address);
                props.close();
                setName("");
              }}
              className={classes.buttonStyles}
            >
              {" "}
              {loaderState === true ? (
                <CircularProgress size={16} className={classes.loaderStyles} />
              ) : (
                "Confirm"
              )}
            </Button>
          </div>
        </div>
      </Backdrop>
    </div>
  );
}
